import { createSlice } from '@reduxjs/toolkit';
import { get_planilla, update_planilla, save_planilla, anular_planilla, update_facturacion_agua_planilla } from './planillaThunk';

const planillaSlice = createSlice({
  name: 'planilla',
  initialState: {
    planilla: null,
    planillaSeleccionado: null,
    status: 'idle',
    error: null,
    status_update_planilla: 'idle',
    error_update_planilla: null,
    status_save_planilla: 'idle',
    error_save_planilla: null,
    status_anular_planilla: 'idle',
    error_anular_planilla: null,    
    status_update_facturacion_agua_planilla: 'idle',
    error_update_facturacion_agua_planilla: null,
  },
  reducers: {
    setPlanillaSeleccionado: (state, action) => {
      state.planillaSeleccionado = action.payload;
      
    },
    clearPlanillaSeleccionado(state) {
      state.planillaSeleccionado = null;
      state.planilla = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_planilla.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_planilla.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.planilla = action.payload;

      })
      .addCase(get_planilla.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })
      .addCase(update_planilla.pending, (state) => {
        state.status_update_planilla = 'loading';
      })
      .addCase(update_planilla.fulfilled, (state, action) => {
        state.status_update_planilla = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.planilla = action.payload.planilla;

      })
      .addCase(update_planilla.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_planilla = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_planilla = action.payload || 'Failed to fetch building';
      })      
      .addCase(update_facturacion_agua_planilla.pending, (state) => {
        state.status_update_facturacion_agua_planilla = 'loading';
      })
      .addCase(update_facturacion_agua_planilla.fulfilled, (state, action) => {
        state.status_update_facturacion_agua_planilla = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.planilla = action.payload.planilla;

      })
      .addCase(update_facturacion_agua_planilla.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_facturacion_agua_planilla = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_facturacion_agua_planilla = action.payload || 'Failed to fetch building';
      })
      .addCase(save_planilla.pending, (state) => {
        state.status_save_planilla = 'loading';
      })
      .addCase(save_planilla.fulfilled, (state, action) => {
        state.status_save_planilla = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.planilla = action.payload.planilla;

      })
      .addCase(save_planilla.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_planilla = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_planilla = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_planilla.pending, (state) => {
        state.status_save_planilla = 'loading';
      })
      .addCase(anular_planilla.fulfilled, (state, action) => {
        state.status_anular_planilla = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.planilla = action.payload.planilla;

      })
      .addCase(anular_planilla.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_planilla = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_planilla = action.payload || 'Failed to fetch building';
      })

  },
});

export const { setPlanillaSeleccionado, clearPlanillaSeleccionado } = planillaSlice.actions;
export default planillaSlice.reducer;
