import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { get_encuestas_x_edificio } from './encuestaThunk';
import './MisEncuestasPage.css';
import { get_respuestas_x_usuario } from './respuestaThunk';

const MisEncuestasPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const encuestas = useSelector((state) => state.encuesta.encuestas) || [];
    const usuario = useSelector((state) => state.auth.usuario);
    const status_get_encuestas_x_edificio = useSelector((state) => state.encuesta.status_get_encuestas_x_edificio);
    const edificio = useSelector((state) => state.edificio.edificio);
    const respuestas = useSelector((state) => state.respuesta.respuestas) || [];

    const [filtro, setFiltro] = useState('');

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        if (edificio?.id) {
            dispatch(get_encuestas_x_edificio({ buildingId: edificio.id }));
            dispatch(get_respuestas_x_usuario({ buildingId: edificio.id, codigo_usuario: usuario.id }));
        }
    }, [edificio, usuario, dispatch]);

    const handleFilterChange = (e) => {
        setFiltro(e.target.value.toLowerCase());
    };

    const encuestasFiltrados = encuestas.filter((encuesta) => {
        const filter = filtro.trim();
        if (filter === '') return true;

        const numero_encuesta = encuesta.numero_encuesta?.toLowerCase() || '';
        const titulo = encuesta.titulo?.toLowerCase() || '';
        const fecha_inicio = encuesta.fecha_inicio?.toLowerCase() || '';
        const fecha_fin = encuesta.fecha_fin?.toLowerCase() || '';
        const estado = encuesta.estado?.toLowerCase() || '';

        return (
            numero_encuesta.includes(filter) ||
            titulo.includes(filter) ||
            fecha_inicio.includes(filter) ||
            fecha_fin.includes(filter) ||
            estado.includes(filter)
        );
    });

    const completarEncuesta = (encuesta) => {
        navigate(`/encuesta/complete/${encuesta.id}`);
    };

    const getEstadoRespuesta = (encuesta) => {
        const respuesta = respuestas.find((a) => a.idEncuesta === encuesta.id);
        if (encuesta.estado === 'publicado' && !respuesta) return 'Pendiente';
        if (encuesta.estado === 'Finalizada' && !respuesta) return 'No respondida';
        if (respuesta) return 'Completada';
        return 'Exonerado';
    };

    const getIdRespuesta = (encuesta) => {
        const respuesta = respuestas.find((a) => a.idEncuesta === encuesta.id);
        return respuesta?.id || null;
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Mis Encuestas</span>
                    <div className="card-action">
                        <button className="btn orange" onClick={() => navigate(`/mis-respuestas`)}>
                            <i className="material-icons left">fingerprint</i>Respuestas
                        </button>
                        <button className="btn blue" onClick={() => navigate('/home')}>
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>
                    </div>

                    <h5>Listado</h5>

                    <input
                        type="text"
                        value={filtro}
                        onChange={handleFilterChange}
                        placeholder="Filtrar por cualquier campo"
                    />

                    {status_get_encuestas_x_edificio === 'loading' && <p>Cargando encuestas...</p>}

                    {status_get_encuestas_x_edificio === 'succeeded' && encuestasFiltrados.length > 0 && (
                        <table className="striped responsive-table tabla_mis_encuestas_propietario">
                            <thead>
                                <tr>
                                    <th>Nro.</th>
                                    <th>Titulo</th>
                                    <th>Inicio</th>
                                    <th>Fin</th>
                                    <th>Estado</th>
                                    <th>Respuesta</th>
                                    <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                {encuestasFiltrados.map((encuesta) => {
                                    const estadoRespuesta = getEstadoRespuesta(encuesta);
                                    const idRespuesta = getIdRespuesta(encuesta);
                                    return (
                                        <tr key={encuesta.id}>
                                            <td>{encuesta.numero_encuesta}</td>
                                            <td>
                                                {idRespuesta ? (
                                                    <Link to={`/respuesta/view/${idRespuesta}`}>{encuesta.titulo}</Link>
                                                ) : (
                                                    <span>{encuesta.titulo}</span>
                                                )}
                                            </td>
                                            <td>{encuesta.fecha_inicio}</td>
                                            <td>{encuesta.fecha_fin}</td>
                                            <td>{encuesta.estado}</td>
                                            <td>{estadoRespuesta}</td>
                                            <td>
                                                <button
                                                    className="btn-small blue"
                                                    onClick={() => completarEncuesta(encuesta)}
                                                    disabled={estadoRespuesta === 'Completada'}
                                                >
                                                    <i className="material-icons">fingerprint</i>
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MisEncuestasPage;
