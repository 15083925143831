import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get_activos_x_unidad_facturacion } from './activoThunk';
import './PlanMantenimiento.css';

const PlanMantenimientoPage = () => {
  const dispatch = useDispatch();
  const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
  const activos = useSelector((state) => state.activo.activos);
  const status = useSelector((state) => state.activo.status_get_activos_x_unidad_facturacion);

  const [tabla, setTabla] = useState({});
  const [frecuenciasVisibles, setFrecuenciasVisibles] = useState([]);

  const ordenFrecuencias = [
    'Diario',
    'Semanal',
    'Mensual',
    'Bimensual',
    'Trimestral',
    'Semestral',
    'Anual',
    'A Demanda',
  ];

  useEffect(() => {
    if (unidad_facturacionSeleccionado?.codigo_unidad_facturacion) {
      dispatch(
        get_activos_x_unidad_facturacion({
          buildingId: unidad_facturacionSeleccionado.buildingId,
          codigo_unidad_facturacion: unidad_facturacionSeleccionado.codigo_unidad_facturacion,
        })
      );
    }
  }, [dispatch, unidad_facturacionSeleccionado]);

  useEffect(() => {
    if (status === 'succeeded') {
      const nuevaTabla = {};
      const frecuenciasSet = new Set();

      activos.forEach((activo) => {
        if (!nuevaTabla[activo.nombre_activo]) {
          nuevaTabla[activo.nombre_activo] = [];
        }

        const actividadesOrdenadas = [...activo.actividades_mantenimiento].sort((a, b) => a.orden - b.orden);

        actividadesOrdenadas.forEach(({ actividad, frecuencia_mantenimiento }) => {
          nuevaTabla[activo.nombre_activo].push({ actividad, frecuencia_mantenimiento });
          frecuenciasSet.add(frecuencia_mantenimiento);
        });
      });

      setTabla(nuevaTabla);

      const frecuenciasOrdenadas = ordenFrecuencias.filter((f) => frecuenciasSet.has(f));
      setFrecuenciasVisibles(frecuenciasOrdenadas);
    }
  }, [activos, status]);

  if (status === 'loading') {
    return <div>Cargando plan de mantenimiento...</div>;
  }

  if (status === 'failed') {
    return <div>Error al cargar el plan de mantenimiento.</div>;
  }

  return (
    <div className="plan-mantenimiento-container">
      <h3>Plan de Mantenimiento de su Departamento</h3>
      <div className="plan-mantenimiento-tabla-container">
        <table className="plan-mantenimiento-tabla">
          <thead>
            <tr>
              <th>Activo / Actividad</th>
              {frecuenciasVisibles.map((frecuencia) => (
                <th key={frecuencia}>{frecuencia}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(tabla).map((nombreActivo) => (
              <React.Fragment key={nombreActivo}>
                <tr className="grupo-activo">
                  <td colSpan={frecuenciasVisibles.length + 1} className="nombre-activo">
                    {nombreActivo}
                  </td>
                </tr>
                {tabla[nombreActivo].map(({ actividad, frecuencia_mantenimiento }, index) => (
                  <tr key={`${nombreActivo}-${index}`}>
                    <td className="actividad">{actividad}</td>
                    {frecuenciasVisibles.map((frecuencia) => (
                      <td key={frecuencia}>
                        {frecuencia === frecuencia_mantenimiento ? '✔️' : ''}
                      </td>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlanMantenimientoPage;
