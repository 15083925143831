import { createSlice } from '@reduxjs/toolkit';
import {get_depas_cargados_x_edificio, mover_depas_cargados} from './depas-cargadosThunk';

const depas_cargadosSlice = createSlice({
    name: 'depas_cargados',
    initialState: {
      depas_cargados: [],
      //fetchStatus : 'idle',
      //error: null,
      status: {
        get_depas_cargados_x_edificio: 'idle',
        moveValidDepartments: 'idle',
      },
      error: {
        get_depas_cargados_x_edificio: null,
        moveValidDepartments: null,
      },
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_depas_cargados_x_edificio.pending, (state) => {
              state.status.get_depas_cargados_x_edificio  = 'loading';
          })
          .addCase(get_depas_cargados_x_edificio.fulfilled, (state, action) => {
              state.status.get_depas_cargados_x_edificio  = 'succeeded';
              //console.log("Depas cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.depas_cargados = action.payload;

          })
          .addCase(get_depas_cargados_x_edificio.rejected, (state, action) => {
            //console.log("ejecutando Slice failed rejected ");
              state.status.get_depas_cargados_x_edificio  = 'failed';              
             // console.log("error action.error.message "+action.error.message );
              state.error.get_depas_cargados_x_edificio = action.payload || 'Failed to fetch receipts';
          })
//
        .addCase(mover_depas_cargados.pending, (state) => {
            state.status.mover_depas_cargados  = 'loading';
        })
        .addCase(mover_depas_cargados.fulfilled, (state, action) => {
            state.status.mover_depas_cargados  = 'succeeded';
            //console.log("Depas cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
            //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
            state.depas_cargados = action.payload;

        })
        .addCase(mover_depas_cargados.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
            state.status.mover_depas_cargados  = 'failed';
            //console.log("error action.error.message "+action.error.message );
            state.error.mover_depas_cargados = action.payload || 'Failed to fetch receipts';
        });

    },
  });
    
  export const selectDepa_cargadoById = (state, depa_cargadoId) =>
    state.depas_cargados.depas_cargados.find((depa_cargado) => depa_cargado.id === depa_cargadoId);
  
  export default depas_cargadosSlice.reducer;
