import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetGrupos_x_tipo } from './grupoSlice';


export const update_grupo = createAsyncThunk('grupo/update_grupo', async ({ idGrupo, grupoData }, thunkAPI) => {
  console.log("ejecutando thunk update_grupo con idGrupo: " + idGrupo);

  try {

    const response = await apiClient.put(`/grupos/${idGrupo}`, grupoData);

    const grupo = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetGrupos_x_tipo(grupo.buildingId));// Resetear el estado para refetch de grupos

    return grupo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_grupo = createAsyncThunk('grupo/save_grupo', async (grupoData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/grupos`, grupoData);

    const grupo = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetGrupos_x_tipo(grupo.buildingId));// Resetear el estado para refetch de grupos

    return grupo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_grupo = createAsyncThunk('grupo/anular_grupo', async ({ idGrupo, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_grupo con idGrupo: " + idGrupo);

  try {

    const response = await apiClient.patch(`/grupos/${idGrupo}/anular`, { usuarioAnulacion });

    const grupo = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetGrupos_x_tipo(grupo.buildingId));// Resetear el estado para refetch de grupos

    return grupo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_grupo = createAsyncThunk('grupo/get_grupo', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_grupo con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/grupos/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const grupo = response.data;

    return grupo;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_grupos_x_tipo = createAsyncThunk('grupo/get_grupos_x_tipo', async ({ buildingId, tipo }, thunkAPI) => {
  console.log("ejecutando thunk get_grupos con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/grupos',{
        params: { 
          buildingId, 
          tipo 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const grupos = response.data;
          
      return grupos ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
