import { createSlice } from '@reduxjs/toolkit';
import { get_justificacion, update_justificacion, save_justificacion, anular_justificacion, get_justificaciones_x_edificio, get_justificaciones_x_usuario } from './justificacionThunk';

const justificacionSlice = createSlice({
  name: 'justificacion',
  initialState: {
    justificacion: null,
    justificaciones: [],
    justificacionSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_justificaciones_x_edificio: 'idle',
    error_get_justificaciones_x_edificio: null,    
    status_get_justificaciones_x_usuario: 'idle',
    error_get_justificaciones_x_usuario: null,
    status_update_justificacion: 'idle',
    error_update_justificacion: null,    
    status_save_justificacion: 'idle',
    error_save_justificacion: null,
    status_anular_justificacion: 'idle',
    error_anular_justificacion: null,
  },
  reducers: {
    setJustificacionSeleccionada: (state, action) => {
      state.justificacionSeleccionada = action.payload;
      
    },
    clearJustificacionSeleccionada(state) {
      state.justificacionSeleccionada = null;
      state.justificacion = null;
    },
    resetStateGetJustificaciones_x_usuario:(state) => {   
        state.status_get_justificaciones_x_usuario = 'idle';
        state.error_get_justificaciones_x_usuario = null;           
        },
    },
  extraReducers: (builder) => {
    builder
      .addCase(get_justificacion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_justificacion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.justificacion = action.payload;

      })
      .addCase(get_justificacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_justificacion.pending, (state) => {
        state.status_update_justificacion = 'loading';
      })
      .addCase(update_justificacion.fulfilled, (state, action) => {
        state.status_update_justificacion = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.justificacion = action.payload.justificacion;

      })
      .addCase(update_justificacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_justificacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_justificacion = action.payload || 'Failed to fetch building';
      })
      .addCase(save_justificacion.pending, (state) => {
        state.status_save_justificacion = 'loading';
      })
      .addCase(save_justificacion.fulfilled, (state, action) => {
        state.status_save_justificacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.justificacion = action.payload.justificacion;

      })
      .addCase(save_justificacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_justificacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_justificacion = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_justificacion.pending, (state) => {
        state.status_save_justificacion = 'loading';
      })
      .addCase(anular_justificacion.fulfilled, (state, action) => {
        state.status_anular_justificacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.justificacion = action.payload.justificacion;

      })
      .addCase(anular_justificacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_justificacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_justificacion = action.payload || 'Failed to fetch building';
      })

      
      .addCase(get_justificaciones_x_usuario.pending, (state) => {
        state.status_get_justificaciones_x_usuario = 'loading';
        
      })
      .addCase(get_justificaciones_x_usuario.fulfilled, (state, action) => {
        state.status_get_justificaciones_x_usuario = 'succeeded';
        console.log("JUSTIFICACIONES JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_justificaciones_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.justificaciones = action.payload;

      })
      .addCase(get_justificaciones_x_usuario.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_justificaciones_x_usuario = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_justificaciones_x_usuario = action.payload || 'Failed to fetch building';
        state.justificaciones =[];
      })

      .addCase(get_justificaciones_x_edificio.pending, (state) => {
        state.status_get_justificaciones_x_edificio = 'loading';
        
      })
      .addCase(get_justificaciones_x_edificio.fulfilled, (state, action) => {
        state.status_get_justificaciones_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_justificaciones_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.justificaciones = action.payload;

      })
      .addCase(get_justificaciones_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_justificaciones_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_justificaciones_x_edificio = action.payload || 'Failed to fetch building';
        state.justificaciones =[];
      })
  },
});

export const { setJustificacionSeleccionada, clearJustificacionSeleccionada } = justificacionSlice.actions;
export const selectJustificaciones_emergenciaById = (state, idJustificacion) =>
    state.justificacion.justificaciones.find((justificacion) => justificacion.id === idJustificacion);
export const { resetStateGetJustificaciones_x_usuario } = justificacionSlice.actions;
export default justificacionSlice.reducer;
