import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetPersonas_asistencia_x_depa } from './persona_asistenciaSlice.js';


export const update_persona_asistencia = createAsyncThunk('persona_asistencia/update_persona_asistencia', async ({ idPersona_asistencia, persona_asistenciaData }, thunkAPI) => {
  console.log("ejecutando thunk update_persona_asistencia con idPersona_asistencia: " + idPersona_asistencia);

  try {

    const response = await apiClient.put(`/personas-asistencia/${idPersona_asistencia}`, persona_asistenciaData);

    const persona_asistencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPersonas_asistencia_x_depa(persona_asistencia.buildingId));// Resetear el estado para refetch de persona_asistencias

    return persona_asistencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_persona_asistencia = createAsyncThunk('persona_asistencia/save_persona_asistencia', async (persona_asistenciaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/personas-asistencia`, persona_asistenciaData);

    const persona_asistencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPersonas_asistencia_x_depa(persona_asistencia.buildingId));// Resetear el estado para refetch de persona_asistencias

    return persona_asistencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_persona_asistencia = createAsyncThunk('persona_asistencia/anular_persona_asistencia', async ({ idPersona_asistencia, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_persona_asistencia con idPersona_asistencia: " + idPersona_asistencia);

  try {

    const response = await apiClient.patch(`/personas-asistencia/${idPersona_asistencia}/anular`, { usuarioAnulacion });

    const persona_asistencia = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetPersonas_asistencia_x_depa(persona_asistencia.buildingId, persona_asistencia.codigo_unidad_facturacion));// Resetear el estado para refetch de persona_asistencias

    return persona_asistencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_persona_asistencia = createAsyncThunk('persona_asistencia/get_persona_asistencia', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_persona_asistencia con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/personas-asistencia/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const persona_asistencia = response.data;

    return persona_asistencia;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_personas_asistencia_x_depa = createAsyncThunk('persona_asistencia/get_personas_asistencia_x_depa', async ({ buildingId, codigo_unidad_facturacion }, thunkAPI) => {
  console.log("ejecutando thunk get_persona_asistencias con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/personas-asistencia',{
        params: { 
          buildingId, 
          codigo_unidad_facturacion 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const persona_asistencias = response.data;
          
      return persona_asistencias ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
