import { createSlice } from '@reduxjs/toolkit';
import { get_suministros, get_suministros_x_tipo } from './suministrosThunk';

const suministrosSlice = createSlice({
    name: 'suministros',
    initialState: {
        suministros: [],
        fetchStatus: 'idle',
        error: null,
    },
    reducers: {
        resetStateGetSuministros: () => {
            return {
                fetchStatus: 'idle',
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_suministros.pending, (state) => {
                state.fetchStatus = 'loading';
            })
            .addCase(get_suministros.fulfilled, (state, action) => {
                state.fetchStatus = 'succeeded';
                //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.suministros = action.payload;

            })
            .addCase(get_suministros.rejected, (state, action) => {
                console.log("ejecutando Slice failed rejected ");
                state.fetchStatus = 'failed';
                console.log("error action.error.message " + action.error.message);
                state.error = action.payload || 'Failed to fetch receipts';
            })
            .addCase(get_suministros_x_tipo.pending, (state) => {
                state.fetchStatus = 'loading';
            })
            .addCase(get_suministros_x_tipo.fulfilled, (state, action) => {
                state.fetchStatus = 'succeeded';
                //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.suministros = action.payload;

            })
            .addCase(get_suministros_x_tipo.rejected, (state, action) => {
                console.log("ejecutando Slice failed rejected ");
                state.fetchStatus = 'failed';
                console.log("error action.error.message " + action.error.message);
                state.error = action.payload || 'Failed to fetch receipts';
            });

    },
});

export const selectSuministroById = (state, suministroId) =>
    state.suministros.suministros.find((suministro) => suministro.id === suministroId);

export const { resetStateGetSuministros } = suministrosSlice.actions;

export default suministrosSlice.reducer;
