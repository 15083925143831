// constants.js
export const API_URL = "https://api.example.com";
export const APP_NAME = "My Awesome App";

export const RECIBO_ESTADO_PAGO_Pendiente="PAGO_PENDIENTE";
export const RECIBO_ESTADO_PAGO_PorConfirmar="PAGO_POR_CONFIRMAR";
export const RECIBO_ESTADO_PAGO_Confirmado="PAGO_CONFIRMADO";
export const RECIBO_ESTADO_PAGO_Parcial="PAGADO_PARCIAL";
export const RECIBO_ESTADO_PAGO_Observado="PAGO_OBSERVADO";

export const RESERVA_ESTADO_PAGO_Pendiente="PAGO_PENDIENTE";
export const RESERVA_ESTADO_PAGO_PorConfirmar="PAGO_POR_CONFIRMAR";
export const RESERVA_ESTADO_PAGO_Confirmado="PAGO_CONFIRMADO";
export const RESERVA_ESTADO_PAGO_Parcial="PAGADO_PARCIAL";
export const RESERVA_ESTADO_PAGO_Observado="PAGO_OBSERVADO";

export const RECIBO_ESTADO_DOC_Cargado="CARGADO";
export const RECIBO_ESTADO_DOC_Pendiente="PENDIENTE";
export const RECIBO_ESTADO_DOC_Pagado="PAGADO";
export const RECIBO_ESTADO_DOC_Vencido="VENCIDO";
export const RECIBO_ESTADO_DOC_Observado="OBSERVADO";

export const PAGO_ESTADO_PorConfirmar="PAGO_POR_CONFIRMAR";
export const PAGO_ESTADO_Confirmado="CONCILIADO";
export const PAGO_ESTADO_Observado="PAGO_OBSERVADO";

export const CARGA_REGISTRO_EXITO="Exito";
export const CARGA_REGISTRO_ERROR="Error";

export const PAGINA_PROCESO_INICIO="PROCESO_INICIO";
export const PAGINA_PROCESO_EXITO="PROCESO_EXITO";
export const PAGINA_PROCESO_ERROR="PROCESO_ERROR";
export const PAGINA_PROCESO_GENERAL="PROCESO_GENERAL";  

export const VISTA_LISTA="VISTA_LISTA";
export const VISTA_LISTA_TODOS="VISTA_LISTA_TODOS";
export const VISTA_CONSULTA="VISTA_CONSULTA";
export const VISTA_REGISTRO="VISTA_REGISTRO";
export const VISTA_EDICION="VISTA_EDICION";
export const VISTA_REPROGRAMAR="VISTA_REPROGRAMAR";
export const VISTA_CARGA="VISTA_CARGA";
export const VISTA_CONCILIAR="VISTA_CONCILIAR";

export const EECC_Estado_EnProceso="EnProceso";
export const EECC_Estado_PorConfirmar="PorConfirmar";
export const EECC_Estado_Confirmado="Confirmado";

export const MOV_CTA_ABONO="ABONO";
export const MOV_CTA_CARGO="CARGO";

export const ORIGEN_DATOS_LOCAL="LOCAL";
export const ORIGEN_DATOS_NUBE="NUBE";

export const SEPARADOR_LLAVE="/-@-/";
export const SEPARADOR_DATOS_CSV_DEFECTO=";";

export const USUARIO_PERFIL_Recaudador="Recaudador";
export const USUARIO_PERFIL_Aportante="Aportante";
export const USUARIO_PERFIL_Propietario="PROPIETARIO";
export const USUARIO_PERFIL_JuntaDirectiva="JuntaDirectiva";
export const USUARIO_PERFIL_Conserje="Conserje";
export const USUARIO_PERFIL_Limpieza="Limpieza";
export const USUARIO_PERFIL_Cochera="Cochera";

export const CONCILIACION_ESTADO_DOC_Consultado="CONSULTADO";
export const CONCILIACION_ESTADO_DOC_Procesado="PROCESADO";

export const TEMPLATE_CARGA_RECIBOS_NOMBRE="plantilla_carga_recibos_v102";
export const TEMPLATE_CARGA_APORTANTES_NOMBRE="plantilla_cargar_aportantes_01";

export const ESTADO_Activo="ACTIVO";
export const ESTADO_Anulado="ANULADO";
export const ESTADO_Inactivo="INACTIVO";
export const ESTADO_PorConfirmar="POR_CONFIRMAR";

export const TIPO_UNID_INMOBIL_Departamento="Departamento";
export const TIPO_UNID_INMOBIL_Estacionamiento="Estacionamiento";
export const TIPO_UNID_INMOBIL_Deposito="Deposito";
export const TIPO_UNID_INMOBIL_LocalComercial="Local-Comercial";
export const TIPO_UNID_INMOBIL_EstacionamientoDeposito="Estacionamiento-Deposito";
export const TIPO_UNID_INMOBIL_EstacionamientoComercial="Estacionamiento-Comercial";

export const GRUPO_PROTOCOLO_Emergencia="Emergencia";
export const GRUPO_PROTOCOLO_Seguridad="Seguridad";
export const GRUPO_PROTOCOLO_Acceso="Acceso";
export const GRUPO_PROTOCOLO_Limpieza="Limpieza";

export const GRUPOS_PROTOCOLO = [
    { value: "Emergencia", label: "Emergencia" },
    { value: "Acceso", label: "Acceso" },
    { value: "Seguridad", label: "Seguridad" },
    { value: "Limpieza", label: "Limpieza" },
];

export const GRUPO_MEDICAMENTOS="MEDICAMENTOS";

export const TIPOS_UNIDADES_INMOBILIARIAS = [
    { value: "Departamento", label: "Departamento" },
    { value: "Local-Comercial", label: "Local-Comercial" },
    { value: "Estacionamiento", label: "Estacionamiento" },
    { value: "Deposito", label: "Deposito" },
    { value: "Estacionamiento-Comercial", label: "Estacionamiento-Comercial" },
    { value: "Estacionamiento-Deposito", label: "Estacionamiento-Deposito" }
];


export const EECC_ESTADO_DOC_PorConfirmar="POR_CONFIRMAR";
export const EECC_ESTADO_DOC_Confirmado="CONFIRMADO";
export const EECC_ESTADO_DOC_Observado="OBSERVADO";

export const REGISTRO_EXISTENTE_CODIGO="90";
export const OPERACION_EXITO_CODIGO="00";
export const OPERACION_PENDIENTE_CONFIRMAR_CODIGO="02";
export const CODIGO_ERROR_PASSWORD_VENCIDO="33";

export const TIPO_RESERVA_EXCLUSIVO="EXCLUSIVO";
export const TIPO_RESERVA_COMPARTIDO="COMPARTIDO";
      
export const TIPO_TURNO_DIA="DIA";
export const TIPO_TURNO_RANGO_HORA="RANGO_HORA";

export const TIPO_AREA_COMUN_PARRILLA="PARRILLA";
export const TIPO_AREA_COMUN_GIMNASIO="GIMNASIO";
export const TIPO_AREA_COMUN_SALA_YOGA="SALA_YOGA";
export const TIPO_AREA_COMUN_SUM="SUM";

export const TIPO_DOC_DNI="DNI";
export const TIPO_DOC_CEX="CEX";
export const TIPO_DOC_SD="SD";
export const TIPO_DOC_RUC="RUC";

export const RESERVA_ESTADO_SEPARADO="SEPARADO";
export const RESERVA_ESTADO_RESERVADO="RESERVADO";
export const RESERVA_ESTADO_RECHAZADO="RECHAZADO";
export const RESERVA_ESTADO_PENDIENTE="PENDIENTE_APROBACION";  
export const RESERVA_ESTADO_ANULADO="ANULADO"; 

export const MODALIDAD_COBRO_PREPAGO="PREPAGO";
export const MODALIDAD_COBRO_POSTPAGO="POSTPAGO";

export const TIPO_USUARIO_EMAIL="EMAIL";
export const TIPO_USUARIO_CODIGO="CODIGO";


export const ROL_PERFIL="PERFIL";
export const ROL_MANTENIMIENTO="MANTENIMIENTO";
export const ROL_MARKETPLACE="MARKETPLACE";
export const ROL_PERIODICO="PERIODICO";

export const FORMA_COBRO_CTA_RECAUDADORA="CUENTA_RECAUDADORA";
export const FORMA_COBRO_YAPE="YAPE";

// constants.js
export const TIPOS_CONCEPTO = [
    { value: "ADMINISTRACION", label: "Administración" },
    { value: "CONSUMO_AGUA", label: "Consumo de Agua" },
    { value: "CONSUMO_LUZ", label: "Consumo de Luz" },
    { value: "MANTENIMIENTO", label: "Mantenimiento" },
    { value: "CONTINGENCIA", label: "Contingencia" },
    { value: "GASTOS_FINANCIEROS", label: "Gastos Financieros" },
    { value: "AREA_COMUN", label: "Alquiler de Área Común" },
    { value: "CARGOS", label: "Notas de Cargo" },
    { value: "ABONOS", label: "Notas de Abono" }
];

export const TIPOS_FACTURACION = [
    { value: "CARGO", label: "Cargo" },
    { value: "ABONO", label: "Abono" }
];

export const TIPOS_PLANILLA = [
    { value: "MANTENIMIENTO", label: "Mantenimiento" },
    { value: "EXTRAORDINARIA", label: "Extraordinaria" }
];
export const TIPOS_SUMINISTRO = [
    { value: "AGUA", label: "Agua" },
    { value: "LUZ", label: "Luz" }
];
export const PROVEEDORES_SUMINISTRO = [
    { value: "SEDAPAL", label: "Sedapal" },
    { value: "LUZ_DEL_SUR", label: "Luz del Sur" },
    { value: "ENEL", label: "ENEL" }
];

export const TIPOS_REQUERIMIENTOS = [
    { value: "CONSULTA", label: "Consulta" },
    { value: "SOLICITUD", label: "Solicitud" }
];
export const ESTADOS_PLANILLA = [
    { value: "POR_APROBAR", label: "Por_Aprobar" },
    { value: "APROBADO", label: "Aprobado" },
    { value: "EMITIDO", label: "Emitido" },
    { value: "CANCELADO", label: "Cancelado" }
];

export const ESTADOS_LECTURA_AGUA = [
    { value: "EN_REGISTRO", label: "En_Registro" },
    { value: "COMPLETADO", label: "Completado" },
    { value: "FACTURABLE", label: "Facturable" },
    { value: "FACTURADO", label: "Facturado" },
    { value: "CANCELADO", label: "Cancelado" }
];

export const MONEDAS = [
    { value: "PEN", label: "S/" },
    { value: "USD", label: "$" }
];

export const TIPOS_CUOTA = [
    { value: "COMUN", label: "Comun" },
    { value: "INDIVIDUAL", label: "Individual" }
];

export const CRITERIOS_ASIGNACION_CUOTA = [
    { value: "Porcentaje_participacion", label: "Porcentaje de Participación" },
    { value: "Monto_fijo", label: "Monto Fijo" },
    { value: "Porcentaje_equitativo", label: "Porcentaje Equitativo" },
    { value: "Lectura_contometro", label: "Lectura de Contómetro" }
];

export const FUENTES_DATOS = [
    { value: "INGRESO_MANUAL", label: "Ingreso Manual" },
    { value: "Lectura_contometro", label: "Lectura de Contómetro" }
];

