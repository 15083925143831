import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_suministros} from './suministrosThunk';
import M from 'materialize-css';
import { Link } from 'react-router-dom';

import {formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import {getEstadoPagoButtonClass , getEstadoPagoIcon, downloadPDF} from '../../utils/util';


const MisSuministros = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const suministros = useSelector((state) => state.suministros.suministros);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus  = useSelector((state) => state.suministros.fetchStatus );  
  const edificio = useSelector(state => state.edificio.edificio);  
  const error = useSelector((state) => state.suministros.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis suministros isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("fetchStatus: "+ fetchStatus);
    console.log("fetchStatus " +fetchStatus+" edificio.buildingId: "+ edificio.id);
    if (fetchStatus  === 'idle' && edificio.id) {
      console.log("entro y va a llamar a get_suministros ");
      dispatch(get_suministros(edificio.id));
      
    }


  }, [usuario, navigate, fetchStatus , edificio, dispatch]);

  
  if (!usuario) {
    return null; // Return null while redirecting
  }

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  //const suministrosFiltrados = suministrosFormateados.filter((planilla) => {
  const suministrosFiltrados = Array.isArray(suministros) ? suministros.filter((suministro) => {
        
    const fechaCreacionFormateado = formatDate02(suministro.fechaCreacion);
        
    const filterLowerCase = filtro.toLowerCase();

    return suministro.numero.toLowerCase().includes(filterLowerCase) ||
      suministro.nota.toLowerCase().includes(filterLowerCase) ||
      suministro.tipo_suministro.toLowerCase().includes(filterLowerCase)  ||      
      suministro.proveedor.toLowerCase().includes(filterLowerCase)  ||      
      suministro.estado.toLowerCase().includes(filterLowerCase)  ||
      fechaCreacionFormateado.includes(filterLowerCase)    ||
      suministro.usuarioRegistrador.toLowerCase().includes(filterLowerCase) 
      ;
  })
  : [];
  

  if (fetchStatus  === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus  === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (    
    <div className="container">
      <div className="card">
          <div className="card-content">
          <span className="card-title">Suministros</span>
          <div className="card-action">
          
            <a className="btn orange tooltipped" data-position="top" data-tooltip="Nueva Lectura" onClick={() => navigate('/suministro/create')}>
              <i className="material-icons left">add_box</i>Crear Suministro
            </a>          
            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/home')}>
              <i className="material-icons left">arrow_back</i>
            </a>              
          </div>
            
  
        <h5>Listado</h5>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_mis_suministros_propietario"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_mis_suministros_propietario" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_mis_suministros_propietario">
      <thead>
        <tr>
          <th>Tipo</th>
          <th>Suminitro</th>
          <th>Número</th>
          <th>Proveedor</th>
          <th>Nota</th>
          <th>Estado</th>
          <th>Creación</th>
          <th>Registrador</th>
        </tr>
      </thead>
      <tbody id="table-body-mis-suministros_propietario">                    
        {suministrosFiltrados.map((suministro) => (
          <tr key={suministro.id}>
            <td>{suministro.tipo_suministro}</td>
            <td>{suministro.nombre}</td>
            <td>
                <Link to={`/suministro/view/${suministro.id}`}>
                  {suministro.numero}
                </Link>
            </td>
            <td>{suministro.proveedor}</td>
            <td>{suministro.nota}</td>
            <td>{suministro.estado}</td>
            <td>{formatDate02(suministro.fechaCreacion)}</td>
            <td>{suministro.usuarioRegistrador}</td>
          </tr>
          ))}
      
      </tbody>
    </table>
    </div>   
          </div> 
    </div>
  );
};

export default MisSuministros;