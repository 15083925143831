import { createSlice } from '@reduxjs/toolkit';
import {get_planillas_recibos} from './planillas_recibosThunk';

const planillas_recibosSlice = createSlice({
    name: 'planillas_recibos',
    initialState: {
      planillas_recibos: [],
      fetchStatus : 'idle',
      error: null,
    },
    reducers: {
      resetStateGetPlanillas: () => {
        return {
          fetchStatus : 'idle',
        };
      },
    },
    extraReducers: (builder) => {
      builder
          .addCase(get_planillas_recibos.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_planillas_recibos.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.planillas_recibos = action.payload;

          })
          .addCase(get_planillas_recibos.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';              
              console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch receipts';
          });
    },
  });
    
  export const selectPlanilla_recibosById = (state, planilla_recibosId) =>
    state.planillas_recibos.planillas_recibos.find((planilla_recibos) => planilla_recibos.id === planilla_recibosId);
  
  export const { resetStateGetPlanillas } = planillas_recibosSlice.actions;

  export default planillas_recibosSlice.reducer;
