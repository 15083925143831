import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_medicamentos_x_edificio } from './medicamentoThunk';
import { useNavigate } from 'react-router-dom';
import './MedicamentosPage.css';

const MedicamentosPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const edificio = useSelector((state) => state.edificio.edificio);
    const buildingId = edificio?.id;
    const medicamentos = useSelector((state) => state.medicamento.medicamentos) || [];

    useEffect(() => {
        if (buildingId) {
            dispatch(get_medicamentos_x_edificio(buildingId));
        }
    }, [dispatch, buildingId]);

    return (
        <div className="medicamentos-page-container">
            <h3 className="center-align">Medicamentos de Primera Necesidad</h3>
            <button className="btn blue return-btn" onClick={() => navigate(-1)}>
                <i className="material-icons left">arrow_back</i> Volver
            </button>
            <div className="medicamentos-grid">
                {medicamentos.length > 0 ? (
                    medicamentos.map((medicamento) => (
                        <div key={medicamento.id} className="medicamento-card">
                            <h5>{medicamento.nombre}</h5>
                            <p><strong>Descripción:</strong> {medicamento.descripcion}</p>
                            <p><strong>Stock:</strong> {medicamento.stock}</p>
                            <p><strong>Grupo:</strong> {medicamento.grupo}</p>
                            {medicamento.archivos.length > 0 && (
                                <div className="medicamento-archivos">
                                    <strong>Archivos:</strong>
                                    {medicamento.archivos.map((fileUrl, index) => (
                                        <a
                                            key={index}
                                            href={fileUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="archivo-link"
                                        >
                                            Ver Archivo {index + 1}
                                        </a>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <p className="center-align">No hay medicamentos registrados.</p>
                )}
            </div>
        </div>
    );
};

export default MedicamentosPage;
