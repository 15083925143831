import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_depas_cargados_x_edificio, mover_depas_cargados} from './depas-cargadosThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Depas_cargados = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  //const depas_cargados = useSelector((state) => state.depas_cargados.depas_cargados);
  const { depas_cargados, status, error } = useSelector((state) => state.depas_cargados);
  const usuario = useSelector((state) => state.auth.usuario);
  //const fetchStatus  = useSelector((state) => state.depas_cargados.fetchStatus );    
  //const error = useSelector((state) => state.depas_cargados.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("status.get_depas_cargados_x_edificio: "+ status.get_depas_cargados_x_edificio);
    //console.log("codigo_edificio: "+ codigo_edificio);
    if (status.get_depas_cargados_x_edificio  === 'idle' && codigo_edificio) {
      dispatch(get_depas_cargados_x_edificio(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, status.get_depas_cargados_x_edificio , codigo_edificio, dispatch]);

  const handleRefresh = () => {
    dispatch(get_depas_cargados_x_edificio(codigo_edificio));
  };

  const formatearDepas = (depas) => {
    if (!Array.isArray(depas)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return depas.map(depa => ({
      ...depa,
      fecha_carga_formato01: formatDate02(depa.timestamp_carga),
    }));
  };

  const depasFormateados = formatearDepas(depas_cargados);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const depasFiltrados = Array.isArray(depasFormateados) ? depasFormateados.filter((depa) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return depa.tipo.includes(filterLowerCase) ||
        depa.numero.toLowerCase().includes(filterLowerCase) ||
        depa.nivel.toString().toLowerCase().includes(filterLowerCase) ||
        depa.tipo_nivel.toString().toLowerCase().includes(filterLowerCase) ||
        depa.area_ocupada.toString().toLowerCase().includes(filterLowerCase) ||
        depa.area_techada.toString().toLowerCase().includes(filterLowerCase) ||
        depa.fecha_carga_formato01.toString().includes(filterLowerCase)  ||
        depa.mensaje_error_carga.toString().includes(filterLowerCase) ||
        depa.validacion_carga.includes(filterLowerCase)
      ;
  })
  : [];

  const handleMoveValidDepartments = async () => {
    let bodyRequest={
        buildingId: codigo_edificio,
        usuario_proceso: usuario.id
    };
    dispatch(mover_depas_cargados(bodyRequest));    
    //dispatch(fetchDepartments()); // Actualiza la lista después de mover los departamentos válidos
    navigate('/departamentos');
  };
  

  if (status.get_depas_cargados_x_edificio  === 'loading') {
    return <div>Loading...</div>;
  }

  if (status.get_depas_cargados_x_edificio  === 'failed') {
    //return <div>Error: {error.get_depas_cargados_x_edificio}</div>;
    return <div>Error: {error.get_depas_cargados_x_edificio.error} </div>;
  }

  return (    
    <div className="container">
        <h5>Depas cargados</h5>
        <button className="btn" onClick={handleRefresh}>
        Refresh List
        </button>
        <button className="btn" onClick={handleMoveValidDepartments}>
        Registrar
        </button>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_depas_cargados"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_depas_cargados" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_depas_cargados">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>Tipo</th>
          <th>Depa.</th>
          <th>Nivel</th>
          <th>Tip_niv</th>          
          <th>Area_ocupada</th>
          <th>Area_techada</th>                    
          <th>Validacion</th>
          <th>Mensaje</th>
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Fecha_carga</th>
        </tr>
      </thead>
      <tbody id="table-body-depas-cargados">                    
        {depasFiltrados.map((depa) => (
          <tr key={depa.id}>
            <td>{depa.codigo_edificio}</td>
            <td>{depa.tipo}</td>
            <td>{depa.numero}</td>
            <td>{depa.nivel}</td>
            <td>{depa.tipo_nivel}</td>            
            <td>{depa.area_ocupada}</td>
            <td>{depa.area_techada}</td>
            <td>{depa.validacion_carga}</td>
            <td>{depa.mensaje_error_carga}</td>
            <td>{depa.usuario_carga}</td>
            <td>{depa.uid_carga}</td>
            <td>{depa.fecha_carga_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Depas_cargados;
