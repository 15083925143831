import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetPlantillas_activo_x_edificio } from './plantilla_activoSlice';


export const update_plantilla_activo = createAsyncThunk('plantilla_activo/update_plantilla_activo', async ({ idActivo, plantilla_activoData }, thunkAPI) => {
  console.log("ejecutando thunk update_plantilla_activo con idActivo: " + idActivo);

  try {

    const response = await apiClient.put(`/plantillas-activo/${idActivo}`, plantilla_activoData);

    const plantilla_activo = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPlantillas_activo_x_edificio(plantilla_activo.buildingId));// Resetear el estado para refetch de plantillas_activo

    return plantilla_activo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_plantilla_activo = createAsyncThunk('plantilla_activo/save_plantilla_activo', async (plantilla_activoData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/plantillas-activo`, plantilla_activoData);

    const plantilla_activo = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPlantillas_activo_x_edificio(plantilla_activo.buildingId));// Resetear el estado para refetch de plantillas_activo

    return plantilla_activo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_plantilla_activo = createAsyncThunk('plantilla_activo/anular_plantilla_activo', async ({ idActivo, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_plantilla_activo con idActivo: " + idActivo);

  try {

    const response = await apiClient.patch(`/plantillas-activo/${idActivo}/anular`, { usuarioAnulacion });

    const plantilla_activo = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPlantillas_activo_x_edificio(plantilla_activo.buildingId));// Resetear el estado para refetch de plantillas_activo

    return plantilla_activo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_plantilla_activo = createAsyncThunk('plantilla_activo/get_plantilla_activo', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_plantilla_activo con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/plantillas-activo/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const plantilla_activo = response.data;

    return plantilla_activo;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_plantillas_activo_x_edificio = createAsyncThunk('plantilla_activo/get_plantillas_activo_x_edificio', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_plantillas_activo con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/plantillas-activo',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const plantillas_activo = response.data;
          
      return plantillas_activo ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
