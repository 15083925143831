import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { selectConcept } from './conceptos_facturacionSlice';
import { get_conceptos_facturacion } from './conceptos_facturacionThunk';
import './SelectConceptsModal.css'; // Asegúrate de importar el archivo CSS

const SelectConceptsModal = ({ isOpen, onRequestClose }) => {
  const dispatch = useDispatch();
  //const { conceptos } = useSelector((state) => state.concepts);
  const conceptos_facturacion = useSelector((state) => state.conceptos_facturacion.conceptos_facturacion);
  const edificio = useSelector(state => state.edificio.edificio);

  const handleSelectConcept = (concept) => {
    // Crear el nuevo concepto_planilla
    const nuevoConceptoPlanilla = {
      nro_linea: Date.now(), // O genera un número correlativo adecuado
      idconcepto: concept.id,
      tipo_concepto: concept.tipo_concepto,
      titulo: concept.titulo,
      tipo_facturacion: concept.tipo_facturacion,
      criterio_asignacion_cuota: concept.criterio_asignacion_cuota, // O un valor predeterminado
      fuente_datos: concept.fuente_datos,
      moneda: concept.moneda,
      importe: concept.importe_defecto, // Se asume que este campo existe
      nro_suministro: concept.nro_suministro || ''
    };

    dispatch(selectConcept(nuevoConceptoPlanilla));
    onRequestClose(); // Cierra el modal
  };

  // Fetch concepts when the modal opens
  React.useEffect(() => {
    if (isOpen) {
      dispatch(get_conceptos_facturacion(edificio.id));
    }
  }, [get_conceptos_facturacion, edificio, isOpen, dispatch]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Seleccionar Conceptos"
      ariaHideApp={false}
      className="custom-modal" // Agrega la clase personalizada
      overlayClassName="custom-overlay" // Agrega la clase para el overlay
    >
      <h5>Seleccionar Conceptos de Facturación</h5>
      <button onClick={onRequestClose}>Cerrar</button>
      <table className="highlight">
        <thead>
          <tr>
            <th>Concepto</th>
            <th>Tipo</th>
            <th>Seleccionar</th>
          </tr>
        </thead>
        <tbody>
          {conceptos_facturacion.length > 0 ? (
            conceptos_facturacion.map((concept, index) => (
              <tr key={index}>
                <td>{concept.nombre}</td>
                <td>{concept.tipo_facturacion}</td>
                <td>
                  <button
                    className="btn waves-effect waves-light"
                    onClick={() => handleSelectConcept(concept)}
                  >
                    Seleccionar
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="2">No se encontraron conceptos.</td>
            </tr>
          )}
        </tbody>
      </table>

    </Modal>
  );
};

export default SelectConceptsModal;

