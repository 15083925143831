import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

  export const get_estacionamientos_x_edificio = createAsyncThunk('estacionamiento/get_estacionamientos_x_edificio', async (codigo_edificio, thunkAPI) => {    
    
    try { 
        console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/estacionamientos`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const estacionamientos = response.data;
            
        return estacionamientos ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  
