import { createSlice } from '@reduxjs/toolkit';
import { get_zona_segura, update_zona_segura, save_zona_segura, anular_zona_segura, get_zonas_seguras_x_edificio, get_zonas_seguras_x_depa } from './zona_seguraThunk';

const zona_seguraSlice = createSlice({
  name: 'zona_segura',
  initialState: {
    zona_segura: null,
    zonas_seguras: [],
    zona_seguraSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_zonas_seguras_x_edificio: 'idle',
    error_get_zonas_seguras_x_edificio: null,
    status_get_zonas_seguras_x_depa: 'idle',
    error_get_zonas_seguras_x_depa: null,    
    status_update_zona_segura: 'idle',
    error_update_zona_segura: null,    
    status_save_zona_segura: 'idle',
    error_save_zona_segura: null,
    status_anular_zona_segura: 'idle',
    error_anular_zona_segura: null,
  },
  reducers: {
    setReservaSeleccionada: (state, action) => {
      state.zona_seguraSeleccionada = action.payload;
      
    },
    clearReservaSeleccionada(state) {
      state.zona_seguraSeleccionada = null;
      state.zona_segura = null;
    },
    resetStateGetZonas_seguras_x_edificio: () => {
        return {
            status_get_zonas_seguras_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_zona_segura.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_zona_segura.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.zona_segura = action.payload;

      })
      .addCase(get_zona_segura.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_zona_segura.pending, (state) => {
        state.status_update_zona_segura = 'loading';
      })
      .addCase(update_zona_segura.fulfilled, (state, action) => {
        state.status_update_zona_segura = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.zona_segura = action.payload.zona_segura;

      })
      .addCase(update_zona_segura.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_zona_segura = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_zona_segura = action.payload || 'Failed to fetch building';
      })
      .addCase(save_zona_segura.pending, (state) => {
        state.status_save_zona_segura = 'loading';
      })
      .addCase(save_zona_segura.fulfilled, (state, action) => {
        state.status_save_zona_segura = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.zona_segura = action.payload.zona_segura;

      })
      .addCase(save_zona_segura.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_zona_segura = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_zona_segura = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_zona_segura.pending, (state) => {
        state.status_save_zona_segura = 'loading';
      })
      .addCase(anular_zona_segura.fulfilled, (state, action) => {
        state.status_anular_zona_segura = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.zona_segura = action.payload.zona_segura;

      })
      .addCase(anular_zona_segura.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_zona_segura = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_zona_segura = action.payload || 'Failed to fetch building';
      })
      .addCase(get_zonas_seguras_x_depa.pending, (state) => {
        state.status_get_zonas_seguras_x_depa = 'loading';
        
      })
      .addCase(get_zonas_seguras_x_depa.fulfilled, (state, action) => {
        state.status_get_zonas_seguras_x_depa = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.zonas_seguras = action.payload;

      })
      .addCase(get_zonas_seguras_x_depa.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_zonas_seguras_x_depa = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_zonas_seguras_x_depa = action.payload || 'Failed to fetch building';
        state.zonas_seguras =[];
      })      
      .addCase(get_zonas_seguras_x_edificio.pending, (state) => {
        state.status_get_zonas_seguras_x_edificio = 'loading';
        
      })
      .addCase(get_zonas_seguras_x_edificio.fulfilled, (state, action) => {
        state.status_get_zonas_seguras_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.zonas_seguras = action.payload;

      })
      .addCase(get_zonas_seguras_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_zonas_seguras_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_zonas_seguras_x_edificio = action.payload || 'Failed to fetch building';
        state.zonas_seguras =[];
      })            
  },
});

export const { setReservaSeleccionada, clearReservaSeleccionada } = zona_seguraSlice.actions;
export const selectZonas_segurasById = (state, idReserva) =>
    state.zona_segura.zonas_seguras.find((zona_segura) => zona_segura.id === idReserva);
export const { resetStateGetZonas_seguras_x_edificio } = zona_seguraSlice.actions;
export default zona_seguraSlice.reducer;
