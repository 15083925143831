import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_conceptos_facturacion} from './conceptos_facturacionThunk';
import M from 'materialize-css';
import { Link } from 'react-router-dom';

import {formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import {getEstadoPagoButtonClass , getEstadoPagoIcon, downloadPDF} from '../../utils/util';


const MisConceptos_facturacion = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const conceptos_facturacion = useSelector((state) => state.conceptos_facturacion.conceptos_facturacion);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus  = useSelector((state) => state.conceptos_facturacion.fetchStatus );  
  const edificio = useSelector(state => state.edificio.edificio);  
  const error = useSelector((state) => state.conceptos_facturacion.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis conceptos_facturacion isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("fetchStatus: "+ fetchStatus);
    //console.log("fetchStatus " +fetchStatus+" edificio.buildingId: "+ edificio.id);
    if (fetchStatus  === 'idle' && edificio.id) {
      console.log("entro y va a llamar a get_conceptos_facturacion ");
      dispatch(get_conceptos_facturacion(edificio.id));
      
    }


  }, [get_conceptos_facturacion , edificio, dispatch]);

  
  if (!usuario) {
    return null; // Return null while redirecting
  }

/*
  const formatearRecibos = (conceptos_facturacion) => {
    return conceptos_facturacion.map(planilla_recibos => ({
      ...planilla_recibos,
      fecha_emision_formato01: formatDate(planilla_recibos.fecha_emision),
      fecha_corte_formato01: formatDate(planilla_recibos.fecha_corte),
      periodo: formatPeriod(planilla_recibos.fecha_emision),
      importe_recibo_formato01: formatCurrency(planilla_recibos.importe_recibo),
    }));
  };
*/

  //const conceptos_facturacionFormateados = formatearRecibos(conceptos_facturacion);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  //const conceptos_facturacionFiltrados = conceptos_facturacionFormateados.filter((planilla) => {

  const conceptos_facturacionFiltrados = Array.isArray(conceptos_facturacion) 
  ? conceptos_facturacion.filter((concepto) => {
      const filterLowerCase = filtro.toLowerCase();
      return concepto.nombre.toLowerCase().includes(filterLowerCase) ||
        concepto.titulo.toLowerCase().includes(filterLowerCase) ||
        concepto.tipo_concepto.toLowerCase().includes(filterLowerCase) ||
        concepto.tipo_facturacion.toLowerCase().includes(filterLowerCase) ||
        concepto.tipo_cuota.toLowerCase().includes(filterLowerCase) ||
        concepto.criterio_asignacion_cuota.toLowerCase().includes(filterLowerCase) ||
        concepto.fuente_datos.toLowerCase().includes(filterLowerCase) ||
        concepto.estado.includes(filterLowerCase) ||
        concepto.usuarioRegistrador.includes(filterLowerCase);
    })
  : [];

  /*
  const conceptos_facturacionFiltrados = conceptos_facturacion.filter((concepto) => {
        
    const filterLowerCase = filtro.toLowerCase();

    return concepto.nombre.toLowerCase().includes(filterLowerCase) ||
        concepto.titulo.toLowerCase().includes(filterLowerCase) ||
        concepto.tipo_concepto.toLowerCase().includes(filterLowerCase) ||
        concepto.tipo_facturacion.toLowerCase().includes(filterLowerCase) ||
        concepto.tipo_cuota.toLowerCase().includes(filterLowerCase) ||
        concepto.criterio_asignacion_cuota.toLowerCase().includes(filterLowerCase) ||
        concepto.fuente_datos.toLowerCase().includes(filterLowerCase) ||
        concepto.estado.includes(filterLowerCase)  ||
        concepto.usuarioRegistrador.includes(filterLowerCase) 
      ;
  });
  */

  if (fetchStatus  === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus  === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (    
    <div className="container">
      <div className="card">
          <div className="card-content">
          <span className="card-title">Conceptos de Facturación</span>
          <div className="card-action">
          
            <a className="btn orange tooltipped" data-position="top" data-tooltip="Nuevo Concepto" onClick={() => navigate(`/concepto/create`)}>
              <i className="material-icons left">add_box</i>Crear Concepto
            </a>
            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/planillas-recibos')}>
              <i className="material-icons left">arrow_back</i>
            </a>              
          </div>
            
  
        <h5>Listado</h5>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_mis_conceptos_facturacion_propietario"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_mis_conceptos_facturacion_propietario" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_mis_conceptos_facturacion_propietario">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>Concepto</th>
          <th>Titulo</th>
          <th>Tipo</th>
          <th>Facturacion</th>
          <th>Cuota</th>
          <th>Criterio</th>
          <th>Fuente</th>
          <th>Estado</th>
          <th>Registrador</th>
        </tr>
      </thead>
      <tbody id="table-body-mis-conceptos_facturacion_propietario">                    
        {conceptos_facturacionFiltrados.map((concepto_facturacion) => (
          <tr key={concepto_facturacion.id}>
            <td>{concepto_facturacion.codigo_edificio}</td>
            <td>
                <Link to={`/concepto/view/${concepto_facturacion.id}`}>
                  {concepto_facturacion.nombre}
                </Link>
            </td>
            <td>{concepto_facturacion.titulo}</td>
            <td>{concepto_facturacion.tipo_concepto}</td>
            <td>{concepto_facturacion.tipo_facturacion}</td>
            <td>{concepto_facturacion.tipo_cuota}</td>
            <td>{concepto_facturacion.criterio_asignacion_cuota}</td>
            <td>{concepto_facturacion.fuente_datos}</td>            
            <td>{concepto_facturacion.estado}</td>
            <td>{concepto_facturacion.usuarioRegistrador}</td>

          </tr>
          ))}
      
      </tbody>
    </table>
    </div>   
          </div> 
    </div>
  );
};

export default MisConceptos_facturacion;