import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_protocolos_x_edificio, save_protocolo, update_protocolo } from './protocoloThunk';
import { getStorage, ref, uploadBytes, getDownloadURL, connectStorageEmulator } from 'firebase/storage'; 
import { GRUPOS_PROTOCOLO } from '../../constants/constants'; 
import M from 'materialize-css/dist/js/materialize.min.js'; // Importar Materialize JS
import './AdminProtocolos.css';

const AdminProtocolos = () => {
    const dispatch = useDispatch();
    const codigo_usuario = useSelector((state) => state.auth.usuario.id);
    const edificio = useSelector(state => state.edificio.edificio);
    const buildingId = edificio?.id;
    const protocolos = useSelector((state) => state.protocolo.protocolos) || [];
    const [selectedProtocolo, setSelectedProtocolo] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileUploads, setFileUploads] = useState([]);

    useEffect(() => {
        if (buildingId) {
            dispatch(get_protocolos_x_edificio({ buildingId }));
        }
    }, [dispatch, buildingId]);

    const handleOpenModal = (protocolo = { titulo: '', descripcion: '', grupo: GRUPOS_PROTOCOLO[0].value, archivos: [] }) => {
        setSelectedProtocolo(protocolo);
        setFileUploads([]); 
        setIsModalOpen(true);

        // Inicializar el select después de abrir el modal
        setTimeout(() => {
            M.FormSelect.init(document.querySelectorAll('select'));
        }, 100);
    };

    const handleCloseModal = () => {
        setSelectedProtocolo(null);
        setIsModalOpen(false);
    };

    const uploadFiles = async () => {
        const storage = getStorage();
        const uploadPromises = fileUploads.map(async (file) => {
            if (window.location.hostname === 'localhost') {
                connectStorageEmulator(storage, 'localhost', 9199);
            }

            const storageRefPath = `cargaProtocolos/${selectedProtocolo.id || 'new'}/${file.name}_${Date.now()}`;
            const fileRef = ref(storage, storageRefPath);

            const snapshot = await uploadBytes(fileRef, file);
            return await getDownloadURL(fileRef);
        });

        return Promise.all(uploadPromises);
    };

    const handleSave = async () => {
        try {
            const archivosSubidos = await uploadFiles();
            const dataToSave = {
                ...selectedProtocolo,
                archivos: [...(selectedProtocolo.archivos || []), ...archivosSubidos],
                buildingId,
                usuarioRegistrador: codigo_usuario,
            };

            if (selectedProtocolo.id) {
                const idProtocolo = selectedProtocolo.id;
                await dispatch(update_protocolo({ idProtocolo, protocoloData: dataToSave })).unwrap();
            } else {
                await dispatch(save_protocolo(dataToSave)).unwrap();
            }

            dispatch(get_protocolos_x_edificio({ buildingId })); 
        } catch (error) {
            console.error("Error al guardar el protocolo:", error);
        } finally {
            handleCloseModal();
        }
    };

    const handleFileChange = (event) => {
        setFileUploads(Array.from(event.target.files));
    };

    const handleRemoveFile = (fileUrl) => {
        const updatedArchivos = selectedProtocolo.archivos.filter((file) => file !== fileUrl);
        setSelectedProtocolo({ ...selectedProtocolo, archivos: updatedArchivos });
    };

    return (
        <div className="admin-protocolos-container">
            <h3 className="center-align">Gestión de Protocolos de Emergencia</h3>
            <button onClick={() => handleOpenModal()} className="btn blue">
                Crear Nuevo Protocolo
            </button>
            <div className="protocolos-grid">
                {protocolos.map((protocolo, index) => (
                    <div key={index} className="protocolo-card">
                        <h5>{protocolo.titulo}</h5>
                        <p>{protocolo.descripcion}</p>
                        <p><strong>Grupo:</strong> {protocolo.grupo}</p>
                        <button onClick={() => handleOpenModal(protocolo)} className="btn yellow">
                            Editar
                        </button>
                    </div>
                ))}
            </div>

            {isModalOpen && (
                <div className="custom-modal">
                    <div className="modal-content">
                        <h4>{selectedProtocolo?.id ? 'Editar' : 'Crear'} Protocolo</h4>
                        <form>
                            <div className="input-field">
                                <input
                                    type="text"
                                    value={selectedProtocolo?.titulo || ''}
                                    onChange={(e) => setSelectedProtocolo({ ...selectedProtocolo, titulo: e.target.value })}
                                    placeholder="Título"
                                />
                            </div>
                            <div className="input-field">
                                <textarea
                                    value={selectedProtocolo?.descripcion || ''}
                                    onChange={(e) => setSelectedProtocolo({ ...selectedProtocolo, descripcion: e.target.value })}
                                    placeholder="Descripción"
                                ></textarea>
                            </div>
                            <div className="input-field">
                                <select
                                    value={selectedProtocolo?.grupo || GRUPOS_PROTOCOLO[0].value}
                                    onChange={(e) => setSelectedProtocolo({ ...selectedProtocolo, grupo: e.target.value })}
                                >
                                    {GRUPOS_PROTOCOLO.map((grupo) => (
                                        <option key={grupo.value} value={grupo.value}>
                                            {grupo.label}
                                        </option>
                                    ))}
                                </select>
                                <label>Grupo</label>
                            </div>
                            <div className="file-field input-field">
                                <div className="btn">
                                    <span>Subir Archivos</span>
                                    <input type="file" multiple onChange={handleFileChange} />
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="Archivos seleccionados" />
                                </div>
                            </div>
                            <div className="file-list">
                                {selectedProtocolo?.archivos.map((fileUrl, index) => (
                                    <div key={index} className="file-item">
                                        <a href={fileUrl} target="_blank" rel="noopener noreferrer" className="file-link">
                                            Archivo {index + 1}
                                        </a>
                                        <button
                                            type="button"
                                            className="btn red btn-small remove-btn"
                                            onClick={() => handleRemoveFile(fileUrl)}
                                        >
                                            Eliminar
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </form>
                        <div className="modal-footer">
                            <button onClick={handleSave} className="btn green">Guardar</button>
                            <button onClick={handleCloseModal} className="btn red">Cancelar</button>
                        </div>
                    </div>
                    <div className="modal-overlay" onClick={handleCloseModal}></div>
                </div>
            )}
        </div>
    );
};

export default AdminProtocolos;
