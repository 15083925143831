import { createSlice } from '@reduxjs/toolkit';
import { get_concepto, update_concepto, save_concepto, anular_concepto } from './conceptoThunk';

const conceptoSlice = createSlice({
  name: 'concepto',
  initialState: {
    concepto: null,
    conceptoSeleccionado: null,
    status: 'idle',
    error: null,
    status_update_concepto: 'idle',
    error_update_concepto: null,
    status_save_concepto: 'idle',
    error_save_concepto: null,
    status_anular_concepto: 'idle',
    error_anular_concepto: null,

  },
  reducers: {
    setConceptoSeleccionado: (state, action) => {
      state.conceptoSeleccionado = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_concepto.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_concepto.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.concepto = action.payload;

      })
      .addCase(get_concepto.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_concepto.pending, (state) => {
        state.status_update_concepto = 'loading';
      })
      .addCase(update_concepto.fulfilled, (state, action) => {
        state.status_update_concepto = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.concepto = action.payload.concepto;

      })
      .addCase(update_concepto.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_concepto = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_concepto = action.payload || 'Failed to fetch building';
      })
      .addCase(save_concepto.pending, (state) => {
        state.status_save_concepto = 'loading';
      })
      .addCase(save_concepto.fulfilled, (state, action) => {
        state.status_save_concepto = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.concepto = action.payload.concepto;

      })
      .addCase(save_concepto.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_concepto = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_concepto = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_concepto.pending, (state) => {
        state.status_save_concepto = 'loading';
      })
      .addCase(anular_concepto.fulfilled, (state, action) => {
        state.status_anular_concepto = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.concepto = action.payload.concepto;

      })
      .addCase(anular_concepto.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_concepto = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_concepto = action.payload || 'Failed to fetch building';
      })

  },
});

export const { setConceptoSeleccionado } = conceptoSlice.actions;
export default conceptoSlice.reducer;
