import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    save_encuesta,
    update_encuesta,
    get_encuesta,
    anular_encuesta,
} from "./encuestaThunk";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";
import M from "materialize-css/dist/js/materialize.min.js";
import { useParams, useNavigate } from "react-router-dom";
import "./AdminEncuestaPage.css";

const AdminEncuestaPage = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: idEncuesta } = useParams();
    const [uploading, setUploading] = useState(false);
    const [previewMode, setPreviewMode] = useState(false);

    // Estados para determinar el modo de edición/creación
    const [isEditing, setIsEditing] = useState(mode === 'edit');
    const [isCreating, setIsCreating] = useState(mode === 'create');

    const isInEditOrCreateMode = () => isEditing || isCreating;

    const encuesta = useSelector((state) => state.encuesta.encuesta);
    const codigo_usuario = useSelector((state) => state.auth.usuario.id);
    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const buildingId = edificioSeleccionado.id;

    const [formData, setFormData] = useState({
        titulo: "",
        descripcion: "",
        preguntas: [
            {
                texto: "",
                tipoRespuesta: "respuesta_corta",
                esObligatoria: false,
                opciones: [""],
                adjunto: null,
                adjuntoUrl: null,
            },
        ],
        estado: "borrador",
        fecha_inicio: "",
        fecha_fin: "",
    });

    // Inicializar Materialize al cargar la página o actualizar datos dinámicos
    useEffect(() => {
        M.AutoInit();
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [formData, previewMode, isInEditOrCreateMode]);

    useEffect(() => {
        if (mode !== "create") {
            dispatch(get_encuesta(idEncuesta));
        }
    }, [dispatch, idEncuesta, mode]);

    useEffect(() => {
        if (encuesta && (mode === "view" || mode === "edit")) {
            setFormData(encuesta);
            setIsEditing(false);
        }
    }, [encuesta, mode]);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handlePreguntaChange = (index, field, value) => {
        const preguntasActualizadas = formData.preguntas.map((pregunta, i) =>
            i === index ? { ...pregunta, [field]: value } : pregunta
        );
        setFormData((prev) => ({ ...prev, preguntas: preguntasActualizadas }));
    };

    const handleOpcionesChange = (index, opcionIndex, value) => {
        const preguntasActualizadas = formData.preguntas.map((pregunta, i) => {
            if (i === index) {
                const opcionesActualizadas = pregunta.opciones.map((opcion, j) =>
                    j === opcionIndex ? value : opcion
                );
                return { ...pregunta, opciones: opcionesActualizadas };
            }
            return pregunta;
        });
        setFormData((prev) => ({ ...prev, preguntas: preguntasActualizadas }));
    };

    const handleAddOpcion = (index) => {
        const preguntasActualizadas = formData.preguntas.map((pregunta, i) => {
            if (i === index) {
                return { ...pregunta, opciones: [...pregunta.opciones, ""] };
            }
            return pregunta;
        });
        setFormData((prev) => ({ ...prev, preguntas: preguntasActualizadas }));
    };

    const handleRemoveOpcion = (index, opcionIndex) => {
        const preguntasActualizadas = formData.preguntas.map((pregunta, i) => {
            if (i === index) {
                return {
                    ...pregunta,
                    opciones: pregunta.opciones.filter((_, j) => j !== opcionIndex),
                };
            }
            return pregunta;
        });
        setFormData((prev) => ({ ...prev, preguntas: preguntasActualizadas }));
    };

    const handleAdjuntoChange = async (index, file) => {
        setUploading(true);
        try {
            const storageRef = ref(storage, `encuestas/${file.name}_${Date.now()}`);
            await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(storageRef);
            handlePreguntaChange(index, "adjuntoUrl", downloadURL);
        } catch (error) {
            M.toast({ html: "Error al cargar el archivo.", classes: "red" });
        } finally {
            setUploading(false);
        }
    };

    const handleAddPregunta = () => {
        setFormData((prev) => ({
            ...prev,
            preguntas: [
                ...prev.preguntas,
                {
                    texto: "",
                    tipoRespuesta: "respuesta_corta",
                    esObligatoria: false,
                    opciones: [""],
                    adjunto: null,
                    adjuntoUrl: null,
                },
            ],
        }));
    };

    const handleRemovePregunta = (index) => {
        const preguntasActualizadas = formData.preguntas.filter((_, i) => i !== index);
        setFormData((prev) => ({ ...prev, preguntas: preguntasActualizadas }));
    };

    const handleSave = async () => {
        setUploading(true);
        try {
            const dataToSave = {
                ...formData,
                buildingId,
                codigo_usuario: codigo_usuario,
            };

            if (mode === "create") {
                await dispatch(save_encuesta(dataToSave)).unwrap();
                M.toast({ html: 'Encuesta creada con éxito', classes: 'green' });
            } else {
                await dispatch(update_encuesta({ idEncuesta, encuestaData: dataToSave })).unwrap();
                M.toast({ html: 'Encuesta actualizada con éxito.', classes: 'green' });
            }

            navigate('/encuestas');
        } catch (error) {
            M.toast({ html: error, classes: 'red' });
        } finally {
            setUploading(false);
        }
    };

    const handleAnular = () => {
        if (window.confirm("¿Estás seguro de que quieres anular esta encuesta?")) {
            dispatch(anular_encuesta({ idEncuesta, usuarioAnulacion: codigo_usuario }));
            M.toast({ html: "Encuesta anulada correctamente.", classes: "green" });
            navigate(-1);
        }
    };

    const handleRetornar = () => {
        navigate(-1);  
    };

    const handleCancel = () => {
        setIsEditing(false);
       
        if (mode === 'create') {
            handleRetornar();
        }
    };

    return (
        <div className="container admin-encuesta">
            <div className="card">
                <div className="card-content">
                    <h5>
                        {mode === "create" ? "Crear Encuesta" : mode === "edit" ? "Editar Encuesta" : "Detalle de Encuesta"}
                    </h5>
                    {previewMode ? (
                        <div>
                            <h6>Título: {formData.titulo}</h6>
                            <p>Descripción: {formData.descripcion}</p>
                            <h6>Preguntas</h6>
                            <ul>
                                {formData.preguntas.map((pregunta, index) => (
                                    <li key={index} className="card">
                                        <div className="card-content">
                                            <p>
                                                {pregunta.texto}{" "}
                                                {pregunta.esObligatoria && <span style={{ color: "red" }}>*</span>}
                                            </p>
                                            {pregunta.tipoRespuesta === "respuesta_corta" && (
                                                <input type="text" disabled placeholder="Respuesta corta" />
                                            )}
                                            {pregunta.tipoRespuesta === "opcion_multiple" && (
                                                <ul>
                                                    {pregunta.opciones.map((opcion, i) => (
                                                        <li key={i}>
                                                            <label>
                                                                <input type="radio" disabled />
                                                                <span>{opcion}</span>
                                                            </label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                            {pregunta.adjuntoUrl && (
                                                <img
                                                    src={pregunta.adjuntoUrl}
                                                    alt="Adjunto"
                                                    style={{ maxWidth: "100%", maxHeight: "200px" }}
                                                />
                                            )}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <button
                                className="btn blue"
                                onClick={() => setPreviewMode(false)}
                            >
                                Volver a Edición
                            </button>
                        </div>
                    ) : (
                        <form>
                            <div className="input-field">
                                <input
                                    type="text"
                                    name="titulo"
                                    value={formData.titulo}
                                    onChange={handleInputChange}
                                    disabled={!isInEditOrCreateMode}
                                />
                                <label className={formData.titulo ? "active" : ""}>Título</label>
                            </div>
                            {/* Campo de Descripción */}
                            <div className="input-field">
                                <textarea
                                    name="descripcion"
                                    className="materialize-textarea"
                                    value={formData.descripcion}
                                    onChange={handleInputChange}
                                    disabled={!isInEditOrCreateMode}
                                ></textarea>
                                <label className={formData.descripcion ? "active" : ""}>
                                    Descripción
                                </label>
                            </div>

                            {/* Preguntas */}
                            <div className="row">
                                <div className="col s12">
                                    <h6>Preguntas</h6>
                                    {formData.preguntas.map((pregunta, index) => (
                                        <div className="row card" key={index}>
                                            <div className="card-content">
                                                {/* Texto de la pregunta */}
                                                <div className="input-field col s8">
                                                    <input
                                                        type="text"
                                                        value={pregunta.texto}
                                                        onChange={(e) =>
                                                            handlePreguntaChange(index, "texto", e.target.value)
                                                        }
                                                        required
                                                        disabled={!isInEditOrCreateMode}
                                                    />
                                                    <label className={pregunta.texto ? "active" : ""}>
                                                        Pregunta {index + 1}
                                                    </label>
                                                </div>

                                                {/* Tipo de Respuesta */}
                                                <div className="input-field col s4">
                                                    <select
                                                        value={pregunta.tipoRespuesta}
                                                        onChange={(e) =>
                                                            handlePreguntaChange(index, "tipoRespuesta", e.target.value)
                                                        }
                                                        disabled={!isInEditOrCreateMode}
                                                    >
                                                        <option value="respuesta_corta">Respuesta Corta</option>
                                                        <option value="opcion_multiple">Opción Múltiple</option>
                                                    </select>
                                                    <label>Tipo de Respuesta</label>
                                                </div>

                                                {/* Opciones para opción múltiple */}
                                                {pregunta.tipoRespuesta === "opcion_multiple" && (
                                                    <div className="col s12">
                                                        {pregunta.opciones.map((opcion, i) => (
                                                            <div key={i} className="row">
                                                                <div className="input-field col s8">
                                                                    <input
                                                                        type="text"
                                                                        value={opcion}
                                                                        onChange={(e) =>
                                                                            handleOpcionesChange(index, i, e.target.value)
                                                                        }
                                                                        required
                                                                        disabled={!isInEditOrCreateMode}
                                                                    />
                                                                    <label
                                                                        className={opcion ? "active" : ""}
                                                                    >{`Opción ${i + 1}`}</label>
                                                                </div>
                                                                {isInEditOrCreateMode && (
                                                                    <div className="col s4">
                                                                        <button
                                                                            type="button"
                                                                            className="btn red"
                                                                            onClick={() => handleRemoveOpcion(index, i)}
                                                                        >
                                                                            <i className="material-icons">delete</i>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))}
                                                        {isInEditOrCreateMode && (
                                                            <button
                                                                type="button"
                                                                className="btn blue"
                                                                onClick={() => handleAddOpcion(index)}
                                                            >
                                                                Agregar Opción
                                                            </button>
                                                        )}
                                                    </div>
                                                )}

                                                {/* Obligatoria */}
                                                <div className="col s12">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={pregunta.esObligatoria}
                                                            onChange={(e) =>
                                                                handlePreguntaChange(
                                                                    index,
                                                                    "esObligatoria",
                                                                    e.target.checked
                                                                )
                                                            }
                                                            disabled={!isInEditOrCreateMode}
                                                        />
                                                        <span>Obligatoria</span>
                                                    </label>
                                                </div>

                                                {/* Adjunto */}
                                                <div className="file-field input-field col s12">
                                                    <div className="btn">
                                                        <span>Adjuntar</span>
                                                        <input
                                                            type="file"
                                                            onChange={(e) =>
                                                                handleAdjuntoChange(index, e.target.files[0])
                                                            }
                                                            disabled={!isInEditOrCreateMode || uploading}
                                                        />
                                                    </div>
                                                    <div className="file-path-wrapper">
                                                        <input
                                                            className="file-path validate"
                                                            type="text"
                                                            placeholder="Selecciona un archivo"
                                                        />
                                                    </div>
                                                </div>
                                                {pregunta.adjuntoUrl && (
                                                    <img
                                                        src={pregunta.adjuntoUrl}
                                                        alt="Adjunto"
                                                        style={{ maxWidth: "100%", maxHeight: "200px" }}
                                                    />
                                                )}
                                            </div>
                                            {isInEditOrCreateMode && (
                                                <div className="card-action">
                                                    <button
                                                        type="button"
                                                        className="btn red"
                                                        onClick={() => handleRemovePregunta(index)}
                                                    >
                                                        Eliminar Pregunta
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                    {isInEditOrCreateMode && (
                                        <button
                                            type="button"
                                            className="btn blue"
                                            onClick={handleAddPregunta}
                                        >
                                            Agregar Pregunta
                                        </button>
                                    )}
                                </div>
                            </div>

                            {/* Fechas */}
                            <div className="row">
                                <div className="input-field col s6">
                                    <input
                                        type="date"
                                        name="fecha_inicio"
                                        value={formData.fecha_inicio}
                                        onChange={handleInputChange}
                                        required
                                        disabled={!isInEditOrCreateMode}
                                    />
                                    <label className={formData.fecha_inicio ? "active" : ""}>
                                        Fecha Inicio
                                    </label>
                                </div>

                                <div className="input-field col s6">
                                    <input
                                        type="date"
                                        name="fecha_fin"
                                        value={formData.fecha_fin}
                                        onChange={handleInputChange}
                                        required
                                        disabled={!isInEditOrCreateMode}
                                    />
                                    <label className={formData.fecha_fin ? "active" : ""}>
                                        Fecha Fin
                                    </label>
                                </div>
                            </div>

                            {/* Estado */}
                            <div className="input-field">
                                <select
                                    name="estado"
                                    value={formData.estado}
                                    onChange={handleInputChange}
                                    disabled={!isInEditOrCreateMode}
                                >
                                    <option value="borrador">Borrador</option>
                                    <option value="publicado">Publicado</option>
                                    <option value="archivado">Archivado</option>
                                </select>
                                <label>Estado</label>
                            </div>

                            {/* Botones */}
                            <div className="card-action">
                                {isInEditOrCreateMode() ? (
                                    <>
                                        <button
                                            type="button"
                                            className="btn sky tooltipped"
                                            data-position="top"
                                            data-tooltip="Guardar cambios"
                                            onClick={handleSave}
                                        >
                                            <i className="material-icons left">save</i>Guardar
                                        </button>
                                        <button
                                            type="button"
                                            className="btn red tooltipped"
                                            data-position="top"
                                            data-tooltip="Cancelar edición"
                                            onClick={handleCancel}
                                        >
                                            <i className="material-icons left">cancel</i>Cancelar
                                        </button>
                                        
                                    </>
                                ) : (
                                    <>
                                        {mode === 'view' && (
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn sky tooltipped"
                                                    data-position="top"
                                                    data-tooltip="Editar detalles"
                                                    onClick={handleEdit}
                                                >
                                                    <i className="material-icons left">edit</i>Editar
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn orange tooltipped"
                                                    data-tooltip="Anular Reserva"
                                                    onClick={handleAnular}
                                                >
                                                    <i className="material-icons left">delete</i>Anular
                                                </button>
                                            </>
                                        )}
                                    </>
                                )}
                                <button
                                    type="button"
                                    className="btn blue tooltipped"
                                    data-position="top"
                                    data-tooltip="Volver"
                                    onClick={handleRetornar}
                                >
                                    <i className="material-icons left">arrow_back</i>Volver
                                </button>
                                <button
                                    type="button"
                                    className="btn orange"
                                    onClick={() => setPreviewMode(true)}
                                    disabled={uploading}
                                >
                                    Vista Previa
                                </button>
                            </div>

                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AdminEncuestaPage;
