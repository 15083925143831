import { createSlice } from '@reduxjs/toolkit';
import { get_protocolo, update_protocolo, save_protocolo, anular_protocolo, get_protocolos_x_edificio } from './protocoloThunk';

const protocoloSlice = createSlice({
  name: 'protocolo',
  initialState: {
    protocolo: null,
    protocolos: [],
    protocoloSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_protocolos_x_edificio: 'idle',
    error_get_protocolos_x_edificio: null,
    status_update_protocolo: 'idle',
    error_update_protocolo: null,    
    status_save_protocolo: 'idle',
    error_save_protocolo: null,
    status_anular_protocolo: 'idle',
    error_anular_protocolo: null,
  },
  reducers: {
    setProtocoloSeleccionada: (state, action) => {
      state.protocoloSeleccionada = action.payload;
      
    },
    clearProtocoloSeleccionada(state) {
      state.protocoloSeleccionada = null;
      state.protocolo = null;
    },
    resetStateGetProtocolos_x_edificio: () => {
        return {
            status_get_protocolos_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_protocolo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_protocolo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.protocolo = action.payload;

      })
      .addCase(get_protocolo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_protocolo.pending, (state) => {
        state.status_update_protocolo = 'loading';
      })
      .addCase(update_protocolo.fulfilled, (state, action) => {
        state.status_update_protocolo = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.protocolo = action.payload.protocolo;

      })
      .addCase(update_protocolo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_protocolo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_protocolo = action.payload || 'Failed to fetch building';
      })
      .addCase(save_protocolo.pending, (state) => {
        state.status_save_protocolo = 'loading';
      })
      .addCase(save_protocolo.fulfilled, (state, action) => {
        state.status_save_protocolo = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.protocolo = action.payload.protocolo;

      })
      .addCase(save_protocolo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_protocolo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_protocolo = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_protocolo.pending, (state) => {
        state.status_save_protocolo = 'loading';
      })
      .addCase(anular_protocolo.fulfilled, (state, action) => {
        state.status_anular_protocolo = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.protocolo = action.payload.protocolo;

      })
      .addCase(anular_protocolo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_protocolo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_protocolo = action.payload || 'Failed to fetch building';
      })
      .addCase(get_protocolos_x_edificio.pending, (state) => {
        state.status_get_protocolos_x_edificio = 'loading';
        
      })
      .addCase(get_protocolos_x_edificio.fulfilled, (state, action) => {
        state.status_get_protocolos_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_protocolos_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.protocolos = action.payload;

      })
      .addCase(get_protocolos_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_protocolos_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_protocolos_x_edificio = action.payload || 'Failed to fetch building';
        state.protocolos =[];
      })
  },
});

export const { setProtocoloSeleccionada, clearProtocoloSeleccionada } = protocoloSlice.actions;
export const selectProtocolos_emergenciaById = (state, idProtocolo) =>
    state.protocolo.protocolos.find((protocolo) => protocolo.id === idProtocolo);
export const { resetStateGetProtocolos_x_edificio } = protocoloSlice.actions;
export default protocoloSlice.reducer;
