import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_propiedades_x_edificio} from './propiedadThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Propiedades_edificio = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  const propiedades = useSelector((state) => state.propiedad.propiedades);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus  = useSelector((state) => state.propiedad.fetchStatus );    
  const error = useSelector((state) => state.propiedad.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    console.log("fetchStatus: "+ fetchStatus);
    console.log("codigo_edificio: "+ codigo_edificio);
    if (fetchStatus  === 'idle' && codigo_edificio) {
      dispatch(get_propiedades_x_edificio(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, fetchStatus , codigo_edificio, dispatch]);

  const handleRefresh = () => {    
    dispatch(get_propiedades_x_edificio(codigo_edificio));
  };

  const formatearPropiedades = (propiedades) => {
    if (!Array.isArray(propiedades)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return propiedades.map(propiedad => ({
      ...propiedad,
      fecha_registro_formato01: formatDate02(propiedad.timestamp_registro),
    }));
  };

  const propiedadesFormateados = formatearPropiedades(propiedades);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const propiedadesFiltrados = Array.isArray(propiedadesFormateados) ? propiedadesFormateados.filter((propiedad) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return propiedad.tipo_doc_propietario.toLowerCase().includes(filterLowerCase) ||
    propiedad.numero_doc_propietario.toLowerCase().includes(filterLowerCase) ||
    propiedad.tipo_unidad_inmobiliaria.toString().toLowerCase().includes(filterLowerCase) ||
    propiedad.numero_unidad_inmobiliaria.toString().toLowerCase().includes(filterLowerCase) ||
    propiedad.fecha_registro_formato01.toString().includes(filterLowerCase)  ||
    propiedad.mensaje_error_carga.toString().includes(filterLowerCase) ||
    propiedad.validacion_carga.includes(filterLowerCase)
      ;
  })
  : [];

  if (fetchStatus  === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus  === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (    
    <div className="container">
        <h5>Propiedades</h5>
        <a className="btn green tooltipped" data-position="top" data-tooltip="Refrescar vista" onClick={() => handleRefresh()}>
              <i className="material-icons left">refresh</i>Refrescar
        </a>
        <a className="btn orange tooltipped" data-position="top" data-tooltip="Cargar Propiedades" onClick={() => navigate('/carga-propiedades')}>
              <i className="material-icons left">cloud_upload</i>Cargar Propiedades
        </a>        
        <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/edificio')}>
              <i className="material-icons left">arrow_back</i>
        </a>        
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_propiedades_cargados"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_propiedades_cargados" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_propiedades_cargados">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>TipUnid.</th>
          <th>NumeroUnid.</th>                       
          <th>TipDoc</th>
          <th>NumDoc</th>
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Registro</th>
        </tr>
      </thead>
      <tbody id="table-body-propiedades-cargados">                    
        {propiedadesFiltrados.map((propiedad) => (
          <tr key={propiedad.id}>
            <td>{propiedad.codigo_edificio}</td>
            <td>{propiedad.tipo_unidad_inmobiliaria}</td>
            <td>{propiedad.numero_unidad_inmobiliaria}</td>    
            <td>{propiedad.tipo_doc_propietario}</td>
            <td>{propiedad.numero_doc_propietario}</td>
            <td>{propiedad.usuario_carga}</td>
            <td>{propiedad.uid_carga}</td>
            <td>{propiedad.fecha_registro_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Propiedades_edificio;
