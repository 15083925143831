import { createSlice } from '@reduxjs/toolkit';
import { get_contenido, update_contenido, save_contenido, anular_contenido, get_contenidos_x_edificio } from './contenidoThunk';

const contenidoSlice = createSlice({
  name: 'contenido',
  initialState: {
    contenido: null,
    contenidos: [],
    contenidoSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_contenidos_x_edificio: 'idle',
    error_get_contenidos_x_edificio: null,
    status_update_contenido: 'idle',
    error_update_contenido: null,    
    status_save_contenido: 'idle',
    error_save_contenido: null,
    status_anular_contenido: 'idle',
    error_anular_contenido: null,
  },
  reducers: {
    setContenidoSeleccionada: (state, action) => {
      state.contenidoSeleccionada = action.payload;
      
    },
    clearContenidoSeleccionada(state) {
      state.contenidoSeleccionada = null;
      state.contenido = null;
    },
    resetStateGetContenidos_x_edificio: () => {
        return {
            status_get_contenidos_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_contenido.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_contenido.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.contenido = action.payload;

      })
      .addCase(get_contenido.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_contenido.pending, (state) => {
        state.status_update_contenido = 'loading';
      })
      .addCase(update_contenido.fulfilled, (state, action) => {
        state.status_update_contenido = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.contenido = action.payload.contenido;

      })
      .addCase(update_contenido.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_contenido = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_contenido = action.payload || 'Failed to fetch building';
      })
      .addCase(save_contenido.pending, (state) => {
        state.status_save_contenido = 'loading';
      })
      .addCase(save_contenido.fulfilled, (state, action) => {
        state.status_save_contenido = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.contenido = action.payload.contenido;

      })
      .addCase(save_contenido.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_contenido = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_contenido = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_contenido.pending, (state) => {
        state.status_save_contenido = 'loading';
      })
      .addCase(anular_contenido.fulfilled, (state, action) => {
        state.status_anular_contenido = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.contenido = action.payload.contenido;

      })
      .addCase(anular_contenido.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_contenido = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_contenido = action.payload || 'Failed to fetch building';
      })
      .addCase(get_contenidos_x_edificio.pending, (state) => {
        state.status_get_contenidos_x_edificio = 'loading';
        
      })
      .addCase(get_contenidos_x_edificio.fulfilled, (state, action) => {
        state.status_get_contenidos_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_contenidos_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.contenidos = action.payload;

      })
      .addCase(get_contenidos_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_contenidos_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_contenidos_x_edificio = action.payload || 'Failed to fetch building';
        state.contenidos =[];
      })
  },
});

export const { setContenidoSeleccionada, clearContenidoSeleccionada } = contenidoSlice.actions;
export const selectContenidos_emergenciaById = (state, idContenido) =>
    state.contenido.contenidos.find((contenido) => contenido.id === idContenido);
export const { resetStateGetContenidos_x_edificio } = contenidoSlice.actions;
export default contenidoSlice.reducer;
