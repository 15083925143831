import { createSlice } from '@reduxjs/toolkit';
import { get_asistencia, update_asistencia, save_asistencia, anular_asistencia, get_asistencias_x_edificio, get_asistencias_x_usuario } from './asistenciaThunk';

const asistenciaSlice = createSlice({
  name: 'asistencia',
  initialState: {
    asistencia: null,
    asistencias: [],
    asistenciaSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_asistencias_x_edificio: 'idle',
    error_get_asistencias_x_edificio: null,    
    status_get_asistencias_x_usuario: 'idle',
    error_get_asistencias_x_usuario: null,
    status_update_asistencia: 'idle',
    error_update_asistencia: null,    
    status_save_asistencia: 'idle',
    error_save_asistencia: null,
    status_anular_asistencia: 'idle',
    error_anular_asistencia: null,
  },
  reducers: {
    setAsistenciaSeleccionada: (state, action) => {
      state.asistenciaSeleccionada = action.payload;
      
    },
    clearAsistenciaSeleccionada(state) {
      state.asistenciaSeleccionada = null;
      state.asistencia = null;
    },
    resetStateGetAsistencias_x_edificio: () => {
        return {
            status_get_asistencias_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_asistencia.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_asistencia.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.asistencia = action.payload;

      })
      .addCase(get_asistencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_asistencia.pending, (state) => {
        state.status_update_asistencia = 'loading';
      })
      .addCase(update_asistencia.fulfilled, (state, action) => {
        state.status_update_asistencia = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.asistencia = action.payload.asistencia;

      })
      .addCase(update_asistencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_asistencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_asistencia = action.payload || 'Failed to fetch building';
      })
      .addCase(save_asistencia.pending, (state) => {
        state.status_save_asistencia = 'loading';
      })
      .addCase(save_asistencia.fulfilled, (state, action) => {
        state.status_save_asistencia = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.asistencia = action.payload.asistencia;

      })
      .addCase(save_asistencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_asistencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_asistencia = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_asistencia.pending, (state) => {
        state.status_save_asistencia = 'loading';
      })
      .addCase(anular_asistencia.fulfilled, (state, action) => {
        state.status_anular_asistencia = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.asistencia = action.payload.asistencia;

      })
      .addCase(anular_asistencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_asistencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_asistencia = action.payload || 'Failed to fetch building';
      })

      
      .addCase(get_asistencias_x_usuario.pending, (state) => {
        state.status_get_asistencias_x_usuario = 'loading';
        
      })
      .addCase(get_asistencias_x_usuario.fulfilled, (state, action) => {
        state.status_get_asistencias_x_usuario = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_asistencias_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.asistencias = action.payload;

      })
      .addCase(get_asistencias_x_usuario.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_asistencias_x_usuario = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_asistencias_x_usuario = action.payload || 'Failed to fetch building';
        state.asistencias =[];
      })

      .addCase(get_asistencias_x_edificio.pending, (state) => {
        state.status_get_asistencias_x_edificio = 'loading';
        
      })
      .addCase(get_asistencias_x_edificio.fulfilled, (state, action) => {
        state.status_get_asistencias_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_asistencias_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.asistencias = action.payload;

      })
      .addCase(get_asistencias_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_asistencias_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_asistencias_x_edificio = action.payload || 'Failed to fetch building';
        state.asistencias =[];
      })
  },
});

export const { setAsistenciaSeleccionada, clearAsistenciaSeleccionada } = asistenciaSlice.actions;
export const selectAsistencias_emergenciaById = (state, idAsistencia) =>
    state.asistencia.asistencias.find((asistencia) => asistencia.id === idAsistencia);
export const { resetStateGetAsistencias_x_edificio } = asistenciaSlice.actions;
export default asistenciaSlice.reducer;
