import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_depositos_x_edificio} from './depositoThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Depositos_edificio = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  const depositos = useSelector((state) => state.deposito.depositos);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus  = useSelector((state) => state.deposito.fetchStatus );    
  const error = useSelector((state) => state.deposito.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    console.log("fetchStatus: "+ fetchStatus);
    console.log("codigo_edificio: "+ codigo_edificio);
    if (fetchStatus  === 'idle' && codigo_edificio) {
      dispatch(get_depositos_x_edificio(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, fetchStatus , codigo_edificio, dispatch]);

  const handleRefresh = () => {    
    dispatch(get_depositos_x_edificio(codigo_edificio));
  };

  const formatearDepositos = (depositos) => {
    if (!Array.isArray(depositos)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return depositos.map(deposito => ({
      ...deposito,
      fecha_registro_formato01: formatDate02(deposito.timestamp_registro),
    }));
  };

  const depositosFormateados = formatearDepositos(depositos);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const depositosFiltrados = Array.isArray(depositosFormateados) ? depositosFormateados.filter((deposito) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return deposito.tipo.includes(filterLowerCase) ||
    deposito.numero.toLowerCase().includes(filterLowerCase) ||
    deposito.nivel.toString().toLowerCase().includes(filterLowerCase) ||
    deposito.tipo_nivel.toString().toLowerCase().includes(filterLowerCase) ||    
    deposito.area_ocupada.toString().toLowerCase().includes(filterLowerCase) ||
    deposito.area_techada.toString().toLowerCase().includes(filterLowerCase) ||
    deposito.fecha_registro_formato01.toString().includes(filterLowerCase)  ||
    deposito.mensaje_error_carga.toString().includes(filterLowerCase) ||
    deposito.validacion_carga.includes(filterLowerCase)
      ;
  })
  : [];

  if (fetchStatus  === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus  === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (    
    <div className="container">
        <h5>Depositos</h5>
        <a className="btn green tooltipped" data-position="top" data-tooltip="Refrescar vista" onClick={() => handleRefresh()}>
              <i className="material-icons left">refresh</i>Refrescar
        </a>
        <a className="btn orange tooltipped" data-position="top" data-tooltip="Cargar Depositos" onClick={() => navigate('/carga-depositos')}>
              <i className="material-icons left">cloud_upload</i>Cargar Depósitos
        </a>        
        <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/edificio')}>
              <i className="material-icons left">arrow_back</i>
        </a>        
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_depositos_cargados"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_depositos_cargados" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_depositos_cargados">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>Tipo</th>
          <th>Depos.</th>
          <th>Nivel</th>
          <th>Tip_niv</th>             
          <th>Area_ocupada</th>
          <th>Area_techada</th>                    
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Registro</th>
        </tr>
      </thead>
      <tbody id="table-body-depositos-cargados">                    
        {depositosFiltrados.map((deposito) => (
          <tr key={deposito.id}>
            <td>{deposito.codigo_edificio}</td>
            <td>{deposito.tipo}</td>
            <td>{deposito.numero}</td>
            <td>{deposito.nivel}</td>
            <td>{deposito.tipo_nivel}</td>                 
            <td>{deposito.area_ocupada}</td>
            <td>{deposito.area_techada}</td>
            <td>{deposito.usuario_carga}</td>
            <td>{deposito.uid_carga}</td>
            <td>{deposito.fecha_registro_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Depositos_edificio;
