import { format } from 'date-fns';
import es from 'date-fns/locale/es'; // Importar el locale español

export const formatearPeriodo_MesAnio02 = (fecha) => {

  //return format(new Date(fecha), 'MMMM-yyyy', { locale: es });
  // Formatea el timestamp a mes y año con la primera letra en mayúscula
  return format(new Date(fecha), 'MMMM-yyyy', { locale: es }).replace(/^\w/, (c) => c.toUpperCase());
};

export const normalizeDate = (dateString) => {
  const localDate = new Date(dateString);
  const offset = localDate.getTimezoneOffset() * 60000; // Desplazamiento en milisegundos
  return new Date(localDate.getTime() - offset).toISOString().split('T')[0]; // Formato YYYY-MM-DD
};

export const formatearPeriodo_MesAnio01 = (fecha) => {
  // Convertir el string YYYY-MM en un objeto Date (agregar el día para hacerlo válido)
  // Extraemos el año y el mes del string YYYY-MM
  const [year, month] = fecha.split('-').map(Number);  // Convertimos año y mes a números

  // Creamos la fecha asegurándonos de que el mes esté correctamente indexado (0 para enero, 1 para febrero, etc.)
  const fechaDate = new Date(year, month - 1, 1);  // Creamos el objeto Date directamente con año y mes ajustados

  // Formatear la fecha para obtener el mes en letras y el año, con la primera letra en mayúscula
  return format(fechaDate, 'MMMM-yyyy', { locale: es }).replace(/^\w/, (c) => c.toUpperCase());
};
export const formatearImporte02 = (importe, moneda) => {

  return `${moneda.simbolo}${(importe).toFixed(2)} (${moneda.codigo})`;
};
export const formatearImporte01 = (importe, moneda) => {

  if(moneda){

    const symbol = moneda.simbolo || '';

    const formattedAmount = new Intl.NumberFormat('es-PE', { style: 'currency', currency: moneda.codigo, minimumFractionDigits: 2 }).format(importe);
  
    return `${formattedAmount}`;
    
  }else{
    const symbol = 'S/';

    const formattedAmount = new Intl.NumberFormat('es-PE', { style: 'currency', currency: 'PEN', minimumFractionDigits: 2 }).format(importe);
  
    return `${formattedAmount}`;
  }

  //return `${symbol} ${formattedAmount}`;
  //return `${formattedAmount}`;
};
export const formatDate = (fecha) => {
//console.log("fecha input "+fecha);
    const date = new Date(fecha._seconds  * 1000); // Convertir Firestore timestamp a Date
  //  console.log("date convert  "+date);
    const day = String(date.getDate()).padStart(2, '0');
    
    const month = String(date.getMonth() + 1).padStart(2, '0');
    
    const year = date.getFullYear();
    
    return `${day}/${month}/${year}`;
  };

export const formatPeriod = (fecha) => {

    const date = new Date(fecha._seconds * 1000); // Convertir Firestore timestamp a Date
    const month = capitalizeFirstLetter(date.toLocaleString('default', { month: 'long' }));
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

 export const formatFriendlyDate = (dateString) => {
    //recibe dd-MM-yyyy HH:mm:ss, sale 22 de noviembre de 2024, 18:25
    const [day, month, yearAndTime] = dateString.split("-");
    const [year, time] = yearAndTime.split(" ");
    const monthNames = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];
    const monthName = monthNames[parseInt(month, 10) - 1];
    return `${parseInt(day, 10)} de ${monthName} de ${year}, ${time.slice(0, 5)}`; // Omite segundos
};


export const formatDate02 = (timestamp) => {
    if(timestamp){
      return format(new Date(timestamp), 'yyyy-MM-dd HH:mm:ss');
    }else{
      return '';
    }
    
  };

export const formatCurrency = (value) => {
    return `S/ ${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };


  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };