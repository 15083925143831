import { createSlice } from '@reduxjs/toolkit';
import { get_grupo, update_grupo, save_grupo, anular_grupo, get_grupos_x_tipo } from './grupoThunk';

const grupoSlice = createSlice({
  name: 'grupo',
  initialState: {
    grupo: null,
    grupos: [],
    formasComunicacion: [],
    grupoSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_grupos_x_tipo: 'idle',
    error_get_grupos_x_tipo: null,
    status_update_grupo: 'idle',
    error_update_grupo: null,    
    status_save_grupo: 'idle',
    error_save_grupo: null,
    status_anular_grupo: 'idle',
    error_anular_grupo: null,
  },
  reducers: {
    setGrupoSeleccionada: (state, action) => {
      state.grupoSeleccionada = action.payload;
      
    },
    clearGrupoSeleccionada(state) {
      state.grupoSeleccionada = null;
      state.grupo = null;
    },
    resetStateGetGrupos_x_tipo: () => {
        return {
            status_get_grupos_x_tipo : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_grupo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_grupo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.grupo = action.payload;

      })
      .addCase(get_grupo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_grupo.pending, (state) => {
        state.status_update_grupo = 'loading';
      })
      .addCase(update_grupo.fulfilled, (state, action) => {
        state.status_update_grupo = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.grupo = action.payload.grupo;

      })
      .addCase(update_grupo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_grupo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_grupo = action.payload || 'Failed to fetch building';
      })
      .addCase(save_grupo.pending, (state) => {
        state.status_save_grupo = 'loading';
      })
      .addCase(save_grupo.fulfilled, (state, action) => {
        state.status_save_grupo = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.grupo = action.payload.grupo;

      })
      .addCase(save_grupo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_grupo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_grupo = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_grupo.pending, (state) => {
        state.status_save_grupo = 'loading';
      })
      .addCase(anular_grupo.fulfilled, (state, action) => {
        state.status_anular_grupo = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.grupo = action.payload.grupo;

      })
      .addCase(anular_grupo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_grupo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_grupo = action.payload || 'Failed to fetch building';
      })
      .addCase(get_grupos_x_tipo.pending, (state) => {
        state.status_get_grupos_x_tipo = 'loading';
        
      })
      .addCase(get_grupos_x_tipo.fulfilled, (state, action) => {
        state.status_get_grupos_x_tipo = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_grupos_x_tipo FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        //state.grupos = action.payload;
        if (action.meta.arg.tipo === 'FORMA_COMUNICACION') {
            state.formasComunicacion = action.payload; // Guardar las formas de comunicación si es el tipo correcto
          } else {
            state.grupos = action.payload;
          }
      })
      .addCase(get_grupos_x_tipo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_grupos_x_tipo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_grupos_x_tipo = action.payload || 'Failed to fetch building';
        state.grupos =[];
      })
  },
});

export const { setGrupoSeleccionada, clearGrupoSeleccionada } = grupoSlice.actions;
export const selectGrupos_emergenciaById = (state, idGrupo) =>
    state.grupo.grupos.find((grupo) => grupo.id === idGrupo);
export const { resetStateGetGrupos_x_tipo } = grupoSlice.actions;
export default grupoSlice.reducer;
