import { createSlice } from '@reduxjs/toolkit';
import { get_infraccion, update_infraccion, save_infraccion, anular_infraccion, get_infracciones_x_edificio, get_infraccion_x_codigo } from './infraccionThunk';

const infraccionSlice = createSlice({
  name: 'infraccion',
  initialState: {
    infraccion: null,
    infracciones: [],
    infraccionSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_infracciones_x_edificio: 'idle',
    error_get_infracciones_x_edificio: null,    
    status_get_infraccion_x_codigo: 'idle',
    error_get_infraccion_x_codigo: null,
    status_update_infraccion: 'idle',
    error_update_infraccion: null,    
    status_save_infraccion: 'idle',
    error_save_infraccion: null,
    status_anular_infraccion: 'idle',
    error_anular_infraccion: null,
  },
  reducers: {
    setInfraccionSeleccionada: (state, action) => {
      state.infraccionSeleccionada = action.payload;
      
    },
    clearInfraccionSeleccionada(state) {
      state.infraccionSeleccionada = null;
      state.infraccion = null;
    },
    resetStateGetInfracciones_x_edificio: () => {
        return {
            status_get_infracciones_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_infraccion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_infraccion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.infraccion = action.payload;

      })
      .addCase(get_infraccion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_infraccion.pending, (state) => {
        state.status_update_infraccion = 'loading';
      })
      .addCase(update_infraccion.fulfilled, (state, action) => {
        state.status_update_infraccion = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.infraccion = action.payload.infraccion;

      })
      .addCase(update_infraccion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_infraccion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_infraccion = action.payload || 'Failed to fetch building';
      })
      .addCase(save_infraccion.pending, (state) => {
        state.status_save_infraccion = 'loading';
      })
      .addCase(save_infraccion.fulfilled, (state, action) => {
        state.status_save_infraccion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.infraccion = action.payload.infraccion;

      })
      .addCase(save_infraccion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_infraccion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_infraccion = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_infraccion.pending, (state) => {
        state.status_save_infraccion = 'loading';
      })
      .addCase(anular_infraccion.fulfilled, (state, action) => {
        state.status_anular_infraccion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.infraccion = action.payload.infraccion;

      })
      .addCase(anular_infraccion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_infraccion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_infraccion = action.payload || 'Failed to fetch building';
      })
      
      .addCase(get_infraccion_x_codigo.pending, (state) => {
        state.status_get_infraccion_x_codigo = 'loading';
        
      })
      .addCase(get_infraccion_x_codigo.fulfilled, (state, action) => {
        state.status_get_infraccion_x_codigo = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_infracciones_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.infraccion = action.payload;

      })
      .addCase(get_infraccion_x_codigo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_infraccion_x_codigo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_infraccion_x_codigo = action.payload || 'Failed to fetch building';
        //state.infraccion =null;
      })

      .addCase(get_infracciones_x_edificio.pending, (state) => {
        state.status_get_infracciones_x_edificio = 'loading';
        
      })
      .addCase(get_infracciones_x_edificio.fulfilled, (state, action) => {
        state.status_get_infracciones_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_infracciones_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.infracciones = action.payload;

      })
      .addCase(get_infracciones_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_infracciones_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_infracciones_x_edificio = action.payload || 'Failed to fetch building';
        state.infracciones =[];
      })
  },
});

export const { setInfraccionSeleccionada, clearInfraccionSeleccionada } = infraccionSlice.actions;
export const selectinfracciones_emergenciaById = (state, idInfraccion) =>
    state.infraccion.infracciones.find((infraccion) => infraccion.id === idInfraccion);
export const { resetStateGetInfracciones_x_edificio } = infraccionSlice.actions;
export default infraccionSlice.reducer;
