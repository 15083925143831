import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_recibos_x_depa } from './recibosThunk';
import M from 'materialize-css';
import { Link } from 'react-router-dom';

import { formatDate, formatCurrency, formatPeriod } from '../../utils/formateo';
import { getEstadoPagoButtonClass, getEstadoPagoIcon, downloadPDF } from '../../utils/util';

const MisRecibos = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const recibos = useSelector((state) => state.recibos.recibos);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus = useSelector((state) => state.recibos.fetchStatus);
  const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);

  const error = useSelector((state) => state.recibos.error);
  const [filtro, setFiltro] = useState('');

  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
  
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("fetchStatus: "+ fetchStatus);
    //console.log("JSON.stringify(unidad_facturacionSeleccionado)): "+ JSON.stringify(unidad_facturacionSeleccionado));
    //if (fetchStatus === 'idle' && unidad_facturacionSeleccionado) {
      //console.log("=>entro");
      dispatch(get_recibos_x_depa(unidad_facturacionSeleccionado.codigo_unidad_facturacion));
      //console.log("=>entro");
    //}

  }, [unidad_facturacionSeleccionado, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) {
      //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS   

  }, [isAuthenticated, navigate]);


  if (!usuario) {
    return null; // Return null while redirecting
  }


  const formatearRecibos = (recibos) => {
    return recibos.map(recibo => ({
      ...recibo,
      fecha_emision_formato01: formatDate(recibo.fecha_emision_timestamp),
      fecha_corte_formato01: formatDate(recibo.fecha_corte_timestamp),      
      total_mes_formato01: formatCurrency(recibo.total_mes),
    }));
  };

  const recibosFormateados = formatearRecibos(recibos);

  const handleFilterChange = (e) => {
    setFiltro(e.target.value);

  };

  const recibosFiltrados = Array.isArray(recibosFormateados) ? recibosFormateados.filter((recibo) => {

    const filterLowerCase = filtro.toLowerCase();
    return recibo.periodo.toLowerCase().includes(filterLowerCase) ||
      recibo.codigo_unidad_facturacion.toLowerCase().includes(filterLowerCase) ||
      recibo.numero_recibo.toLowerCase().includes(filterLowerCase) ||
      recibo.total_mes_formato01.includes(filterLowerCase) ||
      recibo.fecha_emision_formato01.includes(filterLowerCase) ||
      recibo.fecha_corte_formato01.includes(filterLowerCase) ||
      recibo.estado_pago.toLowerCase().includes(filterLowerCase)
      ;
  })
    : [];

  if (fetchStatus === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container">
      <h5>Recibos</h5>
      <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_mis_recibos_propietario"><i className="material-icons">search</i> Filtrar</label>
        <input type="text" id="filtro_tabla_mis_recibos_propietario"
          value={filtro}
          onChange={handleFilterChange} />
      </div>
      <table className="striped responsive-table tabla_mis_recibos_propietario">
        <thead>
          <tr>
            <th>Periodo</th>
            <th>Unid.Fact.</th>
            <th>Numero</th>
            <th>Importe</th>
            <th>Emision</th>
            <th>Corte</th>
            <th>Estado</th>
            <th>Impr.</th>
          </tr>
        </thead>
        <tbody id="table-body-mis-recibos_propietario">
          {recibosFiltrados.map((receipt) => (
            <tr key={receipt.id}>
              <td>
                <Link to={`/recibo/${receipt.id}`}>
                  {receipt.periodo}
                </Link>
              </td>
              <td>{receipt.codigo_unidad_facturacion}</td>
              <td>{receipt.numero_recibo}</td>              
              <td>{receipt.total_mes_formato01}</td>
              <td>{receipt.fecha_emision_formato01}</td>
              <td>{receipt.fecha_corte_formato01}</td>
              <td>
                <button className={getEstadoPagoButtonClass(receipt.estado_pago)}>
                  {getEstadoPagoIcon(receipt.estado_pago)}
                  {receipt.estado_pago}
                </button>
              </td>

              <td>
                <button
                  className="btn tooltipped"
                  data-position="top"
                  data-tooltip="Descargar PDF"
                  onClick={() => downloadPDF('Recibos/FICUS_RECIBO_DE_MANTENIMIENTO_03_2024_FIC003.pdf')}
                >
                  <i className="material-icons">print</i>
                </button>
              </td>
            </tr>
          ))}

        </tbody>
      </table>

    </div>
  );
};

export default MisRecibos;
