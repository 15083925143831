import { createSlice } from '@reduxjs/toolkit';
import {get_lecturas_agua} from './lecturas_aguaThunk';

const lecturas_aguaSlice = createSlice({
    name: 'lecturas_agua',
    initialState: {
      lecturas_agua: [],
      fetchStatus : 'idle',
      error: null,
    },
    reducers: {
      resetStateGetLecturas_agua: () => {
        return {
          fetchStatus : 'idle',
        };
      },
    },
    extraReducers: (builder) => {
      builder
          .addCase(get_lecturas_agua.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_lecturas_agua.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.lecturas_agua = action.payload;

          })
          .addCase(get_lecturas_agua.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';              
              console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch receipts';
          });
    },
  });
    
  export const selectLectura_aguaById = (state, lectura_aguaId) =>
    state.lecturas_agua.lecturas_agua.find((lectura_agua) => lectura_agua.id === lectura_aguaId);
  
  export const { resetStateGetLecturas_agua } = lecturas_aguaSlice.actions;

  export default lecturas_aguaSlice.reducer;
