import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useParams } from 'react-router-dom';
import {
  save_contenido,
  get_contenido,
  update_contenido,
  anular_contenido,
} from './contenidoThunk';
import './AdminContenidoPage.css';

const AdminContenidoPage = ({ mode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: idContenido } = useParams();

  const [formData, setFormData] = useState({
    nombre: '',
    codigo: '',
    modulo: '',
    orden: '',
    color_fondo: "#ffffff",
    data: ''
  });

  const buildingId = useSelector((state) => state.edificio.edificio?.id);
  const usuario = useSelector((state) => state.auth.usuario);
  const contenido = useSelector((state) => state.contenido.contenido);

  const [currentMode, setCurrentMode] = useState(mode); // Control del modo dinámico

  const isEditable = currentMode === 'edit' || currentMode === 'create';
  const isView = currentMode === 'view';

  // Carga de contenido si es necesario
  useEffect(() => {
    if (mode === 'edit' || mode === 'view') {
      dispatch(get_contenido(idContenido));
    }
  }, [dispatch, idContenido, mode]);

  // Inicialización de datos al cargar contenido
  useEffect(() => {
    if (contenido && (mode === 'edit' || mode === 'view')) {
      setFormData({
        nombre: contenido.nombre || '',
        codigo: contenido.codigo || '',
        modulo: contenido.modulo || '',
        orden: contenido.orden || '',
        color_fondo: contenido.color_fondo || "#ffffff",
        data: contenido.data || '',
      });
    }
  }, [contenido, mode]);

  useEffect(() => {
    // Actualiza los labels flotantes de Materialize para evitar superposición
    window.M && window.M.updateTextFields();
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    const contenidoData = {
      ...formData,
      buildingId,
      usuarioRegistrador: usuario.id,
    };

    try {
      if (currentMode === 'edit') {
        await dispatch(update_contenido({ idContenido, contenidoData })).unwrap();
        alert('Contenido actualizado con éxito.');
      } else {
        await dispatch(save_contenido(contenidoData)).unwrap();
        alert('Contenido creado con éxito.');
      }
      navigate(-1);
    } catch (error) {
      console.error('Error al guardar el contenido:', error);
      alert('Error al guardar el contenido.');
    }
  };

  const handleEdit = () => {
    setCurrentMode('edit'); // Cambiar a modo edición
  };

  const handleAnular = () => {
    if (window.confirm('¿Estás seguro de que quieres anular este contenido?')) {
      dispatch(anular_contenido({ idContenido, usuarioAnulacion: usuario.id }));
      alert('Contenido anulado correctamente.');
      navigate(-1);
    }
  };

  return (
    <div className="contenido-page">
      <h4>
        {currentMode === 'create' && 'Crear Contenido'}
        {currentMode === 'edit' && 'Editar Contenido'}
        {currentMode === 'view' && 'Ver Contenido'}
      </h4>

      <form>
        <div className="input-field">
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            id="nombre"
            disabled={isView}
          />
          <label htmlFor="nombre" className={formData.nombre ? 'active' : ''}>
            Nombre
          </label>
        </div>

        <div className="input-field">
          <input
            type="text"
            name="codigo"
            value={formData.codigo}
            onChange={handleChange}
            id="codigo"
            disabled={isView}
          />
          <label htmlFor="codigo" className={formData.codigo ? 'active' : ''}>
            Código
          </label>
        </div>

        <div className="input-field">
          <input
            type="text"
            name="modulo"
            value={formData.modulo}
            onChange={handleChange}
            id="modulo"
            disabled={isView}
          />
          <label htmlFor="modulo" className={formData.modulo ? 'active' : ''}>
            Módulo
          </label>
        </div>

        <div className="input-field">
          <input
            type="text"
            name="orden"
            value={formData.orden}
            onChange={handleChange}
            id="orden"
            disabled={isView}
          />
          <label htmlFor="orden" className={formData.orden ? 'active' : ''}>
            Orden
          </label>
        </div>

        <div className="input-field">
          <label htmlFor="color_fondo">Color de Fondo</label>
          <input
            type="color"
            name="color_fondo"
            value={formData.color_fondo}
            onChange={handleChange}
            id="color_fondo"
            disabled={isView}
          />
        </div>

        <div className="react-quill-container">
          <ReactQuill
            key={currentMode} // Fuerza un nuevo renderizado al cambiar de modo
            value={formData.data}
            onChange={(value) =>
              setFormData((prevFormData) => ({ ...prevFormData, data: value }))
            }
            readOnly={isView}
            theme="snow"
            placeholder="Escribe aquí..."
            modules={{
              toolbar: isView
                ? false
                : [
                    [{ header: [1, 2, 3, false] }],
                    [{ size: ["small", false, "large", "huge"] }],
                    ["bold", "italic", "underline"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["link", "image"],
                    [{ color: [] }, { background: [] }],
                    ["clean"],
                  ],
            }}
          />
        </div>

        <div className="button-group">
          {isView ? (
            <button type="button" className="btn blue" onClick={handleEdit}>
              Editar
            </button>
          ) : (
            <button type="button" className="btn green" onClick={handleSave}>
              {currentMode === 'edit' ? 'Guardar Cambios' : 'Crear Contenido'}
            </button>
          )}
          {currentMode === 'view' && (
            <button
              type="button"
              className="btn red"
              onClick={handleAnular}
              style={{ marginLeft: '10px' }}
            >
              Anular Contenido
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default AdminContenidoPage;
