import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import { storage } from '../../firebase';
import M from 'materialize-css'; // Importa Materialize CSS
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import {TIPO_DOC_SD, TIPO_USUARIO_EMAIL, TIPO_USUARIO_CODIGO} from './../../constants/constants';
import {isValidEmail , isValidCode, isValidRole} from '../../utils/util';

const Carga_residentes = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  //const codigoDelEdificio = useSelector(state => state.edificio.edificio.codigo);
  const edificio = useSelector(state => state.edificio.edificio);
  const usuario = useSelector((state) => state.auth.usuario);

  useEffect(() => {
    // Inicializar Materialize JS components
    M.AutoInit();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async() => {
    if (!file) return;
    setUploading(true);
    setSuccess(null);
    setError(null);

    // Validar el archivo CSV antes de subirlo
    console.log("Cargando archivo");
    const validationResult = await validateCSVFile(file);
    console.log("validationResult: "+validationResult);
    if (!validationResult.valid) {
      setUploading(false);
      setError(validationResult.error);
      return;
    }

    const uniqueId = uuidv4();
    const fileName = `CARGA-RESIDENTES-${edificio.codigo}-${uniqueId}.csv`;
    
     // Conectar al emulador de Storage si se está ejecutando en local
  if (window.location.hostname === 'localhost') {
    connectStorageEmulator(storage, 'localhost', 9199);
  }

    const storageRef = ref(storage, `CargaAportantes/${fileName}`);
    
    const metadata = {
      customMetadata: {
        buildingId: edificio.id,
        usuario_carga: usuario.id,
        uid_carga: uniqueId, // Optionally, add more user details
      }
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Optionally handle progress
      },
      (error) => {
        setUploading(false);
        setError(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUploading(false);
          setSuccess('File uploaded successfully!');
          triggerCloudFunction(downloadURL);
        });
      }
    );
  };

  const triggerCloudFunction = async (downloadURL) => {
    // Lógica para desencadenar la Cloud Function (si es necesario)
  };


    // Función para validar el archivo CSV antes de subirlo
    const validateCSVFile = async (csvFile) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const text = event.target.result;
          const lines = text.split(/\r?\n/);
  
          // Detectar el delimitador en la primera línea
          const delimiters = [',', ';'];
          let delimiter = delimiters.find(d => lines[0].includes(d)) || ',';
          //console.log("validando header: ");
          // Validar la cabecera
          const header = lines[0].split(delimiter);
          if (header.length !== 15 ||
              header[0].trim() !== 'Edificio' ||
              header[1].trim() !== 'Perfil' ||
              header[2].trim() !== 'Tipo_documento' ||
              header[3].trim() !== 'Numero_documento' ||
              header[4].trim() !== 'Titular' ||              
              header[5].trim() !== 'Email' || 
              header[6].trim() !== 'Nro_celular' ||
              header[7].trim() !== 'Tipo_usuario' ||
              header[8].trim() !== 'Usuario' ||
              header[9].trim() !== 'Roles' ||
              header[10].trim() !== 'Nombres' ||
              header[11].trim() !== 'Primer_apellido' ||
              header[12].trim() !== 'Segundo_apellido' ||
              header[13].trim() !== 'Email_2' ||
              header[14].trim() !== 'Nro_celular_2') {
            resolve({ valid: false, error: 'Cabecera inválida. Asegúrate de que la cabecera contenga las columnas "Edificio", "Perfil", "Tipo_documento", "Nro_documento", "Titular", "Email", "Nro_celular", "Tipo_usuario", "Usuario", "Roles", "Nombres", "Primer_apellido", "Segundo_apellido" "Email_2", "Nro_celular_2".' });
            return;
          }
          //console.log("validando datos: ");
          // Validar cada línea de datos
          for (let i = 1; i < lines.length; i++) {
            const line = lines[i].split(delimiter);

            //console.log(`Analizando fila ${i + 1} line.length ${line.length}.`)
            if (line.length !== 15) {
              //console.log(`==>Analizando fila ${i + 1} delimiter ${delimiter} line ${line}.`)
              resolve({ valid: false, error: `Fila ${i + 1} no tiene el número correcto de columnas.` });
              return;
            }
  
            // Validar que el código de edificio exista en el store de Redux (reemplaza esto con tu lógica real)
            const codigoEdificio = line[0].trim();
            //console.log(`Analizando fila ${i + 1} codigoEdificio ${codigoEdificio}.`)
            if (codigoEdificio !== edificio.codigo) {
              //console.log(`==>Error fila ${i + 1} `)
              resolve({ valid: false, error: `El código de edificio en la fila ${i + 1} no coincide con el edificio actual ${edificio.codigo}.` });
              return;
            }
  
            // Validar que Piso no estén vacíos
            const perfil = line[1].trim();
            //console.log(`Analizando fila ${i + 1} perfil ${perfil}.`)
            if (!perfil ) {
              resolve({ valid: false, error: `El perfil en la fila ${i + 1} está vacío.` });
              return;
            }
            
            // Validar que Departamento no estén vacíos
            const tipo_documento = line[2].trim();
            //console.log(`Analizando fila ${i + 1} tipo_documento ${tipo_documento}.`)
            if (!tipo_documento) {
              resolve({ valid: false, error: `El tipo de documento en la fila ${i + 1} está vacío.` });
              return;
            }

            // Validar que Departamento no estén vacíos
            const numero_documento = line[3].trim();
            //console.log(`Analizando fila ${i + 1} numero_documento ${numero_documento}.`)
            if (!numero_documento) {
              //console.log(`=>Analizando fila ${i + 1} numero_documento vacio, evaluando TIPO_DOC_SD ${TIPO_DOC_SD}.`)
              if(tipo_documento!==TIPO_DOC_SD){
                //console.log(`==>Analizando fila ${i + 1} error .`)
                resolve({ valid: false, error: `El número de documento en la fila ${i + 1} está vacío.` });
                return;
              }
            }
            
            // Validar que Departamento no estén vacíos
            const titular = line[4].trim();
            //console.log(`Analizando fila ${i + 1} titular ${titular}.`)
            if (!titular) {
                resolve({ valid: false, error: `El titular en la fila ${i + 1} está vacío.` });
                return;
            }
            /*
            // Validar que Departamento no estén vacíos
            const email = line[5].trim();
            if (!email) {
                resolve({ valid: false, error: `El email en la fila ${i + 1} está vacío.` });
                return;
            }
            
            
            // Validar que Departamento no estén vacíos
            const nro_celular = line[6].trim();
            if (!nro_celular) {
                resolve({ valid: false, error: `El Número de celular en la fila ${i + 1} está vacío.` });
                return;
            } 
            */ 

            const tipo_usuario = line[7].trim();
            const usuario = line[8].trim();
            const roles = line[9].trim();

            if (tipo_usuario) {
              if (tipo_usuario !== TIPO_USUARIO_EMAIL && tipo_usuario !== TIPO_USUARIO_CODIGO) {
                //throw new Error(`Fila ${index + 1}: Tipo de usuario debe ser 'EMAIL' o 'CODIGO'`);
                resolve({ valid: false, error: `Tipo de usuario debe ser ${TIPO_USUARIO_EMAIL} o ${TIPO_USUARIO_CODIGO} en la fila ${i + 1}.` });
                return;

              }
  
              if (tipo_usuario === TIPO_USUARIO_EMAIL && (!usuario || !isValidEmail(usuario))) {
                //throw new Error(`Fila ${index + 1}: Usuario debe ser un email válido`);
                resolve({ valid: false, error: `Usuario debe ser un email válido en la fila ${i + 1}.` });
                return;

              }
  
              if (tipo_usuario === TIPO_USUARIO_CODIGO && (!usuario || !isValidCode(usuario))) {
                //throw new Error(`Fila ${index + 1}: Usuario debe ser un código válido`);
                resolve({ valid: false, error: `Usuario debe ser un código válido en la fila ${i + 1}.` });
                return;

              }
  
              const roleArray = roles.split('-').map(role => role.trim());
              roleArray.forEach(role => {
                if (!isValidRole(role)) {
                  //throw new Error(`Fila ${index + 1}: Rol '${role}' no es válido`);
                  resolve({ valid: false, error: `Rol '${role}' no es válido en la fila ${i + 1}.` });
                  return;
  
                }
              });
            }
                        
            //console.log("validado titular: "+titular);                                  
          }
          
          resolve({ valid: true });
        };
  
        reader.onerror = (event) => {
          reject('Error al leer el archivo.');
        };
  
        reader.readAsText(csvFile);
      });
    };

  return (
    <div className="container">
      <h3>Subir Archivo CSV</h3>
      <div className="file-field input-field">
        <div className="btn">
          <span>Archivo</span>
          <input type="file" accept=".csv" onChange={handleFileChange} />
        </div>
        <div className="file-path-wrapper">
          <input className="file-path validate" type="text" />
        </div>
      </div>
      <button className="btn" onClick={handleUpload} disabled={uploading}>
        {uploading ? 'Subiendo...' : 'Subir'}
      </button>
      {uploading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}
      {success && <p className="green-text">{success}</p>}
      {error && <p className="red-text">{error}</p>}
    </div>
  );
};

export default Carga_residentes;
