import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_planillas_recibos} from './planillas_recibosThunk';
import M from 'materialize-css';
import { Link } from 'react-router-dom';

import {formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';
import {getEstadoPagoButtonClass , getEstadoPagoIcon, downloadPDF} from '../../utils/util';


const MisPlanillas_recibos = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const planillas_recibos = useSelector((state) => state.planillas_recibos.planillas_recibos);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus  = useSelector((state) => state.planillas_recibos.fetchStatus );  
  const edificio = useSelector(state => state.edificio.edificio);  
  const error = useSelector((state) => state.planillas_recibos.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis planillas_recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("fetchStatus: "+ fetchStatus);
    console.log("fetchStatus " +fetchStatus+" edificio.buildingId: "+ edificio.id);
    if (fetchStatus  === 'idle' && edificio.id) {
      console.log("entro y va a llamar a get_planillas_recibos ");
      dispatch(get_planillas_recibos(edificio.id));
      
    }


  }, [usuario, navigate, fetchStatus , edificio, dispatch]);

  
  if (!usuario) {
    return null; // Return null while redirecting
  }

/*
  const formatearRecibos = (planillas_recibos) => {
    return planillas_recibos.map(planilla_recibos => ({
      ...planilla_recibos,
      fecha_emision_formato01: formatDate(planilla_recibos.fecha_emision),
      fecha_corte_formato01: formatDate(planilla_recibos.fecha_corte),
      periodo: formatPeriod(planilla_recibos.fecha_emision),
      importe_recibo_formato01: formatCurrency(planilla_recibos.importe_recibo),
    }));
  };
*/

  //const planillas_recibosFormateados = formatearRecibos(planillas_recibos);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  //const planillas_recibosFiltrados = planillas_recibosFormateados.filter((planilla) => {
  const planillas_recibosFiltrados = Array.isArray(planillas_recibos) ? planillas_recibos.filter((planilla) => {
    
    const periodoFormateado = formatearPeriodo_MesAnio01(planilla.periodo);
    const total_planillaFormateado=formatearImporte01(planilla.total_planilla, planilla.moneda)
    const fechaCreacionFormateado = formatDate02(planilla.fechaCreacion);
    const fechaAprobacionFormateado = formatDate02(planilla.fecha_aprobacion);
    const fechaEmisionFormateado = formatDate02(planilla.fecha_emision);
    
    const filterLowerCase = filtro.toLowerCase();

    return planilla.codigo_edificio.toLowerCase().includes(filterLowerCase) ||
      planilla.tipo_planilla.toLowerCase().includes(filterLowerCase) ||
      periodoFormateado.toLowerCase().includes(filterLowerCase) ||
      total_planillaFormateado.toLowerCase().includes(filterLowerCase)  ||
      planilla.recibos_planilla.toString().includes(filterLowerCase)  ||
      planilla.estado.includes(filterLowerCase)  ||
      fechaCreacionFormateado.includes(filterLowerCase)    ||
      fechaAprobacionFormateado.includes(filterLowerCase)    ||
      fechaEmisionFormateado.includes(filterLowerCase)    ||
      planilla.usuarioRegistrador.includes(filterLowerCase) 
      ;
  })
  : [];
  

  if (fetchStatus  === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus  === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (    
    <div className="container">
      <div className="card">
          <div className="card-content">
          <span className="card-title">Planillas de Recibos</span>
          <div className="card-action">
          
            <a className="btn orange tooltipped" data-position="top" data-tooltip="Nueva Planilla" onClick={() => navigate('/planilla-recibos/create')}>
              <i className="material-icons left">add_box</i>Crear Planilla
            </a>
            <a className="btn orange tooltipped" data-position="top" data-tooltip="Conceptos de facturación" onClick={() => navigate('/conceptos-facturacion')}>
              <i className="material-icons left">add_shopping_cart</i>Conceptos de Facturación
            </a>            
            <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/home')}>
              <i className="material-icons left">arrow_back</i>
            </a>              
          </div>
            
  
        <h5>Listado</h5>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_mis_planillas_recibos_propietario"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_mis_planillas_recibos_propietario" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_mis_planillas_recibos_propietario">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>Planilla</th>
          <th>Periodo</th>
          <th>Importe</th>
          <th>Recibos</th>
          <th>Estado</th>
          <th>Creación</th>
          <th>Aprobación</th>
          <th>Emisión</th>
          <th>Registrador</th>
        </tr>
      </thead>
      <tbody id="table-body-mis-planillas_recibos_propietario">                    
        {planillas_recibosFiltrados.map((planilla_recibos) => (
          <tr key={planilla_recibos.id}>
            <td>{planilla_recibos.codigo_edificio}</td>
            <td>{planilla_recibos.tipo_planilla}</td>
            <td>
                <Link to={`/planilla-recibos/view/${planilla_recibos.id}`}>
                  {formatearPeriodo_MesAnio01(planilla_recibos.periodo)}
                </Link>
            </td>
            <td>{formatearImporte01(planilla_recibos.total_planilla, planilla_recibos.moneda)}</td>
            <td>{planilla_recibos.recibos_planilla}</td>
            <td>{planilla_recibos.estado}</td>
            <td>{formatDate02(planilla_recibos.fechaCreacion)}</td>
            <td>{formatDate02(planilla_recibos.fechaAprobacion)}</td>
            <td>{formatDate02(planilla_recibos.fechaEmision)}</td>
            <td>{planilla_recibos.usuarioRegistrador}</td>
          </tr>
          ))}
      
      </tbody>
    </table>
    </div>   
          </div> 
    </div>
  );
};

export default MisPlanillas_recibos;