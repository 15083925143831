import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { get_publicacion, save_publicacion, update_publicacion } from './publicacionThunk';
import { uploadImageToStorage } from '../../utils/storage';
import './PublicacionPage.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { get_grupos_x_tipo } from '../medicamentos/grupoThunk';

const PublicacionPage = ({ mode }) => {
    const { id: idPublicacion } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const usuario = useSelector((state) => state.auth.usuario);
    const categorias_interes = useSelector((state) => state.grupo.grupos) || [];    
    const formas_comunicacion = useSelector((state) => state.grupo.formasComunicacion) || [];
    const buildingId = useSelector((state) => state.edificio.edificio?.id);
    const publicacion = useSelector((state) => state.publicacion.publicacion);

    const [form, setForm] = useState({
        titulo: '',
        descripcion: '',
        idcategoria_interes: '',
        categoria_interes: '',
        precio: '',
        estado: 'preparacion', // Estado inicial
        imagenes: [],
        formas_comunicacion: {},
        tipos_oferta: {
            regalo: false,
            descuento: false,
            novedades: false,
        },
        contacto: {
            celular: usuario?.celular || '',
            correo: usuario?.email || '',
        },
    });

    const [isLoading, setIsLoading] = useState(true);
    const [uploadingImage, setUploadingImage] = useState(false);

    useEffect(() => {
        if (buildingId) {
            dispatch(get_grupos_x_tipo({ buildingId, tipo: 'PREFERENCIAS' }));
            dispatch(get_grupos_x_tipo({ buildingId, tipo: 'FORMA_COMUNICACION' }));
        }
    }, [dispatch, buildingId]);

    useEffect(() => {
        if (mode === 'edit') {
            dispatch(get_publicacion(idPublicacion)).then((res) => {
                if (res.payload) {
                    setForm((prev) => ({
                        ...prev,
                        idcategoria_interes: res.payload.idcategoria_interes || '',
                        categoria_interes: categorias_interes.find(
                            (cat) => cat.id === res.payload.idcategoria_interes
                        )?.nombre || '',
                    }));
                    setTimeout(() => {
                        const elems = document.querySelectorAll('select');
                        M.FormSelect.init(elems);
                    }, 0);
                }
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [dispatch, idPublicacion, mode, categorias_interes]);

    useEffect(() => {
        if (mode === 'edit') { // Añadido modo 'edit'
            // **Escenario 3:** Modo "edit"
            console.log("Escenario 3: Modo 'edit'");
            if (publicacion) {
                setForm({
                    titulo: publicacion.titulo,
                    descripcion: publicacion.descripcion,
                    idcategoria_interes: publicacion.idcategoria_interes,
                    categoria_interes: publicacion.categoria_interes,
                    precio: publicacion.precio,
                    estado: publicacion.estado,
                    imagenes: publicacion.imagenes,
                    formas_comunicacion: publicacion.formas_comunicacion,
                    tipos_oferta: publicacion.tipos_oferta,
                    contacto: publicacion.contacto,
                });
                //setTurnosDisponibles(reserva.area_comun.turnos);        
              
            }
        }
    }, [mode, categorias_interes, publicacion]);  

    useEffect(() => {
        M.AutoInit();
        const elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [categorias_interes, formas_comunicacion]);

    const handleIdcategoria_interesChange = (e) => {
        const selectedIdcategoria_interes = e.target.value;
        setForm((prev) => ({
            ...prev,
            idcategoria_interes: selectedIdcategoria_interes,
        }));

        const selectedCategoria_interes = categorias_interes.find((categoria) => categoria.id === selectedIdcategoria_interes);
        if (selectedCategoria_interes) {
            setForm((prev) => ({
                ...prev,
                categoria_interes: selectedCategoria_interes.nombre,
            }));
        } else {
            setForm((prev) => ({
                ...prev,
                idcategoria_interes: '',
                categoria_interes: '',
            }));
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setForm((prev) => ({
            ...prev,
            contacto: {
                ...prev.contacto,
                [name]: value,
            },
        }));
    };

    const handleCheckboxChange = (group, name) => {
        setForm((prev) => {
            const updatedGroup = {
                ...prev[group],
                [name]: !prev[group][name],
            };

            // Si "regalo" está activo, establecer el precio en cero
            if (group === 'tipos_oferta' && name === 'regalo' && updatedGroup[name]) {
                return { ...prev, [group]: updatedGroup, precio: '0' };
            }

            return { ...prev, [group]: updatedGroup };
        });
    };

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setUploadingImage(true);
            try {
                const imageUrl = await uploadImageToStorage(file, `marketplace/${usuario?.id}`);
                setForm((prev) => ({ ...prev, imagenes: [...prev.imagenes, imageUrl] }));
                M.toast({ html: 'Imagen cargada exitosamente.', classes: 'green' });
            } catch (error) {
                M.toast({ html: 'Error al cargar la imagen.', classes: 'red' });
            } finally {
                setUploadingImage(false);
            }
        }
    };

    const handleDeleteImage = (index) => {
        setForm((prev) => ({
            ...prev,
            imagenes: prev.imagenes.filter((_, i) => i !== index),
        }));
    };

    const handleSave = () => {
        if (!form.titulo || !form.descripcion || !form.idcategoria_interes) {
            M.toast({ html: 'Por favor, completa todos los campos requeridos.', classes: 'red' });
            return;
        }

        if (mode === 'create') {
            const data = { ...form, buildingId, codigo_usuario: usuario.codigo_usuario, idVendedor: usuario.id };
            dispatch(save_publicacion(data)).then(() => {
                const message = 'Publicación creada exitosamente.';
                M.toast({ html: message, classes: 'green' });
                navigate(-1);
            });;
        } else {
            const data = { ...form, usuarioActualizacion: usuario.codigo_usuario };
            dispatch(update_publicacion({ idPublicacion, publicacionData: data })).then(() => {
                const message = 'Publicación actualizada exitosamente.';
                M.toast({ html: message, classes: 'green' });
                navigate(-1);
            });;
        }
    };

    if (isLoading) {
        return <div className="center-align">Cargando...</div>;
    }

    return (
        <div className="publicacion-page-container">
            <div className="page-header">
                <button className="btn-flat back-button" onClick={() => navigate(-1)}>
                    <i className="material-icons">arrow_back</i> Volver
                </button>
                <h3>{mode === 'create' ? 'Crear Publicación' : 'Editar Publicación'}</h3>
            </div>

            <div className="content">
                <div className="form-section">
                    <form>
                        {/* Título y Descripción */}
                        <div className="input-field">
                            <label className={form.titulo ? 'active' : ''}>Título</label>
                            <input
                                type="text"
                                name="titulo"
                                value={form.titulo}
                                onChange={handleInputChange}
                                placeholder="Título"
                            />
                        </div>
                        <div className="input-field">
                            <label className={form.descripcion ? 'active' : ''}>Descripción</label>
                            <textarea
                                name="descripcion"
                                value={form.descripcion}
                                onChange={handleInputChange}
                                className="materialize-textarea"
                                placeholder="Descripción"
                            ></textarea>
                        </div>
                        <div className="checkbox-group row">
                            <h5>Tipos de Oferta</h5>
                            {['regalo', 'descuento', 'novedades'].map((tipo) => (
                                <div key={tipo} className="col s6">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={form.tipos_oferta[tipo]}
                                            onChange={() => handleCheckboxChange('tipos_oferta', tipo)}
                                        />
                                        <span>{tipo.charAt(0).toUpperCase() + tipo.slice(1)}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                        {/* Categoría y Precio */}
                        <div className="row">
                            <div className="input-field col s12 m6">                               
                                <select
                                    name="idcategoria_interes"
                                    value={form.idcategoria_interes || ''} // Vincula al estado
                                    onChange={handleIdcategoria_interesChange} // Actualiza el estado al cambiar
                                >
                                    <option value="" disabled>Selecciona una Categoría</option>
                                    {categorias_interes.map((categoria) => (
                                        <option key={categoria.id} value={categoria.id}>
                                            {categoria.nombre}
                                        </option>
                                    ))}
                                </select>
                                <label>Categoría de Interés</label>


                            </div>
                            <div className="input-field col s12 m6">
                                <label className={form.precio ? 'active' : ''}>Precio</label>
                                <input
                                    type="number"
                                    name="precio"
                                    value={form.precio}
                                    onChange={handleInputChange}
                                    placeholder="Precio (opcional)"
                                />
                            </div>
                        </div>
                        <div className='row'>
                             {/* Formas de Comunicación */}
                        <div className="checkbox-group row">
                            <h5>Campaña publicitaria</h5>
                            {formas_comunicacion.map((forma) => (
                                <div key={forma.id} className="col s12 m6">
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={!!form.formas_comunicacion[forma.id]}
                                            onChange={() => handleCheckboxChange('formas_comunicacion', forma.id)}
                                        />
                                        <span>{forma.nombre}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                        </div>
                        {/* Estado */}
                        <div className="row">
                            <div className="input-field col s12">
                                <select
                                    name="estado"
                                    value={form.estado}
                                    onChange={handleInputChange}
                                >
                                    <option value="preparacion">Preparación</option>
                                    <option value="publicado">Publicado</option>
                                    <option value="pausado">Pausado</option>
                                    <option value="finalizado">Finalizado</option>
                                </select>
                                <label>Estado de la Publicación</label>
                            </div>
                        </div>

                        {/* Contacto */}
                        <div className="row">
                            <div className="input-field col s12 m6">
                                <label className={form.contacto.celular ? 'active' : ''}>Celular de Contacto</label>
                                <input
                                    type="text"
                                    name="celular"
                                    value={form.contacto.celular}
                                    onChange={handleContactChange}
                                    placeholder="Celular de Contacto"
                                />
                            </div>
                            <div className="input-field col s12 m6">
                                <label className={form.contacto.correo ? 'active' : ''}>Correo de Contacto</label>
                                <input
                                    type="email"
                                    name="correo"
                                    value={form.contacto.correo}
                                    onChange={handleContactChange}
                                    placeholder="Correo de Contacto"
                                />
                            </div>
                        </div>

                        {/* Imágenes */}
                        <div className="input-field" style={{ marginTop: '20px' }}>
                            <label className="active">Cargar Imágenes</label>
                            <input type="file" onChange={handleImageUpload} disabled={uploadingImage} />
                        </div>

                        <div className="action-buttons">
                            <button type="button" className="btn green" onClick={handleSave}>
                                {mode === 'edit' ? 'Actualizar' : 'Guardar'}
                            </button>
                            <button type="button" className="btn grey" onClick={() => navigate(-1)}>
                                Cancelar
                            </button>
                        </div>
                    </form>
                </div>

                {/* Vista previa */}
                <div className="preview-section">
                    <div className="preview-card">
                        <h5>{form.titulo || 'Título de la Publicación'}</h5>
                        <p>{form.descripcion || 'Aquí aparecerá la descripción de la publicación.'}</p>
                        <p><strong>Categoría:</strong> {form.categoria_interes || 'Sin categoría'}</p>
                        <p><strong>Precio:</strong> {form.precio ? `S/ ${form.precio}` : 'Gratuito'}</p>
                        <p><strong>Tipos de Oferta:</strong> {Object.keys(form.tipos_oferta).filter((key) => form.tipos_oferta[key]).join(', ') || 'Ninguno seleccionado'}</p>
                        <p><strong>WhtasApp:</strong> {form.contacto.celular}</p>
                        <p><strong>Correo de Contacto:</strong> {form.contacto.correo}</p>
                        <div className="images-preview">
                            {form.imagenes.map((url, index) => (
                                <div key={index} style={{ position: 'relative' }}>
                                    <img src={url} alt={`Imagen ${index + 1}`} />
                                    <button
                                        className="btn-floating btn-small red"
                                        style={{ position: 'absolute', top: 5, right: 5 }}
                                        onClick={() => handleDeleteImage(index)}
                                    >
                                        <i className="material-icons">close</i>
                                    </button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicacionPage;
