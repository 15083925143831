import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetJustificaciones_x_usuario } from './justificacionSlice';


export const update_justificacion = createAsyncThunk('justificacion/update_justificacion', async ({ idJustificacion, justificacionData }, thunkAPI) => {
  console.log("ejecutando thunk update_justificacion con idJustificacion: " + idJustificacion);

  try {

    const response = await apiClient.put(`/justificaciones/${idJustificacion}`, justificacionData);

    const justificacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetJustificaciones_x_usuario);// Resetear el estado para refetch de justificaciones

    return justificacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_justificacion = createAsyncThunk('justificacion/save_justificacion', async (justificacionData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/justificaciones`, justificacionData);

    const justificacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetJustificaciones_x_usuario);// Resetear el estado para refetch de justificaciones

    return justificacion;

  } catch (error) {
    
    const errorMessage = error.response?.data?.error || 'Error al crear la reunión.';
    console.log("errorMessage " + errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const validar_token_justificacion = createAsyncThunk(
  'justificacion/validar_token_justificacion',
  async ({ justificacionId, justificacionData }, thunkAPI) => {
      try {
          const response = await apiClient.post(`/justificaciones/${justificacionId}/validar-justificacion`, { justificacionData });
          return response.data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response?.data || 'Error al validar justificacion.');
      }
  }
);

export const anular_justificacion = createAsyncThunk('justificacion/anular_justificacion', async ({ idJustificacion, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_justificacion con idJustificacion: " + idJustificacion);

  try {

    const response = await apiClient.patch(`/justificaciones/${idJustificacion}/anular`, { usuarioAnulacion });

    const justificacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetJustificaciones_x_usuario);// Resetear el estado para refetch de justificaciones

    return justificacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_justificacion = createAsyncThunk('justificacion/get_justificacion', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_justificacion con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/justificaciones/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const justificacion = response.data;

    return justificacion;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_justificaciones_x_usuario = createAsyncThunk('justificacion/get_justificaciones_x_usuario', async ({ buildingId, codigo_usuario, estado }, thunkAPI) => {
    console.log("ejecutando thunk get_justificaciones con "+buildingId);
    
    try { 
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get('/justificaciones',{
          params: { 
            buildingId,
            codigo_usuario,
            estado
          }
      });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const justificaciones = response.data;
            
        return justificaciones ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
export const get_justificaciones_x_edificio = createAsyncThunk('justificacion/get_justificaciones_x_edificio', async ({ buildingId }, thunkAPI) => {
  console.log("ejecutando thunk get_justificaciones con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/justificaciones',{
        params: { 
          buildingId           
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const justificaciones = response.data;
          
      return justificaciones ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
