import { createSlice } from '@reduxjs/toolkit';
import {get_facturaciones_agua} from './facturaciones_aguaThunk';

const facturaciones_aguaSlice = createSlice({
    name: 'facturaciones_agua',
    initialState: {
      facturaciones_agua: [],
      fetchStatus : 'idle',
      error: null,
    },
    reducers: {
      resetStateGetFacturaciones_agua: () => {
        return {
          fetchStatus : 'idle',
        };
      },
    },
    extraReducers: (builder) => {
      builder
          .addCase(get_facturaciones_agua.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_facturaciones_agua.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.facturaciones_agua = action.payload;

          })
          .addCase(get_facturaciones_agua.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';              
              console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch receipts';
          });
    },
  });
    
  export const selectLectura_aguaById = (state, lectura_aguaId) =>
    state.facturaciones_agua.facturaciones_agua.find((lectura_agua) => lectura_agua.id === lectura_aguaId);
  
  export const { resetStateGetFacturaciones_agua } = facturaciones_aguaSlice.actions;

  export default facturaciones_aguaSlice.reducer;
