import { createSlice } from '@reduxjs/toolkit';
import {get_depositos_cargados_x_edificio, mover_depositos_cargados} from './depositos-cargadosThunk';

const depositos_cargadosSlice = createSlice({
    name: 'depositos_cargados',
    initialState: {
      depositos_cargados: [],
      //fetchStatus : 'idle',
      //error: null,
      status: {
        get_depositos_cargados_x_edificio: 'idle',
        moveValidDepositos: 'idle',
      },
      error: {
        get_depositos_cargados_x_edificio: null,
        moveValidDepositos: null,
      },
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_depositos_cargados_x_edificio.pending, (state) => {
              state.status.get_depositos_cargados_x_edificio  = 'loading';
          })
          .addCase(get_depositos_cargados_x_edificio.fulfilled, (state, action) => {
              state.status.get_depositos_cargados_x_edificio  = 'succeeded';
              console.log("Depositos cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.depositos_cargados = action.payload;

          })
          .addCase(get_depositos_cargados_x_edificio.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.status.get_depositos_cargados_x_edificio  = 'failed';              
              console.log("error action.error.message "+action.error.message );
              state.error.get_depositos_cargados_x_edificio = action.payload || 'Failed to fetch receipts';
          })
//
        .addCase(mover_depositos_cargados.pending, (state) => {
            state.status.mover_depositos_cargados  = 'loading';
        })
        .addCase(mover_depositos_cargados.fulfilled, (state, action) => {
            state.status.mover_depositos_cargados  = 'succeeded';
            //console.log("Depositos cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
            //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
            state.depositos_cargados = action.payload;

        })
        .addCase(mover_depositos_cargados.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
            state.status.mover_depositos_cargados  = 'failed';
            //console.log("error action.error.message "+action.error.message );
            state.error.mover_depositos_cargados = action.payload || 'Failed to fetch receipts';
        });

    },
  });
    
  export const selectDeposito_cargadoById = (state, deposito_cargadoId) =>
    state.depositos_cargados.depositos_cargados.find((deposito_cargado) => deposito_cargado.id === deposito_cargadoId);
  
  export default depositos_cargadosSlice.reducer;
