import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    get_preferencia,
    save_preferencia,
    update_preferencia,
} from './preferenciaThunk'; // Ajusta el path según tu estructura
import { get_grupos_x_tipo } from '../medicamentos/grupoThunk';
import './PreferencesPage.css';
import M from 'materialize-css/dist/js/materialize.min.js';

const PreferencesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const codigo_usuario = useSelector((state) => state.auth.usuario.id);
    const buildingId = useSelector((state) => state.edificio.edificio?.id);
    const categorias_interes = useSelector((state) => state.grupo.grupos) || [];
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    const codigo_unidad_facturacion = unidad_facturacionSeleccionado?.codigo_unidad_facturacion || '';

    const [preferences, setPreferences] = useState(null); // Estado inicial
    const [isLoading, setIsLoading] = useState(true);

    const preferenciaId = `${buildingId}_${codigo_usuario}`;

    useEffect(() => {
        M.AutoInit(); // Inicializa Materialize
        if (buildingId) {
            dispatch(get_grupos_x_tipo({ buildingId, tipo: 'PREFERENCIAS' }));
        }
    }, [dispatch, buildingId]);

    useEffect(() => {
        if (preferenciaId) {
            dispatch(get_preferencia(preferenciaId)).then((res) => {
                console.log("Preferencia cargada:", res.payload);
                if (res.payload?.categorias !== undefined) {
                    // Si el registro existe
                    setPreferences({
                        categorias: res.payload.categorias || [],
                        descuentos: res.payload.descuentos ?? false,
                        recibir_notificaciones: res.payload.recibir_notificaciones ?? false,
                        regalo: res.payload.regalo ?? false,
                        novedades: res.payload.novedades ?? false,
                        todas_las_ofertas: res.payload.todas_las_ofertas ?? false,
                        formas_comunicacion: {
                            correo: res.payload.formas_comunicacion?.correo ?? false,
                            whatsapp: res.payload.formas_comunicacion?.whatsapp ?? false,
                            sms: res.payload.formas_comunicacion?.sms ?? false,
                            plataforma: res.payload.formas_comunicacion?.plataforma ?? false,
                        },
                    });
                } else {
                    // Si no existe, inicializar con valores por defecto
                    console.log("No se encontró registro, inicializando valores predeterminados.");
                    setPreferences({
                        categorias: categorias_interes.map((cat) => ({ id: cat.id, nombre: cat.nombre })),
                        descuentos: true,
                        recibir_notificaciones: true,
                        regalo: true,
                        novedades: true,
                        todas_las_ofertas: true,
                        formas_comunicacion: {
                            correo: true,
                            whatsapp: true,
                            sms: true,
                            plataforma: true,
                        },
                    });
                }
                setIsLoading(false);
            });
        }
    }, [dispatch, preferenciaId, categorias_interes]);

    const handleRetornar = () => navigate(-1);

    const handleCheckboxChange = (category) => {
        setPreferences((prev) => ({
            ...prev,
            categorias: prev.categorias.some((cat) => cat.id === category.id)
                ? prev.categorias.filter((cat) => cat.id !== category.id)
                : [...prev.categorias, { id: category.id, nombre: category.nombre }],
        }));
    };

    const handleSelectAllCategories = (selectAll) => {
        setPreferences((prev) => ({
            ...prev,
            categorias: selectAll
                ? categorias_interes.map((cat) => ({ id: cat.id, nombre: cat.nombre }))
                : [],
        }));
    };

    const handleToggleOffers = (selectAll) => {
        setPreferences((prev) => ({
            ...prev,
            regalo: selectAll,
            descuentos: selectAll,
            novedades: selectAll,
            todas_las_ofertas: selectAll,
        }));
    };

    const handleToggleCommunication = (selectAll) => {
        setPreferences((prev) => ({
            ...prev,
            formas_comunicacion: {
                correo: selectAll,
                whatsapp: selectAll,
                sms: selectAll,
                plataforma: selectAll,
            },
        }));
    };

    const handleToggleChange = (e) => {
        const { name, checked } = e.target;
        setPreferences((prev) => ({
            ...prev,
            [name]: checked,
        }));
    };

    const handleSave = () => {
        const dataToSave = {
            ...preferences,
            id: preferenciaId,
            codigo_usuario,
            buildingId,
            codigo_unidad_facturacion,
        };

        if (preferences?.id) {
            // Si ya existe un registro, actualizarlo
            dispatch(update_preferencia({ idPreferencia: preferences.id, preferenciaData: dataToSave })).then(() => {
                M.toast({ html: 'Preferencias actualizadas exitosamente.', classes: 'green' });
            });
        } else {
            // Si no existe, crearlo
            dispatch(save_preferencia(dataToSave)).then(() => {
                M.toast({ html: 'Preferencias guardadas exitosamente.', classes: 'green' });
            });
        }
    };

    if (isLoading || !preferences) {
        return <div className="center-align">Cargando preferencias...</div>;
    }

    return (
        <div className="preferences-container">
            <a className="btn green" onClick={handleRetornar}>
                <i className="material-icons left">arrow_back</i>Volver
            </a>
            <h3 className="center-align">Gustos y Preferencias</h3>
            <form>
                {/* Áreas de Interés */}
                <div className="section">
                    <h5>Explorar Todo</h5>
                    <div className="row">
                        <div className="col s12">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={preferences.categorias.length === categorias_interes.length}
                                    onChange={(e) => handleSelectAllCategories(e.target.checked)}
                                />
                                <span>Seleccionar Todas las Categorías</span>
                            </label>
                        </div>
                        {categorias_interes.map((category) => (
                            <div key={category.id} className="col s6 m4">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={preferences.categorias.some((cat) => cat.id === category.id)}
                                        onChange={() => handleCheckboxChange(category)}
                                    />
                                    <span>{category.nombre}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Opciones de Ofertas */}
                <div className="section">
                    <h5>Tipos de Ofertas</h5>
                    <div className="row">
                        <div className="col s12">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={preferences.todas_las_ofertas}
                                    onChange={(e) => handleToggleOffers(e.target.checked)}
                                />
                                <span>Seleccionar Todas las Ofertas</span>
                            </label>
                        </div>
                        {["Regalo", "Descuentos", "Novedades"].map((oferta, index) => (
                            <div key={index} className="col s6 m4">
                                <label>
                                    <input
                                        type="checkbox"
                                        name={oferta.toLowerCase()}
                                        checked={preferences[oferta.toLowerCase()] || false}
                                        onChange={(e) => handleToggleChange(e)}
                                    />
                                    <span>{oferta}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Cómo Quiero Enterarme */}
                <div className="section">
                    <h5>Elegir Todas las Vías</h5>
                    <div className="row">
                        <div className="col s12">
                            <label>
                                <input
                                    type="checkbox"
                                    checked={Object.values(preferences.formas_comunicacion).every((val) => val)}
                                    onChange={(e) => handleToggleCommunication(e.target.checked)}
                                />
                                <span>Seleccionar Todas las Formas de Comunicación</span>
                            </label>
                        </div>
                        {["Correo", "WhatsApp", "SMS", "Plataforma"].map((medio, index) => (
                            <div key={index} className="col s6 m4">
                                <label>
                                    <input
                                        type="checkbox"
                                        name={`formas_comunicacion.${medio.toLowerCase()}`}
                                        checked={preferences.formas_comunicacion[medio.toLowerCase()] || false}
                                        onChange={(e) =>
                                            setPreferences((prev) => ({
                                                ...prev,
                                                formas_comunicacion: {
                                                    ...prev.formas_comunicacion,
                                                    [medio.toLowerCase()]: e.target.checked,
                                                },
                                            }))
                                        }
                                    />
                                    <span>{medio}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="section">
                    <button type="button" className="btn green" onClick={handleSave}>
                        Guardar Preferencias
                    </button>
                </div>
            </form>
        </div>
    );
};

export default PreferencesPage;
