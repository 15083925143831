import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetReuniones_x_edificio } from './reunionSlice';


export const update_reunion = createAsyncThunk('reunion/update_reunion', async ({ idReunion, reunionData }, thunkAPI) => {
  console.log("ejecutando thunk update_reunion con idReunion: " + idReunion);

  try {

    const response = await apiClient.put(`/reuniones/${idReunion}`, reunionData);

    const reunion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetReuniones_x_edificio(reunion.buildingId));// Resetear el estado para refetch de reuniones

    return reunion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_reunion = createAsyncThunk('reunion/save_reunion', async (reunionData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/reuniones`, reunionData);

    const reunion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetReuniones_x_edificio(reunion.buildingId));// Resetear el estado para refetch de reuniones

    return reunion;

  } catch (error) {
    
    const errorMessage = error.response?.data?.error || 'Error al crear la reunión.';
    console.log("errorMessage " + errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const validar_token_asistencia = createAsyncThunk(
  'reunion/validar_token_asistencia',
  async ({ reunionId, asistenciaData }, thunkAPI) => {
      try {
          const response = await apiClient.post(`/reuniones/${reunionId}/validar-asistencia`, { asistenciaData });
          return response.data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response?.data || 'Error al validar asistencia.');
      }
  }
);

export const anular_reunion = createAsyncThunk('reunion/anular_reunion', async ({ idReunion, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_reunion con idReunion: " + idReunion);

  try {

    const response = await apiClient.patch(`/reuniones/${idReunion}/anular`, { usuarioAnulacion });

    const reunion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetReuniones_x_edificio(reunion.buildingId));// Resetear el estado para refetch de reuniones

    return reunion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_reunion = createAsyncThunk('reunion/get_reunion', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_reunion con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/reuniones/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const reunion = response.data;

    return reunion;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_reuniones_x_edificio = createAsyncThunk('reunion/get_reuniones_x_edificio', async ({ buildingId }, thunkAPI) => {
  console.log("ejecutando thunk get_reuniones con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/reuniones',{
        params: { 
          buildingId           
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const reuniones = response.data;
          
      return reuniones ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
