import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import { storage } from '../../firebase';
import M from 'materialize-css'; // Importa Materialize CSS
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';


const Carga_config_unidades_facturacion = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  //const codigoDelEdificio = useSelector(state => state.edificio.edificio.codigo);
  const edificio = useSelector(state => state.edificio.edificio);
  const usuario = useSelector((state) => state.auth.usuario);

  useEffect(() => {
    // Inicializar Materialize JS components
    M.AutoInit();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async() => {
    if (!file) return;
    setUploading(true);
    setSuccess(null);
    setError(null);

    // Validar el archivo CSV antes de subirlo
    const validationResult = await validateCSVFile(file);
    if (!validationResult.valid) {
      setUploading(false);
      setError(validationResult.error);
      return;
    }

    const uniqueId = uuidv4();
    const fileName = `CARGA-CONFIG-UNID-FACTURACION-${edificio.codigo}-${uniqueId}.csv`;
    
     // Conectar al emulador de Storage si se está ejecutando en local
  if (window.location.hostname === 'localhost') {
    connectStorageEmulator(storage, 'localhost', 9199);
  }

    const storageRef = ref(storage, `CargaAportantes/${fileName}`);
    
    const metadata = {
      customMetadata: {
        buildingId: edificio.id,
        usuario_carga: usuario.id,
        uid_carga: uniqueId, // Optionally, add more user details
      }
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Optionally handle progress
      },
      (error) => {
        setUploading(false);
        setError(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUploading(false);
          setSuccess('File uploaded successfully!');
          triggerCloudFunction(downloadURL);
        });
      }
    );
  };

  const triggerCloudFunction = async (downloadURL) => {
    // Lógica para desencadenar la Cloud Function (si es necesario)
  };


    // Función para validar el archivo CSV antes de subirlo
    const validateCSVFile = async (csvFile) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const text = event.target.result;
          const lines = text.split(/\r?\n/);
  
          // Detectar el delimitador en la primera línea
          const delimiters = [',', ';'];
          let delimiter = delimiters.find(d => lines[0].includes(d)) || ',';
          console.log("validando header: ");
          // Validar la cabecera
          const header = lines[0].split(delimiter);
          //console.log("leyendo header: "+header);
          //console.log("leyendo header.length: "+header.length);
          if (header.length !== 5 ||
              header[0].trim() !== 'Edificio' || 			
              header[1].trim() !== 'Tipo_unidad_facturacion' ||
              header[2].trim() !== 'Codigo_unidad_facturacion' ||
              header[3].trim() !== 'Tipo_unidad_inmobiliaria' ||
              header[4].trim() !== 'Numero_unidad_inmobiliaria' ) {
            resolve({ valid: false, error: 'Cabecera inválida. Asegúrate de que la cabecera contenga las columnas "Edificio", "Tipo_unidad_facturacion", "Codigo_unidad_facturacion", "Tipo_unidad_inmobiliaria", "Numero_unidad_inmobiliaria".' });
            return;
          }
  
          // Validar cada línea de datos
          for (let i = 1; i < lines.length; i++) {
            const line = lines[i].split(delimiter);
            //console.log("validando total columnas line: "+line);
            if (line.length !== 5) {
              resolve({ valid: false, error: `Fila ${i + 1} no tiene el número correcto de columnas.` });
              return;
            }
            //console.log("validando codigo edificio: ");
            // Validar que el código de edificio exista en el store de Redux (reemplaza esto con tu lógica real)
            const codigoEdificio = line[0].trim();
            if (codigoEdificio !== edificio.codigo) {
              resolve({ valid: false, error: `El código de edificio en la fila ${i + 1} no coincide con el edificio actual ${edificio.codigo}.` });
              return;
            }
  
            // Validar que Piso no estén vacíos
            const tipo_unidad_facturacion = line[1].trim();
            if (!tipo_unidad_facturacion ) {
              resolve({ valid: false, error: `El tipo de unidad inmobiliaria en la fila ${i + 1} está vacío.` });
              return;
            }

            // Validar que Deposito no estén vacíos
            const codigo_unidad_facturacion = line[2].trim();
            if (!codigo_unidad_facturacion) {
              resolve({ valid: false, error: `El codigo de unidad inmobiliaria en la fila ${i + 1} está vacío.` });
              return;
            }
            
          }
  
          resolve({ valid: true });
        };
  
        reader.onerror = (event) => {
          reject('Error al leer el archivo.');
        };
  
        reader.readAsText(csvFile);
      });
    };

  return (
    <div className="container">
      <h3>Subir Archivo CSV</h3>
      <div className="file-field input-field">
        <div className="btn">
          <span>Archivo</span>
          <input type="file" accept=".csv" onChange={handleFileChange} />
        </div>
        <div className="file-path-wrapper">
          <input className="file-path validate" type="text" />
        </div>
      </div>
      <button className="btn" onClick={handleUpload} disabled={uploading}>
        {uploading ? 'Subiendo...' : 'Subir'}
      </button>
      {uploading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}
      {success && <p className="green-text">{success}</p>}
      {error && <p className="red-text">{error}</p>}
    </div>
  );
};

export default Carga_config_unidades_facturacion;
