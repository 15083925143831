import { createSlice } from '@reduxjs/toolkit';
import { password_reset, validar_token_reset, update_password, crear_usuarios_residentes } from './seguridadThunk';

const seguridadSlice = createSlice({
  name: 'seguridad',
  initialState: {
    status_password_reset: 'idle',
    status_validar_token_reset: 'idle',
    status_update_password: 'idle',
    status_crear_usuarios_residentes: 'idle',
    error_password_reset: null,
    error_validar_token_reset: null,
    error_update_password: null,
    error_crear_usuarios_residentes: null,
    json_resp_password_reset: null,
    json_resp_validar_token_reset: null,
    json_resp_update_password: null,
    json_resp_crear_usuarios_residentes: null,
  },
  reducers: {
    resetState: (state) => {
      state.status_password_reset = 'idle';
      state.status_validar_token_reset = 'idle';
      state.status_update_password = 'idle';
      state.status_crear_usuarios_residentes = 'idle';
      state.error_password_reset = null;
      state.error_validar_token_reset = null;
      state.error_update_password = null;
      state.error_crear_usuarios_residentes = null;
      state.json_resp_password_reset = null;
      state.json_resp_validar_token_reset = null;
      state.json_resp_update_password = null;
      state.json_resp_crear_usuarios_residentes = null;

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(password_reset.pending, (state) => {
        state.status_password_reset = 'loading';
        console.log("add case state.status.password_reset : " + state.status_password_reset);
      })
      .addCase(password_reset.fulfilled, (state, action) => {
        //state.status.password_reset  = 'succeeded';
        state.status_password_reset = 'succeeded';
        console.log("add case state.status.password_reset : " + state.status_password_reset);
        console.log("Respuesta PASSWORD_RESET JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        //state.depas_cargados = action.payload;
        if (action.payload.success) {
          state.status_password_reset = 'succeeded';
          state.json_resp_password_reset = action.payload;
        } else {
          state.status_password_reset = 'failed';
          state.error_password_reset = action.payload.message;
        }

      })
      .addCase(password_reset.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        //state.status.password_reset  = 'failed';              
        state.status_password_reset = 'failed';
        console.log("add case state.status.password_reset : " + state.status_password_reset);
        console.log("error action.error.message " + action.error.message);
        //state.error.password_reset = action.payload || 'Failed to fetch receipts';
        state.error_password_reset = action.payload || 'Failed to fetch receipts';
      })
      //
      .addCase(crear_usuarios_residentes.pending, (state) => {
        state.status_crear_usuarios_residentes = 'loading';
        console.log("add case state.status.password_reset : " + state.status_crear_usuarios_residentes);
      })
      .addCase(crear_usuarios_residentes.fulfilled, (state, action) => {
        //state.status.password_reset  = 'succeeded';
        state.status_crear_usuarios_residentes = 'succeeded';
        console.log("add case state.status.password_reset : " + state.status_crear_usuarios_residentes);
        console.log("Respuesta crear_usuarios_residentes JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        //state.depas_cargados = action.payload;
        if (action.payload.success) {
          state.status_crear_usuarios_residentes = 'succeeded';
          state.json_resp_crear_usuarios_residentes = action.payload;
        } else {
          state.status_crear_usuarios_residentes = 'failed';
          state.error_crear_usuarios_residentes = action.payload.message;
        }

      })
      .addCase(crear_usuarios_residentes.rejected, (state, action) => {
        console.log("ejecutando Slice crear_usuarios_residentes failed rejected ");
        //state.status.password_reset  = 'failed';              
        state.status_crear_usuarios_residentes = 'failed';
        console.log("add case state.status.crear_usuarios_residentes : " + state.status_crear_usuarios_residentes);
        console.log("error action.error.message " + action.error.message);
        //state.error.password_reset = action.payload || 'Failed to fetch receipts';
        state.error_crear_usuarios_residentes = action.payload || 'Failed to fetch receipts';
      })
      //
      .addCase(validar_token_reset.pending, (state) => {
        //state.status.validar_token_reset  = 'loading';
        state.status_validar_token_reset = 'loading';
      })
      .addCase(validar_token_reset.fulfilled, (state, action) => {
        state.status_validar_token_reset = 'succeeded';
        console.log("Depas cargados JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        //state.depas_cargados = action.payload;
        if (action.payload.success) {
          state.status_validar_token_reset = 'succeeded';
          state.json_resp_validar_token_reset = action.payload;
        } else {
          state.status_validar_token_reset = 'failed';
          state.error_validar_token_reset = action.payload.message;
        }
      })
      .addCase(validar_token_reset.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        //state.status.validar_token_reset  = 'failed';
        state.status_validar_token_reset = 'failed';
        console.log("VALIDANDO REJECTED JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("error action.error.message "+action.error.message );
        state.error_validar_token_reset = action.payload.error || ' Fallo al validar el token';
      })
      //
      .addCase(update_password.pending, (state) => {
        state.status_update_password = 'loading';
      })
      .addCase(update_password.fulfilled, (state, action) => {
        state.status_update_password = 'succeeded';
        console.log("Depas cargados JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        //state.depas_cargados = action.payload;
        if (action.payload.success) {
          state.status_update_password = 'succeeded';
          state.json_resp_update_password = action.payload;
        } else {
          state.status_update_password = 'failed';
          state.error_update_password = action.payload.message;
        }
      })
      .addCase(update_password.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        //state.status.validar_token_reset  = 'failed';
        state.status_update_password = 'failed';
        console.log("VALIDANDO REJECTED JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("error action.error.message "+action.error.message );
        state.error_update_password = action.payload.error || ' Fallo al actualizar password';
      });

  },
});
export const { resetState } = seguridadSlice.actions;
export default seguridadSlice.reducer;
