import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginUsuario } from '../auth/authThunk';
import { setEdificioSeleccionado } from '../edificio/edificioSlice';
import { setUnidad_facturacionSeleccionado } from '../edificio/unidad_facturacionSlice';
import { resetState } from '../auth/authSlice';
import './LoginPage.css'; // Importa el CSS aquí

const Login = () => {
  const [codigo_usuario, setCodigo_usuario] = useState('');
  const [password, setPassword] = useState('');
  const [loging, setLoging] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status, error } = useSelector((state) => state.auth);

  useEffect(() => {
    // Limpia el estado del usuario al entrar en LoginPage
    dispatch(resetState());
  }, [dispatch]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoging(true);

    const credencial = { codigo_usuario, password };

    try {
      const resultAction = await dispatch(loginUsuario(credencial));
      if (loginUsuario.fulfilled.match(resultAction)) {
        const { edificios, unidades_facturacion } = resultAction.payload;

        if (edificios.length === 1) {
          dispatch(setEdificioSeleccionado(edificios[0]));
          if (unidades_facturacion.length === 1) {
            dispatch(setUnidad_facturacionSeleccionado(unidades_facturacion[0]));
            navigate('/dashboard');
          }
        } else if (edificios.length > 1) {
          navigate('/edificioIngreso');
        } else {
          navigate('/about');
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoging(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') handleLogin(e);
  };

  return (
    <div id="cont_login" className="login">
      <form onSubmit={handleLogin} className="formulario">
        <h5 className="center">Iniciar Sesión</h5>
        <div className="input-field">
          <i className="material-icons prefix">person</i>
          <input
            id="cont_login_usuario"
            name="cont_login_usuario"
            value={codigo_usuario}
            type="text"
            onChange={(e) => setCodigo_usuario(e.target.value)}
            required
          />
          <label htmlFor="cont_login_usuario">Usuario</label>
        </div>
        <div className="input-field">
          <i className="material-icons prefix">vpn_key</i>
          <input
            id="cont_login_password"
            name="cont_login_password"
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyDown}
            required
          />
          <label htmlFor="cont_login_password">Contraseña</label>
        </div>
        <button
          type="submit"
          className="waves-effect waves-light btn"
          disabled={loging}
        >
          {loging ? 'Loging...' : 'Login'}
        </button>
        {error && <p className="red-text center">{error}</p>}
        <p className="center">
          <a href="/forgot-password">¿Olvidaste tu contraseña?</a>
        </p>
      </form>
    </div>
  );
};

export default Login;
