import { createSlice } from '@reduxjs/toolkit';
import { get_persona_asistencia, update_persona_asistencia, save_persona_asistencia, anular_persona_asistencia, get_personas_asistencia_x_depa } from './persona_asistenciaThunk';

const persona_asistenciaSlice = createSlice({
  name: 'persona_asistencia',
  initialState: {
    persona_asistencia: null,
    personas_asistencia: [],
    persona_asistenciaSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_personas_asistencia_x_depa: 'idle',
    error_get_personas_asistencia_x_depa: null,
    status_update_persona_asistencia: 'idle',
    error_update_persona_asistencia: null,    
    status_save_persona_asistencia: 'idle',
    error_save_persona_asistencia: null,
    status_anular_persona_asistencia: 'idle',
    error_anular_persona_asistencia: null,
  },
  reducers: {
    setPersona_asistenciaSeleccionada: (state, action) => {
      state.persona_asistenciaSeleccionada = action.payload;
      
    },
    clearPersona_asistenciaSeleccionada(state) {
      state.persona_asistenciaSeleccionada = null;
      state.persona_asistencia = null;
    },
    resetStateGetPersonas_asistencia_x_depa: () => {
        return {
            status_get_personas_asistencia_x_depa : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_persona_asistencia.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_persona_asistencia.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.persona_asistencia = action.payload;

      })
      .addCase(get_persona_asistencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_persona_asistencia.pending, (state) => {
        state.status_update_persona_asistencia = 'loading';
      })
      .addCase(update_persona_asistencia.fulfilled, (state, action) => {
        state.status_update_persona_asistencia = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.persona_asistencia = action.payload.persona_asistencia;

      })
      .addCase(update_persona_asistencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_persona_asistencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_persona_asistencia = action.payload || 'Failed to fetch building';
      })
      .addCase(save_persona_asistencia.pending, (state) => {
        state.status_save_persona_asistencia = 'loading';
      })
      .addCase(save_persona_asistencia.fulfilled, (state, action) => {
        state.status_save_persona_asistencia = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.persona_asistencia = action.payload.persona_asistencia;

      })
      .addCase(save_persona_asistencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_persona_asistencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_persona_asistencia = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_persona_asistencia.pending, (state) => {
        state.status_save_persona_asistencia = 'loading';
      })
      .addCase(anular_persona_asistencia.fulfilled, (state, action) => {
        state.status_anular_persona_asistencia = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.persona_asistencia = action.payload.persona_asistencia;

      })
      .addCase(anular_persona_asistencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_persona_asistencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_persona_asistencia = action.payload || 'Failed to fetch building';
      })
      .addCase(get_personas_asistencia_x_depa.pending, (state) => {
        state.status_get_personas_asistencia_x_depa = 'loading';
        
      })
      .addCase(get_personas_asistencia_x_depa.fulfilled, (state, action) => {
        state.status_get_personas_asistencia_x_depa = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_personas_asistencia_x_depa FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.personas_asistencia = action.payload;

      })
      .addCase(get_personas_asistencia_x_depa.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_personas_asistencia_x_depa = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_personas_asistencia_x_depa = action.payload || 'Failed to fetch building';
        state.personas_asistencia =[];
      })
  },
});

export const { setPersona_asistenciaSeleccionada, clearPersona_asistenciaSeleccionada } = persona_asistenciaSlice.actions;
export const selectPersona_asistenciasById = (state, idPersona_asistencia) =>
    state.persona_asistencia.personas_asistencia.find((persona_asistencia) => persona_asistencia.id === idPersona_asistencia);
export const { resetStateGetPersonas_asistencia_x_depa } = persona_asistenciaSlice.actions;
export default persona_asistenciaSlice.reducer;
