import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';


export const update_edificio = createAsyncThunk('edificio/update_edificio', async ({ buildingId, buildingData }, thunkAPI) => {
  console.log("ejecutando thunk update_edificio con buildingId: "+buildingId);
  
  try { 
      
      const response = await apiClient.put(`/edificios/${buildingId}`, buildingData);
      
      const edificio =  response.data;
      return edificio ;

    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const get_edificio_x_codigo = createAsyncThunk('edificio/get_edificio_x_codigo', async (codigo_edificio, thunkAPI) => {
    //console.log("ejecutando thunk get_recibos_x_depa con "+codigo_inmobiliario);
    
    try { 
      const codigo=codigo_edificio;
        console.log("ejecutando thunk codigo_edificio: "+codigo);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        //parametro pasado por path
        const response = await apiClient.get(`/edificios/${codigo}`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const edificio = response.data;
            
        return edificio ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
  export const get_edificio_x_codigoOLD2 = createAsyncThunk('edificio/get_edificio_x_codigo', async (codigo_edificio, thunkAPI) => {
    //console.log("ejecutando thunk get_recibos_x_depa con "+codigo_inmobiliario);
    
    try { 
      const codigo=codigo_edificio;
        console.log("ejecutando thunk codigo_edificio: "+codigo);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get('/edificios',{
            params: {codigo}
        });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const edificio = response.data;
            
        return edificio ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
