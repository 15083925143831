import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetContenidos_x_edificio } from './contenidoSlice';


export const update_contenido = createAsyncThunk('contenido/update_contenido', async ({ idContenido, contenidoData }, thunkAPI) => {
  console.log("ejecutando thunk update_contenido con idContenido: " + idContenido);

  try {

    const response = await apiClient.put(`/contenidos/${idContenido}`, contenidoData);

    const contenido = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetContenidos_x_edificio(contenido.buildingId));// Resetear el estado para refetch de contenidos

    return contenido;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_contenido = createAsyncThunk('contenido/save_contenido', async (contenidoData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/contenidos`, contenidoData);

    const contenido = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetContenidos_x_edificio(contenido.buildingId));// Resetear el estado para refetch de contenidos

    return contenido;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_contenido = createAsyncThunk('contenido/anular_contenido', async ({ idContenido, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_contenido con idContenido: " + idContenido);

  try {

    const response = await apiClient.patch(`/contenidos/${idContenido}/anular`, { usuarioAnulacion });

    const contenido = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetContenidos_x_edificio(contenido.buildingId));// Resetear el estado para refetch de contenidos

    return contenido;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_contenido = createAsyncThunk('contenido/get_contenido', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_contenido con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/contenidos/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const contenido = response.data;

    return contenido;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_contenidos_x_edificio = createAsyncThunk('contenido/get_contenidos_x_edificio', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_contenidos con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/contenidos',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const contenidos = response.data;
          
      return contenidos ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
