import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_residentes_cargados_x_edificio, mover_residentes_cargados} from './residentes-cargadosThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Residentes_cargados = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  //const Residentes_cargados = useSelector((state) => state.Residentes_cargados.Residentes_cargados);
  const { residentes_cargados, status, error } = useSelector((state) => state.residentes_cargados);
  const usuario = useSelector((state) => state.auth.usuario);
  //const fetchStatus  = useSelector((state) => state.residentes_cargados.fetchStatus );    
  //const error = useSelector((state) => state.residentes_cargados.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("status.get_residentes_cargados_x_edificio: "+ status.get_residentes_cargados_x_edificio);
    //console.log("codigo_edificio: "+ codigo_edificio);
    if (status.get_residentes_cargados_x_edificio  === 'idle' && codigo_edificio) {
      dispatch(get_residentes_cargados_x_edificio(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, status.get_residentes_cargados_x_edificio , codigo_edificio, dispatch]);

  const handleRefresh = () => {
    dispatch(get_residentes_cargados_x_edificio(codigo_edificio));
  };

  const formatearResidentes = (residentes) => {
    if (!Array.isArray(residentes)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return residentes.map(residente => ({
      ...residente,
      fecha_carga_formato01: formatDate02(residente.timestamp_carga),
    }));
  };

  const residentesFormateados = formatearResidentes(residentes_cargados);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const residentesFiltrados = Array.isArray(residentesFormateados) ? residentesFormateados.filter((residente) => {    
    const filterLowerCase = filtro.toLowerCase();
    return (
        residente.perfil?.toLowerCase().includes(filterLowerCase) ||
        residente.tipo_documento?.toLowerCase().includes(filterLowerCase) ||
        residente.numero_documento?.toLowerCase().includes(filterLowerCase) ||
        residente.titular?.toLowerCase().includes(filterLowerCase) ||
        residente.email?.toLowerCase().includes(filterLowerCase) ||
        residente.celular?.toLowerCase().includes(filterLowerCase) ||
        residente.tipo_usuario?.toLowerCase().includes(filterLowerCase) ||
        residente.usuario?.toLowerCase().includes(filterLowerCase) ||
        residente.roles?.toLowerCase().includes(filterLowerCase) ||
        residente.fecha_carga_formato01?.toString().includes(filterLowerCase) ||
        residente.mensaje_error_carga?.toString().includes(filterLowerCase) ||
        residente.validacion_carga?.includes(filterLowerCase)
    );
}) : [];

  const handleMoverresidentesValidos = async () => {
    let bodyRequest={
        buildingId: codigo_edificio,
        usuario_proceso: usuario.id
    };
    dispatch(mover_residentes_cargados(bodyRequest));    
    //dispatch(fetchDepartments()); // Actualiza la lista después de mover los departamentos válidos
    navigate('/residentes');
  };
  

  if (status.get_residentes_cargados_x_edificio  === 'loading') {
    return <div>Loading...</div>;
  }

  if (status.get_residentes_cargados_x_edificio  === 'failed') {
    return <div>Error: {error.get_residentes_cargados_x_edificio}</div>;
  }

  return (    
    <div className="container">
        <h5>residentes cargados</h5>
        <button className="btn" onClick={handleRefresh}>
        Refresh List
        </button>
        <button className="btn" onClick={handleMoverresidentesValidos}>
        Registrar
        </button>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_residentes_cargados"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_residentes_cargados" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_residentes_cargados">
      <thead>
        <tr>
        <th>Edificio</th>
          <th>Perfil</th>
          <th>TipDoc</th>
          <th>NumDoc</th>
          <th>Titular</th>
          <th>Email</th>
          <th>Celular</th>
          <th>TipUsua.</th>
          <th>Usuario</th>
          <th>Roles</th>
          <th>Validacion</th>
          <th>Mensaje</th>
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Fecha_carga</th>
        </tr>
      </thead>
      <tbody id="table-body-residentes-cargados">                    
        {residentesFiltrados.map((residente) => (
          <tr key={residente.id}>
            <td>{residente.codigo_edificio}</td>
            <td>{residente.perfil}</td>
            <td>{residente.tipo_documento}</td>
            <td>{residente.numero_documento}</td>
            <td>{residente.titular}</td>
            <td>{residente.email}</td>
            <td>{residente.celular}</td>
            <td>{residente.tipo_usuario}</td>
            <td>{residente.usuario}</td>
            <td>{residente.roles}</td>
            <td>{residente.validacion_carga}</td>
            <td>{residente.mensaje_error_carga}</td>
            <td>{residente.usuario_carga}</td>
            <td>{residente.uid_carga}</td>
            <td>{residente.fecha_carga_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Residentes_cargados;
