import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_config_unidades_facturacion} from './config_unidad_facturacionThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Config_unidades_facturacion = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  const config_unidades_facturacion = useSelector((state) => state.config_unidad_facturacion.config_unidades_facturacion);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus  = useSelector((state) => state.config_unidad_facturacion.fetchStatus );    
  const error = useSelector((state) => state.config_unidad_facturacion.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("fetchStatus: "+ fetchStatus);
    //console.log("codigo_edificio: "+ codigo_edificio);
    if (fetchStatus  === 'idle' && codigo_edificio) {
      dispatch(get_config_unidades_facturacion(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, fetchStatus , codigo_edificio, dispatch]);

  const handleRefresh = () => {    
    dispatch(get_config_unidades_facturacion(codigo_edificio));
  };

  const formatearUnidades_facturacion = (config_unidades_facturacion) => {
    if (!Array.isArray(config_unidades_facturacion)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return config_unidades_facturacion.map(config_unidad_facturacion => ({
      ...config_unidad_facturacion,
      fecha_registro_formato01: formatDate02(config_unidad_facturacion.timestamp_registro),
    }));
  };

  const config_unidades_facturacionFormateados = formatearUnidades_facturacion(config_unidades_facturacion);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const config_unidades_facturacionFiltrados = Array.isArray(config_unidades_facturacionFormateados) ? config_unidades_facturacionFormateados.filter((config_unidad_facturacion) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return config_unidad_facturacion.tipo_unidad_facturacion.toLowerCase().includes(filterLowerCase) ||
    config_unidad_facturacion.nombre_unidad_facturacion.toLowerCase().includes(filterLowerCase) ||
    config_unidad_facturacion.tipo_unidad_inmobiliaria.toString().toLowerCase().includes(filterLowerCase) ||
    config_unidad_facturacion.numero_unidad_inmobiliaria.toString().toLowerCase().includes(filterLowerCase) ||
    config_unidad_facturacion.fecha_registro_formato01.toString().includes(filterLowerCase)  ||
    config_unidad_facturacion.mensaje_error_carga.toString().includes(filterLowerCase) ||
    config_unidad_facturacion.validacion_carga.includes(filterLowerCase)
      ;
  })
  : [];

  if (fetchStatus  === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus  === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (    
    <div className="container">
        <h5>Config. Unidades de facturación</h5>
        <a className="btn green tooltipped" data-position="top" data-tooltip="Refrescar vista" onClick={() => handleRefresh()}>
              <i className="material-icons left">refresh</i>Refrescar
        </a>
        <a className="btn orange tooltipped" data-position="top" data-tooltip="Cargar Config. Unid. Facturación" onClick={() => navigate('/carga-config-unidades-facturacion')}>
              <i className="material-icons left">cloud_upload</i>Cargar Config. Unid. facturación
        </a>        
        <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/unidades-facturacion')}>
              <i className="material-icons left">arrow_back</i>
        </a>        
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_config_unidades_facturacion_cargados"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_config_unidades_facturacion_cargados" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_config_unidades_facturacion_cargados">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>TipFact.</th>
          <th>Unid.Fact.</th>       
          <th>TipUnid.</th>
          <th>NumeroUnid.</th>    
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Registro</th>
        </tr>
      </thead>
      <tbody id="table-body-config_unidades_facturacion-cargados">                    
        {config_unidades_facturacionFiltrados.map((config_unidad_facturacion) => (
          <tr key={config_unidad_facturacion.id}>
            <td>{config_unidad_facturacion.codigo_edificio}</td>
            <td>{config_unidad_facturacion.tipo_unidad_facturacion}</td>
            <td>{config_unidad_facturacion.nombre_unidad_facturacion}</td>        
            <td>{config_unidad_facturacion.tipo_unidad_inmobiliaria}</td>
            <td>{config_unidad_facturacion.numero_unidad_inmobiliaria}</td>    
            <td>{config_unidad_facturacion.usuario_carga}</td>
            <td>{config_unidad_facturacion.uid_carga}</td>
            <td>{config_unidad_facturacion.fecha_registro_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Config_unidades_facturacion;
