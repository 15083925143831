import { createSlice } from '@reduxjs/toolkit';
import { get_contacto_referencia, update_contacto_referencia, save_contacto_referencia, anular_contacto_referencia, get_contactos_referencia_x_edificio } from './contacto_referenciaThunk';

const contacto_referenciaSlice = createSlice({
  name: 'contacto_referencia',
  initialState: {
    contacto_referencia: null,
    contactos_referencia: [],
    contacto_referenciaSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_contactos_referencia_x_edificio: 'idle',
    error_get_contactos_referencia_x_edificio: null,
    status_update_contacto_referencia: 'idle',
    error_update_contacto_referencia: null,    
    status_save_contacto_referencia: 'idle',
    error_save_contacto_referencia: null,
    status_anular_contacto_referencia: 'idle',
    error_anular_contacto_referencia: null,
  },
  reducers: {
    setProtocoloSeleccionada: (state, action) => {
      state.contacto_referenciaSeleccionada = action.payload;
      
    },
    clearProtocoloSeleccionada(state) {
      state.contacto_referenciaSeleccionada = null;
      state.contacto_referencia = null;
    },
    resetStateGetContactos_referencia_x_edificio: () => {
        return {
            status_get_contactos_referencia_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_contacto_referencia.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_contacto_referencia.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.contacto_referencia = action.payload;

      })
      .addCase(get_contacto_referencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_contacto_referencia.pending, (state) => {
        state.status_update_contacto_referencia = 'loading';
      })
      .addCase(update_contacto_referencia.fulfilled, (state, action) => {
        state.status_update_contacto_referencia = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.contacto_referencia = action.payload.contacto_referencia;

      })
      .addCase(update_contacto_referencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_contacto_referencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_contacto_referencia = action.payload || 'Failed to fetch building';
      })
      .addCase(save_contacto_referencia.pending, (state) => {
        state.status_save_contacto_referencia = 'loading';
      })
      .addCase(save_contacto_referencia.fulfilled, (state, action) => {
        state.status_save_contacto_referencia = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.contacto_referencia = action.payload.contacto_referencia;

      })
      .addCase(save_contacto_referencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_contacto_referencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_contacto_referencia = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_contacto_referencia.pending, (state) => {
        state.status_save_contacto_referencia = 'loading';
      })
      .addCase(anular_contacto_referencia.fulfilled, (state, action) => {
        state.status_anular_contacto_referencia = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.contacto_referencia = action.payload.contacto_referencia;

      })
      .addCase(anular_contacto_referencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_contacto_referencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_contacto_referencia = action.payload || 'Failed to fetch building';
      })
      .addCase(get_contactos_referencia_x_edificio.pending, (state) => {
        state.status_get_contactos_referencia_x_edificio = 'loading';
        
      })
      .addCase(get_contactos_referencia_x_edificio.fulfilled, (state, action) => {
        state.status_get_contactos_referencia_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_contactos_referencia_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.contactos_referencia = action.payload;

      })
      .addCase(get_contactos_referencia_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_contactos_referencia_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_contactos_referencia_x_edificio = action.payload || 'Failed to fetch building';
        state.contactos_referencia =[];
      })
  },
});

export const { setProtocoloSeleccionada, clearProtocoloSeleccionada } = contacto_referenciaSlice.actions;
export const selectContactos_referencia_emergenciaById = (state, idProtocolo) =>
    state.contacto_referencia.contactos_referencia.find((contacto_referencia) => contacto_referencia.id === idProtocolo);
export const { resetStateGetContactos_referencia_x_edificio } = contacto_referenciaSlice.actions;
export default contacto_referenciaSlice.reducer;
