import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_publicacion } from './publicacionThunk';
import { get_lead_x_publicacion_comprador, save_lead, agregar_mensaje } from './leadThunk';
import './PublicacionDetallePage.css';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../firebase';

const PublicacionDetallePage = () => {
    const { id: idPublicacion } = useParams();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({});
    const [newMessage, setNewMessage] = useState('');
    const [messageFile, setMessageFile] = useState(null);
    const [uploading, setUploading] = useState(false);

    const publicacion = useSelector((state) => state.publicacion.publicacion);
    const usuario = useSelector((state) => state.auth.usuario);
    const lead = useSelector((state) => state.lead.lead);
    const status_get_lead_x_publicacion_comprador  = useSelector((state) => state.lead.status_get_lead_x_publicacion_comprador );

    useEffect(() => {
        dispatch(get_publicacion(idPublicacion));
        dispatch(get_lead_x_publicacion_comprador({ idPublicacion, idComprador: usuario.id }));
    }, [dispatch, idPublicacion, usuario]);

    useEffect(() => {
   
        if (status_get_lead_x_publicacion_comprador  === 'idle' && usuario.id && idPublicacion) {
          console.log("entro y va a llamar a get_lead_x_publicacion_comprador ");
          dispatch(get_lead_x_publicacion_comprador({ idPublicacion, idComprador: usuario.id }));       
        }
    
      }, [dispatch, status_get_lead_x_publicacion_comprador, idPublicacion, usuario]);

    useEffect(() => {
        if (lead) {
            // Si el lead existe, poblar formData con los datos del lead
            setFormData({
                titulo: lead.titulo,
                descripcion: lead.descripcion,
                contenido: lead.contenido,
                categoria_interes: lead.categoria_interes,
                precio: lead.precio,
                formas_comunicacion: lead.formas_comunicacion,
                tipos_oferta: lead.tipos_oferta,
                imagenes: lead.imagenes,
                mensajes: lead.mensajes || [],
            });
        } else if (publicacion) {
            // Si no hay lead, poblar formData con los datos de la publicación
            setFormData({
                titulo: publicacion.titulo,
                descripcion: publicacion.descripcion,
                contenido: '',
                categoria_interes: publicacion.categoria_interes,
                precio: publicacion.precio,
                formas_comunicacion: publicacion.formas_comunicacion,
                tipos_oferta: publicacion.tipos_oferta,
                imagenes: publicacion.imagenes,
                mensajes: [],
            });
        }
    }, [lead, publicacion]);

    const handleSendMessage = async () => {
        if (!newMessage.trim() && !messageFile) {
            M.toast({ html: 'Escribe un mensaje o adjunta un archivo.', classes: 'red' });
            return;
        }

        setUploading(true);

        try {
            let fileUrl = null;

            if (messageFile) {
                const fileRef = ref(storage, `leads/${idPublicacion}/${usuario.id}/${Date.now()}_${messageFile.name}`);
                await uploadBytes(fileRef, messageFile);
                fileUrl = await getDownloadURL(fileRef);
            }

            if (!lead) {
                const newLead = {
                    idPublicacion,
                    contenido: newMessage.trim() || null,
                    idComprador: usuario.id,
                    idVendedor: publicacion.idVendedor,
                    titulo: formData.titulo,
                    descripcion: formData.descripcion,
                    categoria_interes: formData.categoria_interes,
                    precio: formData.precio,
                    formas_comunicacion: formData.formas_comunicacion,
                    tipos_oferta: formData.tipos_oferta,
                    imagenes: formData.imagenes,
                    mensajes: [{
                        idUsuario: usuario.id,
                        contenido: newMessage.trim() || null,
                        archivoUrl: fileUrl || null,     
                    }],
                };

                await dispatch(save_lead(newLead));
                M.toast({ html: 'Lead creado exitosamente.', classes: 'green' });
            } else {
                const messageData = {
                    idUsuario: usuario.id,
                    contenido: newMessage.trim() || null,
                    archivoUrl: fileUrl || null,
                };

                await dispatch(agregar_mensaje({ idLead: lead.id, nuevoMensaje: messageData }));
                M.toast({ html: 'Mensaje enviado.', classes: 'green' });

                // Actualizar mensajes localmente en el formData
                setFormData((prevData) => ({
                    ...prevData,
                    mensajes: [...prevData.mensajes, { ...messageData, fecha_formateada: new Date().toLocaleString() }],
                }));
            }

            setNewMessage('');
            setMessageFile(null);
        } catch (error) {
            console.error('Error al enviar el mensaje:', error);
            M.toast({ html: 'Error al enviar el mensaje.', classes: 'red' });
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="publicacion-detalle-container">
            <div className="publicacion-detalle-header">
                <h1>{formData.titulo}</h1>
                <p>{formData.descripcion}</p>
                <p>
                    <strong>Precio:</strong>{' '}
                    {formData.tipos_oferta?.regalo ? 'Regalo' : `S/ ${formData.precio || '0.00'}`}
                </p>
            </div>

            <div className="mensajes-container">
                <h5>Conversación</h5>
                <div className="chat-box">
                    <div className="messages">
                        {/* Mostrar el contenido inicial del lead si existe */}
                        {formData?.contenido ? (
                            <div className="message-initial">
                                <p>{formData.contenido}</p>
                            </div>
                        ) : (
                            <p>No hay contenido inicial disponible.</p>
                        )}

                        {/* Mostrar mensajes adicionales */}
                        {Array.isArray(formData?.mensajes) && formData.mensajes.length > 0 ? (
                            formData.mensajes.map((mensaje, index) => (
                                <div
                                    key={index}
                                    className={`message ${mensaje.idUsuario === usuario.id ? 'message-out' : 'message-in'}`}
                                >
                                    <div className="message-content">
                                        <p>{mensaje.contenido}</p>
                                        {mensaje.archivoUrl && (
                                            <a href={mensaje.archivoUrl} target="_blank" rel="noopener noreferrer">
                                                Ver archivo adjunto
                                            </a>
                                        )}
                                        <span className="message-time">{mensaje.fecha_formateada}</span>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>No hay mensajes adicionales.</p>
                        )}
                    </div>
                </div>
            </div>


            <h5>Agregar Nuevo Mensaje</h5>
            <div className="new-message-section">
                <textarea
                    id="newMessage"
                    name="newMessage"
                    className="message-input"
                    placeholder="Escribe un mensaje..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                ></textarea>
                <label htmlFor="fileInput" className="file-upload-icon">
                    <i className="material-icons">attach_file</i>
                </label>
                <input
                    type="file"
                    id="fileInput"
                    className="file-input"
                    onChange={(e) => setMessageFile(e.target.files[0])}
                />
                <button
                    className={`send-button ${uploading ? 'disabled' : ''}`}
                    onClick={handleSendMessage}
                    disabled={uploading}
                >
                    {uploading ? <i className="material-icons spin">refresh</i> : <i className="material-icons">send</i>}
                </button>
            </div>
        </div>
    );
};

export default PublicacionDetallePage;
