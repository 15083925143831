import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectReciboById } from './recibosSlice';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { formatDate, formatCurrency, formatPeriod } from '../../utils/formateo';

const ReciboDetalle = () => {
  console.log("Iniciando detalle");
  const { id } = useParams();
  const recibo = useSelector((state) => selectReciboById(state, id));
  const dispatch = useDispatch();

  useEffect(() => {
    // Inicializar componentes de Materialize si es necesario
    M.AutoInit();
  }, []);

  console.log("ID:", id);

  const handleUpdate = () => {
    // Lógica para actualizar el recibo
    // dispatch(updateRecibo(recibo));
    M.toast({html: 'Funcionalidad de actualización no implementada aún.', classes: 'rounded'});
  };

  if (!recibo) {
    return (
      <div className="container center-align" style={{ marginTop: '50px' }}>
        <h5>Recibo no encontrado</h5>
      </div>
    );
  }

  return (
    <div className="container" style={{ marginTop: '30px' }}>
      <h3 className="center-align">Detalles del Recibo</h3>
      
      {/* Información General */}
      <div className="row">
        <div className="col s12 m6">
          <div className="card blue-grey darken-1">
            <div className="card-content white-text">
              <span className="card-title">Información General</span>
              <p><strong>Código Unidad Facturación:</strong> {recibo.codigo_unidad_facturacion || 'N/A'}</p>
              <p><strong>Número de Recibo:</strong> {recibo.numero_recibo || 'N/A'}</p>
              <p><strong>Periodo (Mes/Año):</strong> {recibo.periodo_mes_anio || 'N/A'}</p>
              <p><strong>Porcentaje Participación:</strong> {recibo.porcentaje_participacion || 'N/A'}%</p>              
            </div>
          </div>
        </div>

        {/* Fechas Importantes */}
        <div className="col s12 m6">
          <div className="card teal lighten-2">
            <div className="card-content white-text">
              <span className="card-title">Fechas Importantes</span>
              <p><strong>Fecha de Emisión:</strong> {formatDate(recibo.fecha_emision_timestamp)}</p>
              <p><strong>Fecha de Corte:</strong> {formatDate(recibo.fecha_corte_timestamp)}</p>
              <p><strong>Fecha de Vencimiento:</strong> {formatDate(recibo.fecha_vencimiento_timestamp)}</p>
            </div>
          </div>
        </div>
      </div>

      {/* Propietarios */}
      <div className="row">
        <div className="col s12 m6">
          <div className="card green lighten-2">
            <div className="card-content white-text">
              <span className="card-title">Propietarios</span>
              <p>{recibo.nombres_propietarios}</p>
            </div>            
          </div>
        </div>
      

      {/* Resumen de Propiedades */}
        <div className="col s12 m6">
          <div className="card orange lighten-2">
            <div className="card-content white-text">
              <span className="card-title">Resumen de Propiedades</span>
              <p>{recibo.resumen_propiedades || 'N/A'}</p>
            </div>
          </div>
        </div>
        </div>
      {/* Estado del Documento y Pago */}
      <div className="row">   

        <div className="col s12 m6">
          <div className={`card ${recibo.estado_pago === 'Pagado' ? 'green' : (recibo.estado_pago === 'Pendiente' ? 'orange' : 'red')} lighten-2`}>
            <div className="card-content white-text">
              <span className="card-title">Estado de Pago</span>
              <p>{recibo.estado_pago || 'N/A'}</p>
            </div>
          </div>
        </div>

        <div className="col s12 m6">
          <div className="card indigo lighten-2">
            <div className="card-content white-text">
              <span className="card-title">Importe del Recibo</span>
              <p><strong>Total:</strong> S/ {recibo.total_mes || '0.00'}</p>
            </div>
          </div>
        </div>

      </div>
   

      {/* Acciones */}
      <div className="row">
        <div className="col s12 center-align">
          <button className="btn waves-effect waves-light blue" onClick={handleUpdate}>
            <i className="material-icons left">edit</i>Actualizar
          </button>
          <button className="btn waves-effect waves-light orange" style={{ marginLeft: '10px' }} onClick={() => M.toast({html: 'Funcionalidad de Reclamo no implementada aún.', classes: 'rounded'})}>
            <i className="material-icons left">report_problem</i>Reclamo
          </button>
          <button className="btn waves-effect waves-light green" style={{ marginLeft: '10px' }} onClick={() => M.toast({html: 'Funcionalidad de Pago no implementada aún.', classes: 'rounded'})}>
            <i className="material-icons left">payment</i>Pagar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReciboDetalle;
