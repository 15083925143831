import { createSlice } from '@reduxjs/toolkit';
import { get_facturacion_agua, update_facturacion_agua, save_facturacion_agua, anular_facturacion_agua } from './facturacion_aguaThunk';

const facturacion_aguaSlice = createSlice({
  name: 'facturacion_agua',
  initialState: {
    facturacion_agua: null,
    facturacion_aguaSeleccionado: null,
    status: 'idle',
    error: null,
    status_update_facturacion_agua: 'idle',
    error_update_facturacion_agua: null,
    status_save_facturacion_agua: 'idle',
    error_save_facturacion_agua: null,
    status_anular_facturacion_agua: 'idle',
    error_anular_facturacion_agua: null,

  },
  reducers: {
    setFacturacion_aguaSeleccionado: (state, action) => {
      state.facturacion_aguaSeleccionado = action.payload;
      
    },
    clearFacturacion_aguaSeleccionado(state) {
      state.facturacion_aguaSeleccionado = null;
      state.facturacion_agua = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_facturacion_agua.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_facturacion_agua.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.facturacion_agua = action.payload;

      })
      .addCase(get_facturacion_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_facturacion_agua.pending, (state) => {
        state.status_update_facturacion_agua = 'loading';
      })
      .addCase(update_facturacion_agua.fulfilled, (state, action) => {
        state.status_update_facturacion_agua = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.facturacion_agua = action.payload.facturacion_agua;

      })
      .addCase(update_facturacion_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_facturacion_agua = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_facturacion_agua = action.payload || 'Failed to fetch building';
      })
      .addCase(save_facturacion_agua.pending, (state) => {
        state.status_save_facturacion_agua = 'loading';
      })
      .addCase(save_facturacion_agua.fulfilled, (state, action) => {
        state.status_save_facturacion_agua = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.facturacion_agua = action.payload.facturacion_agua;

      })
      .addCase(save_facturacion_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_facturacion_agua = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_facturacion_agua = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_facturacion_agua.pending, (state) => {
        state.status_save_facturacion_agua = 'loading';
      })
      .addCase(anular_facturacion_agua.fulfilled, (state, action) => {
        state.status_anular_facturacion_agua = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.facturacion_agua = action.payload.facturacion_agua;

      })
      .addCase(anular_facturacion_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_facturacion_agua = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_facturacion_agua = action.payload || 'Failed to fetch building';
      })

  },
});

export const { setFacturacion_aguaSeleccionado, clearFacturacion_aguaSeleccionado } = facturacion_aguaSlice.actions;
export default facturacion_aguaSlice.reducer;
