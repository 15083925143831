import { createSlice } from '@reduxjs/toolkit';
import { get_encuesta, update_encuesta, save_encuesta, anular_encuesta, get_encuestas_x_edificio } from './encuestaThunk';

const encuestaSlice = createSlice({
  name: 'encuesta',
  initialState: {
    encuesta: null,
    encuestas: [],
    encuestaSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_encuestas_x_edificio: 'idle',
    error_get_encuestas_x_edificio: null,
    status_update_encuesta: 'idle',
    error_update_encuesta: null,    
    status_save_encuesta: 'idle',
    error_save_encuesta: null,
    status_anular_encuesta: 'idle',
    error_anular_encuesta: null,
  },
  reducers: {
    setEncuestaSeleccionada: (state, action) => {
      state.encuestaSeleccionada = action.payload;
      
    },
    clearEncuestaSeleccionada(state) {
      state.encuestaSeleccionada = null;
      state.encuesta = null;
    },
    resetStateGetEncuestas_x_edificio: () => {
        return {
            status_get_encuestas_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_encuesta.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_encuesta.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.encuesta = action.payload;

      })
      .addCase(get_encuesta.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_encuesta.pending, (state) => {
        state.status_update_encuesta = 'loading';
      })
      .addCase(update_encuesta.fulfilled, (state, action) => {
        state.status_update_encuesta = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.encuesta = action.payload.encuesta;

      })
      .addCase(update_encuesta.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_encuesta = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_encuesta = action.payload || 'Failed to fetch building';
      })
      .addCase(save_encuesta.pending, (state) => {
        state.status_save_encuesta = 'loading';
      })
      .addCase(save_encuesta.fulfilled, (state, action) => {
        state.status_save_encuesta = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.encuesta = action.payload.encuesta;

      })
      .addCase(save_encuesta.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_encuesta = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_encuesta = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_encuesta.pending, (state) => {
        state.status_save_encuesta = 'loading';
      })
      .addCase(anular_encuesta.fulfilled, (state, action) => {
        state.status_anular_encuesta = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.encuesta = action.payload.encuesta;

      })
      .addCase(anular_encuesta.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_encuesta = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_encuesta = action.payload || 'Failed to fetch building';
      })
      .addCase(get_encuestas_x_edificio.pending, (state) => {
        state.status_get_encuestas_x_edificio = 'loading';
        
      })
      .addCase(get_encuestas_x_edificio.fulfilled, (state, action) => {
        state.status_get_encuestas_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_encuestas_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.encuestas = action.payload;

      })
      .addCase(get_encuestas_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_encuestas_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_encuestas_x_edificio = action.payload || 'Failed to fetch building';
        state.encuestas =[];
      })
  },
});

export const { setEncuestaSeleccionada, clearEncuestaSeleccionada } = encuestaSlice.actions;
export const selectEncuestas_emergenciaById = (state, idEncuesta) =>
    state.encuesta.encuestas.find((encuesta) => encuesta.id === idEncuesta);
export const { resetStateGetEncuestas_x_edificio } = encuestaSlice.actions;
export default encuestaSlice.reducer;
