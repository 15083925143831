import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

  export const get_depas_cargados_x_edificio = createAsyncThunk('depas-carga/get_depas_cargados_x_edificio', async (codigo_edificio, thunkAPI) => {
    //console.log("ejecutando thunk registrar_depas_cargados con "+codigo_edificio);
    
    try { 
       // console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/depas-cargados`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const depas_carga = response.data;
            
        return depas_carga ;
      } catch (error) {
        //console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const mover_depas_cargados = createAsyncThunk('depas-carga/mover_depas_cargados', async (jsonBodyRequest, thunkAPI) => {
    //console.log("ejecutando thunk mover_depas_cargados con "+JSON.stringify(jsonBodyRequest));
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.post('/depas-carga/registro',jsonBodyRequest);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const resp_registro = response.data;
            
        return resp_registro;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  
