import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_unidades_inmobiliarias_cargadas, mover_unidades_inmobiliarias_cargadas} from './unidades-inmobiliarias-cargadasThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Unidades_inmobiliarias_cargadas = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  //const unidades_inmobiliarias_cargadas = useSelector((state) => state.unidades_inmobiliarias_cargadas.unidades_inmobiliarias_cargadas);
  const { unidades_inmobiliarias_cargadas, status, error } = useSelector((state) => state.unidades_inmobiliarias_cargadas);
  const usuario = useSelector((state) => state.auth.usuario);
  //const fetchStatus  = useSelector((state) => state.unidades_inmobiliarias_cargadas.fetchStatus );    
  //const error = useSelector((state) => state.unidades_inmobiliarias_cargadas.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("status.get_unidades_inmobiliarias_cargadas: "+ status.get_unidades_inmobiliarias_cargadas);
    //console.log("codigo_edificio: "+ codigo_edificio);
    if (status.get_unidades_inmobiliarias_cargadas  === 'idle' && codigo_edificio) {
      dispatch(get_unidades_inmobiliarias_cargadas(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, status.get_unidades_inmobiliarias_cargadas , codigo_edificio, dispatch]);

  const handleRefresh = () => {
    dispatch(get_unidades_inmobiliarias_cargadas(codigo_edificio));
  };

  const formatearDepas = (unidades_inmobiliarias) => {
    if (!Array.isArray(unidades_inmobiliarias)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return unidades_inmobiliarias.map(unidad_inmobiliaria => ({
      ...unidad_inmobiliaria,
      fecha_carga_formato01: formatDate02(unidad_inmobiliaria.timestamp_carga),
    }));
  };

  const depasFormateados = formatearDepas(unidades_inmobiliarias_cargadas);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const depasFiltrados = Array.isArray(depasFormateados) ? depasFormateados.filter((unidad_inmobiliaria) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return unidad_inmobiliaria.tipo_unidad_inmobiliaria.includes(filterLowerCase) ||
        unidad_inmobiliaria.numero_unidad_inmobiliaria.toLowerCase().includes(filterLowerCase) ||
        unidad_inmobiliaria.nivel.toString().toLowerCase().includes(filterLowerCase) ||
        unidad_inmobiliaria.tipo_nivel.toString().toLowerCase().includes(filterLowerCase) ||
        unidad_inmobiliaria.area_ocupada.toString().toLowerCase().includes(filterLowerCase) ||
        unidad_inmobiliaria.area_techada.toString().toLowerCase().includes(filterLowerCase) ||
        unidad_inmobiliaria.fecha_carga_formato01.toString().includes(filterLowerCase)  ||
        unidad_inmobiliaria.mensaje_error_carga.toString().includes(filterLowerCase) ||
        unidad_inmobiliaria.validacion_carga.includes(filterLowerCase)
      ;
  })
  : [];

  const handleMoveValidUnidades_inmobiliarias = async () => {
    let bodyRequest={
        buildingId: codigo_edificio,
        usuario_proceso: usuario.id
    };
    dispatch(mover_unidades_inmobiliarias_cargadas(bodyRequest));    
    //dispatch(fetchDepartments()); // Actualiza la lista después de mover los departamentos válidos
    navigate('/unidades-inmobiliarias');
  };
  

  if (status.get_unidades_inmobiliarias_cargadas  === 'loading') {
    return <div>Loading...</div>;
  }

  if (status.get_unidades_inmobiliarias_cargadas  === 'failed') {
    //return <div>Error: {error.get_unidades_inmobiliarias_cargadas}</div>;
    return <div>Error: {error.get_unidades_inmobiliarias_cargadas.error} </div>;
  }

  return (    
    <div className="container">
        <h5>Unid. Inmobiliarias cargadas</h5>
        <button className="btn" onClick={handleRefresh}>
        Refresh List
        </button>
        <button className="btn" onClick={handleMoveValidUnidades_inmobiliarias}>
        Registrar
        </button>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_unidades_inmobiliarias_cargadas"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_unidades_inmobiliarias_cargadas" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_unidades_inmobiliarias_cargadas">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>TipUnid.</th>
          <th>NumeroUnid.</th>   
          <th>Nivel</th>
          <th>Tip_niv</th>          
          <th>Area_ocupada</th>
          <th>Area_techada</th>                    
          <th>Validacion</th>
          <th>Mensaje</th>
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Fecha_carga</th>
        </tr>
      </thead>
      <tbody id="table-body-depas-cargados">                    
        {depasFiltrados.map((unidad_inmobiliaria) => (
          <tr key={unidad_inmobiliaria.id}>
            <td>{unidad_inmobiliaria.codigo_edificio}</td>
            <td>{unidad_inmobiliaria.tipo_unidad_inmobiliaria}</td>
            <td>{unidad_inmobiliaria.numero_unidad_inmobiliaria}</td>
            <td>{unidad_inmobiliaria.nivel}</td>
            <td>{unidad_inmobiliaria.tipo_nivel}</td>            
            <td>{unidad_inmobiliaria.area_ocupada}</td>
            <td>{unidad_inmobiliaria.area_techada}</td>
            <td>{unidad_inmobiliaria.validacion_carga}</td>
            <td>{unidad_inmobiliaria.mensaje_error_carga}</td>
            <td>{unidad_inmobiliaria.usuario_carga}</td>
            <td>{unidad_inmobiliaria.uid_carga}</td>
            <td>{unidad_inmobiliaria.fecha_carga_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Unidades_inmobiliarias_cargadas;
