import { createSlice } from '@reduxjs/toolkit';
import {get_personas_cargadas_x_edificio, mover_personas_cargadas} from './personas-cargadasThunk';

const personas_cargadasSlice = createSlice({
    name: 'personas_cargadas',
    initialState: {
      personas_cargadas: [],
      //fetchStatus : 'idle',
      //error: null,
      status: {
        get_personas_cargadas_x_edificio: 'idle',
        moveValidpersonas: 'idle',
      },
      error: {
        get_personas_cargadas_x_edificio: null,
        moveValidDepartments: null,
      },
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_personas_cargadas_x_edificio.pending, (state) => {
              state.status.get_personas_cargadas_x_edificio  = 'loading';
          })
          .addCase(get_personas_cargadas_x_edificio.fulfilled, (state, action) => {
              state.status.get_personas_cargadas_x_edificio  = 'succeeded';
              //console.log("Depas cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.personas_cargadas = action.payload;

          })
          .addCase(get_personas_cargadas_x_edificio.rejected, (state, action) => {
            //console.log("ejecutando Slice failed rejected ");
              state.status.get_personas_cargadas_x_edificio  = 'failed';              
              //console.log("error action.error.message "+action.error.message );
              state.error.get_personas_cargadas_x_edificio = action.payload || 'Failed to fetch receipts';
          })
//
        .addCase(mover_personas_cargadas.pending, (state) => {
            state.status.mover_personas_cargadas  = 'loading';
        })
        .addCase(mover_personas_cargadas.fulfilled, (state, action) => {
            state.status.mover_personas_cargadas  = 'succeeded';
            //console.log("Depas cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
            //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
            state.personas_cargadas = action.payload;

        })
        .addCase(mover_personas_cargadas.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
            state.status.mover_personas_cargadas  = 'failed';
            //console.log("error action.error.message "+action.error.message );
            state.error.mover_personas_cargadas = action.payload || 'Failed to fetch receipts';
        });

    },
  });
    
  export const selectResidente_cargadoById = (state, persona_cargadoId) =>
    state.depas_cargados.personas_cargadas.find((persona_cargado) => persona_cargado.id === persona_cargadoId);
  
  export default personas_cargadasSlice.reducer;
