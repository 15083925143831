import { storage } from '../firebase'; // Importa el storage de Firebase
import { ref, getDownloadURL } from "firebase/storage"; // Importa las funciones necesarias
import {
  RECIBO_ESTADO_PAGO_Pendiente, RECIBO_ESTADO_PAGO_PorConfirmar, RECIBO_ESTADO_PAGO_Confirmado, RECIBO_ESTADO_PAGO_Parcial
  , RECIBO_ESTADO_PAGO_Observado, ROL_PERFIL, ROL_MANTENIMIENTO, ROL_MARKETPLACE, ROL_PERIODICO
} from '../constants/constants';

export const downloadPDF = async (pdfPath) => {
  try {
    const pdfRef = ref(storage, pdfPath);
    const url = await getDownloadURL(pdfRef);
    window.open(url, '_blank');
  } catch (error) {
    console.error('Error fetching the PDF file:', error);
  }
};
export const formatPeriodo = (date) => {
  // Función para formatear la fecha a YYYY-MM
  //recibe date, retorna YYYY-MM

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Asegura que el mes tenga 2 dígitos
  return `${year}-${month}`;
};
 // Función para convertir de YYYY-MM a un objeto Date
 export const parseFromYYYYMMOLD = (yyyyMM) => {
  console.log(" parseFromYYYYMM - yyyyMM: "+yyyyMM);

  const [year, month] = yyyyMM.split('-').map(Number);

  //console.log(" parseFromYYYYMM - year: "+year);
  //console.log(" parseFromYYYYMM - month: "+month);

  return new Date(year, month - 1); // El mes está indexado desde 0 en Date
};
export const parseFromYYYYMM = (yyyyMM) => {
  if (typeof yyyyMM === 'string') {
    // Si es un string en formato YYYY-MM, dividirlo y devolver un Date
    const [year, month] = yyyyMM.split('-').map(Number);
    return new Date(year, month - 1); // El mes en Date es indexado desde 0
  } else if (yyyyMM instanceof Date) {
    // Si es un objeto Date, devolverlo directamente
    return yyyyMM;
  } else {
    throw new Error("Formato inválido para el periodo");
  }
};
export const getEstadoPagoButtonClass = (estado) => {
  switch (estado) {
    case RECIBO_ESTADO_PAGO_Pendiente:
      return 'btn orange darken-2';
    case RECIBO_ESTADO_PAGO_PorConfirmar:
      return 'btn yellow darken-2';
    case RECIBO_ESTADO_PAGO_Confirmado:
      return 'btn green darken-2';
    case RECIBO_ESTADO_PAGO_Parcial:
      return 'btn blue darken-2';
    case RECIBO_ESTADO_PAGO_Observado:
      return 'btn red darken-2';
    default:
      return 'btn grey';
  }
};
// Validar si el email es válido
export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Validar si el código es válido (sin caracteres extraños ni espacios)
export const isValidCode = (code) => {
  const codeRegex = /^[a-zA-Z0-9]+$/;
  return codeRegex.test(code);
};

// Validar roles
const validRoles = [ROL_PERFIL, ROL_MANTENIMIENTO, ROL_MARKETPLACE, ROL_PERIODICO];
export const isValidRole = (role) => {
  return validRoles.includes(role);
};

export const getEstadoPagoIcon = (estado) => {
  switch (estado) {
    case RECIBO_ESTADO_PAGO_Confirmado:
      //return <i className="material-icons left green-text">check_circle</i>;
      return <i className="material-icons left">check_circle</i>;
    case RECIBO_ESTADO_PAGO_Pendiente:
      return <i className="material-icons left">hourglass_empty</i>;
    case RECIBO_ESTADO_PAGO_Observado:
      return <i className="material-icons left">warning</i>;
    default:
      return <i className="material-icons left">help_outline</i>;
  }
};