import { createSlice } from '@reduxjs/toolkit';
import { get_respuesta, update_respuesta, save_respuesta, anular_respuesta, get_respuestas_x_edificio, get_respuestas_x_usuario } from './respuestaThunk';

const respuestaSlice = createSlice({
    name: 'respuesta',
    initialState: {
        respuesta: null,
        respuestas: [],
        respuestaSeleccionada: null,
        status: 'idle',
        error: null,
        status_get_respuestas_x_edificio: 'idle',
        error_get_respuestas_x_edificio: null,
        status_get_respuestas_x_usuario: 'idle',
        error_get_respuestas_x_usuario: null,
        status_update_respuesta: 'idle',
        error_update_respuesta: null,
        status_save_respuesta: 'idle',
        error_save_respuesta: null,
        status_anular_respuesta: 'idle',
        error_anular_respuesta: null,
    },
    reducers: {
        setRespuestaSeleccionada: (state, action) => {
            state.respuestaSeleccionada = action.payload;

        },
        clearRespuestaSeleccionada(state) {
            state.respuestaSeleccionada = null;
            state.respuesta = null;
        },
        resetStateGetRespuestas_x_edificio: () => {
            return {
                status_get_respuestas_x_edificio: 'idle',
            };
        },
        resetStateGetRespuestas_x_usuario: () => {
            return {
                status_get_respuestas_x_usuario: 'idle',
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_respuesta.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(get_respuesta.fulfilled, (state, action) => {
                state.status = 'succeeded';
                //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.respuesta = action.payload;

            })
            .addCase(get_respuesta.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error = action.payload || 'Failed to fetch building';
            })

            .addCase(update_respuesta.pending, (state) => {
                state.status_update_respuesta = 'loading';
            })
            .addCase(update_respuesta.fulfilled, (state, action) => {
                state.status_update_respuesta = 'succeeded';
                //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.respuesta = action.payload.respuesta;

            })
            .addCase(update_respuesta.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_update_respuesta = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_update_respuesta = action.payload || 'Failed to fetch building';
            })
            .addCase(save_respuesta.pending, (state) => {
                state.status_save_respuesta = 'loading';
            })
            .addCase(save_respuesta.fulfilled, (state, action) => {
                state.status_save_respuesta = 'succeeded';
                //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.respuesta = action.payload.respuesta;

            })
            .addCase(save_respuesta.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_save_respuesta = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_save_respuesta = action.payload || 'Failed to fetch building';
            })
            .addCase(anular_respuesta.pending, (state) => {
                state.status_save_respuesta = 'loading';
            })
            .addCase(anular_respuesta.fulfilled, (state, action) => {
                state.status_anular_respuesta = 'succeeded';
                //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
                state.respuesta = action.payload.respuesta;

            })
            .addCase(anular_respuesta.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_anular_respuesta = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_anular_respuesta = action.payload || 'Failed to fetch building';
            })           

            .addCase(get_respuestas_x_edificio.pending, (state) => {
                state.status_get_respuestas_x_edificio = 'loading';

            })
            .addCase(get_respuestas_x_edificio.fulfilled, (state, action) => {
                state.status_get_respuestas_x_edificio = 'succeeded';
                //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("status_get_respuestas_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
                state.respuestas = action.payload;

            })
            .addCase(get_respuestas_x_edificio.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_get_respuestas_x_edificio = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_get_respuestas_x_edificio = action.payload || 'Failed to fetch building';
                state.respuestas = [];
            })

            .addCase(get_respuestas_x_usuario.pending, (state) => {
                state.status_get_respuestas_x_usuario = 'loading';
                
              })
              .addCase(get_respuestas_x_usuario.fulfilled, (state, action) => {
                state.status_get_respuestas_x_usuario = 'succeeded';
                //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
                //console.log("status_get_respuestas_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
                state.respuestas = action.payload;
        
              })
              .addCase(get_respuestas_x_usuario.rejected, (state, action) => {
                //console.log("ejecutando Slice failed rejected ");
                state.status_get_respuestas_x_usuario = 'failed';
                //console.log("error action.error.message " + action.error.message);
                state.error_get_respuestas_x_usuario = action.payload || 'Failed to fetch building';
                state.respuestas =[];
              })
        
    },
});

export const { setRespuestaSeleccionada, clearRespuestaSeleccionada } = respuestaSlice.actions;
export const selectRespuestas_emergenciaById = (state, idRespuesta) =>
    state.respuesta.respuestas.find((respuesta) => respuesta.id === idRespuesta);
export const { resetStateGetRespuestas_x_edificio } = respuestaSlice.actions;
export const { resetStateGetRespuestas_x_usuario } = respuestaSlice.actions;
export default respuestaSlice.reducer;
