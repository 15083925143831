import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetInfracciones_x_edificio } from './infraccionSlice';


export const update_infraccion = createAsyncThunk('infraccion/update_infraccion', async ({ idInfraccion, infraccionData }, thunkAPI) => {
  console.log("ejecutando thunk update_infraccion con idInfraccion: " + idInfraccion);

  try {

    const response = await apiClient.put(`/infracciones/${idInfraccion}`, infraccionData);

    const infraccion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetInfracciones_x_edificio(infraccion.buildingId));// Resetear el estado para refetch de infracciones

    return infraccion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_infraccion = createAsyncThunk('infraccion/save_infraccion', async (infraccionData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/infracciones`, infraccionData);

    const infraccion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetInfracciones_x_edificio(infraccion.buildingId));// Resetear el estado para refetch de infracciones

    return infraccion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_infraccion = createAsyncThunk('infraccion/anular_infraccion', async ({ idInfraccion, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_infraccion con idInfraccion: " + idInfraccion);

  try {

    const response = await apiClient.patch(`/infracciones/${idInfraccion}/anular`, { usuarioAnulacion });

    const infraccion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetInfracciones_x_edificio(infraccion.buildingId));// Resetear el estado para refetch de infracciones

    return infraccion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_infraccion = createAsyncThunk('infraccion/get_infraccion', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_infraccion con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/infracciones/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const infraccion = response.data;

    return infraccion;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_infraccion_x_codigo = createAsyncThunk('infraccion/get_infraccion_x_codigo', async ({ buildingId, codigo }, thunkAPI) => {
    console.log("ejecutando thunk get_infraccion_x_codigo con "+codigo);
    
    try { 
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get('/infracciones/get-by-codigo',{
          params: { 
            buildingId, 
            codigo 
          }
      });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const infracciones = response.data;
            
        return infracciones ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

export const get_infracciones_x_edificio = createAsyncThunk('infraccion/get_infracciones_x_edificio', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_infracciones con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/infracciones',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const infracciones = response.data;
          
      return infracciones ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
