import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_unidades_facturacion_x_edificio } from './unidad_facturacionThunk';
import M from 'materialize-css';
import { formatDate02 } from '../../utils/formateo';
import ExcelJS from 'exceljs';

const Unidades_facturacion_edificio = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  const unidades_facturacion = useSelector((state) => state.unidad_facturacion.unidades_facturacion);
  const fetchStatus = useSelector((state) => state.unidad_facturacion.fetchStatus);
  const error = useSelector((state) => state.unidad_facturacion.error);
  const [filtro, setFiltro] = useState('');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
    M.AutoInit();

    if (fetchStatus === 'idle' && codigo_edificio) {
      dispatch(get_unidades_facturacion_x_edificio(codigo_edificio));
    }
  }, [isAuthenticated, navigate, fetchStatus, codigo_edificio, dispatch]);

  const handleRefresh = () => {
    dispatch(get_unidades_facturacion_x_edificio(codigo_edificio));
  };

  const formatearUnidades_facturacion = (unidades_facturacion) => {
    if (!Array.isArray(unidades_facturacion)) {
      return [];
    }

    return unidades_facturacion.map(unidad_facturacion => ({
      ...unidad_facturacion,
      fecha_registro_formato01: formatDate02(unidad_facturacion.timestamp_registro),
    }));
  };

  const unidades_facturacionFormateados = formatearUnidades_facturacion(unidades_facturacion);

  const handleFilterChange = (e) => {
    setFiltro(e.target.value);
  };

  const unidades_facturacionFiltrados = Array.isArray(unidades_facturacionFormateados) ? unidades_facturacionFormateados.filter((unidad_facturacion) => {
    const filterLowerCase = filtro.toLowerCase();
    return unidad_facturacion.codigo_unidad_facturacion.toLowerCase().includes(filterLowerCase) ||
      unidad_facturacion.resumen_propietarios.toLowerCase().includes(filterLowerCase) ||
      unidad_facturacion.resumen_propiedades.toLowerCase().includes(filterLowerCase) ||
      unidad_facturacion.area_ocupada_total.toString().toLowerCase().includes(filterLowerCase) ||
      unidad_facturacion.porcentaje_participacion.toString().toLowerCase().includes(filterLowerCase) ||
      unidad_facturacion.validacion_carga.toLowerCase().includes(filterLowerCase) ||
      unidad_facturacion.mensaje_error_carga.toLowerCase().includes(filterLowerCase);
  }) : [];

  if (fetchStatus === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus === 'failed') {
    return <div>Error: {error}</div>;
  }

  // Calcular totales
  const totalAreaOcupada = unidades_facturacionFiltrados.reduce((sum, unidad) => sum + parseFloat(unidad.area_ocupada_total || 0), 0);
  const totalPorcentajeParticipacion = unidades_facturacionFiltrados.reduce((sum, unidad) => sum + parseFloat(unidad.porcentaje_participacion || 0), 0);
  const totalUnidadesFacturacion = unidades_facturacionFiltrados.length; // Total de unidades de facturación

  // Función para formatear números con separador de miles
  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  // Función para exportar a Excel
  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Unidades Facturación');
  
    // Definir las columnas
    worksheet.columns = [
      { header: 'Código Edificio', key: 'codigo_edificio', width: 20 },
      { header: 'Código Unidad Facturación', key: 'codigo_unidad_facturacion', width: 30 },
      { header: 'Resumen Propietarios', key: 'resumen_propietarios', width: 30 },
      { header: 'Resumen Propiedades', key: 'resumen_propiedades', width: 30 },
      { header: 'Área Ocupada Total', key: 'area_ocupada_total', width: 20 },
      { header: 'Porcentaje Participación', key: 'porcentaje_participacion', width: 20 },
      { header: 'Fecha de Registro', key: 'fecha_registro_formato01', width: 20 }
    ];
  
    // Agregar datos
    unidades_facturacionFiltrados.forEach(unidad => {
      worksheet.addRow({
        codigo_edificio: unidad.codigo_edificio,
        codigo_unidad_facturacion: unidad.codigo_unidad_facturacion,
        resumen_propietarios: unidad.resumen_propietarios,
        resumen_propiedades: unidad.resumen_propiedades,
        area_ocupada_total: unidad.area_ocupada_total,
        porcentaje_participacion: unidad.porcentaje_participacion,
        fecha_registro_formato01: unidad.fecha_registro_formato01
      });
    });
  
    // Estilo para la cabecera
    const headerRow = worksheet.getRow(1);
    headerRow.font = { bold: true };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' } // Color de fondo amarillo
    };
  
    // Aplicar bordes a todas las celdas
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });
    });
  
    // Crear un archivo Excel
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
  
    // Descargar el archivo
    const a = document.createElement('a');
    a.href = url;
    a.download = 'unidades_facturacion.xlsx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="container">
      <h5>Unidades de facturación</h5>
      <a className="btn green tooltipped" data-position="top" data-tooltip="Refrescar vista" onClick={handleRefresh}>
        <i className="material-icons left">refresh</i>Refrescar
      </a>
      <a className="btn orange tooltipped" data-position="top" data-tooltip="Cargar Unid. Facturación" onClick={() => navigate('/carga-unidades-facturacion')}>
        <i className="material-icons left">cloud_upload</i>Cargar Unid. Facturación
      </a>
      <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/edificio')}>
        <i className="material-icons left">arrow_back</i>
      </a>
      <button className="btn" onClick={handleExport}>
        <i className="material-icons left">file_download</i> Descargar Excel
      </button>
      <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_unidades_facturacion_cargados"><i className="material-icons">search</i> Filtrar</label>
        <input type="text" id="filtro_tabla_unidades_facturacion_cargados" value={filtro} onChange={handleFilterChange} />
      </div>
      <table className="striped responsive-table tabla_unidades_facturacion_cargados">
        <thead>
          <tr>
            <th>Edificio</th>
            <th>Unid.Fact.</th>
            <th>Propietarios</th>
            <th>Unid. Inmobiliarias</th>
            <th>Area ocupada</th>
            <th>% Particip.</th>
            <th>Validación</th>
            <th>Mensaje</th>
            <th>Usuario</th>
            <th>Id. carga</th>
            <th>Fecha carga</th>
          </tr>
        </thead>
        <tbody id="table-body-unidades_facturacion-cargados">
          <tr>
            <td><strong>Total</strong></td>
            <td><strong>{totalUnidadesFacturacion}</strong></td>
            <td colSpan="2"></td>
            <td><strong>{formatNumber(totalAreaOcupada.toFixed(2))}</strong></td>
            <td><strong>{formatNumber(totalPorcentajeParticipacion.toFixed(2))}</strong></td>
          </tr>
          {unidades_facturacionFiltrados.map((unidad_facturacion) => (
            <tr key={unidad_facturacion.id}>
              <td>{unidad_facturacion.codigo_edificio}</td>
              <td>{unidad_facturacion.codigo_unidad_facturacion}</td>
              <td>{unidad_facturacion.resumen_propietarios}</td>
              <td>{unidad_facturacion.resumen_propiedades}</td>
              <td>{unidad_facturacion.area_ocupada_total}</td>
              <td>{unidad_facturacion.porcentaje_participacion}</td>
              <td>{unidad_facturacion.validacion_carga}</td>
              <td>{unidad_facturacion.mensaje_error_carga}</td>
              <td>{unidad_facturacion.usuario_carga}</td>
              <td>{unidad_facturacion.uid_carga}</td>
              <td>{unidad_facturacion.timestamp_carga_formateado}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Unidades_facturacion_edificio;
