import { createSlice } from '@reduxjs/toolkit';
import { get_preferencia, update_preferencia, save_preferencia, anular_preferencia, get_preferencias_x_edificio, get_preferencias_x_depa } from './preferenciaThunk';

const preferenciaSlice = createSlice({
  name: 'preferencia',
  initialState: {
    preferencia: null,
    preferencias: [],
    preferenciaSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_preferencias_x_edificio: 'idle',
    error_get_preferencias_x_edificio: null,
    status_get_preferencias_x_depa: 'idle',
    error_get_preferencias_x_depa: null,    
    status_update_preferencia: 'idle',
    error_update_preferencia: null,    
    status_save_preferencia: 'idle',
    error_save_preferencia: null,
    status_anular_preferencia: 'idle',
    error_anular_preferencia: null,
  },
  reducers: {
    setPreferenciaSeleccionada: (state, action) => {
      state.preferenciaSeleccionada = action.payload;
      
    },
    clearPreferenciaSeleccionada(state) {
      state.preferenciaSeleccionada = null;
      state.preferencia = null;
    },
    resetStateGetPreferencias_x_edificio: () => {
        return {
            status_get_preferencias_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_preferencia.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_preferencia.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.preferencia = action.payload;

      })
      .addCase(get_preferencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_preferencia.pending, (state) => {
        state.status_update_preferencia = 'loading';
      })
      .addCase(update_preferencia.fulfilled, (state, action) => {
        state.status_update_preferencia = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.preferencia = action.payload.preferencia;

      })
      .addCase(update_preferencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_preferencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_preferencia = action.payload || 'Failed to fetch building';
      })
      .addCase(save_preferencia.pending, (state) => {
        state.status_save_preferencia = 'loading';
      })
      .addCase(save_preferencia.fulfilled, (state, action) => {
        state.status_save_preferencia = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.preferencia = action.payload.preferencia;

      })
      .addCase(save_preferencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_preferencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_preferencia = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_preferencia.pending, (state) => {
        state.status_save_preferencia = 'loading';
      })
      .addCase(anular_preferencia.fulfilled, (state, action) => {
        state.status_anular_preferencia = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.preferencia = action.payload.preferencia;

      })
      .addCase(anular_preferencia.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_preferencia = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_preferencia = action.payload || 'Failed to fetch building';
      })
      .addCase(get_preferencias_x_depa.pending, (state) => {
        state.status_get_preferencias_x_depa = 'loading';
        
      })
      .addCase(get_preferencias_x_depa.fulfilled, (state, action) => {
        state.status_get_preferencias_x_depa = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.preferencias = action.payload;

      })
      .addCase(get_preferencias_x_depa.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_preferencias_x_depa = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_preferencias_x_depa = action.payload || 'Failed to fetch building';
        state.preferencias =[];
      })      
      .addCase(get_preferencias_x_edificio.pending, (state) => {
        state.status_get_preferencias_x_edificio = 'loading';
        
      })
      .addCase(get_preferencias_x_edificio.fulfilled, (state, action) => {
        state.status_get_preferencias_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.preferencias = action.payload;

      })
      .addCase(get_preferencias_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_preferencias_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_preferencias_x_edificio = action.payload || 'Failed to fetch building';
        state.preferencias =[];
      })            
  },
});

export const { setPreferenciaSeleccionada, clearPreferenciaSeleccionada } = preferenciaSlice.actions;
export const selectPreferenciasById = (state, idPreferencia) =>
    state.preferencia.preferencias.find((preferencia) => preferencia.id === idPreferencia);
export const { resetStateGetPreferencias_x_edificio } = preferenciaSlice.actions;
export default preferenciaSlice.reducer;
