import { createSlice } from '@reduxjs/toolkit';
import {get_config_unidades_facturacion_cargadas, mover_config_unidades_facturacion_cargadas} from './config-unidades-facturacion-cargadasThunk';

const config_unidades_facturacion_cargadasSlice = createSlice({
    name: 'config_unidades_facturacion_cargadas',
    initialState: {
      config_unidades_facturacion_cargadas: [],
      //fetchStatus : 'idle',
      //error: null,
      status: {
        get_config_unidades_facturacion_cargadas: 'idle',
        moveValidPropiedades: 'idle',
      },
      error: {
        get_config_unidades_facturacion_cargadas: null,
        moveValidPropiedades: null,
      },
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_config_unidades_facturacion_cargadas.pending, (state) => {
              state.status.get_config_unidades_facturacion_cargadas  = 'loading';
          })
          .addCase(get_config_unidades_facturacion_cargadas.fulfilled, (state, action) => {
              state.status.get_config_unidades_facturacion_cargadas  = 'succeeded';
              //console.log("Propiedades cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.config_unidades_facturacion_cargadas = action.payload;

          })
          .addCase(get_config_unidades_facturacion_cargadas.rejected, (state, action) => {
            //console.log("ejecutando Slice failed rejected ");
              state.status.get_config_unidades_facturacion_cargadas  = 'failed';              
              //console.log("error action.error.message "+action.error.message );
              state.error.get_config_unidades_facturacion_cargadas = action.payload || 'Failed to fetch receipts';
          })
//
        .addCase(mover_config_unidades_facturacion_cargadas.pending, (state) => {
            state.status.mover_config_unidades_facturacion_cargadas  = 'loading';
        })
        .addCase(mover_config_unidades_facturacion_cargadas.fulfilled, (state, action) => {
            state.status.mover_config_unidades_facturacion_cargadas  = 'succeeded';
            //console.log("Propiedades cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
            //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
            state.config_unidades_facturacion_cargadas = action.payload;

        })
        .addCase(mover_config_unidades_facturacion_cargadas.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
            state.status.mover_config_unidades_facturacion_cargadas  = 'failed';
            //console.log("error action.error.message "+action.error.message );
            state.error.mover_config_unidades_facturacion_cargadas = action.payload || 'Failed to fetch receipts';
        });

    },
  });
    
  export const selectConfig_unidad_facturacion_cargadaById = (state, config_unidad_facturacion_cargadaId) =>
    state.config_unidades_facturacion_cargadas.config_unidades_facturacion_cargadas.find((config_unidad_facturacion_cargada) => config_unidad_facturacion_cargada.id === config_unidad_facturacion_cargadaId);
  
  export default config_unidades_facturacion_cargadasSlice.reducer;
