import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get_publicaciones_x_edificio } from "../features/publicaciones/publicacionThunk";
import Slider from "react-slick";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import "./DashboardPage.css";
import { get_opciones } from '../features/dashboard/dashboardThunk';

const DashboardPage = () => {
  const user = useSelector((state) => state.auth.usuario);
  const buildingId = useSelector((state) => state.edificio.edificio?.id);
  const { opciones, status, error } = useSelector((state) => state.dashboard);
  const [destacadas, setDestacadas] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const edificioSeleccionado = useSelector((state) => state.edificio.edificio);

  const handleNavigation = (route) => {
    navigate(route);
  };

  useEffect(() => {
    if (edificioSeleccionado && user && Array.isArray(user.edificios)) {
      //console.log("Edificio seleccionado:", JSON.stringify(edificioSeleccionado));

      // Buscar el edificio en el array de edificios del usuario
      const edificioEncontrado = user.edificios.find(
        (edif) => edif.buildingId === edificioSeleccionado.id
      );

      if (edificioEncontrado) {
        const rolesDelEdificio = edificioEncontrado.roles || [];
        console.log("Roles del edificio encontrado:", rolesDelEdificio);

        // Despachar los roles a la función get_opciones
        dispatch(get_opciones(rolesDelEdificio));
      } else {
        console.warn(`No se encontró el edificio con buildingId: ${edificioSeleccionado.id} en user.edificios.`);

        // Opcional: Despachar un arreglo vacío o manejar el caso de otra manera
        dispatch(get_opciones([]));
      }
    }
  }, [edificioSeleccionado, user.edificios, dispatch]);

  useEffect(() => {
    dispatch(get_publicaciones_x_edificio({ buildingId, estado: "publicado" }))
      .then((res) => {
        if (res.payload) {
          setDestacadas(res.payload);
        }
      })
      .catch(() => { });
  }, [dispatch, buildingId]);

  const settings = {
    dots: true,
    infinite: destacadas.length > 1, // Solo activar bucle infinito si hay más de una publicación
    speed: 500,
    slidesToShow: Math.min(3, destacadas.length), // Mostrar hasta 3 publicaciones o la cantidad disponible
    slidesToScroll: 1,
    autoplay: destacadas.length > 1, // Solo activar autoplay si hay más de una publicación
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(1, destacadas.length), // Manejar responsividad para una sola publicación
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <div className="container dashboard-container">
      {/* Título ajustado con estilos */}
      <h5 className="dashboard-title center-align">Dashboard</h5>
       {/*
      <div className="section">
        <div className="card" style={{ backgroundColor: "#546e7a" }}>
          <div className="card-content white-text">
            <span className="card-title">Publicaciones Destacadas</span>
            <div className="row">
              {destacadas.length > 0 ? (
                <Slider {...settings}>
                  {destacadas.map((publicacion) => (
                    <div key={publicacion.id} className="publicacion-card">
                      {publicacion.imagenes?.[0] ? (
                        <img
                          src={publicacion.imagenes[0]}
                          alt={publicacion.titulo}
                          className="publicacion-image"
                        />
                      ) : (
                        <div className="publicacion-placeholder">
                          <span className="placeholder-text">Oferta Especial</span>
                        </div>
                      )}
                      <div className="publicacion-details">
                        <h5>{publicacion.titulo}</h5>
                        <p>{publicacion.descripcion.substring(0, 100)}...</p>
                        <p className="precio">
                          {publicacion.tipos_oferta?.regalo
                            ? "Regalo"
                            : `S/ ${publicacion.precio || "0.00"}`}
                        </p>
                        <button
                          className="btn blue"
                          onClick={() => navigate(`/publicacion/${publicacion.id}`)}
                        >
                          Ver Detalles
                        </button>
                      </div>
                    </div>
                  ))}
                </Slider>
              ) : (
                <p>No hay publicaciones destacadas disponibles.</p>
              )}

            </div>
          </div>
        </div>
      </div>
*/}
      {/* Renderizado de secciones */}
      {opciones && opciones.length > 0 ? (
        opciones.map((section, index) => (
          <div key={index}>
            <div className="section">
              <div className="card" style={{ backgroundColor: section.backgroundColor || '#546e7a' }}>
                <div className="card-content white-text">
                  <span className="card-title">{section.titulo}</span>
                  <div className="row">
                    {section.options.map((option, idx) => (
                      <div className="col s12 m4" key={idx}>
                        <div
                          className="icon-block"
                          onClick={() => handleNavigation(option.route)}
                          style={{ cursor: 'pointer' }}
                        >
                          <i className="material-icons medium">{option.icon}</i>
                          <p>{option.title}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="center-align">No sections available</p>
      )}
    </div>
  );
};

export default DashboardPage;
