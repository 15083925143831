import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_depositos_cargados_x_edificio, mover_depositos_cargados} from './depositos-cargadosThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Depositos_cargados = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  //const depas_cargados = useSelector((state) => state.depas_cargados.depas_cargados);
  const { depositos_cargados, status, error } = useSelector((state) => state.depositos_cargados);
  const usuario = useSelector((state) => state.auth.usuario);
  //const fetchStatus  = useSelector((state) => state.depas_cargados.fetchStatus );    
  //const error = useSelector((state) => state.depas_cargados.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    console.log("status.get_depositos_cargados_x_edificio: "+ status.get_depositos_cargados_x_edificio);
    console.log("codigo_edificio: "+ codigo_edificio);
    if (status.get_depositos_cargados_x_edificio  === 'idle' && codigo_edificio) {
      dispatch(get_depositos_cargados_x_edificio(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, status.get_depositos_cargados_x_edificio , codigo_edificio, dispatch]);

  const handleRefresh = () => {
    dispatch(get_depositos_cargados_x_edificio(codigo_edificio));
  };

  const formatearDepositos = (depositos) => {
    if (!Array.isArray(depositos)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return depositos.map(deposito => ({
      ...deposito,
      fecha_carga_formato01: formatDate02(deposito.timestamp_carga),
    }));
  };

  const depositosFormateados = formatearDepositos(depositos_cargados);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const depositosFiltrados = Array.isArray(depositosFormateados) ? depositosFormateados.filter((deposito) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return deposito.tipo.includes(filterLowerCase) ||
        deposito.numero.toLowerCase().includes(filterLowerCase) ||
        deposito.nivel.toString().toLowerCase().includes(filterLowerCase) ||
        deposito.tipo_nivel.toString().toLowerCase().includes(filterLowerCase) ||
        deposito.area_ocupada.toString().toLowerCase().includes(filterLowerCase) ||
        deposito.area_techada.toString().toLowerCase().includes(filterLowerCase) ||
        deposito.fecha_carga_formato01.toString().includes(filterLowerCase)  ||
        deposito.mensaje_error_carga.toString().includes(filterLowerCase) ||
        deposito.validacion_carga.includes(filterLowerCase)
      ;
  })
  : [];

  const handleMoveValidDepositos = async () => {
    let bodyRequest={
        buildingId: codigo_edificio,
        usuario_proceso: usuario.id
    };
    dispatch(mover_depositos_cargados(bodyRequest));    
    //dispatch(fetchDepartments()); // Actualiza la lista después de mover los departamentos válidos
    navigate('/depositos');
  };
  

  if (status.get_depositos_cargados_x_edificio  === 'loading') {
    return <div>Loading...</div>;
  }

  if (status.get_depositos_cargados_x_edificio  === 'failed') {
    return <div>Error: {error.get_depositos_cargados_x_edificio}</div>;
  }

  return (    
    <div className="container">
        <h5>Depositos cargados</h5>
        <button className="btn" onClick={handleRefresh}>
        Refresh List
        </button>
        <button className="btn" onClick={handleMoveValidDepositos}>
        Registrar
        </button>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_depositos_cargados"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_depositos_cargados" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_depositos_cargados">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>Tipo</th>
          <th>Depos.</th>
          <th>Nivel</th>
          <th>Tip_niv</th>
          <th>Area_ocupada</th>
          <th>Area_techada</th>                    
          <th>Validacion</th>
          <th>Mensaje</th>
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Fecha_carga</th>
        </tr>
      </thead>
      <tbody id="table-body-depositos-cargados">                    
        {depositosFiltrados.map((deposito) => (
          <tr key={deposito.id}>
            <td>{deposito.codigo_edificio}</td>
            <td>{deposito.tipo}</td>
            <td>{deposito.numero}</td>
            <td>{deposito.nivel}</td>
            <td>{deposito.tipo_nivel}</td>             
            <td>{deposito.area_ocupada}</td>
            <td>{deposito.area_techada}</td>
            <td>{deposito.validacion_carga}</td>
            <td>{deposito.mensaje_error_carga}</td>
            <td>{deposito.usuario_carga}</td>
            <td>{deposito.uid_carga}</td>
            <td>{deposito.fecha_carga_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Depositos_cargados;