// frontend/src/redux/slices/categoria_requerimientoSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { 
    get_categoria_requerimiento, 
    get_categorias_requerimientos,
    update_categoria_requerimiento, 
    save_categoria_requerimiento, 
    anular_categoria_requerimiento 
} from './categoria_requerimientoThunk';

const categoria_requerimientoSlice = createSlice({
    name: 'categoria_requerimiento',
    initialState: {
        categoria_requerimiento: null,
        categorias_requerimientos: [],
        categoria_requerimientoSeleccionado: null,
        status: 'idle',
        error: null,      
        status_get_categorias_requerimientos: 'idle',
        error_get_categorias_requerimientos: null,
        status_update_categoria_requerimiento: 'idle',
        error_update_categoria_requerimiento: null,
        status_save_categoria_requerimiento: 'idle',
        error_save_categoria_requerimiento: null,
        status_anular_categoria_requerimiento: 'idle',
        error_anular_categoria_requerimiento: null,
    },
    reducers: {
        setCategoria_requerimientoSeleccionado: (state, action) => {
            state.categoria_requerimientoSeleccionado = action.payload;
        },
        clearCategoria_requerimientoSeleccionado(state) {
            state.categoria_requerimientoSeleccionado = null;
            state.categoria_requerimiento = null;
        },
        resetStateGetCategorias_requerimientos: (state) => {
            state.status_get_categorias_requerimientos = 'idle';
            state.error_get_categorias_requerimientos = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Obtener una facturación individual
            .addCase(get_categoria_requerimiento.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(get_categoria_requerimiento.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.categoria_requerimiento = action.payload;
            })
            .addCase(get_categoria_requerimiento.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch facturacion individual';
            })                   
            .addCase(get_categorias_requerimientos.pending, (state) => {
                state.status_get_categorias_requerimientos = 'loading';
                state.error_get_categorias_requerimientos = null;
            })
            .addCase(get_categorias_requerimientos.fulfilled, (state, action) => {
                state.status_get_categorias_requerimientos = 'succeeded';
                state.categorias_requerimientos = action.payload;
            })
            .addCase(get_categorias_requerimientos.rejected, (state, action) => {
                state.status_get_categorias_requerimientos = 'failed';
                state.error_get_categorias_requerimientos = action.payload || 'Failed to fetch facturaciones individuales';
            })
            // Actualizar una facturación individual
            .addCase(update_categoria_requerimiento.pending, (state) => {
                state.status_update_categoria_requerimiento = 'loading';
            })
            .addCase(update_categoria_requerimiento.fulfilled, (state, action) => {
                state.status_update_categoria_requerimiento = 'succeeded';
                state.categoria_requerimiento = action.payload.categoria_requerimiento; 
            })
            .addCase(update_categoria_requerimiento.rejected, (state, action) => {
                state.status_update_categoria_requerimiento = 'failed';
                state.error_update_categoria_requerimiento = action.payload || 'Failed to update facturacion individual';
            })

            // Guardar una nueva facturación individual
            .addCase(save_categoria_requerimiento.pending, (state) => {
                state.status_save_categoria_requerimiento = 'loading';
            })
            .addCase(save_categoria_requerimiento.fulfilled, (state, action) => {
                state.status_save_categoria_requerimiento = 'succeeded';
                state.categoria_requerimiento = action.payload.categoria_requerimiento;
                state.categorias_requerimientos.push(action.payload.categoria_requerimiento);
            })
            .addCase(save_categoria_requerimiento.rejected, (state, action) => {
                state.status_save_categoria_requerimiento = 'failed';
                state.error_save_categoria_requerimiento = action.payload || 'Failed to save facturacion individual';
            })

            // Anular una facturación individual
            .addCase(anular_categoria_requerimiento.pending, (state) => {
                state.status_anular_categoria_requerimiento = 'loading';
            })
            .addCase(anular_categoria_requerimiento.fulfilled, (state, action) => {
                state.status_anular_categoria_requerimiento = 'succeeded';
                state.categoria_requerimiento = action.payload.categoria_requerimiento;
            })
            .addCase(anular_categoria_requerimiento.rejected, (state, action) => {
                state.status_anular_categoria_requerimiento = 'failed';
                state.error_anular_categoria_requerimiento = action.payload || 'Failed to anular facturacion individual';
            })      
        },
      });

export const { 
    setCategoria_requerimientoSeleccionado, 
    clearCategoria_requerimientoSeleccionado, 
    resetStateGetCategorias_requerimientos 
} = categoria_requerimientoSlice.actions;

export const selectcategorias_requerimientosById = (state, idCategoria_requerimiento) =>
    state.categoria_requerimiento.categorias_requerimientos.find((categoria_requerimiento) => categoria_requerimiento.id === idCategoria_requerimiento);

export default categoria_requerimientoSlice.reducer;
