import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetAsistencias_x_edificio } from './asistenciaSlice';


export const update_asistencia = createAsyncThunk('asistencia/update_asistencia', async ({ idAsistencia, asistenciaData }, thunkAPI) => {
  console.log("ejecutando thunk update_asistencia con idAsistencia: " + idAsistencia);

  try {

    const response = await apiClient.put(`/asistencias/${idAsistencia}`, asistenciaData);

    const asistencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetAsistencias_x_edificio(asistencia.buildingId));// Resetear el estado para refetch de asistencias

    return asistencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_asistencia = createAsyncThunk('asistencia/save_asistencia', async (asistenciaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/asistencias`, asistenciaData);

    const asistencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetAsistencias_x_edificio(asistencia.buildingId));// Resetear el estado para refetch de asistencias

    return asistencia;

  } catch (error) {
    
    const errorMessage = error.response?.data?.error || 'Error al crear la reunión.';
    console.log("errorMessage " + errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const validar_token_asistencia = createAsyncThunk(
  'asistencia/validar_token_asistencia',
  async ({ asistenciaId, asistenciaData }, thunkAPI) => {
      try {
          const response = await apiClient.post(`/asistencias/${asistenciaId}/validar-asistencia`, { asistenciaData });
          return response.data;
      } catch (error) {
          return thunkAPI.rejectWithValue(error.response?.data || 'Error al validar asistencia.');
      }
  }
);

export const anular_asistencia = createAsyncThunk('asistencia/anular_asistencia', async ({ idAsistencia, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_asistencia con idAsistencia: " + idAsistencia);

  try {

    const response = await apiClient.patch(`/asistencias/${idAsistencia}/anular`, { usuarioAnulacion });

    const asistencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetAsistencias_x_edificio(asistencia.buildingId));// Resetear el estado para refetch de asistencias

    return asistencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_asistencia = createAsyncThunk('asistencia/get_asistencia', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_asistencia con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/asistencias/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const asistencia = response.data;

    return asistencia;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_asistencias_x_usuario = createAsyncThunk('asistencia/get_asistencias_x_usuario', async ({ buildingId, codigo_usuario }, thunkAPI) => {
    console.log("ejecutando thunk get_asistencias con "+buildingId);
    
    try { 
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get('/asistencias',{
          params: { 
            buildingId,
            codigo_usuario,
            estado: 'ACTIVO'
          }
      });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const asistencias = response.data;
            
        return asistencias ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
export const get_asistencias_x_edificio = createAsyncThunk('asistencia/get_asistencias_x_edificio', async ({ buildingId }, thunkAPI) => {
  console.log("ejecutando thunk get_asistencias con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/asistencias',{
        params: { 
          buildingId           
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const asistencias = response.data;
          
      return asistencias ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
