import 'materialize-css/dist/css/materialize.min.css';
import React, { useEffect } from 'react';


function Footer() {

  useEffect(() => {
    // Inicializa los tooltips después de que el componente se monte en el DOM
  
  }, []); // La dependencia vacía asegura que se ejecute solo una vez después del montaje inicial


  return (  
         <footer className="page-footer blue darken-1">
          <div className="container">
            <div className="row">
              <div className="col l6 s12">
                <h5 className="white-text">Tantaq</h5>
                <p className="grey-text text-lighten-4">Dirección: Av. 28 de Julio 368, Jesús María - Lima - Péru</p>
                <p className="grey-text text-lighten-4">WhatsApp: +51 902 656 631</p>
                <p className="grey-text text-lighten-4">Email: info@tantaq.com</p>                
              </div>
              <div className="col l4 offset-l2 s12">
                <h5 className="white-text">Redes sociales</h5>
                <ul>
                  <li><a className="grey-text text-lighten-3" href="#!">Facebook</a></li>
                  <li><a className="grey-text text-lighten-3" href="#!">Youtube</a></li>
                  <li><a className="grey-text text-lighten-3" href="#!">Linkedin</a></li>
                  <li><a className="grey-text text-lighten-3" href="#!">TikTok</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <div className="container">
            © 2023 HANDSPER S.A.C. Todos los derechos reservados.
            <a className="grey-text text-lighten-4 right" href="#!">Nosotros</a>
            </div>
          </div>
        </footer>
  );
 
}

export default Footer;