import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

export const get_opciones = createAsyncThunk('dashboard/get_opciones', async (roles, thunkAPI) => {
    //console.log("ejecutando thunk get_recibos_x_depa con "+codigo_inmobiliario);
    
    try { 
        console.log("ejecutando thunk roles: "+roles);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get('/dashboard', {
            params: {
              roles: JSON.stringify(roles),
              },
        });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const opciones = response.data;
            
        return opciones ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
