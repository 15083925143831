import { createSlice } from '@reduxjs/toolkit';
import { get_area_comun, get_areas_comunes, update_area_comun, save_area_comun, anular_area_comun } from './area_comunThunk';

const area_comunSlice = createSlice({
  name: 'area_comun',
  initialState: {
    area_comun: null,
    areas_comunes: [],
    area_comunSeleccionada: null,    
    status: 'idle',
    error: null,
    status_get_areas_comunes: 'idle',
    error_get_areas_comunes: null,
    status_update_area_comun: 'idle',
    error_update_area_comun: null,
    status_save_area_comun: 'idle',
    error_save_area_comun: null,
    status_anular_area_comun: 'idle',
    error_anular_area_comun: null,
  },
  reducers: {
    setArea_comunSeleccionada: (state, action) => {
      state.area_comunSeleccionada = action.payload;
      
    },
    clearArea_comunSeleccionada(state) {
      state.area_comunSeleccionada = null;
      state.area_comun = null;
    },
    resetStateGetAreas_comunes: () => {
        return {
            status_get_areas_comunes : 'idle',
        };
      },   
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_area_comun.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_area_comun.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.area_comun = action.payload;

      })
      .addCase(get_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })
      .addCase(update_area_comun.pending, (state) => {
        state.status_update_area_comun = 'loading';
      })
      .addCase(update_area_comun.fulfilled, (state, action) => {
        state.status_update_area_comun = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.area_comun = action.payload.area_comun;

      })
      .addCase(update_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_area_comun = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_area_comun = action.payload || 'Failed to fetch building';
      })
      .addCase(save_area_comun.pending, (state) => {
        state.status_save_area_comun = 'loading';
      })
      .addCase(save_area_comun.fulfilled, (state, action) => {
        state.status_save_area_comun = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.area_comun = action.payload.area_comun;

      })
      .addCase(save_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_area_comun = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_area_comun = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_area_comun.pending, (state) => {
        state.status_save_area_comun = 'loading';
      })
      .addCase(anular_area_comun.fulfilled, (state, action) => {
        state.status_anular_area_comun = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.area_comun = action.payload.area_comun;

      })
      .addCase(anular_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_area_comun = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_area_comun = action.payload || 'Failed to fetch building';
      })
      .addCase(get_areas_comunes.pending, (state) => {
        state.status_get_areas_comunes = 'loading';
        
      })
      .addCase(get_areas_comunes.fulfilled, (state, action) => {
        state.status_get_areas_comunes = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.areas_comunes = action.payload;

      })
      .addCase(get_areas_comunes.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_areas_comunes = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_areas_comunes = action.payload || 'Failed to fetch building';
      })
  },
});

export const { setArea_comunSeleccionada, clearArea_comunSeleccionada } = area_comunSlice.actions;
export const selectAreas_comunesById = (state, idArea_comun) =>
    state.area_comun.areas_comunes.find((area_comun) => area_comun.id === idArea_comun);
export const { resetStateGetAreas_comunes } = area_comunSlice.actions;
export default area_comunSlice.reducer;
