// src/pages/UploadAndListDepartments.js
import React from 'react';
import Carga_depas from './carga-depasPage';
import Depas_cargados from './depas-cargadosPage';
import { useNavigate } from 'react-router-dom';


const Carga_lista_departamentos = () => {
  const navigate = useNavigate();
  
  return (
    <div className="container">
      <h4>Carga Depas</h4>
      <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/departamentos')}>
              <i className="material-icons left">arrow_back</i>
        </a>     
      <Carga_depas />
      <Depas_cargados />
    </div>
  );
};

export default Carga_lista_departamentos;