import { createSlice } from '@reduxjs/toolkit';
import {get_estacionamientos_x_edificio} from './estacionamientoThunk';

const estacionamientoSlice = createSlice({
    name: 'estacionamiento',
    initialState: {
      estacionamientos: [],
      fetchStatus : 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_estacionamientos_x_edificio.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_estacionamientos_x_edificio.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              console.log("Depas cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.estacionamientos = action.payload;

          })
          .addCase(get_estacionamientos_x_edificio.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';              
              console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch receipts';
          });
    },
  });
    
  export const selectEstacionamientoById = (state, estacionamientoId) =>
    state.estacionamiento.departamentos.find((estacionamiento) => estacionamiento.id === estacionamientoId);
  
  export default estacionamientoSlice.reducer;
