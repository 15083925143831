import { createSlice } from '@reduxjs/toolkit';
import { get_publicacion, update_publicacion, save_publicacion, anular_publicacion, get_publicaciones_x_edificio, get_publicaciones_x_depa } from './publicacionThunk';

const publicacionSlice = createSlice({
  name: 'publicacion',
  initialState: {
    publicacion: null,
    publicaciones: [],
    publicacionSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_publicaciones_x_edificio: 'idle',
    error_get_publicaciones_x_edificio: null,    
    status_get_publicaciones_x_depa: 'idle',
    error_get_publicaciones_x_depa: null,
    status_update_publicacion: 'idle',
    error_update_publicacion: null,    
    status_save_publicacion: 'idle',
    error_save_publicacion: null,
    status_anular_publicacion: 'idle',
    error_anular_publicacion: null,
  },
  reducers: {
    setProtocoloSeleccionada: (state, action) => {
      state.publicacionSeleccionada = action.payload;
      
    },
    clearProtocoloSeleccionada(state) {
      state.publicacionSeleccionada = null;
      state.publicacion = null;
    },
    resetStateGetPublicaciones_x_edificio: () => {
        return {
            status_get_publicaciones_x_edificio : 'idle',
        };
      },
      resetStateGetPublicaciones_x_depa: () => {
        return {
            status_get_publicaciones_x_depa : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_publicacion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_publicacion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.publicacion = action.payload;

      })
      .addCase(get_publicacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_publicacion.pending, (state) => {
        state.status_update_publicacion = 'loading';
      })
      .addCase(update_publicacion.fulfilled, (state, action) => {
        state.status_update_publicacion = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.publicacion = action.payload.publicacion;

      })
      .addCase(update_publicacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_publicacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_publicacion = action.payload || 'Failed to fetch building';
      })
      .addCase(save_publicacion.pending, (state) => {
        state.status_save_publicacion = 'loading';
      })
      .addCase(save_publicacion.fulfilled, (state, action) => {
        state.status_save_publicacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.publicacion = action.payload.publicacion;

      })
      .addCase(save_publicacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_publicacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_publicacion = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_publicacion.pending, (state) => {
        state.status_save_publicacion = 'loading';
      })
      .addCase(anular_publicacion.fulfilled, (state, action) => {
        state.status_anular_publicacion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.publicacion = action.payload.publicacion;

      })
      .addCase(anular_publicacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_publicacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_publicacion = action.payload || 'Failed to fetch building';
      })
      
      .addCase(get_publicaciones_x_depa.pending, (state) => {
        state.status_get_publicaciones_x_depa = 'loading';
        
      })
      .addCase(get_publicaciones_x_depa.fulfilled, (state, action) => {
        state.status_get_publicaciones_x_depa = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_publicaciones_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.publicaciones = action.payload;

      })
      .addCase(get_publicaciones_x_depa.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_publicaciones_x_depa = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_publicaciones_x_depa = action.payload || 'Failed to fetch building';
        state.publicaciones =[];
      })
      
      .addCase(get_publicaciones_x_edificio.pending, (state) => {
        state.status_get_publicaciones_x_edificio = 'loading';
        
      })
      .addCase(get_publicaciones_x_edificio.fulfilled, (state, action) => {
        state.status_get_publicaciones_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_publicaciones_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.publicaciones = action.payload;

      })
      .addCase(get_publicaciones_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_publicaciones_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_publicaciones_x_edificio = action.payload || 'Failed to fetch building';
        state.publicaciones =[];
      })
  },
});

export const { setProtocoloSeleccionada, clearProtocoloSeleccionada } = publicacionSlice.actions;
export const selectPublicacionesById = (state, idProtocolo) =>
    state.publicacion.publicaciones.find((publicacion) => publicacion.id === idProtocolo);
export const { resetStateGetPublicaciones_x_edificio } = publicacionSlice.actions;
export const { resetStateGetPublicaciones_x_depa } = publicacionSlice.actions;
export default publicacionSlice.reducer;
