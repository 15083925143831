import axios from 'axios';

const apiClient = axios.create({
  //baseURL: 'http://127.0.0.1:5001/tantaq-apis/us-central1/api',
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default apiClient;
