import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { save_persona_asistencia, get_persona_asistencia } from './persona_asistenciaThunk';
import { get_unidades_inmobiliarias_x_tipo } from '../edificio/unidad_inmobiliariaThunk';
import { get_grupos_x_tipo } from '../medicamentos/grupoThunk';
import { useParams, useNavigate } from 'react-router-dom';
import './RegistroAsistenciaEspecialPage.css';
import M from 'materialize-css/dist/js/materialize.min.js';

const RegistroAsistenciaEspecialPage = () => {
    const { id } = useParams(); // Captura el ID de persona para editar o visualizar
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const buildingId = useSelector((state) => state.edificio.edificio?.id);
    const unidades_inmobiliarias = useSelector((state) => state.unidad_inmobiliaria.unidades_inmobiliarias) || [];
    const tipos_asistencias = useSelector((state) => state.grupo.grupos) || [];

    const [formData, setFormData] = useState({
        idunidad_inmobiliaria: '',
        tipo_unidad_inmobiliaria: '',
        numero_unidad_inmobiliaria: '',
        nombre: '',
        edad: '',
        idtipo_asistencia: '',
        tipo_asistencia: '',
        descripcion: '',
        contacto_emergencia: { nombre: '', telefono: '', relacion: '' },
        equipos_especiales: '',
        ruta_evacuacion: '',
        punto_encuentro: '',
    });

    useEffect(() => {
        if (buildingId) {
            dispatch(get_grupos_x_tipo({ buildingId, tipo: 'ASISTENCIA_ESPECIAL' }));
        }
    }, [dispatch, buildingId]);

    useEffect(() => {
        if (buildingId && formData.tipo_unidad_inmobiliaria) {
            dispatch(get_unidades_inmobiliarias_x_tipo({ buildingId, tipo_unidad_inmobiliaria: formData.tipo_unidad_inmobiliaria }));
        }
    }, [dispatch, buildingId, formData.tipo_unidad_inmobiliaria]);

    useEffect(() => {
        if (id) {
            dispatch(get_persona_asistencia(id)).then((response) => {
                setFormData(response.payload);
                M.updateTextFields();
            });
        }
    }, [dispatch, id]);

    useEffect(() => {
        M.FormSelect.init(document.querySelectorAll('select'));
        M.updateTextFields();
    }, [formData, unidades_inmobiliarias, tipos_asistencias]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('contacto_emergencia')) {
            const field = name.split('.')[1];
            setFormData((prev) => ({
                ...prev,
                contacto_emergencia: { ...prev.contacto_emergencia, [field]: value },
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const selectedTipoAsistencia = tipos_asistencias.find((t) => t.id === formData.idtipo_asistencia);
        dispatch(
            save_persona_asistencia({
                ...formData,
                tipo_asistencia: selectedTipoAsistencia?.nombre || '',
                buildingId,
            })
        ).then(() => navigate('/lista-personas-asistencia'));
    };

    return (
        <div className="registro-asistencia-container">
            <h3>{id ? 'Editar' : 'Registrar'} Persona con Asistencia Especial</h3>
            <form onSubmit={handleSubmit} className="asistencia-form">
                <div className="input-field">
                    <select
                        name="tipo_unidad_inmobiliaria"
                        value={formData.tipo_unidad_inmobiliaria}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="" disabled>
                            Seleccione tipo de unidad
                        </option>
                        <option value="Departamento">Departamento</option>
                        <option value="Local-Comercial">Local Comercial</option>
                    </select>
                    <label>Tipo de Unidad Inmobiliaria</label>
                </div>
                <div className="input-field">
                    <select
                        name="numero_unidad_inmobiliaria"
                        value={formData.numero_unidad_inmobiliaria}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="" disabled>
                            Seleccione una unidad
                        </option>
                        {unidades_inmobiliarias.map((unidad) => (
                            <option key={unidad.id} value={unidad.numero_unidad_inmobiliaria}>
                                {unidad.numero_unidad_inmobiliaria}
                            </option>
                        ))}
                    </select>
                    <label>Unidad Inmobiliaria</label>
                </div>
                {/* Campos de nombre, edad, tipo de asistencia */}
                <div className="input-field">
                    <input
                        type="text"
                        name="nombre"
                        value={formData.nombre}
                        onChange={handleInputChange}
                        required
                    />
                    <label className="active">Nombre</label>
                </div>
                <div className="input-field">
                    <input
                        type="number"
                        name="edad"
                        min="0"
                        value={formData.edad}
                        onChange={handleInputChange}
                        required
                    />
                    <label className="active">Edad</label>
                </div>
                <div className="input-field">
                    <select
                        name="idtipo_asistencia"
                        value={formData.idtipo_asistencia}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="" disabled>
                            Seleccionar tipo de asistencia
                        </option>
                        {tipos_asistencias.map((t) => (
                            <option key={t.id} value={t.id}>
                                {t.nombre}
                            </option>
                        ))}
                    </select>
                    <label>Tipo de Asistencia</label>
                </div>
                <button type="submit" className="btn green">
                    {id ? 'Actualizar' : 'Guardar'}
                </button>
            </form>
        </div>
    );
};

export default RegistroAsistenciaEspecialPage;
