import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetContactos_referencia_x_edificio } from './contacto_referenciaSlice';


export const update_contacto_referencia = createAsyncThunk('contacto_referencia/update_contacto_referencia', async ({ idContacto_referencia, contacto_referenciaData }, thunkAPI) => {
  console.log("ejecutando thunk update_contacto_referencia con idContacto_referencia: " + idContacto_referencia);

  try {

    const response = await apiClient.put(`/contactos-referencia/${idContacto_referencia}`, contacto_referenciaData);

    const contacto_referencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetContactos_referencia_x_edificio(contacto_referencia.buildingId));// Resetear el estado para refetch de contactos_referencia

    return contacto_referencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_contacto_referencia = createAsyncThunk('contacto_referencia/save_contacto_referencia', async (contacto_referenciaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/contactos-referencia`, contacto_referenciaData);

    const contacto_referencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetContactos_referencia_x_edificio(contacto_referencia.buildingId));// Resetear el estado para refetch de contactos_referencia

    return contacto_referencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_contacto_referencia = createAsyncThunk('contacto_referencia/anular_contacto_referencia', async ({ idContacto_referencia, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_contacto_referencia con idContacto_referencia: " + idContacto_referencia);

  try {

    const response = await apiClient.patch(`/contactos-referencia/${idContacto_referencia}/anular`, { usuarioAnulacion });

    const contacto_referencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetContactos_referencia_x_edificio(contacto_referencia.buildingId));// Resetear el estado para refetch de contactos_referencia

    return contacto_referencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_contacto_referencia = createAsyncThunk('contacto_referencia/get_contacto_referencia', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_contacto_referencia con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/contactos-referencia/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const contacto_referencia = response.data;

    return contacto_referencia;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_contactos_referencia_x_edificio = createAsyncThunk('contacto_referencia/get_contactos_referencia_x_edificio', async ({ buildingId, tipo }, thunkAPI) => {
  console.log("ejecutando thunk get_contactos_referencia con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/contactos-referencia',{
        params: { 
          buildingId, 
          tipo 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const contactos_referencia = response.data;
          
      return contactos_referencia ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
