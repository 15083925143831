import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const useAuth = () => {
    const usuario = useSelector((state) => state.auth.usuario);
    const navigate = useNavigate();

    useEffect(() => {
        if (!usuario) {
            navigate('/'); // Redirige si no hay usuario autenticado
        }
    }, [usuario, navigate]);

    return usuario; // Retorna el usuario autenticado
};

export default useAuth;
