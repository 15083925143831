// frontend/src/pages/MisJustificacionesPage.js 
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { get_justificaciones_x_usuario } from './justificacionThunk';
import M from 'materialize-css';

import { 
    formatDate, 
    formatCurrency, 
    formatPeriod, 
    formatDate02, 
    formatearPeriodo_MesAnio01, 
    formatearImporte01 
} from '../../utils/formateo';
import { downloadPDF } from '../../utils/util';

const MisJustificacionesPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Selectores del estado de Redux
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const justificaciones = useSelector((state) => state.justificacion.justificaciones) || [];
    const usuario = useSelector((state) => state.auth.usuario);
    const status_get_justificaciones_x_usuario = useSelector((state) => state.justificacion.status_get_justificaciones_x_usuario);
    const edificio = useSelector((state) => state.edificio.edificio);
    const error_get_justificaciones_x_usuario = useSelector((state) => state.justificacion.error_get_justificaciones_x_usuario);
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);

    const [filtro, setFiltro] = useState('');

    // Verificar autenticación y redireccionar si no está autenticado
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    // Inicializar Materialize CSS y cargar justificaciones
    useEffect(() => {
        M.AutoInit(); // Inicializa los componentes de Materialize CSS
        //console.log("ANTES status_get_justificaciones_x_usuario:", status_get_justificaciones_x_usuario);
        
        //if (usuario  && edificio?.id) {
                dispatch(get_justificaciones_x_usuario(
                    {
                        buildingId: edificio.id, 
                        codigo_usuario: usuario.id,
                        estado: 'ACTIVO'
                    }
                )); 
            //}
        /*
        } else {
            console.log("unidad_facturacionSeleccionado, edificio.id o ambos no están definidos.");
        }
            */
}, [edificio, usuario, dispatch]);

    if (!usuario) {
        return null; // Evita renderizar mientras se verifica la autenticación
    }

    const handleFilterChange = (e) => {    
        setFiltro(e.target.value.toLowerCase());
    };

    const justificacionesFiltrados = justificaciones.filter((justificacion) => {
        const filter = filtro.trim();
        if (filter === '') return true; // Si el filtro está vacío, mostrar todos

        // Asegurar que los campos existen antes de aplicarle métodos
        const numero_justificacion = justificacion.numero_justificacion?.toLowerCase() || '';
        const tipo_atencion = justificacion.tipo_atencion?.toLowerCase() || '';
        const categoria = justificacion.categoria?.toLowerCase() || '';
        const fecha_solicitud = justificacion.fecha_solicitud?.toLowerCase() || '';
        const fecha_cierre = justificacion.fecha_cierre?.toLowerCase() || '';
        const estado = justificacion.estado?.toLowerCase() || '';

        return (
            numero_justificacion.includes(filter) ||
            tipo_atencion.includes(filter) ||
            categoria.includes(filter) ||
            fecha_solicitud.includes(filter) ||
            fecha_cierre.includes(filter) ||     
            estado.includes(filter)
        );
    });

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <span className="card-title">Justificaciones</span>
                    <div className="card-action">
                        <button 
                            className="btn orange tooltipped" 
                            data-position="top" 
                            data-tooltip="Nuevo Justificacion" 
                            onClick={() => navigate(`/justificacion/create`)}
                        >
                            <i className="material-icons left">add_box</i>Crear Justificacion
                        </button>
                        <button 
                            className="btn blue tooltipped" 
                            data-position="top" 
                            data-tooltip="Volver" 
                            onClick={() => navigate('/home')}
                        >
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>              
                    </div>
                    
                    <h5>Listado</h5>

                    <div className="input-field">
                        <i className="material-icons prefix">search</i>
                        <input 
                            type="text" 
                            id="filtro_tabla_mis_justificaciones_propietario" 
                            value={filtro}
                            onChange={handleFilterChange} 
                            placeholder="Filtrar por cualquier campo" 
                        />
                    </div>

                    {status_get_justificaciones_x_usuario === 'loading' && (
                        <div className="progress">
                            <div className="indeterminate"></div>
                        </div>
                    )}

                    {status_get_justificaciones_x_usuario === 'failed' && (
                        <div className="card-panel red lighten-4 red-text text-darken-4">
                            <span>Error: {error_get_justificaciones_x_usuario}</span>
                        </div>
                    )}

                    {status_get_justificaciones_x_usuario === 'succeeded' && justificacionesFiltrados.length === 0 && (
                        <div className="card-panel yellow lighten-4 yellow-text text-darken-4">
                            <span>No se encontraron justificaciones que coincidan con el filtro.</span>
                        </div>
                    )}

                    {status_get_justificaciones_x_usuario === 'succeeded' && justificacionesFiltrados.length > 0 && (
                        <table className="striped responsive-table tabla_mis_justificaciones_propietario">
                            <thead>
                                <tr>
                                    <th>Just.</th>
                                    <th>Reunión</th>
                                    <th>Asunto</th>
                                    <th>Solicitud</th>
                                    <th>Motivo</th>
                                    <th>Cerrado</th>
                                    <th>Estado</th>                                    
                                </tr>
                            </thead>
                            <tbody id="table-body-mis-justificaciones_propietario">                    
                                {justificacionesFiltrados.map((justificacion) => (
                                    <tr key={justificacion.id}>                                        
                                        <td>
                                            <Link to={`/justificacion/view/${justificacion.id}`}>
                                                {justificacion.numero_justificacion}
                                            </Link>
                                        </td>
                                        <td>{justificacion.fecha_reunion}</td>
                                        <td>{justificacion.asunto}</td>
                                        <td>{justificacion.fecha_solicitud}</td>
                                        <td>{justificacion.motivo}</td>
                                        <td>{justificacion.fecha_cierre}</td>
                                        <td>{justificacion.estado}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>   
            </div> 
        </div>
    );
};

export default MisJustificacionesPage;
