import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetRequerimientos } from './requerimientoSlice';


export const update_requerimiento = createAsyncThunk('requerimiento/update_requerimiento', async ({ idRequerimiento, requerimientoData }, thunkAPI) => {
  //console.log("ejecutando thunk update_requerimiento con idRequerimiento: " + idRequerimiento);

  try {

    const response = await apiClient.put(`/requerimientos/${idRequerimiento}`, requerimientoData);

    const requerimiento = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetRequerimientos);// Resetear el estado para refetch de requerimientos

    return requerimiento;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_requerimiento = createAsyncThunk('requerimiento/save_requerimiento', async (requerimientoData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/requerimientos`, requerimientoData);

    const requerimiento = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetRequerimientos);// Resetear el estado para refetch de requerimientos

    return requerimiento;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_requerimiento = createAsyncThunk('requerimiento/anular_requerimiento', async ({ idRequerimiento, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_requerimiento con idRequerimiento: " + idRequerimiento);

  try {

    const response = await apiClient.patch(`/requerimientos/${idRequerimiento}/anular`, { usuarioAnulacion });

    const requerimiento = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetRequerimientos);// Resetear el estado para refetch de requerimientos

    return requerimiento;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_requerimientos_x_depa = createAsyncThunk('requerimiento/get_requerimientos_x_depa', async ({ buildingId, codigo_unidad_facturacion, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_requerimientos_x_depa con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/requerimientos',{
        params: { 
          buildingId, 
          codigo_unidad_facturacion,
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const requerimientos = response.data;
          
      return requerimientos ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_requerimiento = createAsyncThunk('requerimiento/get_requerimiento', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_requerimiento con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/requerimientos/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const requerimiento = response.data;

    return requerimiento;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});