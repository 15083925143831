import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {signOutUsuario} from '../auth/authSlice';

const Logout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  console.log("INICIANDO LOGOUT");

  const handleLogout = async () => {
    try{
        console.log("Logout firebase");
        await dispatch(signOutUsuario());
        console.log("Logout redireccion");
        navigate('/'); // Redirigir a la página de login después de cerrar sesión    
    }catch(error){
        console.error("Error signout: ", error);
    }
  };
  
  useEffect(() => {
    handleLogout();
  }, [handleLogout]);


  return (
    <div>
      Logging out...
    </div>
  );
};

export default Logout;