import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_leads_x_vendedor } from './leadThunk';
import './MisPedidosPage.css';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';

const MisPedidosPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedLead, setSelectedLead] = useState(null);

    const usuario = useSelector((state) => state.auth.usuario);
    const leads = useSelector((state) => state.lead.leads); // Supongamos que el estado guarda los leads
    const status_get_leads_x_vendedor = useSelector((state) => state.lead.status_get_leads_x_vendedor); // Manejo de estados para carga de leads
    const edificio = useSelector((state) => state.edificio.edificio);


    useEffect(() => {
        if (usuario?.id) {
            dispatch(get_leads_x_vendedor({buildingId: edificio.id, idVendedor: usuario.id})); // Acción para obtener los leads del vendedor
        }
    }, [dispatch, edificio, usuario]);

    const handleLeadClick = (lead) => {
        setSelectedLead(lead);
        navigate(`/leads/detalle/${lead.id}`); // Navegar a la página de detalle del lead
    };

    if (status_get_leads_x_vendedor === 'loading') {
        return <p>Cargando leads...</p>;
    }

    if (status_get_leads_x_vendedor === 'failed') {
        return <p>Error al cargar los leads. Intenta nuevamente más tarde.</p>;
    }

    return (
        <div className="leads-vendedor-container">
            <h1>Mis Leads</h1>
            <div className="leads-list">
                {leads?.length > 0 ? (
                    leads.map((lead) => (
                        <div
                            key={lead.id}
                            className="lead-item card-panel"
                            onClick={() => handleLeadClick(lead)}
                        >
                            <h5>{lead.titulo}</h5>
                            <p>{lead.descripcion}</p>
                            <p><strong>Interesado:</strong> {lead.nombreComprador}</p>
                        </div>
                    ))
                ) : (
                    <p>No tienes leads aún.</p>
                )}
            </div>
        </div>
    );
};

export default MisPedidosPage;
