import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_personas_asistencia_x_depa } from './persona_asistenciaThunk';
import { useNavigate } from 'react-router-dom';
import './ListaPersonasAsistenciaPage.css';

const ListaPersonasAsistenciaPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const buildingId = useSelector((state) => state.edificio.edificio?.id);
    const personas_asistencia = useSelector((state) => state.persona_asistencia.personas_asistencia) || [];

    useEffect(() => {
        if (buildingId) {
            dispatch(get_personas_asistencia_x_depa({ buildingId }));
        }
    }, [dispatch, buildingId]);

    return (
        <div className="lista-personas-asistencia-container">
            <h3>Personas con Asistencia Especial</h3>
            <button className="btn blue" onClick={() => navigate('/persona-vulnerable/create')}>
                Registrar Nueva Persona
            </button>
            <div className="personas_asistencia-list">
                {personas_asistencia.map((persona) => (
                    <div key={persona.id} className="persona-item">
                        <h5>{persona.nombre}</h5>
                        <p><strong>Edad:</strong> {persona.edad}</p>
                        <p><strong>Tipo de Asistencia:</strong> {persona.tipo_asistencia}</p>
                        <button className="btn yellow" onClick={() => navigate(`/persona-vulnerable/view/${persona.id}`)}>
                            Editar / Ver
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ListaPersonasAsistenciaPage;
