import { createSlice } from '@reduxjs/toolkit';
import { get_reserva, get_reservas, update_reserva, update_constancia_reserva, save_reserva, anular_reserva, validate_turno, get_reservas_x_edificio, get_reservas_x_area_comun, get_reservas_x_depa_tipo_area_comun } from './reservaThunk';

const reservaSlice = createSlice({
  name: 'reserva',
  initialState: {
    reserva: null,
    reservas: [],
    reservaSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_reservas: 'idle',
    error_get_reservas: null,    
    status_get_reservas_x_edificio: 'idle',
    error_get_reservas_x_edificio: null,
    status_get_reservas_x_area_comun: 'idle',
    error_get_reservas_x_area_comun: null,    
    status_get_reservas_x_depa_tipo_area_comun: 'idle',
    error_get_reservas_x_depa_tipo_area_comun: null,
    status_update_reserva: 'idle',
    error_update_reserva: null,    
    status_update_constancia_reserva: 'idle',
    error_update_constancia_reserva: null,
    status_save_reserva: 'idle',
    error_save_reserva: null,
    status_anular_reserva: 'idle',
    error_anular_reserva: null,
    status_validate_turno: 'idle',
    error_validate_turno: null,
    turno_available: null,
    turno_message: ''
  },
  reducers: {
    setReservaSeleccionada: (state, action) => {
      state.reservaSeleccionada = action.payload;
      
    },
    clearReservaSeleccionada(state) {
      state.reservaSeleccionada = null;
      state.reserva = null;
    },
    resetStateGetReservas: () => {
        return {
            status_get_reservas : 'idle',
        };
      },
      clearTurnoValidation: (state) => {
        state.status_validate_turno = 'idle';
        state.error_validate_turno = null;
        state.turno_available = null;
        state.turno_message = '';
    }      
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_reserva.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_reserva.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reserva = action.payload;

      })
      .addCase(get_reserva.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })
      

      .addCase(update_constancia_reserva.pending, (state) => {
        state.status_update_constancia_reserva = 'loading';
      })
      .addCase(update_constancia_reserva.fulfilled, (state, action) => {
        state.status_update_constancia_reserva = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reserva = action.payload.reserva;

      })
      .addCase(update_constancia_reserva.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_constancia_reserva = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_constancia_reserva = action.payload || 'Failed to fetch building';
      })


      .addCase(update_reserva.pending, (state) => {
        state.status_update_reserva = 'loading';
      })
      .addCase(update_reserva.fulfilled, (state, action) => {
        state.status_update_reserva = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reserva = action.payload.reserva;

      })
      .addCase(update_reserva.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_reserva = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_reserva = action.payload || 'Failed to fetch building';
      })
      .addCase(save_reserva.pending, (state) => {
        state.status_save_reserva = 'loading';
      })
      .addCase(save_reserva.fulfilled, (state, action) => {
        state.status_save_reserva = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reserva = action.payload.reserva;

      })
      .addCase(save_reserva.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_reserva = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_reserva = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_reserva.pending, (state) => {
        state.status_save_reserva = 'loading';
      })
      .addCase(anular_reserva.fulfilled, (state, action) => {
        state.status_anular_reserva = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reserva = action.payload.reserva;

      })
      .addCase(anular_reserva.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_reserva = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_reserva = action.payload || 'Failed to fetch building';
      })
      .addCase(get_reservas.pending, (state) => {
        state.status_get_reservas = 'loading';
        
      })
      .addCase(get_reservas.fulfilled, (state, action) => {
        state.status_get_reservas = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reservas = action.payload;

      })
      .addCase(get_reservas.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_reservas = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_reservas = action.payload || 'Failed to fetch building';
      })      
      .addCase(get_reservas_x_area_comun.pending, (state) => {
        state.status_get_reservas_x_area_comun = 'loading';
        
      })
      .addCase(get_reservas_x_area_comun.fulfilled, (state, action) => {
        state.status_get_reservas_x_area_comun = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reservas = action.payload;

      })
      .addCase(get_reservas_x_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_reservas_x_area_comun = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_reservas_x_area_comun = action.payload || 'Failed to fetch building';
        state.reservas =[];
      })      
      .addCase(get_reservas_x_edificio.pending, (state) => {
        state.status_get_reservas_x_edificio = 'loading';
        
      })
      .addCase(get_reservas_x_edificio.fulfilled, (state, action) => {
        state.status_get_reservas_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reservas = action.payload;

      })
      .addCase(get_reservas_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_reservas_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_reservas_x_edificio = action.payload || 'Failed to fetch building';
        state.reservas =[];
      })            
      .addCase(get_reservas_x_depa_tipo_area_comun.pending, (state) => {
        state.status_get_reservas_x_depa_tipo_area_comun = 'loading';
        
      })
      .addCase(get_reservas_x_depa_tipo_area_comun.fulfilled, (state, action) => {
        state.status_get_reservas_x_depa_tipo_area_comun = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reservas = action.payload;

      })
      .addCase(get_reservas_x_depa_tipo_area_comun.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_reservas_x_depa_tipo_area_comun = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_reservas_x_depa_tipo_area_comun = action.payload || 'Failed to fetch building';
        state.reservas =[];
      })
      .addCase(validate_turno.pending, (state) => {
        state.status_validate_turno = 'loading';
        state.error_validate_turno = null;
        state.turno_available = null;
        state.turno_message = '';
    })
    .addCase(validate_turno.fulfilled, (state, action) => {
        state.status_validate_turno = 'succeeded';
        state.turno_available = action.payload.available;
        state.turno_message = action.payload.message;
    })
    .addCase(validate_turno.rejected, (state, action) => {
        state.status_validate_turno = 'failed';
        state.error_validate_turno = action.payload || 'Error al validar el turno.';
        state.turno_available = null;
        state.turno_message = '';
    });      
  },
});

export const { setReservaSeleccionada, clearReservaSeleccionada, clearTurnoValidation } = reservaSlice.actions;
export const selectReservasById = (state, idReserva) =>
    state.reserva.reservas.find((reserva) => reserva.id === idReserva);
export const { resetStateGetReservas } = reservaSlice.actions;
export default reservaSlice.reducer;
