import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_unidades_facturacion_cargadas, mover_unidades_facturacion_cargadas} from './unidades-facturacion-cargadasThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Unidades_facturacion_cargadas = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  //const depas_cargados = useSelector((state) => state.depas_cargados.depas_cargados);
  const { unidades_facturacion_cargadas, status, error } = useSelector((state) => state.unidades_facturacion_cargadas);
  const usuario = useSelector((state) => state.auth.usuario);
  //const fetchStatus  = useSelector((state) => state.depas_cargados.fetchStatus );    
  //const error = useSelector((state) => state.depas_cargados.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("status.get_unidades_facturacion_cargadas: "+ status.get_unidades_facturacion_cargadas);
    //console.log("codigo_edificio: "+ codigo_edificio);
    if (status.get_unidades_facturacion_cargadas  === 'idle' && codigo_edificio) {
      dispatch(get_unidades_facturacion_cargadas(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, status.get_unidades_facturacion_cargadas , codigo_edificio, dispatch]);

  const handleRefresh = () => {
    dispatch(get_unidades_facturacion_cargadas(codigo_edificio));
  };

  const formatearUnidades_facturacion = (unidades_facturacion) => {
    if (!Array.isArray(unidades_facturacion)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return unidades_facturacion.map(unidad_facturacion => ({
      ...unidad_facturacion,
      fecha_carga_formato01: formatDate02(unidad_facturacion.timestamp_carga),
    }));
  };

  const unidades_facturacionFormateados = formatearUnidades_facturacion(unidades_facturacion_cargadas);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const unidades_facturacionFiltrados = Array.isArray(unidades_facturacionFormateados) ? unidades_facturacionFormateados.filter((unidad_facturacion) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return unidad_facturacion.codigo_unidad_facturacion.toLowerCase().includes(filterLowerCase) ||
        unidad_facturacion.resumen_propietarios.toLowerCase().includes(filterLowerCase) ||
        unidad_facturacion.resumen_propiedades.toLowerCase().includes(filterLowerCase) ||
        unidad_facturacion.area_ocupada_total.toString().toLowerCase().includes(filterLowerCase) ||
        unidad_facturacion.porcentaje_participacion.toString().toLowerCase().includes(filterLowerCase) ||
        unidad_facturacion.validacion_carga.toLowerCase().includes(filterLowerCase)  ||
        unidad_facturacion.mensaje_error_carga.toLowerCase().includes(filterLowerCase) 
        
      ;
  })
  : [];

  const handleMoveValidUnidades_facturacion = async () => {
    let bodyRequest={
        buildingId: codigo_edificio,
        usuario_proceso: usuario.id
    };
    dispatch(mover_unidades_facturacion_cargadas(bodyRequest));    
    //dispatch(fetchDepartments()); // Actualiza la lista después de mover los departamentos válidos
    navigate('/unidades-facturacion');
  };
  

  if (status.get_unidades_facturacion_cargadas  === 'loading') {
    return <div>Loading...</div>;
  }

  if (status.get_unidades_facturacion_cargadas  === 'failed') {
    return <div>Error: {error.get_unidades_facturacion_cargadas}</div>;
  }

  return (    
    <div className="container">
        <h5>Unid. Facturación cargadas</h5>
        <button className="btn" onClick={handleRefresh}>
        Refresh List
        </button>
        <button className="btn" onClick={handleMoveValidUnidades_facturacion}>
        Registrar
        </button>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_unidades_facturacion_cargadas"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_unidades_facturacion_cargadas" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_unidades_facturacion_cargadas">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>Unid.Fact.</th>            
          <th>Propietarios</th>
          <th>Unid. Inmobiliarias</th>         
          <th>Area_ocupada</th>
          <th>%Particip.</th>
          <th>Validacion</th>
          <th>Mensaje</th>
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Fecha_carga</th>
        </tr>
      </thead>
      <tbody id="table-body-unidades_facturacion-cargados">                    
        {unidades_facturacionFiltrados.map((unidad_facturacion) => (
          <tr key={unidad_facturacion.id}>
            <td>{unidad_facturacion.codigo_edificio}</td>
            <td>{unidad_facturacion.codigo_unidad_facturacion}</td>
            <td>{unidad_facturacion.resumen_propietarios}</td>        
            <td>{unidad_facturacion.resumen_propiedades}</td>
            <td>{unidad_facturacion.area_ocupada_total}</td>
            <td>{unidad_facturacion.porcentaje_participacion}</td>
            <td>{unidad_facturacion.validacion_carga}</td>
            <td>{unidad_facturacion.mensaje_error_carga}</td>
            <td>{unidad_facturacion.usuario_carga}</td>
            <td>{unidad_facturacion.uid_carga}</td>
            <td>{unidad_facturacion.timestamp_carga_formateado}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Unidades_facturacion_cargadas;