import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetMedicamentos_x_edificio } from './medicamentoSlice';


export const update_medicamento = createAsyncThunk('medicamento/update_medicamento', async ({ idMedicamento, medicamentoData }, thunkAPI) => {
  console.log("ejecutando thunk update_medicamento con idMedicamento: " + idMedicamento);

  try {

    const response = await apiClient.put(`/medicamentos/${idMedicamento}`, medicamentoData);

    const medicamento = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetMedicamentos_x_edificio(medicamento.buildingId));// Resetear el estado para refetch de medicamentos

    return medicamento;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_medicamento = createAsyncThunk('medicamento/save_medicamento', async (medicamentoData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/medicamentos`, medicamentoData);

    const medicamento = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetMedicamentos_x_edificio(medicamento.buildingId));// Resetear el estado para refetch de medicamentos

    return medicamento;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_medicamento = createAsyncThunk('medicamento/anular_medicamento', async ({ idMedicamento, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_medicamento con idMedicamento: " + idMedicamento);

  try {

    const response = await apiClient.patch(`/medicamentos/${idMedicamento}/anular`, { usuarioAnulacion });

    const medicamento = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetMedicamentos_x_edificio(medicamento.buildingId));// Resetear el estado para refetch de medicamentos

    return medicamento;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_medicamento = createAsyncThunk('medicamento/get_medicamento', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_medicamento con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/medicamentos/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const medicamento = response.data;

    return medicamento;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_medicamentos_x_edificio = createAsyncThunk('medicamento/get_medicamentos_x_edificio', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_medicamentos con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/medicamentos',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const medicamentos = response.data;
          
      return medicamentos ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
