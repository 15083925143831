import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

  export const get_config_unidades_facturacion_cargadas = createAsyncThunk('config_unidades_facturacion-carga/get_config_unidades_facturacion_cargadas', async (codigo_edificio, thunkAPI) => {
    //console.log("ejecutando thunk registrar_config_unidades_facturacion_cargadas con "+codigo_edificio);
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/config-unidades-facturacion-cargadas`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const config_unidades_facturacion_cargadas = response.data;
            
        return config_unidades_facturacion_cargadas ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const mover_config_unidades_facturacion_cargadas = createAsyncThunk('unidades_facturacion-carga/mover_config_unidades_facturacion_cargadas', async (jsonBodyRequest, thunkAPI) => {
    //console.log("ejecutando thunk mover_config_unidades_facturacion_cargadas con "+JSON.stringify(jsonBodyRequest));
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.post('/config-unidades-facturacion-carga/registro',jsonBodyRequest);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const resp_registro = response.data;
            
        return resp_registro;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  
