import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetRespuestas_x_edificio, resetStateGetRespuestas_x_usuario } from './respuestaSlice';


export const update_respuesta = createAsyncThunk('respuesta/update_respuesta', async ({ idRespuesta, respuestaData }, thunkAPI) => {
  console.log("ejecutando thunk update_respuesta con idRespuesta: " + idRespuesta);

  try {

    const response = await apiClient.put(`/respuestas/${idRespuesta}`, respuestaData);

    const respuesta = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetRespuestas_x_edificio);// Resetear el estado para refetch de respuestas
    thunkAPI.dispatch(resetStateGetRespuestas_x_usuario);// Resetear el estado para refetch de respuestas
    return respuesta;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_respuesta = createAsyncThunk('respuesta/save_respuesta', async (respuestaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/respuestas`, respuestaData);

    const respuesta = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetRespuestas_x_edificio);// Resetear el estado para refetch de respuestas
    thunkAPI.dispatch(resetStateGetRespuestas_x_usuario);// Resetear el estado para refetch de respuestas

    return respuesta;

  } catch (error) {
    
    const errorMessage = error.response?.data?.error || 'Error al crear la reunión.';
    console.log("errorMessage " + errorMessage);
    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const anular_respuesta = createAsyncThunk('respuesta/anular_respuesta', async ({ idRespuesta, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_respuesta con idRespuesta: " + idRespuesta);

  try {

    const response = await apiClient.patch(`/respuestas/${idRespuesta}/anular`, { usuarioAnulacion });

    const respuesta = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetRespuestas_x_edificio);// Resetear el estado para refetch de respuestas
    thunkAPI.dispatch(resetStateGetRespuestas_x_usuario);// Resetear el estado para refetch de respuestas
    return respuesta;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_respuesta = createAsyncThunk('respuesta/get_respuesta', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_respuesta con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/respuestas/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const respuesta = response.data;

    return respuesta;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_respuestas_x_edificio = createAsyncThunk('respuesta/get_respuestas_x_edificio', async ({ buildingId }, thunkAPI) => {
  console.log("ejecutando thunk get_respuestas con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/respuestas',{
        params: { 
          buildingId           
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const respuestas = response.data;
          
      return respuestas ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const get_respuestas_x_usuario = createAsyncThunk('respuesta/get_respuestas_x_usuario', async ({ buildingId, codigo_usuario }, thunkAPI) => {
    console.log("ejecutando thunk get_respuestas con "+buildingId);
    
    try { 
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get('/respuestas',{
          params: { 
            buildingId,
            codigo_usuario,
            estado: 'ACTIVO'
          }
      });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const respuestas = response.data;
            
        return respuestas ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });