import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_estacionamientos_cargados_x_edificio, mover_estacionamientos_cargados} from './estacionamientos-cargadosThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Estacionamientos_cargados = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  const codigo_edificio = edificio?.id;
  //const Estacionamientos_cargados = useSelector((state) => state.Estacionamientos_cargados.Estacionamientos_cargados);
  const { estacionamientos_cargados, status, error } = useSelector((state) => state.estacionamientos_cargados);
  const usuario = useSelector((state) => state.auth.usuario);
  //const fetchStatus  = useSelector((state) => state.Estacionamientos_cargados.fetchStatus );    
  //const error = useSelector((state) => state.Estacionamientos_cargados.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("status.get_estacionamientos_cargados_x_edificio: "+ status.get_estacionamientos_cargados_x_edificio);
    //console.log("codigo_edificio: "+ codigo_edificio);
    if (status.get_estacionamientos_cargados_x_edificio  === 'idle' && codigo_edificio) {
      dispatch(get_estacionamientos_cargados_x_edificio(codigo_edificio));
      
    }


  }, [isAuthenticated, navigate, status.get_estacionamientos_cargados_x_edificio , codigo_edificio, dispatch]);

  const handleRefresh = () => {
    dispatch(get_estacionamientos_cargados_x_edificio(codigo_edificio));
  };

  const formatearEstacionamientos = (estacionamientos) => {
    if (!Array.isArray(estacionamientos)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }

    return estacionamientos.map(estacionamiento => ({
      ...estacionamiento,
      fecha_carga_formato01: formatDate02(estacionamiento.timestamp_carga),
    }));
  };

  const estacionamientosFormateados = formatearEstacionamientos(estacionamientos_cargados);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const estacionamientosFiltrados = Array.isArray(estacionamientosFormateados) ? estacionamientosFormateados.filter((estacionamiento) => {    

    const filterLowerCase = filtro.toLowerCase();
    return estacionamiento.tipo.toLowerCase().includes(filterLowerCase) ||
        estacionamiento.numero.toLowerCase().includes(filterLowerCase) ||
        estacionamiento.nivel.toString().toLowerCase().includes(filterLowerCase) ||
        estacionamiento.tipo_nivel.toString().toLowerCase().includes(filterLowerCase) ||
        estacionamiento.area_ocupada.toString().toLowerCase().includes(filterLowerCase) ||
        estacionamiento.area_techada.toString().toLowerCase().includes(filterLowerCase) ||
        estacionamiento.fecha_carga_formato01.toString().includes(filterLowerCase)  ||
        estacionamiento.mensaje_error_carga.toString().includes(filterLowerCase) ||
        estacionamiento.validacion_carga.includes(filterLowerCase)
      ;
  })
  : [];

  const handleMoverEstacionamientosValidos = async () => {
    let bodyRequest={
        buildingId: codigo_edificio,
        usuario_proceso: usuario.id
    };
    dispatch(mover_estacionamientos_cargados(bodyRequest));    
    //dispatch(fetchDepartments()); // Actualiza la lista después de mover los departamentos válidos
    navigate('/estacionamientos');
  };
  

  if (status.get_estacionamientos_cargados_x_edificio  === 'loading') {
    return <div>Loading...</div>;
  }

  if (status.get_estacionamientos_cargados_x_edificio  === 'failed') {
    return <div>Error: {error.get_estacionamientos_cargados_x_edificio}</div>;
  }

  return (    
    <div className="container">
        <h5>Estacionamientos cargados</h5>
        <button className="btn" onClick={handleRefresh}>
        Refresh List
        </button>
        <button className="btn" onClick={handleMoverEstacionamientosValidos}>
        Registrar
        </button>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_estacionamientos_cargados"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_estacionamientos_cargados" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_estacionamientos_cargados">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>Tipo</th>
          <th>Estac.</th>
          <th>Nivel</th>
          <th>Tip_niv</th>
          <th>Area_ocupada</th>
          <th>Area_techada</th>                    
          <th>Validacion</th>
          <th>Mensaje</th>
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Fecha_carga</th>
        </tr>
      </thead>
      <tbody id="table-body-estacionamientos-cargados">                    
        {estacionamientosFiltrados.map((estacionamiento) => (
          <tr key={estacionamiento.id}>
            <td>{estacionamiento.codigo_edificio}</td>
            <td>{estacionamiento.tipo}</td>
            <td>{estacionamiento.numero}</td>
            <td>{estacionamiento.nivel}</td>
            <td>{estacionamiento.tipo_nivel}</td>
            <td>{estacionamiento.area_ocupada}</td>
            <td>{estacionamiento.area_techada}</td>
            <td>{estacionamiento.validacion_carga}</td>
            <td>{estacionamiento.mensaje_error_carga}</td>
            <td>{estacionamiento.usuario_carga}</td>
            <td>{estacionamiento.uid_carga}</td>
            <td>{estacionamiento.fecha_carga_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Estacionamientos_cargados;
