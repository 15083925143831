// src/pages/UploadAndListDepartments.js
import React from 'react';
import Carga_residentes from './carga-residentesPage';
import Residentes_cargados from './residentes-cargadosPage';
import { useNavigate } from 'react-router-dom';

const Carga_lista_residentes = () => {
  const navigate = useNavigate();

  
  return (
    <div className="container">
      <h4>Carga Residentes</h4>
      <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/residentes')}>
              <i className="material-icons left">arrow_back</i>
        </a>         
      <Carga_residentes />
      <Residentes_cargados />
    </div>
  );
};

export default Carga_lista_residentes;