import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetReservas } from './reservaSlice';

export const validate_turno = createAsyncThunk(
  'reserva/validate_turno',
  async ({ buildingId, area_comun, fecha_reserva, idturno, turno_hora_inicio, turno_hora_fin }, thunkAPI) => {
    console.log(`Validando turno para buildingId: ${buildingId}, área: ${area_comun}, fecha: ${fecha_reserva}, idturno: ${idturno}`);
    
    try {
      const response = await apiClient.post('/reservas/validate-turno', {
        buildingId,
        area_comun,
        fecha_reserva,
        idturno,
        turno_hora_inicio,
        turno_hora_fin
      });
      return response.data; // { available: boolean, message: string }
    } catch (error) {
      console.error("Error en validate_turno:", error.response ? error.response.data : error.message);
      return thunkAPI.rejectWithValue(error.response ? error.response.data : error.message);
    }
  }
);

export const update_reserva = createAsyncThunk('reserva/update_reserva', async ({ idReserva, reservaData }, thunkAPI) => {
  //console.log("ejecutando thunk update_reserva con idReserva: " + idReserva);

  try {

    const response = await apiClient.put(`/reservas/${idReserva}`, reservaData);

    const reserva = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetReservas(reserva.buildingId));// Resetear el estado para refetch de reservas

    return reserva;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_reserva = createAsyncThunk('reserva/save_reserva', async (reservaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/reservas`, reservaData);

    const reserva = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetReservas(reserva.buildingId));// Resetear el estado para refetch de reservas

    return reserva;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_reserva = createAsyncThunk('reserva/anular_reserva', async ({ idReserva, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_reserva con idReserva: " + idReserva);

  try {

    const response = await apiClient.patch(`/reservas/${idReserva}/anular`, { usuarioAnulacion });

    const reserva = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetReservas(reserva.buildingId));// Resetear el estado para refetch de reservas

    return reserva;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_reserva = createAsyncThunk('reserva/get_reserva', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_reserva con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/reservas/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const reserva = response.data;

    return reserva;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_reservas = createAsyncThunk('reserva/get_reservas', async (codigo_unidad_facturacion, thunkAPI) => {
  console.log("ejecutando thunk get_reservas con "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/reservas',{
        params: {codigo_unidad_facturacion}
    });
      console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const reservas = response.data;
          
      return reservas ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_reservas_x_edificio = createAsyncThunk('reserva/get_reservas_x_edificio', async ({ buildingId, tipo_area_comun }, thunkAPI) => {
  console.log("ejecutando thunk get_reservas con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/reservas',{
        params: { 
          buildingId, 
          tipo_area_comun 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const reservas = response.data;
          
      return reservas ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_reservas_x_depa_tipo_area_comun = createAsyncThunk('reserva/get_reservas_x_depa_tipo_area_comun', async ({ buildingId, codigo_unidad_facturacion, tipo_area_comun }, thunkAPI) => {
  console.log("ejecutando thunk get_reservas con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/reservas',{
        params: { 
          buildingId, 
          codigo_unidad_facturacion,
          tipo_area_comun 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const reservas = response.data;
          
      return reservas ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_reservas_x_area_comun = createAsyncThunk('reserva/get_reservas_x_area_comun', async ({ buildingId, area_comun }, thunkAPI) => {
  console.log("ejecutando thunk get_reservas con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/reservas',{
        params: { 
          buildingId, 
          area_comun 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const reservas = response.data;
          
      return reservas ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});


export const update_constancia_reserva  = createAsyncThunk('reserva/update_constancia_reserva', async ({ idReserva, reservaData }, thunkAPI) => {
  //console.log("ejecutando thunk update_reserva con idReserva: " + idReserva);

  try {

    const response = await apiClient.post(`/reservas/${idReserva}/update-constancia`, reservaData);

    const reserva = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetReservas(reserva.buildingId));// Resetear el estado para refetch de reservas

    return reserva;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
