import { createSlice } from '@reduxjs/toolkit';
import {get_depas_x_edificio} from './departamentoThunk';

const departamentoSlice = createSlice({
    name: 'departamento',
    initialState: {
      departamentos: [],
      status_get_depas_x_edificio : 'idle',
      error_get_depas_x_edificio: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_depas_x_edificio.pending, (state) => {
              state.status_get_depas_x_edificio  = 'loading';
          })
          .addCase(get_depas_x_edificio.fulfilled, (state, action) => {
              state.status_get_depas_x_edificio  = 'succeeded';
              console.log("Depas cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.departamentos = action.payload;

          })
          .addCase(get_depas_x_edificio.rejected, (state, action) => {
            //console.log("ejecutando Slice failed rejected ");
              state.status_get_depas_x_edificio  = 'failed2';              
              //console.log("error action.error.message "+action.error.message );
              //state.error = action.payload || 'Failed to fetch receipts';
              state.error_get_depas_x_edificio =  'Failed to fetch receipts';
          });
    },
  });
    
  export const selectDepartamentoById = (state, departamentoId) =>
    state.departamento.departamentos.find((departamento) => departamento.id === departamentoId);
  
  export default departamentoSlice.reducer;
