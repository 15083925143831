import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import { storage } from '../../firebase';
import M from 'materialize-css';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import './carga-personasPage.css';

const Carga_personas = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const edificio = useSelector(state => state.edificio.edificio);
  const usuario = useSelector((state) => state.auth.usuario);

  useEffect(() => {
    M.AutoInit();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) return;
    setUploading(true);
    setSuccess(null);
    setError(null);

    const uniqueId = uuidv4();
    const fileName = `CARGA-PERSONAS-${edificio.codigo}-${uniqueId}.csv`;

    if (window.location.hostname === 'localhost') {
      connectStorageEmulator(storage, 'localhost', 9199);
    }

    const storageRef = ref(storage, `CargaAportantes/${fileName}`);
    const metadata = {
      customMetadata: {
        buildingId: edificio.id,
        usuario_carga: usuario.id,
        uid_carga: uniqueId,
      }
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {},
      (error) => {
        setUploading(false);
        setError(error.message);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUploading(false);
          setSuccess('Archivo subido con éxito.');
        });
      }
    );
  };

  return (
    <div className="container carga-personas">
      <h3 className="center-align">Subir Archivo CSV</h3>
      
      <div className="row center-align">
        <div className="col s12 m8 offset-m2">
          <div className="file-field input-field">
            <div className="btn">
              <span>Seleccionar Archivo</span>
              <input type="file" accept=".csv" onChange={handleFileChange} />
            </div>
            <div className="file-path-wrapper">
              <input className="file-path validate" type="text" placeholder="Sube tu archivo CSV" />
            </div>
          </div>
        </div>
      </div>

      <div className="row center-align">
        <button className="btn waves-effect waves-light" onClick={handleUpload} disabled={uploading}>
          {uploading ? 'Subiendo...' : 'Subir'}
        </button>
      </div>

      {uploading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}

      {success && <p className="green-text center-align">{success}</p>}
      {error && <p className="red-text center-align">{error}</p>}
    </div>
  );
};

export default Carga_personas;
