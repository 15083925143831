import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

export const get_conceptos_facturacion = createAsyncThunk('conceptos_facturacion/get_conceptos_facturacion', async (buildingId, thunkAPI) => {
    //console.log("ejecutando thunk get_conceptos_facturacion con "+buildingId);
    
    try { 
        //console.log("ejecutando thunk buildingId: "+buildingId);
        const response = await apiClient.get(`/edificios/${buildingId}/conceptos-facturacion`);
       
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const conceptos_facturacion = response.data;
            
        return conceptos_facturacion ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
