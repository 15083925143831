import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

export const get_depas_x_edificio = createAsyncThunk('departamento/get_depas_x_edificio', async (codigo_edificio, thunkAPI) => {    
    
  try { 
      //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
      //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
      const response = await apiClient.get(`/edificios/${codigo_edificio}/departamentos`);
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const departamentos = response.data;
          
      return departamentos ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const get_depas_x_edificioOLD = createAsyncThunk('departamento/get_depas_x_edificio', async (codigo_edificio, thunkAPI) => {    
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get('/departamentos',{
            params: {codigo_edificio}
        });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const departamentos = response.data;
            
        return departamentos ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  
