import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_persona_asistencia,
    update_persona_asistencia,
    save_persona_asistencia
} from './persona_asistenciaThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { get_grupos_x_tipo } from '../medicamentos/grupoThunk';
import M from 'materialize-css/dist/js/materialize.min.js';
import './persona_asistenciaFormPage.css';

const Persona_asistenciaForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: idPersona_asistencia } = useParams();

    const [isEditing, setIsEditing] = useState(mode !== 'view');
    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const persona_asistencia = useSelector(state => state.persona_asistencia.persona_asistencia);
    const buildingId = edificioSeleccionado?.id;
    const tipos_asistencias = useSelector((state) => state.grupo.grupos) || [];
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    const propiedades = unidad_facturacionSeleccionado?.propiedades || [];
    const codigo_unidad_facturacion = unidad_facturacionSeleccionado?.codigo_unidad_facturacion || '';

    const [formData, setFormData] = useState({
        idunidad_inmobiliaria: propiedades.length > 0 ? propiedades[0].id : '',
        nombre: '',
        edad: '',
        idtipo_asistencia: '',
        tipo_asistencia: '',
        descripcion_asistencia: '',
        descripcion: '',
        contactos_emergencia: [{ nombre: '', telefono: '', relacion: '' }],
        equipos_especiales: '',
        ruta_evacuacion: '',
        punto_encuentro: '',
    });

    useEffect(() => {
        M.AutoInit();
        if (buildingId) {
            dispatch(get_grupos_x_tipo({ buildingId, tipo: 'ASISTENCIA_ESPECIAL' }));
        }
    }, [dispatch, buildingId]);

    useEffect(() => {
        if (mode !== 'create') {
            dispatch(get_persona_asistencia(idPersona_asistencia));
        }
    }, [dispatch, idPersona_asistencia, mode]);

    useEffect(() => {
        if (persona_asistencia && (mode === 'view' || mode === 'edit')) {
            setFormData({
                idunidad_inmobiliaria: persona_asistencia.idunidad_inmobiliaria || propiedades[0]?.id || '',
                nombre: persona_asistencia.nombre || '',
                edad: persona_asistencia.edad || '',
                idtipo_asistencia: persona_asistencia.idtipo_asistencia || '',
                tipo_asistencia: persona_asistencia.tipo_asistencia || '',
                descripcion_asistencia: persona_asistencia.descripcion_asistencia || '',
                descripcion: persona_asistencia.descripcion || '',
                contactos_emergencia: persona_asistencia.contactos_emergencia || [{ nombre: '', telefono: '', relacion: '' }],
                equipos_especiales: persona_asistencia.equipos_especiales || '',
                ruta_evacuacion: persona_asistencia.ruta_evacuacion || '',
                punto_encuentro: persona_asistencia.punto_encuentro || '',
            });
            setIsEditing(false);
        }
    }, [persona_asistencia, mode]);

    useEffect(() => {
        M.FormSelect.init(document.querySelectorAll('select'));
        M.updateTextFields();
    }, [formData]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name.startsWith('contactos_emergencia')) {
            const [_, index, field] = name.split('.');
            const updatedContactos = [...formData.contactos_emergencia];
            updatedContactos[index][field] = value;
            setFormData((prev) => ({ ...prev, contactos_emergencia: updatedContactos }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleAddContact = () => {
        setFormData((prev) => ({
            ...prev,
            contactos_emergencia: [...prev.contactos_emergencia, { nombre: '', telefono: '', relacion: '' }],
        }));
    };

    const handleRemoveContact = (index) => {
        setFormData((prev) => ({
            ...prev,
            contactos_emergencia: prev.contactos_emergencia.filter((_, i) => i !== index),
        }));
    };

    const handleIdtipo_asistencia = (e) => {
        const selectedIdtipo_asistencia = e.target.value;
        setFormData(prev => ({ ...prev, idtipo_asistencia: selectedIdtipo_asistencia }));

        const selectedTipo_asistencia = tipos_asistencias.find(tipo_asistencia => tipo_asistencia.id === selectedIdtipo_asistencia);

        setFormData(prev => ({
            ...prev,
            descripcion_asistencia: selectedTipo_asistencia?.descripcion || '',
        }));
    };

    const handleSave = () => {
        const selectedTipoAsistencia = tipos_asistencias.find((t) => t.id === formData.idtipo_asistencia);
        const dataToSave = {
            ...formData,
            tipo_asistencia: selectedTipoAsistencia?.nombre || '',
            buildingId,
            codigo_unidad_facturacion,
        };

        if (mode === 'create') {
            dispatch(save_persona_asistencia(dataToSave));
        } else {
            dispatch(update_persona_asistencia({ idPersona_asistencia, persona_asistenciaData: dataToSave }));
        }

        navigate(-1);
    };

    const handleRetornar = () => navigate(-1);
    const handleEdit = () => setIsEditing(true);
    const handleCancel = () => navigate(-1);

    return (
        <div className="container persona-asistencia-form">
            <div className="card-action">
                {isEditing ? (
                    <>
                        <a className="btn green" onClick={handleSave}>
                            <i className="material-icons left">save</i>Guardar
                        </a>
                        <a className="btn red" onClick={handleCancel}>
                            <i className="material-icons left">cancel</i>Cancelar
                        </a>
                    </>
                ) : (
                    <a className="btn green" onClick={handleEdit}>
                        <i className="material-icons left">edit</i>Editar
                    </a>
                )}
                <a className="btn green" onClick={handleRetornar}>
                    <i className="material-icons left">arrow_back</i>Volver
                </a>
            </div>

            <form>
                <h4>{mode === 'create' ? 'Registrar Persona' : 'Editar Persona'}</h4>
                <div className="row">
                    <div className="input-field col s6 m3">
                        <select
                            name="idunidad_inmobiliaria"
                            value={formData.idunidad_inmobiliaria}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                        >
                            {propiedades.map((unidad) => (
                                <option key={unidad.id} value={unidad.id}>
                                    {unidad.tipo_unidad_inmobiliaria} - {unidad.numero_unidad_inmobiliaria}
                                </option>
                            ))}
                        </select>
                        <label>Unidad Inmobiliaria</label>
                    </div>
                    <div className="input-field col s6 m4">
                        <input
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                        />
                        <label className={formData.nombre ? 'active' : ''}>Nombre</label>
                    </div>
                    <div className="input-field col s6 m2">
                        <input
                            type="number"
                            name="edad"
                            min="0"
                            value={formData.edad}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                        />
                        <label className={formData.edad ? 'active' : ''}>Edad</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6 m4">
                        <select
                            name="idtipo_asistencia"
                            value={formData.idtipo_asistencia}
                            onChange={handleIdtipo_asistencia}
                            disabled={!isEditing}
                        >
                            <option value="" disabled>Seleccionar tipo de asistencia</option>
                            {tipos_asistencias.map((t) => (
                                <option key={t.id} value={t.id}>{t.nombre}</option>
                            ))}
                        </select>
                        <label>Tipo de Asistencia</label>
                    </div>
                    <div className="input-field col s6 m4">
                        <textarea
                            name="descripcion_asistencia"
                            value={formData.descripcion_asistencia}
                            disabled
                            className="materialize-textarea"
                            style={{ height: '100px' }} // Ajuste de altura
                        />
                        <label className="active">Ejemplo</label>
                    </div>
                </div>
                <h5>Contactos de Emergencia</h5>
                {formData.contactos_emergencia.map((contacto, index) => (
                    <div key={index} className="contact-item row">
                        <div className="input-field col s4">
                            <input
                                type="text"
                                name={`contactos_emergencia.${index}.nombre`}
                                value={contacto.nombre}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            <label className={contacto.nombre ? 'active' : ''}>Nombre</label>
                        </div>
                        <div className="input-field col s4">
                            <input
                                type="text"
                                name={`contactos_emergencia.${index}.telefono`}
                                value={contacto.telefono}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            <label className={contacto.telefono ? 'active' : ''}>Teléfono</label>
                        </div>
                        <div className="input-field col s4">
                            <input
                                type="text"
                                name={`contactos_emergencia.${index}.relacion`}
                                value={contacto.relacion}
                                onChange={handleInputChange}
                                disabled={!isEditing}
                            />
                            <label className={contacto.relacion ? 'active' : ''}>Relación</label>
                        </div>
                        {isEditing && (
                            <button
                                type="button"
                                className="btn red"
                                onClick={() => handleRemoveContact(index)}
                            >
                                Eliminar
                            </button>
                        )}
                    </div>
                ))}
                {isEditing && (
                    <button
                        type="button"
                        className="btn blue center-align"
                        onClick={handleAddContact}
                    >
                        Agregar Contacto
                    </button>
                )}
                <div className="row">
                    <div className="input-field col s6">
                        <input
                            type="text"
                            name="equipos_especiales"
                            value={formData.equipos_especiales}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                        />
                        <label className={formData.equipos_especiales ? 'active' : ''}>Equipos Especiales</label>
                    </div>
                    <div className="input-field col s6">
                        <input
                            type="text"
                            name="ruta_evacuacion"
                            value={formData.ruta_evacuacion}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                        />
                        <label className={formData.ruta_evacuacion ? 'active' : ''}>Ruta Evacuación</label>
                    </div>
                </div>
                <div className="row">
                    <div className="input-field col s6">
                        <input
                            type="text"
                            name="punto_encuentro"
                            value={formData.punto_encuentro}
                            onChange={handleInputChange}
                            disabled={!isEditing}
                        />
                        <label className={formData.punto_encuentro ? 'active' : ''}>Punto de Encuentro</label>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Persona_asistenciaForm;
