import { createSlice } from '@reduxjs/toolkit';
import { get_activo, update_activo, save_activo, anular_activo, get_activos_x_edificio, get_activos_x_unidad_facturacion } from './activoThunk';

const activoSlice = createSlice({
  name: 'activo',
  initialState: {
    activo: null,
    activos: [],
    activoSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_activos_x_edificio: 'idle',
    error_get_activos_x_edificio: null,
    status_get_activos_x_unidad_facturacion: 'idle',
    error_get_activos_x_unidad_facturacion: null,
    status_update_activo: 'idle',
    error_update_activo: null,    
    status_save_activo: 'idle',
    error_save_activo: null,
    status_anular_activo: 'idle',
    error_anular_activo: null,
  },
  reducers: {
    setActivoSeleccionada: (state, action) => {
      state.activoSeleccionada = action.payload;
      
    },
    clearActivoSeleccionada(state) {
      state.activoSeleccionada = null;
      state.activo = null;
    },
    resetStateGetActivos_x_edificio: () => {
        return {
            status_get_activos_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_activo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_activo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.activo = action.payload;

      })
      .addCase(get_activo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_activo.pending, (state) => {
        state.status_update_activo = 'loading';
      })
      .addCase(update_activo.fulfilled, (state, action) => {
        state.status_update_activo = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.activo = action.payload.activo;

      })
      .addCase(update_activo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_activo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_activo = action.payload || 'Failed to fetch building';
      })
      .addCase(save_activo.pending, (state) => {
        state.status_save_activo = 'loading';
      })
      .addCase(save_activo.fulfilled, (state, action) => {
        state.status_save_activo = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.activo = action.payload.activo;

      })
      .addCase(save_activo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_activo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_activo = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_activo.pending, (state) => {
        state.status_save_activo = 'loading';
      })
      .addCase(anular_activo.fulfilled, (state, action) => {
        state.status_anular_activo = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.activo = action.payload.activo;

      })
      .addCase(anular_activo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_activo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_activo = action.payload || 'Failed to fetch building';
      })
      .addCase(get_activos_x_edificio.pending, (state) => {
        state.status_get_activos_x_edificio = 'loading';
        
      })
      .addCase(get_activos_x_edificio.fulfilled, (state, action) => {
        state.status_get_activos_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_activos_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.activos = action.payload;

      })
      .addCase(get_activos_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_activos_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_activos_x_edificio = action.payload || 'Failed to fetch building';
        state.activos =[];
      })      
      .addCase(get_activos_x_unidad_facturacion.pending, (state) => {
        state.status_get_activos_x_unidad_facturacion = 'loading';
        
      })
      .addCase(get_activos_x_unidad_facturacion.fulfilled, (state, action) => {
        state.status_get_activos_x_unidad_facturacion = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_activos_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.activos = action.payload;

      })
      .addCase(get_activos_x_unidad_facturacion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_activos_x_unidad_facturacion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_activos_x_unidad_facturacion = action.payload || 'Failed to fetch building';
        state.activos =[];
      })
  },
});

export const { setActivoSeleccionada, clearActivoSeleccionada } = activoSlice.actions;
export const selectActivos_emergenciaById = (state, idActivo) =>
    state.activo.activos.find((activo) => activo.id === idActivo);
export const { resetStateGetActivos_x_edificio } = activoSlice.actions;
export default activoSlice.reducer;
