import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

  export const get_personas_cargadas_x_edificio = createAsyncThunk('personas-carga/get_personas_cargadas_x_edificio', async (codigo_edificio, thunkAPI) => {
    //console.log("ejecutando thunk get_personas_cargadas_x_edificio con "+codigo_edificio);
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/personas-cargadas`);
        //console.log("**personas_carga JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const personas_carga = response.data;
            
        return personas_carga;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const mover_personas_cargadas = createAsyncThunk('personas-carga/mover_personas_cargadas', async (jsonBodyRequest, thunkAPI) => {
    //console.log("ejecutando thunk mover_personas_cargadas con "+JSON.stringify(jsonBodyRequest));
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.post('/personas-carga/registro',jsonBodyRequest);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const resp_registro = response.data;
            
        return resp_registro;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
