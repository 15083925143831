import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

  export const get_propiedades_cargadas_x_edificio = createAsyncThunk('propiedades-carga/get_propiedades_cargadas_x_edificio', async (codigo_edificio, thunkAPI) => {
    console.log("ejecutando thunk registrar_propiedades_cargadas con "+codigo_edificio);
    
    try { 
        console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/propiedades-cargadas`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const propiedades_carga = response.data;
            
        return propiedades_carga ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const mover_propiedades_cargadas = createAsyncThunk('propiedades-carga/mover_propiedades_cargadas', async (jsonBodyRequest, thunkAPI) => {
    //console.log("ejecutando thunk mover_propiedades_cargadas con "+JSON.stringify(jsonBodyRequest));
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.post('/propiedades-carga/registro',jsonBodyRequest);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const resp_registro = response.data;
            
        return resp_registro;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  
