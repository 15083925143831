import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_zona_segura,
    update_zona_segura,
    save_zona_segura,
    anular_zona_segura
} from './zona_seguraThunk';
import {
    get_unidades_inmobiliarias_x_tipo
} from '../edificio/unidad_inmobiliariaThunk';
import { useNavigate, useParams } from 'react-router-dom';
import { TIPOS_UNIDADES_INMOBILIARIAS } from '../../constants/constants';
import { ref, uploadBytes, getDownloadURL, connectStorageEmulator, deleteObject } from 'firebase/storage';
import { storage } from '../../firebase'; // Ajusta según tu configuración
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './zona_seguraFormPage.css';

const Zona_seguraForm = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: idZona_segura } = useParams();

    const [isEditing, setIsEditing] = useState(mode !== 'view');
    const [uploading, setUploading] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    const codigo_unidad_facturacion = unidad_facturacionSeleccionado.codigo_unidad_facturacion;
    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
    const zona_segura = useSelector(state => state.zona_segura.zona_segura);
    const buildingId = edificioSeleccionado.id;
    const codigo_usuario = useSelector((state) => state.auth.usuario.id);
    const unidades_inmobiliarias = useSelector((state) => state.unidad_inmobiliaria.unidades_inmobiliarias) || [];

    const [formData, setFormData] = useState({
        tipo_unidad_inmobiliaria: '',
        numero_unidad_inmobiliaria: '',
        imageUrl: [],
        titulo: '',
        descripcion: '',
        codigo_unidad_facturacion: ''
    });

    const [fileUploads, setFileUploads] = useState([]);

    useEffect(() => {
        if (mode !== 'create') {
            dispatch(get_zona_segura(idZona_segura));
        }
    }, [dispatch, idZona_segura, mode]);

    useEffect(() => {
        if (zona_segura && (mode === 'view' || mode === 'edit')) {
            setFormData({
                tipo_unidad_inmobiliaria: zona_segura.tipo_unidad_inmobiliaria,
                numero_unidad_inmobiliaria: zona_segura.numero_unidad_inmobiliaria,
                imageUrl: zona_segura.imageUrl || [],
                titulo: zona_segura.titulo,
                descripcion: zona_segura.descripcion,
                codigo_unidad_facturacion: zona_segura.codigo_unidad_facturacion,
            });
            setIsEditing(false);
        }
    }, [zona_segura, mode]);

    useEffect(() => {
        M.FormSelect.init(document.querySelectorAll('select'));
    }, [TIPOS_UNIDADES_INMOBILIARIAS, unidades_inmobiliarias]);

    useEffect(() => {
        if (formData.tipo_unidad_inmobiliaria) {
            dispatch(get_unidades_inmobiliarias_x_tipo({ buildingId, tipo_unidad_inmobiliaria: formData.tipo_unidad_inmobiliaria }));
        }
    }, [dispatch, buildingId, formData.tipo_unidad_inmobiliaria]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setFileUploads(files);
    };

    const uploadFiles = async () => {
        const uploadPromises = fileUploads.map(async (file) => {
            if (window.location.hostname === 'localhost') {
                connectStorageEmulator(storage, 'localhost', 9199);
            }

            const storageRefPath = `cargaZonas_seguras/${idZona_segura || 'new'}/${file.name}_${Date.now()}`;
            const fileRef = ref(storage, storageRefPath);

            const snapshot = await uploadBytes(fileRef, file);
            return await getDownloadURL(fileRef);
        });

        return Promise.all(uploadPromises);
    };

    const handleSave = async () => {
        if (isEditing) {
            setUploading(true);
            setUploadError('');

            try {
                let imageUrls = formData.imageUrl || [];

                if (fileUploads.length > 0) {
                    const uploadedUrls = await uploadFiles();
                    imageUrls = [...imageUrls, ...uploadedUrls];
                }

                const dataToSave = {
                    ...formData,
                    imageUrl: imageUrls,
                    buildingId,
                    usuarioRegistrador: codigo_usuario,
                    codigo_unidad_facturacion: codigo_unidad_facturacion,
                };

                if (mode === 'create') {
                    dispatch(save_zona_segura(dataToSave));
                } else {
                    dispatch(update_zona_segura({ idZona_segura, zona_seguraData: dataToSave }));
                }

                M.toast({ html: 'Zona segura guardada exitosamente.', classes: 'green' });
                navigate('/zonas-seguras');
            } catch (error) {
                console.error("Error al subir las imágenes:", error);
                setUploadError(error.message || 'Ocurrió un error al subir las imágenes.');
                M.toast({ html: uploadError || 'Error al subir las imágenes.', classes: 'red' });
            } finally {
                setUploading(false);
            }
        }
    };

    const handleDeleteImage = async (url) => {
        try {
            const fileRef = ref(storage, url);
            await deleteObject(fileRef);
            setFormData(prev => ({
                ...prev,
                imageUrl: prev.imageUrl.filter(image => image !== url),
            }));
            M.toast({ html: 'Imagen eliminada exitosamente.', classes: 'green' });
        } catch (error) {
            console.error("Error al eliminar la imagen:", error);
            M.toast({ html: 'Error al eliminar la imagen.', classes: 'red' });
        }
    };

    const handleEdit = () => setIsEditing(true);

    const handleCancel = () => {
        if (mode === 'create') {
            navigate('/zonas-seguras');
        } else {
            setIsEditing(false);
        }
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <div className="card-action">
                        {isEditing ? (
                            <>
                                <button className="btn green" onClick={handleSave} disabled={uploading}>Guardar</button>
                                <button className="btn red" onClick={handleCancel}>Cancelar</button>
                            </>
                        ) : (
                            <>
                                <button className="btn green" onClick={handleEdit}>Editar</button>
                            </>
                        )}
                        <button className="btn blue" onClick={() => navigate('/zonas-seguras')}>Volver</button>
                    </div>
                    <form>
                        <div className="row">
                            <div className="input-field col s6 m3">
                                <select
                                    name="tipo_unidad_inmobiliaria"
                                    value={formData.tipo_unidad_inmobiliaria}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                >
                                    <option value="" disabled>Seleccione un tipo</option>
                                    {TIPOS_UNIDADES_INMOBILIARIAS.map(tipo => (
                                        <option key={tipo.value} value={tipo.value}>{tipo.label}</option>
                                    ))}
                                </select>
                                <label>Tipo de Unidad Inmobiliaria</label>
                            </div>
                            <div className="input-field col s6 m4">
                                <select
                                    name="numero_unidad_inmobiliaria"
                                    value={formData.numero_unidad_inmobiliaria}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                >
                                    <option value="" disabled>Seleccione una unidad</option>
                                    {unidades_inmobiliarias.map(unidad => (
                                        <option key={unidad.id} value={unidad.numero_unidad_inmobiliaria}>{unidad.numero_unidad_inmobiliaria}</option>
                                    ))}
                                </select>
                                <label>Unidad Inmobiliaria</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s6">
                                <input
                                    type="text"
                                    name="titulo"
                                    value={formData.titulo}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                />
                                <label className="active">Título</label>
                            </div>
                            <div className="input-field col s6">
                                <input
                                    type="text"
                                    name="descripcion"
                                    value={formData.descripcion}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                />
                                <label className="active">Descripción</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="file-field input-field col s12">
                                <div className="btn">
                                    <span>Subir Imágenes</span>
                                    <input
                                        type="file"
                                        multiple
                                        onChange={handleFileChange}
                                    />
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="Selecciona imágenes" />
                                </div>
                            </div>
                        </div>
                        {formData.imageUrl.length > 0 && (
                            <div className="row">
                                <h5>Imágenes Cargadas</h5>
                                <div className="image-preview">
                                    {formData.imageUrl.map((url, index) => (
                                        <div key={index} className="image-container">
                                            <img src={url} alt={`Imagen ${index + 1}`} className="responsive-img" />
                                            <button
                                                type="button"
                                                className="btn red"
                                                onClick={() => handleDeleteImage(url)}
                                            >
                                                Eliminar
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Zona_seguraForm;
