import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetEncuestas_x_edificio } from './encuestaSlice';


export const update_encuesta = createAsyncThunk('encuesta/update_encuesta', async ({ idEncuesta, encuestaData }, thunkAPI) => {
  console.log("ejecutando thunk update_encuesta con idEncuesta: " + idEncuesta);

  try {

    const response = await apiClient.put(`/encuestas/${idEncuesta}`, encuestaData);

    const encuesta = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetEncuestas_x_edificio(encuesta.buildingId));// Resetear el estado para refetch de encuestas

    return encuesta;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_encuesta = createAsyncThunk('encuesta/save_encuesta', async (encuestaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/encuestas`, encuestaData);

    const encuesta = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetEncuestas_x_edificio(encuesta.buildingId));// Resetear el estado para refetch de encuestas

    return encuesta;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_encuesta = createAsyncThunk('encuesta/anular_encuesta', async ({ idEncuesta, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_encuesta con idEncuesta: " + idEncuesta);

  try {

    const response = await apiClient.patch(`/encuestas/${idEncuesta}/anular`, { usuarioAnulacion });

    const encuesta = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetEncuestas_x_edificio(encuesta.buildingId));// Resetear el estado para refetch de encuestas

    return encuesta;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_encuesta = createAsyncThunk('encuesta/get_encuesta', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_encuesta con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/encuestas/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const encuesta = response.data;

    return encuesta;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_encuestas_x_edificio = createAsyncThunk('encuesta/get_encuestas_x_edificio', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_encuestas con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/encuestas',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const encuestas = response.data;
          
      return encuestas ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
