import { createSlice } from '@reduxjs/toolkit';
import {get_unidades_inmobiliarias, get_unidades_inmobiliarias_x_tipo, get_unidades_inmobiliarias_habitables_x_depa} from './unidad_inmobiliariaThunk';

const unidad_inmobiliariaSlice = createSlice({
    name: 'unidad_inmobiliaria',
    initialState: {
      unidades_inmobiliarias: [],
      fetchStatus : 'idle',
      error: null,
      status_get_unidades_inmobiliarias_x_tipo: 'idle',
      error_get_unidades_inmobiliarias_x_tipo: null,
      status_get_unidades_inmobiliarias_habitables_x_depa: 'idle',
      error_get_unidades_inmobiliarias_habitables_x_depa: null,      
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_unidades_inmobiliarias.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_unidades_inmobiliarias.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              console.log("Unidades_inmobiliarias cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.unidades_inmobiliarias = action.payload;

          })
          .addCase(get_unidades_inmobiliarias.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';
              console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch receipts';
          })
          .addCase(get_unidades_inmobiliarias_x_tipo.pending, (state) => {
            //console.log("Estamos en get_unidades_inmobiliarias_x_tipo PENDING");
            //console.log("->PEN antes state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
            state.status_get_unidades_inmobiliarias_x_tipo = 'loading';
            state.error_get_unidades_inmobiliarias_x_tipo = null;
            //console.log("->PEN despues state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
        })
        .addCase(get_unidades_inmobiliarias_x_tipo.fulfilled, (state, action) => {
            //console.log("Estamos en get_unidades_inmobiliarias_x_tipo FULLFILLED");
            //console.log("->FULL antes state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
            state.status_get_unidades_inmobiliarias_x_tipo = 'succeeded';
            state.unidades_inmobiliarias = action.payload; // Asignar directamente a 'requerimientos'
            //console.log("FULL despues state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
        })
        .addCase(get_unidades_inmobiliarias_x_tipo.rejected, (state, action) => {
            //console.log("Estamos en get_unidades_inmobiliarias_x_tipo REJECTED");
            //console.log("->FAIL antes state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
            state.status_get_unidades_inmobiliarias_x_tipo = 'failed';
            state.error_get_unidades_inmobiliarias_x_tipo = action.payload || 'Failed to fetch unidades inmobiliarias por tipo';
            state.unidades_inmobiliarias = [];
            //console.log("FAIL despues state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
        })

        .addCase(get_unidades_inmobiliarias_habitables_x_depa.pending, (state) => {
          //console.log("Estamos en get_unidades_inmobiliarias_x_tipo PENDING");
          //console.log("->PEN antes state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
          state.status_get_unidades_inmobiliarias_habitables_x_depa = 'loading';
          state.error_get_unidades_inmobiliarias_habitables_x_depa = null;
          //console.log("->PEN despues state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
      })
      .addCase(get_unidades_inmobiliarias_habitables_x_depa.fulfilled, (state, action) => {
          //console.log("Estamos en get_unidades_inmobiliarias_x_tipo FULLFILLED");
          //console.log("->FULL antes state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
          state.status_get_unidades_inmobiliarias_habitables_x_depa = 'succeeded';
          state.unidades_inmobiliarias = action.payload; // Asignar directamente a 'requerimientos'
          //console.log("FULL despues state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
      })
      .addCase(get_unidades_inmobiliarias_habitables_x_depa.rejected, (state, action) => {
          //console.log("Estamos en get_unidades_inmobiliarias_x_tipo REJECTED");
          //console.log("->FAIL antes state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
          state.status_get_unidades_inmobiliarias_habitables_x_depa = 'failed';
          state.error_get_unidades_inmobiliarias_habitables_x_depa = action.payload || 'Failed to fetch unidades inmobiliarias por depa';
          state.unidades_inmobiliarias = [];
          //console.log("FAIL despues state.status_get_unidades_inmobiliarias_x_tipo: "+state.status_get_unidades_inmobiliarias_x_tipo);
      });
    },
  });
    
  export const selectUnidad_inmobiliariaById = (state, unidad_inmobiliariaId) =>
    state.unidad_inmobiliaria.unidades_inmobiliarias.find((unidad_inmobiliaria) => unidad_inmobiliaria.id === unidad_inmobiliariaId);
  
  export default unidad_inmobiliariaSlice.reducer;
