import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetLeads_x_publicacion } from './leadSlice.js';


export const agregar_mensaje = createAsyncThunk('lead/agregar_mensaje', async ({ idLead, nuevoMensaje }, thunkAPI) => {
    console.log("ejecutando thunk agregar_mensaje con idLead: " + idLead);
    console.log("ejecutando thunk agregar_mensaje con nuevoMensaje: " + JSON.stringify(nuevoMensaje));

    try {

        const response = await apiClient.post(`/leads/${idLead}/mensaje`, nuevoMensaje);

        const lead = response.data;

        // Despachar acción para resetear el estado de fetching
        thunkAPI.dispatch(resetStateGetLeads_x_publicacion());// Resetear el estado para refetch de leads

        return lead;

    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const update_lead = createAsyncThunk('lead/update_lead', async ({ idLead, leadData }, thunkAPI) => {
    console.log("ejecutando thunk update_lead con idLead: " + idLead);

    try {

        const response = await apiClient.put(`/leads/${idLead}`, leadData);

        const lead = response.data;

        // Despachar acción para resetear el estado de fetching
        thunkAPI.dispatch(resetStateGetLeads_x_publicacion());// Resetear el estado para refetch de leads

        return lead;

    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const save_lead = createAsyncThunk('lead/save_lead', async (leadData, thunkAPI) => {

    try {

        const response = await apiClient.post(`/leads`, leadData);

        const lead = response.data;

        // Despachar acción para resetear el estado de fetching
        thunkAPI.dispatch(resetStateGetLeads_x_publicacion());// Resetear el estado para refetch de leads

        return lead;

    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const anular_lead = createAsyncThunk('lead/anular_lead', async ({ idLead, usuarioAnulacion }, thunkAPI) => {
    //console.log("ejecutando thunk anular_lead con idLead: " + idLead);

    try {

        const response = await apiClient.patch(`/leads/${idLead}/anular`, { usuarioAnulacion });

        const lead = response.data;

        // Despachar acción para resetear el estado de fetching
        //thunkAPI.dispatch(resetStateGetLeads_x_publicacion(lead.buildingId));// Resetear el estado para refetch de leads

        return lead;

    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const get_lead = createAsyncThunk('lead/get_lead', async (id, thunkAPI) => {
    //console.log("ejecutando thunk get_lead con id: " + id);

    try {

        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        //parametro pasado por path
        const response = await apiClient.get(`/leads/${id}`);
        //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
        const lead = response.data;

        return lead;
    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});


export const get_lead_x_publicacion_comprador = createAsyncThunk('lead/get_lead_x_publicacion_comprador', async ({ idPublicacion, idComprador }, thunkAPI) => {
    console.log("ejecutando thunk get_leads con idPublicacion " + idPublicacion + " idComprador " + idComprador);

    try {
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get(`/leads/${idPublicacion}/${idComprador}`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const lead = response.data;

        return lead;
    } catch (error) {
        console.log("error.response.data " + error.response.data);
        if (error.response && error.response.status === 404) {
            return null; // Lead no encontrado
        }
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const get_leads_x_vendedor = createAsyncThunk('lead/get_leads_x_vendedor', async ({ buildingId, idVendedor }, thunkAPI) => {
    console.log("ejecutando thunk get_leads con " + buildingId + " idVendedor " + idVendedor);

    try {
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get('/leads', {
            params: {
                buildingId,
                idVendedor
            }
        });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const leads = response.data;

        return leads;
    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_leads_x_depa = createAsyncThunk('lead/get_leads_x_depa', async ({ buildingId, codigo_usuario }, thunkAPI) => {
    console.log("ejecutando thunk get_leads con " + buildingId + " codigo_usuario " + codigo_usuario);

    try {
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get('/leads', {
            params: {
                buildingId,
                codigo_usuario
            }
        });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const leads = response.data;

        return leads;
    } catch (error) {
        console.log("error.response.data " + error.response.data);
        return thunkAPI.rejectWithValue(error.response.data);
    }
});