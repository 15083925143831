import { createSlice } from '@reduxjs/toolkit';
import {get_propiedades_cargadas_x_edificio, mover_propiedades_cargadas} from './propiedades-cargadasThunk';

const propiedades_cargadasSlice = createSlice({
    name: 'propiedades_cargadas',
    initialState: {
      propiedades_cargadas: [],
      //fetchStatus : 'idle',
      //error: null,
      status: {
        get_propiedades_cargadas_x_edificio: 'idle',
        moveValidPropiedades: 'idle',
      },
      error: {
        get_propiedades_cargadas_x_edificio: null,
        moveValidPropiedades: null,
      },
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_propiedades_cargadas_x_edificio.pending, (state) => {
              state.status.get_propiedades_cargadas_x_edificio  = 'loading';
          })
          .addCase(get_propiedades_cargadas_x_edificio.fulfilled, (state, action) => {
              state.status.get_propiedades_cargadas_x_edificio  = 'succeeded';
              console.log("Propiedades cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.propiedades_cargadas = action.payload;

          })
          .addCase(get_propiedades_cargadas_x_edificio.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.status.get_propiedades_cargadas_x_edificio  = 'failed';              
              console.log("error action.error.message "+action.error.message );
              state.error.get_propiedades_cargadas_x_edificio = action.payload || 'Failed to fetch receipts';
          })
//
        .addCase(mover_propiedades_cargadas.pending, (state) => {
            state.status.mover_propiedades_cargadas  = 'loading';
        })
        .addCase(mover_propiedades_cargadas.fulfilled, (state, action) => {
            state.status.mover_propiedades_cargadas  = 'succeeded';
            //console.log("Propiedades cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
            //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
            state.propiedades_cargadas = action.payload;

        })
        .addCase(mover_propiedades_cargadas.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
            state.status.mover_propiedades_cargadas  = 'failed';
            //console.log("error action.error.message "+action.error.message );
            state.error.mover_propiedades_cargadas = action.payload || 'Failed to fetch receipts';
        });

    },
  });
    
  export const selectPropiedad_cargadaById = (state, propiedad_cargadoId) =>
    state.propiedades_cargadas.propiedades_cargadas.find((propiedad_cargado) => propiedad_cargado.id === propiedad_cargadoId);
  
  export default propiedades_cargadasSlice.reducer;
