import { createSlice } from '@reduxjs/toolkit';
import {get_depositos_x_edificio} from './depositoThunk';

const depositoSlice = createSlice({
    name: 'deposito',
    initialState: {
      depositos: [],
      fetchStatus : 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_depositos_x_edificio.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_depositos_x_edificio.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              console.log("Depositos cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.depositos = action.payload;

          })
          .addCase(get_depositos_x_edificio.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';
              console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch receipts';
          });
    },
  });
    
  export const selectDepositoById = (state, depositoId) =>
    state.deposito.depositos.find((deposito) => deposito.id === depositoId);
  
  export default depositoSlice.reducer;
