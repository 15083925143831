import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_edificios_x_admin} from './edificiosThunk';
import M from 'materialize-css';
import { Link } from 'react-router-dom';

import {formatDate, formatCurrency, formatPeriod} from '../../utils/formateo';
import {getEstadoPagoButtonClass , getEstadoPagoIcon, downloadPDF} from '../../utils/util';

const MisEdificios = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const edificios = useSelector((state) => state.edificios.edificios);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus  = useSelector((state) => state.edificios.fetchStatus );  
  const usuario_admin = usuario?.codigo_usuario;
  const error = useSelector((state) => state.edificios.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis edificios isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    //console.log("fetchStatus: "+ fetchStatus);
    //console.log("usuario_admin: "+ usuario_admin);
    if (fetchStatus  === 'idle' && usuario_admin) {
        console.log("get_edificios_x_admin usuario_admin: "+ usuario_admin);
      dispatch(get_edificios_x_admin(usuario_admin));
      
    }


  }, [usuario, navigate, fetchStatus , usuario_admin, dispatch]);

  
  if (!usuario) {
    return null; // Return null while redirecting
  }


  const formatearedificios = (edificios) => {
    return edificios.map(edificio => ({
      ...edificio,
      fecha_emision_formato01: formatDate(edificio.fecha_emision),
      fecha_corte_formato01: formatDate(edificio.fecha_corte),
      periodo: formatPeriod(edificio.fecha_emision),
      importe_edificio_formato01: formatCurrency(edificio.importe_edificio),
    }));
  };

  const edificiosFormateados = formatearedificios(edificios);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const edificiosFiltrados = Array.isArray(edificiosFormateados) ? edificiosFormateados.filter((edificio) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return edificio.periodo.toLowerCase().includes(filterLowerCase) ||
      edificio.usuario_admin.toLowerCase().includes(filterLowerCase) ||
      edificio.codigo_propietario.toLowerCase().includes(filterLowerCase) ||
      edificio.importe_edificio_formato01.toLowerCase().includes(filterLowerCase)  ||
      edificio.fecha_emision_formato01.toString().includes(filterLowerCase)  ||
      edificio.fecha_corte_formato01.toString().includes(filterLowerCase)    ||
      edificio.estado.includes(filterLowerCase) 
      ;
  })
  : [];

  if (fetchStatus  === 'loading') {
    return <div>Loading...</div>;
  }

  if (fetchStatus  === 'failed') {
    return <div>Error: {error}</div>;
  }

  return (    
    <div className="container">
        <h5>Edificios</h5>
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_mis_edificios_propietario"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_mis_edificios_propietario" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_mis_edificios_propietario">
      <thead>
        <tr>
          <th>Periodo</th>
          <th>Depa</th>
          <th>Importe</th>
          <th>Emision</th>
          <th>Corte</th>
          <th>Estado</th>
          <th>Impr.</th>
        </tr>
      </thead>
      <tbody id="table-body-mis-edificios_propietario">                    
        {edificiosFiltrados.map((receipt) => (
          <tr key={receipt.id}>
            <td>
                <Link to={`/edificio/${receipt.id}`}>
                  {receipt.periodo}
                </Link>
            </td>
            <td>{receipt.usuario_admin}</td>
            <td>{receipt.importe_edificio_formato01}</td>
            <td>{receipt.fecha_emision_formato01}</td>
            <td>{receipt.fecha_corte_formato01}</td>            
            <td>
                <button className={getEstadoPagoButtonClass(receipt.estado_pago)}>
                  {getEstadoPagoIcon(receipt.estado_pago)}
                  {receipt.estado_pago}
                </button>         
            </td>
            
            <td>
            <button
                  className="btn tooltipped"
                  data-position="top"
                  data-tooltip="Descargar PDF"
                  onClick={() => downloadPDF('edificios/FICUS_edificio_DE_MANTENIMIENTO_03_2024_FIC003.pdf')}
                >
                  <i className="material-icons">print</i>
                </button>
            </td>
          </tr>
          ))}
      
      </tbody>
    </table>

    </div>
  );
};

export default MisEdificios;
