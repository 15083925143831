// src/components/MainLayout.js
import React from 'react';
import Menu from './menuPage';
import Footer from './footerPage';

const MainLayout = ({ children, showFooter }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Menu />
      <main style={{ flex: 1 }}>{children}</main>
      {showFooter && <Footer />}
    </div>
  );
};

export default MainLayout;
