import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

  export const get_config_unidades_facturacion = createAsyncThunk('config_unidad_facturacion/get_config_unidades_facturacion', async (codigo_edificio, thunkAPI) => {    
    
    try { 
        //ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/config-unidades-facturacion`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const config_unidades_facturacion = response.data;
            
        return config_unidades_facturacion ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  
