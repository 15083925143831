import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import M from 'materialize-css';
import { useNavigate, useParams } from 'react-router-dom';
import {
    save_reunion,
    get_reunion,
    update_reunion,
    anular_reunion,
} from './reunionThunk';
import { get_infraccion_x_codigo } from '../infracciones/infraccionThunk';
import './ReunionPage.css';
import { normalizeDate } from '../../utils/formateo';


const ReunionPage = ({ mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: idReunion } = useParams();

    const [formData, setFormData] = useState({
        tipo_reunion: 'Extraordinaria', // Inicializado con la primera opción
        convocante: 'Junta_Directiva', // Inicializado con la primera opción
        asunto: '',
        fecha_reunion: '',
        hora_inicio: '',
        modalidad: 'Virtual', // Inicializado con la primera opción
        lugar: '',
        enlace: '', // Nuevo campo para el enlace de reunión
        resumen: '',
        penalidad: '',
        importe_multa: 0,
        fecha_convocatoria: '',
        infraccion: null, // Objeto para guardar datos de la infracción (id, codigo, descripcion, multa)
    });

    const [isEditing, setIsEditing] = useState(false);
    const buildingId = useSelector((state) => state.edificio.edificio?.id);
    const usuario = useSelector((state) => state.auth.usuario);
    const status = useSelector((state) => state.reunion.status);
    const error = useSelector((state) => state.reunion.error);

    // Inicialización de Materialize select
    useEffect(() => {
        const elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [formData]);

    // Cargar datos de la reunión si se está editando o consultando
    useEffect(() => {
        if (idReunion) {
            dispatch(get_reunion(idReunion)).then((response) => {
                if (response.payload) {
                    setFormData({
                        ...response.payload,
                        fecha_reunion: response.payload.fecha_hora_reunion.split('T')[0],
                        hora_inicio: response.payload.fecha_hora_reunion.split('T')[1].substring(0, 5),
                        fecha_convocatoria: response.payload.fecha_convocatoria.split('T')[0],
                    });
                    setIsEditing(true);
                }
            });
        }
    }, [dispatch, idReunion]);

    // Manejo de cambios en el formulario
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Obtener infracción por código
    const handleGetInfraccion = async () => {
        const codigo = formData.penalidad;

        if (!buildingId || !codigo) {
            alert('Building ID y Código de Infracción son requeridos.');
            return;
        }

        try {
            const infraccion = await dispatch(get_infraccion_x_codigo({ buildingId, codigo })).unwrap();
            if (infraccion) {
                setFormData({
                    ...formData,
                    infraccion: {
                        id: infraccion.id,
                        codigo: infraccion.codigo,
                        descripcion: infraccion.descripcion,
                        multa: infraccion.multa,
                    },
                    importe_multa: infraccion.multa,
                });
                M.updateTextFields(); // Actualiza los labels de Materialize
                alert('Infracción cargada correctamente.');
            } else {
                alert('No se encontró infracción con el código proporcionado.');
            }
        } catch (error) {
            console.error('Error obteniendo infracción:', error);
            alert('Error obteniendo la infracción.');
        }
    };

    // Guardar reunión
    const handleSave = async () => {
        const fecha_hora_reunion = `${formData.fecha_reunion}T${formData.hora_inicio}:00`;

        const reunionData = {
            ...formData,
            fecha_hora_reunion,
            buildingId,
            usuarioRegistrador: usuario.id,
            infraccion: formData.infraccion, // Solo graba los datos necesarios de la infracción
        };

        try {
            if (isEditing) {
                await dispatch(update_reunion({ idReunion, reunionData })).unwrap();
                M.toast({ html: 'Reunión actualizada con éxito.', classes: 'green' });
            } else {
                await dispatch(save_reunion(reunionData)).unwrap();
                M.toast({ html: 'Reunión creada con éxito', classes: 'green' });
            }
            navigate(-1); // Regresa al nivel anterior en el historial
        } catch (error) {
            console.error('Error al guardar la reunión:', JSON.stringify(error));

            M.toast({ html: error, classes: 'red' });
            //alert(error); // Muestra el mensaje adecuado
        }
    };
    // Generar un token aleatorio de 4 dígitos
    const handleGenerateToken = () => {
        const randomToken = Math.floor(1000 + Math.random() * 9000).toString(); // Genera un número de 4 dígitos
        setFormData({ ...formData, token_asistencia: randomToken });
        M.toast({ html: 'Token generado: ' + randomToken, classes: 'green' });
    };
    // Anular reunión
    const handleAnular = () => {
        if (window.confirm('¿Estás seguro de que quieres anular esta reunión?')) {
            dispatch(anular_reunion({ idReunion, usuarioAnulacion: 'admin' }));
            M.toast({ html: "Encuesta anulada correctamente.", classes: "green" });
            navigate(-1);
        }
    };

    return (
        <div className="container reunion-page">
            <h4>{isEditing ? 'Editar Reunión' : 'Crear Reunión'}</h4>

            <form>
                <div className="row">
                    {/* Tipo de Reunión */}
                    <div className="input-field col s12 m2">
                        <select
                            name="tipo_reunion"
                            value={formData.tipo_reunion}
                            onChange={handleChange}
                        >
                            <option value="extraordinaria">Extraordinaria</option>
                            <option value="Ordinaria">Ordinaria</option>
                        </select>
                        <label>Tipo de Reunión</label>
                    </div>

                    {/* Convocante */}
                    <div className="input-field col s12 m2">
                        <select
                            name="convocante"
                            value={formData.convocante}
                            onChange={handleChange}
                        >
                            <option value="Junta_Directiva">Junta Directiva</option>
                            <option value="Administrador">Administrador</option>
                        </select>
                        <label>Convocante</label>
                    </div>

                    {/* Modalidad */}
                    <div className="input-field col s12 m2">
                        <select
                            name="modalidad"
                            value={formData.modalidad}
                            onChange={handleChange}
                        >
                            <option value="virtual">Virtual</option>
                            <option value="Presencial">Presencial</option>
                        </select>
                        <label>Modalidad</label>
                    </div>

                    {/* Enlace (visible solo si es virtual) */}
                    {formData.modalidad === 'Virtual' && (
                        <div className="input-field col s12 m6">
                            <input
                                type="text"
                                name="enlace"
                                value={formData.enlace}
                                onChange={handleChange}
                            />
                            <label>Enlace de la Reunión</label>
                        </div>
                    )}
                </div>

                <div className="row">
                    {/* Fecha de Reunión */}
                    <div className="input-field col s12 m2">
                        <input
                            type="date"
                            name="fecha_reunion"
                            value={formData.fecha_reunion}
                            onChange={handleChange}
                        />
                        <label className="active">Fecha de la Reunión</label>
                    </div>

                    {/* Hora de Inicio */}
                    <div className="input-field col s12 m2">
                        <input
                            type="time"
                            name="hora_inicio"
                            value={formData.hora_inicio}
                            onChange={handleChange}
                        />
                        <label className="active">Hora de Inicio</label>
                    </div>
                </div>

                <div className="row">
                    {/* Penalidad e Infracción */}
                    <div className="input-field col s12 m2">
                        <input
                            type="text"
                            name="penalidad"
                            value={formData.penalidad}
                            onChange={handleChange}
                        />
                        <label>Código de Infracción</label>
                        <button
                            type="button"
                            className="btn-small blue"
                            style={{ marginLeft: '10px' }}
                            onClick={handleGetInfraccion}
                        >
                            Buscar
                        </button>
                    </div>

                    {/* Importe Multa */}
                    <div className="input-field col s12 m2">
                        <input
                            type="number"
                            name="importe_multa"
                            value={formData.importe_multa}
                            disabled
                        />
                        <label>Importe Multa (S/)</label>
                    </div>
                    <div className="input-field col s12 m2">
                        <input
                            type="date"
                            name="fecha_convocatoria"
                            value={formData.fecha_convocatoria}
                            onChange={handleChange}
                        />
                        <label className="active">Fecha Convocatoria</label>
                    </div>
                    {/* Campo de Estado: Solo visible al editar o consultar */}
                    {isEditing && (
                        <div className="input-field col s12 m2">
                            <select
                                name="estado"
                                value={formData.estado}
                                onChange={handleChange}
                            >
                                <option value="Programada">Programada</option>
                                <option value="En Curso">En Curso</option>
                                <option value="Finalizada">Finalizada</option>
                                <option value="Anulada">Anulada</option>
                            </select>
                            <label>Estado</label>
                        </div>
                    )}

                    {/* Token de Asistencia: Solo visible al editar */}
                    {isEditing && (
                        <div className="input-field col s12 m3" style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="text"
                                name="token_asistencia"
                                value={formData.token_asistencia}
                                readOnly
                            />
                            <label className="active">Token de Asistencia</label>
                            <button
                                type="button"
                                className="btn-small blue"
                                style={{ marginLeft: '10px' }}
                                onClick={handleGenerateToken}
                            >
                                Generar Token
                            </button>
                        </div>
                    )}

                </div>

                {/* Botones */}
                <div className="col s12">
                    <button
                        type="button"
                        className="btn blue"
                        onClick={handleSave}
                    >
                        {isEditing ? 'Guardar Cambios' : 'Crear Reunión'}
                    </button>
                    {isEditing && (
                        <button
                            type="button"
                            className="btn red lighten-1"
                            onClick={handleAnular}
                            style={{ marginLeft: '10px' }}
                        >
                            Anular Reunión
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default ReunionPage;
