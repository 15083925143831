import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetPublicaciones_x_edificio } from './publicacionSlice.js';


export const update_publicacion = createAsyncThunk('publicacion/update_publicacion', async ({ idPublicacion, publicacionData }, thunkAPI) => {
  console.log("ejecutando thunk update_publicacion con idPublicacion: " + idPublicacion);

  try {

    const response = await apiClient.put(`/publicaciones/${idPublicacion}`, publicacionData);

    const publicacion = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetPublicaciones_x_edificio(publicacion.buildingId));// Resetear el estado para refetch de publicaciones

    return publicacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_publicacion = createAsyncThunk('publicacion/save_publicacion', async (publicacionData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/publicaciones`, publicacionData);

    const publicacion = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPublicaciones_x_edificio(publicacion.buildingId));// Resetear el estado para refetch de publicaciones

    return publicacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_publicacion = createAsyncThunk('publicacion/anular_publicacion', async ({ idPublicacion, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_publicacion con idPublicacion: " + idPublicacion);

  try {

    const response = await apiClient.patch(`/publicaciones/${idPublicacion}/anular`, { usuarioAnulacion });

    const publicacion = response.data;

    // Despachar acción para resetear el estado de fetching
    //thunkAPI.dispatch(resetStateGetPublicaciones_x_edificio(publicacion.buildingId));// Resetear el estado para refetch de publicaciones

    return publicacion;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_publicacion = createAsyncThunk('publicacion/get_publicacion', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_publicacion con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/publicaciones/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const publicacion = response.data;

    return publicacion;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_publicaciones_x_edificio = createAsyncThunk('publicacion/get_publicaciones_x_edificio', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_publicaciones con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/publicaciones',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const publicaciones = response.data;
          
      return publicaciones ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_publicaciones_x_depa = createAsyncThunk('publicacion/get_publicaciones_x_depa', async ({ buildingId, codigo_usuario }, thunkAPI) => {
    console.log("ejecutando thunk get_publicaciones con "+buildingId + " codigo_usuario "+codigo_usuario);
    
    try { 
        //console.log("ejecutando thunk buildingId: "+buildingId);      
        const response = await apiClient.get('/publicaciones',{
          params: { 
            buildingId, 
            codigo_usuario 
          }
      });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const publicaciones = response.data;
            
        return publicaciones ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });