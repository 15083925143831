import { createSlice } from '@reduxjs/toolkit';
import {loginUsuario} from './authThunk';
//import {signInWithCustomToken} from 'firebase/auth';
import { getAuth, signOut } from 'firebase/auth';


const authSlice = createSlice({
    name: 'auth',
    initialState: {
      isAuthenticated: false,
      usuario: null,
      token: null,
      status: 'idle',
      error: null,
      edificios: [],
      unidades_facturacion: [],
      persona: null,
    },
    reducers: {
      logout: (state) => {
          //console.log(" Dentro de logout-inicializacion de variables");
          state.isAuthenticated = false;  
          state.usuario = null;
          state.status = 'idle';
          state.error = null;
          state.token = null;
          state.edificios = [];
          state.unidades_facturacion = [];
          state.persona = null;
        },
      resetState: () => {
        return {
          isAuthenticated : false,
          user: null,
          status: 'idle',
          error: null,
          token: null,
          edificios: [],
          unidades_facturacion: [],
          persona: null,
        };
      },
    },
    extraReducers: (builder) => {
      builder
          .addCase(loginUsuario.pending, (state) => {
              state.status = 'loading';
          })
          .addCase(loginUsuario.fulfilled, (state, action) => {
              state.status = 'succeeded';
              state.isAuthenticated = true;
              //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.usuario = action.payload.usuario;
              //console.log("3-2 JSON.stringify(state.usuario): "+JSON.stringify(state.usuario));
              state.token = action.payload.usuario.customToken;
              //state.edificios = action.payload.edificios;
              state.edificios = action.payload.edificios;
              state.unidades_facturacion = action.payload.unidades_facturacion;
              state.persona = action.payload.persona;
              //console.log("SLICE action.payload.usuario: "+JSON.stringify(action.payload.usuario));
              //console.log("SLICE action.payload.persona: "+JSON.stringify(action.payload.persona));
              //console.log("SLICE action.payload.unidades_facturacion: "+JSON.stringify(action.payload.unidades_facturacion));
              //console.log("SLICE action.payload.edificios: "+JSON.stringify(action.payload.edificios));

          })
          .addCase(loginUsuario.rejected, (state, action) => {
              state.status = 'idle';
              console.log("error action " +JSON.stringify(action) );
              state.error = action.payload.error;
              console.log("error state.error " +state.error);
          })

    },
  });
  
  export const { logout, resetState } = authSlice.actions;

  export const signOutUsuario = () => async (dispatch) => {
    const auth = getAuth();
    
    try {
      //console.log(" antes de signOuth con auth");
      await signOut(auth);
      dispatch(logout());
      dispatch(resetState());
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  //export const authReducer=authSlice.reducer;
  export default authSlice.reducer;
