import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_contenido } from "./contenidoThunk";
import { useParams } from "react-router-dom";

const MostrarContenidoPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams(); // Obtener el ID del contenido desde la URL
  const [loading, setLoading] = useState(true);

  // Seleccionar el contenido del store
  const contenido = useSelector((state) => state.contenido.contenido);

  useEffect(() => {
    // Llamar a la acción para obtener el contenido por ID
    const fetchContenido = async () => {
      try {
        console.log(" se imprime id "+id)
        await dispatch(get_contenido(id));
      } catch (error) {
        console.error("Error al cargar el contenido:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchContenido();
  }, [dispatch, id]);

  if (loading) {
    return <p>Cargando contenido...</p>;
  }

  if (!contenido) {
    return <p>No se encontró el contenido.</p>;
  }

  const renderImagen = () => {
    if (!contenido.imagenUrl) return null;

    const styles = {
      top: { display: "block", marginBottom: "20px", maxWidth: "100%" },
      left: { float: "left", marginRight: "20px", maxWidth: "50%" },
      right: { float: "right", marginLeft: "20px", maxWidth: "50%" },
      bottom: { display: "block", marginTop: "20px", maxWidth: "100%" },
    };

    return <img src={contenido.imagenUrl} alt="Contenido" style={styles[contenido.posicionImagen]} />;
  };
  return (
    <div
      style={{
        backgroundColor: contenido.colorFondo || "#ffffff",
        padding: "20px",
        minHeight: "100vh",
        boxSizing: "border-box",
      }}
    >
      {renderImagen()}
      <div
        dangerouslySetInnerHTML={{ __html: contenido.data }}
        style={{
          clear: contenido.posicionImagen === "left" || contenido.posicionImagen === "right" ? "both" : "none",
        }}
      />
    </div>
  );
};


export default MostrarContenidoPage;
