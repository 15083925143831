// src/pages/UploadAndListDepartments.js
import React from 'react';
import Carga_unidades_inmobiliarias from './carga-unidades-inmobiliariasPage';
import Unidades_inmobiliarias_cargadas from './unidades-inmobiliarias-cargadasPage';
import { useNavigate } from 'react-router-dom';


const Carga_lista_unidades_inmobiliarias = () => {
  const navigate = useNavigate();
  
  return (
    <div className="container">
      <h4>Carga Unid. Inmobiliarias</h4>
      <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/unidades-inmobiliarias')}>
              <i className="material-icons left">arrow_back</i>
        </a>     
      <Carga_unidades_inmobiliarias />
      <Unidades_inmobiliarias_cargadas />
    </div>
  );
};

export default Carga_lista_unidades_inmobiliarias;