import { createSlice } from '@reduxjs/toolkit';
import { get_reunion, update_reunion, save_reunion, anular_reunion, get_reuniones_x_edificio, validar_token_asistencia } from './reunionThunk';

const reunionSlice = createSlice({
  name: 'reunion',
  initialState: {
    reunion: null,
    reuniones: [],
    reunionSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_reuniones_x_edificio: 'idle',
    error_get_reuniones_x_edificio: null,
    status_validar_token_asistencia: 'idle',
    error_validar_token_asistencia: null,
    status_update_reunion: 'idle',
    error_update_reunion: null,    
    status_save_reunion: 'idle',
    error_save_reunion: null,
    status_anular_reunion: 'idle',
    error_anular_reunion: null,
  },
  reducers: {
    setReunionSeleccionada: (state, action) => {
      state.reunionSeleccionada = action.payload;
      
    },
    clearReunionSeleccionada(state) {
      state.reunionSeleccionada = null;
      state.reunion = null;
    },
    resetStateGetReuniones_x_edificio: () => {
        return {
            status_get_reuniones_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_reunion.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_reunion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reunion = action.payload;

      })
      .addCase(get_reunion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_reunion.pending, (state) => {
        state.status_update_reunion = 'loading';
      })
      .addCase(update_reunion.fulfilled, (state, action) => {
        state.status_update_reunion = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reunion = action.payload.reunion;

      })
      .addCase(update_reunion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_reunion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_reunion = action.payload || 'Failed to fetch building';
      })
      .addCase(save_reunion.pending, (state) => {
        state.status_save_reunion = 'loading';
      })
      .addCase(save_reunion.fulfilled, (state, action) => {
        state.status_save_reunion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reunion = action.payload.reunion;

      })
      .addCase(save_reunion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_reunion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_reunion = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_reunion.pending, (state) => {
        state.status_save_reunion = 'loading';
      })
      .addCase(anular_reunion.fulfilled, (state, action) => {
        state.status_anular_reunion = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.reunion = action.payload.reunion;

      })
      .addCase(anular_reunion.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_reunion = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_reunion = action.payload || 'Failed to fetch building';
      })

      // Validar token de asistencia
      .addCase(validar_token_asistencia.pending, (state) => {
        state.status_validar_token_asistencia = 'loading';
    })
    .addCase(validar_token_asistencia.fulfilled, (state) => {
        state.status_validar_token_asistencia = 'succeeded';
    })
    .addCase(validar_token_asistencia.rejected, (state, action) => {
        state.status_validar_token_asistencia = 'failed';
        state.error_validar_token_asistencia = action.payload;
    })

      .addCase(get_reuniones_x_edificio.pending, (state) => {
        state.status_get_reuniones_x_edificio = 'loading';
        
      })
      .addCase(get_reuniones_x_edificio.fulfilled, (state, action) => {
        state.status_get_reuniones_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_reuniones_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.reuniones = action.payload;

      })
      .addCase(get_reuniones_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_reuniones_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_reuniones_x_edificio = action.payload || 'Failed to fetch building';
        state.reuniones =[];
      })
  },
});

export const { setReunionSeleccionada, clearReunionSeleccionada } = reunionSlice.actions;
export const selectReuniones_emergenciaById = (state, idReunion) =>
    state.reunion.reuniones.find((reunion) => reunion.id === idReunion);
export const { resetStateGetReuniones_x_edificio } = reunionSlice.actions;
export default reunionSlice.reducer;
