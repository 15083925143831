import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { get_publicaciones_x_depa, anular_publicacion } from './publicacionThunk';
import './MisPublicacionesPage.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import useAuth from '../../utils/useAuth';
import {formatFriendlyDate} from '../../utils/formateo';

const MisPublicacionesPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //const codigo_usuario = useSelector((state) => state.auth.usuario.id);
    const usuario = useAuth(); // Valida la sesión y obtiene el usuario
    const buildingId = useSelector((state) => state.edificio.edificio?.id);
    const publicaciones = useSelector((state) => state.publicacion.publicaciones) || [];
    const status_get_publicaciones_x_depa = useSelector((state) => state.publicacion.status_get_publicaciones_x_depa);
    const error_get_publicaciones_x_depa = useSelector((state) => state.publicacion.error_get_publicaciones_x_depa);

    useEffect(() => {
        M.AutoInit();
        if (usuario) {
            dispatch(get_publicaciones_x_depa({ buildingId, codigo_usuario: usuario.id })).then(() => {                
            });
        }
    }, [dispatch, buildingId, usuario]);

    const handleCrearPublicacion = () => {
        navigate('/publicacion/create');
    };

    const handleEliminarPublicacion = (id) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar esta publicación?')) {
            dispatch(anular_publicacion(id)).then(() => {
                M.toast({ html: 'Publicación eliminada exitosamente.', classes: 'green' });
            });
        }
    };

    if (!usuario) {
        return <div className="center-align">Redirigiendo al inicio de sesión...</div>;
    }

    
    {status_get_publicaciones_x_depa === 'loading' && (
        <div className="center-align">Cargando publicaciones...</div>
    )}

    {status_get_publicaciones_x_depa === 'failed' && (
        <div className="card-panel red lighten-4 red-text text-darken-4">
            <span>Error: {error_get_publicaciones_x_depa}</span>
        </div>
    )}

    if (!usuario) {
        return null; // Evita renderizar mientras redirige
    }
    const handleRetornar = () => navigate(-1);
    return (
        <div className="mis-publicaciones-container">
            <div className="header">
                <h3 className="page-title">Mis Publicaciones</h3>
                <div className="button-container">
                    <button className="btn-small green create-button" onClick={handleCrearPublicacion}>
                        <i className="material-icons left">add</i> Crear Publicación
                    </button>
                    <button className="btn-small orange create-button" onClick={handleRetornar}>
                        <i className="material-icons left">arrow_back</i> Volver
                    </button>
                </div>
            </div>
            {Array.isArray(publicaciones) && publicaciones.length === 0 ? (
                <div className="center-align">No tienes publicaciones aún.</div>
            ) : (
                <div className="publicaciones-list">
                    {publicaciones.map((publicacion) => (
                        <div key={publicacion.id} className="card publicacion-card">
                            <div className="card-image">
                                <img
                                    src={publicacion.imagenes?.[0] || '/default-image.png'}
                                    alt={publicacion.titulo}
                                    className="responsive-img"
                                />
                            </div>
                            <div className="card-content">
                                <h5 className="card-title">{publicacion.titulo}</h5>
                                <p>{publicacion.descripcion.substring(0, 100)}...</p>
                                <p className="fecha-publicacion">Publicado el: {formatFriendlyDate(publicacion.createdAtFormatted)}</p>
                            </div>
                            <div className="card-action">
                                <button
                                    className="btn blue"
                                    onClick={() => navigate(`/publicacion/edit/${publicacion.id}`)}
                                >
                                    Editar
                                </button>                                
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MisPublicacionesPage;
