// frontend/src/redux/slices/requerimientoSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { 
    get_requerimiento, 
    get_requerimientos_x_depa,
    update_requerimiento, 
    save_requerimiento, 
    anular_requerimiento 
} from './requerimientoThunk';

const requerimientoSlice = createSlice({
    name: 'requerimiento',
    initialState: {
        requerimiento: null,
        requerimientos: [],
        requerimientoSeleccionado: null,
        status: 'idle',
        error: null,      
        status_get_requerimientos_x_depa: 'idle',
        error_get_requerimientos_x_depa: null,
        status_update_requerimiento: 'idle',
        error_update_requerimiento: null,
        status_save_requerimiento: 'idle',
        error_save_requerimiento: null,
        status_anular_requerimiento: 'idle',
        error_anular_requerimiento: null,
    },
    reducers: {
        setRequerimientoSeleccionado: (state, action) => {
            state.requerimientoSeleccionado = action.payload;
        },
        clearRequerimientoSeleccionado(state) {
            state.requerimientoSeleccionado = null;
            state.requerimiento = null;
        },
        resetStateGetRequerimientos: (state) => {
            state.status_get_requerimientos_x_depa = 'idle';
            state.error_get_requerimientos_x_depa = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Obtener una facturación individual
            .addCase(get_requerimiento.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(get_requerimiento.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.requerimiento = action.payload;
            })
            .addCase(get_requerimiento.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || 'Failed to fetch requerimiento individual';
            })                   
            // Obtener requerimientos por departamento
            .addCase(get_requerimientos_x_depa.pending, (state) => {
                console.log("Estamos en get_requerimientos_x_depa PENDING");
                console.log("->PEN antes state.status_get_requerimientos_x_depa: "+state.status_get_requerimientos_x_depa);
                state.status_get_requerimientos_x_depa = 'loading';
                state.error_get_requerimientos_x_depa = null;
                console.log("->PEN despues state.status_get_requerimientos_x_depa: "+state.status_get_requerimientos_x_depa);
            })
            .addCase(get_requerimientos_x_depa.fulfilled, (state, action) => {
                console.log("Estamos en get_requerimientos_x_depa FULLFILLED");
                console.log("->FULL antes state.status_get_requerimientos_x_depa: "+state.status_get_requerimientos_x_depa);
                state.status_get_requerimientos_x_depa = 'succeeded';
                state.requerimientos = action.payload; // Asignar directamente a 'requerimientos'
                console.log("FULL despues state.status_get_requerimientos_x_depa: "+state.status_get_requerimientos_x_depa);
            })
            .addCase(get_requerimientos_x_depa.rejected, (state, action) => {
                console.log("Estamos en get_requerimientos_x_depa REJECTED");
                console.log("->FAIL antes state.status_get_requerimientos_x_depa: "+state.status_get_requerimientos_x_depa);
                state.status_get_requerimientos_x_depa = 'failed';
                state.error_get_requerimientos_x_depa = action.payload || 'Failed to fetch requerimientos por departamento';
                state.requerimientos = [];
                console.log("FAIL despues state.status_get_requerimientos_x_depa: "+state.status_get_requerimientos_x_depa);
            })
            // Actualizar una facturación individual
            .addCase(update_requerimiento.pending, (state) => {
                state.status_update_requerimiento = 'loading';
                state.error_update_requerimiento = null;
            })
            .addCase(update_requerimiento.fulfilled, (state, action) => {
                state.status_update_requerimiento = 'succeeded';
                state.requerimiento = action.payload; // Asignar directamente
                
                // Actualizar el requerimiento en la lista 'requerimientos'
                const index = state.requerimientos.findIndex(r => r.id === action.payload.id);
                if (index !== -1) {
                    state.requerimientos[index] = action.payload;
                }
            })
            .addCase(update_requerimiento.rejected, (state, action) => {
                state.status_update_requerimiento = 'failed';
                state.error_update_requerimiento = action.payload || 'Failed to update requerimiento';
            })

            // Guardar una nueva facturación individual
            .addCase(save_requerimiento.pending, (state) => {
                state.status_save_requerimiento = 'loading';
                state.error_save_requerimiento = null;
            })
            .addCase(save_requerimiento.fulfilled, (state, action) => {
                state.status_save_requerimiento = 'succeeded';
                state.requerimiento = action.payload; // Asignar directamente
                state.requerimientos.push(action.payload); // Agregar a la lista
            })
            .addCase(save_requerimiento.rejected, (state, action) => {
                state.status_save_requerimiento = 'failed';
                state.error_save_requerimiento = action.payload || 'Failed to save requerimiento';
            })

            // Anular una facturación individual
            .addCase(anular_requerimiento.pending, (state) => {
                state.status_anular_requerimiento = 'loading';
                state.error_anular_requerimiento = null;
            })
            .addCase(anular_requerimiento.fulfilled, (state, action) => {
                state.status_anular_requerimiento = 'succeeded';
                state.requerimiento = action.payload; // Asignar directamente

                // Actualizar el requerimiento en la lista 'requerimientos'
                const index = state.requerimientos.findIndex(r => r.id === action.payload.id);
                if (index !== -1) {
                    state.requerimientos[index] = action.payload;
                }
            })
            .addCase(anular_requerimiento.rejected, (state, action) => {
                state.status_anular_requerimiento = 'failed';
                state.error_anular_requerimiento = action.payload || 'Failed to anular requerimiento';
            })      
        },
      });

export const { 
    setRequerimientoSeleccionado, 
    clearRequerimientoSeleccionado, 
    resetStateGetRequerimientos 
} = requerimientoSlice.actions;

export const selectrequerimientosById = (state, idRequerimiento) =>
    state.requerimiento.requerimientos.find((requerimiento) => requerimiento.id === idRequerimiento);

export default requerimientoSlice.reducer;
