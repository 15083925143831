// src/pages/UploadAndListDepartments.js
import React from 'react';
import Carga_propiedades from './carga-propiedadesPage';
import Propiedades_cargadas from './propiedades-cargadasPage';
import { useNavigate } from 'react-router-dom';

const Carga_lista_propiedades = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <h4>Carga Propiedades</h4>
      <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/propiedades')}>
              <i className="material-icons left">arrow_back</i>
        </a>         
      <Carga_propiedades />
      <Propiedades_cargadas />
    </div>
  );
};

export default Carga_lista_propiedades;