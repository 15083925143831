import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import { fetchBuilding, updateBuilding } from '../redux/buildingSlice'; // Asegúrate de que estas funciones existan en tu slice
//import { fetchDepartments, fetchParkingSpaces } from '../redux/departmentSlice'; // Ajusta según tu estructura
import { get_edificio_x_codigo, update_edificio } from './edificioThunk';
import { useNavigate } from 'react-router-dom';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './edificioFormPage.css'; // Asegúrate de importar tu archivo CSS personalizado

const EdificioForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const edificioSeleccionado = useSelector((state) => state.edificio.edificioSeleccionado);
  const buildingId = edificioSeleccionado.id; // ID del edificio desde la URL
  //const building = useSelector(state => state.building.currentBuilding);
  const building = useSelector(state => state.edificio.edificio);


  useEffect(() => {
    if (building) {
      setFormData(building);
      // Actualiza los campos de texto de Materialize cuando los datos del edificio se cargan
      M.updateTextFields();
    }
  }, [building]);

  useEffect(() => {
    //dispatch(fetchBuilding(buildingId));
    dispatch(get_edificio_x_codigo(buildingId));
  }, [dispatch, buildingId]);

  const handleSave = () => {
    console.log("buildingId antes de update: " + buildingId);
    dispatch(update_edificio({ buildingId, buildingData: formData }))
      .unwrap()
      .then(() => {
        setIsEditing(false);
        // Mostrar mensaje de éxito si es necesario
        M.toast({ html: 'Edificio actualizado con éxito!', classes: 'tostada', displayLength: 3000 });
      })
      .catch((error) => {
        // Manejar el error
        M.toast({ html: 'Error al actualizar el edificio. Inténtalo de nuevo.', classes: 'red', displayLength: 3000 });
        console.error('Error updating building details:', error);
      });
  };
  useEffect(() => {
    // Actualiza los campos de texto de Materialize cuando el componente se monta
    M.updateTextFields();
  }, [building]);




  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="container">
      <div>
        <div className="card">
          <div className="card-content">
            <div className="card-action">
              <div className="row ">

                {isEditing ? (
                  <>
                  <div className="col s12 m3">
                    <a className="btn green tooltipped" data-position="top" data-tooltip="Guardar cambios" onClick={handleSave}>
                      <i className="material-icons left">save</i>Guardar
                    </a>
                    </div>
                    <div className="col s12 m3">
                    <a className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={() => setIsEditing(false)}>
                      <i className="material-icons left">cancel</i>Cancelar
                    </a>
                    </div>
                  </>
                ) : (
                  <div className="col s12 m3">
                  <a className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={() => setIsEditing(true)}>
                    <i className="material-icons left">edit</i>Editar
                  </a>
                  </div>
                )}
<div className="col s12 m3">
                <a className="btn orange tooltipped" data-position="top" data-tooltip="Mostrar ResidentePersonas" onClick={() => navigate('/personas')}>
                  <i className="material-icons left">group</i>Personas
                </a>
                </div>
                <div className="col s12 m3">
                <a className="btn orange tooltipped" data-position="top" data-tooltip="Unidades Inmobiliarias" onClick={() => navigate('/unidades-inmobiliarias')}>
                  <i className="material-icons left">home  </i>Unid. Inmobiliarias
                </a>
                </div>
                <div className="col s12 m3">
                <a className="btn orange tooltipped" data-position="top" data-tooltip="Unidades de Facturación" onClick={() => navigate('/unidades-facturacion')}>
                  <i className="material-icons left">account_balance_wallet  </i>Unid. Facturación
                </a>
                </div>
                <div className="col s12 m3">
                <a className="btn orange tooltipped" data-position="top" data-tooltip="Suministros" onClick={() => navigate('/suministros')}>
                  <i className="material-icons left">image_aspect_ratio  </i>Suministros
                </a>
                </div>
                <div className="col s12 m3">
                <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/home')}>
                  <i className="material-icons left">arrow_back</i>
                </a>
                </div>
              </div>

            </div>

            <span className="card-title">Información del Edificio</span>


            <div className="row">
              <div className="input-field col s6 m2">
                <input
                  type="text"
                  name="codigo"
                  value={formData.codigo || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="codigo">Código</label>
              </div>

              <div className="input-field col s6 m4">
                <input
                  type="text"
                  name="nombre"
                  value={formData.nombre || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="nombre">Edificio</label>
              </div>
              <div className="input-field col s6 m6">
                <input
                  type="text"
                  name="direccion"
                  value={formData.direccion || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="direccion">Dirección</label>
              </div>

            </div>

            <div className="row">
              <div className="input-field col s6 m2">
                <input
                  type="number"
                  name="pisos"
                  value={formData.pisos || 0}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="pisos">N° Pisos</label>
              </div>

              <div className="input-field col s6 m2">
                <input
                  type="number"
                  name="sotanos"
                  value={formData.sotanos || 0}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="sotanos">N° Sótanos</label>
              </div>
              <div className="input-field col s6 m2">
                <input
                  type="text"
                  name="estado"
                  value={formData.estado || 0}
                  onChange={handleChange}
                  disabled={true}
                />
                <label className="active" htmlFor="estado">Estado</label>
              </div>
              <div className="input-field col s6 m3">
                <input
                  type="text"
                  name="registrador"
                  value={formData.usuario_registrador || ''}
                  onChange={handleChange}
                  disabled={true}
                />
                <label className="active" htmlFor="registrador">Registrador</label>
              </div>
            </div>

            <div className="row">
              <div className="input-field col s6 m3">
                <input
                  type="number"
                  name="unidades_facturacion"
                  value={formData.unidades_facturacion || 0}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="unidades_facturacion">N° Un.Factur.</label>
              </div>

              <div className="input-field col s6 m2">
                <input
                  type="number"
                  name="departamentos"
                  value={formData.departamentos || 0}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="departamentos">N° Depart.</label>
              </div>

              <div className="input-field col s6 m2">
                <input
                  type="number"
                  name="estacionamientos"
                  value={formData.estacionamientos || 0}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="estacionamientos">N° Estacio.</label>
              </div>
              <div className="input-field col s6 m2">
                <input
                  type="number"
                  name="depositos"
                  value={formData.depositos || 0}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="depositos">N° Depósitos</label>
              </div>
              <div className="input-field col s6 m3">
                <input
                  type="number"
                  name="area_ocupada"
                  value={formData.area_ocupada || 0}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
                <label className="active" htmlFor="area_ocupada">Área Ocupada (m²)</label>
              </div>

            </div>

            <div className="row">
              <div className="input-field col s6 m3">
                <input
                  type="number"
                  name="unidades_facturacion_asignadas"
                  value={formData.unidades_facturacion_asignadas || 0}
                  onChange={handleChange}
                  disabled={true}
                />
                <label className="active" htmlFor="unidades_facturacion_asignadas">N° Unid. Fact. Asig.</label>
              </div>

              <div className="input-field col s6 m2">
                <input
                  type="number"
                  name="departamentos_asignados"
                  value={formData.departamentos_asignados || 0}
                  onChange={handleChange}
                  disabled={true}
                />
                <label className="active" htmlFor="departamentos_asignados">N° Depas. As.</label>
              </div>

              <div className="input-field col s6 m2">
                <input
                  type="number"
                  name="estacionamientos_asignados"
                  value={formData.estacionamientos_asignados || 0}
                  onChange={handleChange}
                  disabled={true}
                />
                <label className="active" htmlFor="estacionamientos_asignados">N° Estac. Asig.</label>
              </div>
              <div className="input-field col s6 m2">
                <input
                  type="number"
                  name="depositos_asignados"
                  value={formData.depositos_asignados || 0}
                  onChange={handleChange}
                  disabled={true}
                />
                <label className="active" htmlFor="depositos_asignados">N° Depós. As.</label>
              </div>
              <div className="input-field col s6 m3">
                <input
                  type="number"
                  name="area_ocupada_asignada"
                  value={formData.area_ocupada_asignada || 0}
                  onChange={handleChange}
                  disabled={true}
                />
                <label className="active" htmlFor="area_ocupada_asignada">Área Ocup. Asig.(m²)</label>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default EdificioForm;
