import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_contactos_referencia_x_edificio } from './contacto_referenciaThunk';
import './ContactosReferenciaPage.css';
import { useNavigate } from 'react-router-dom';

const ContactosReferenciaPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const edificio = useSelector((state) => state.edificio.edificio);
    const buildingId = edificio?.id;
    const contactos = useSelector(state => state.contacto_referencia.contactos_referencia) || [];

    useEffect(() => {
        if (buildingId) {
            dispatch(get_contactos_referencia_x_edificio({ buildingId, tipo: 'EMERGENCIA' }));
        }
    }, [dispatch, buildingId]);

    const handleRetornar = () => navigate(-1);
    
    return (
        <div className="contactos-referencia-container">
                <a className="btn green" onClick={handleRetornar}>
                    <i className="material-icons left">arrow_back</i>Volver
                </a>
            <h3 className="center-align">Contactos de Emergencia y Servicios de Interés</h3>
            <div className="contactos-list">
                {contactos.map((contacto) => (
                    <div key={contacto.id} className="contacto-card">
                        {contacto.logo_url && (
                            <div className="contacto-logo">
                                <img src={contacto.logo_url} alt={`${contacto.nombre} logo`} />
                            </div>
                        )}
                        <div className="contacto-info">
                            <h5 className="contacto-nombre">{contacto.nombre}</h5>
                            <p className="contacto-telefono"><strong>Teléfono:</strong> {contacto.telefono}</p>
                            {contacto.descripcion && <p>{contacto.descripcion}</p>}
                            {contacto.email && <p><strong>Email:</strong> {contacto.email}</p>}
                            {contacto.direccion && <p><strong>Dirección:</strong> {contacto.direccion}</p>}
                            {contacto.horario_atencion && (
                                <p><strong>Horario:</strong> {contacto.horario_atencion}</p>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ContactosReferenciaPage;
