import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Cleave from 'cleave.js/react';
import { get_suministros_x_tipo } from '../edificio/suministrosThunk';
import { get_lectura_agua_by_periodo } from './lectura_aguaThunk'; // Agregar este thunk que traiga las lecturas
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { ESTADOS_LECTURA_AGUA, TIPOS_SUMINISTRO , MONEDAS} from '../../constants/constants';
import {
  anular_facturacion_agua,
  save_facturacion_agua,
  update_facturacion_agua,
  get_facturacion_agua
} from './facturacion_aguaThunk'; // Asegúrate de tener la ruta correcta
import './facturacion_aguaFormPage.css'; // Importa tu CSS si lo necesitas
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import {parseFromYYYYMM , formatPeriodo} from '../../utils/util';


const Facturacion_aguaForm = ({ mode }) => {

  const { id: idFacturacion_agua } = useParams();
  const facturacion_agua = useSelector(state => state.facturacion_agua.facturacion_agua);

  const [isEditing, setIsEditing] = useState(mode !== 'view');

  registerLocale('es', es);

  const usuario = useSelector((state) => state.auth.usuario);
  const codigo_usuario = usuario ? usuario.id : null;

  const edificioSeleccionado = useSelector((state) => state.edificio.edificio);
  const buildingId = edificioSeleccionado.id;
  const codigo_edificio = edificioSeleccionado.codigo;

  const { status_save_facturacion_agua, error_save_facturacion_agua, status_update_facturacion_agua, error_update_facturacion_agua } = useSelector((state) => state.facturacion_agua);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Obtener el periodo actual al iniciar el componente
  const periodoActual = formatPeriodo(new Date());

  const [formData, setFormData] = useState({
    nro_suministro: '',
    periodo: periodoActual,
    importe_recibo_suministro: 0,
    importe_descuento_agua: 0,
    importe_incremento_agua: 0,
    importe_total_facturar: 0,
    fecha_vencimiento_recibo: new Date(),
    consumo_unidades_facturacion: 0,
    factor_agua: 0,
    estado: ESTADOS_LECTURA_AGUA[0].label,
    moneda: { codigo: 'PEN', simbolo: 'S/' }, // Moneda predeterminada
    lecturas: [], // Esta es la tabla que contiene las lecturas del mes anterior y actual
  });

  const suministros = useSelector(state => state.suministros.suministros);

  // Obtener suministros (medidores) cuando el componente se monte
  useEffect(() => {
    dispatch(get_suministros_x_tipo({ buildingId, tipo_suministro: TIPOS_SUMINISTRO[0].value }));
    //dispatch(get_unidades_facturacion_x_edificio(buildingId));

    if (mode !== 'create') {
      dispatch(get_facturacion_agua(idFacturacion_agua));
    }

  }, [dispatch, idFacturacion_agua, mode, buildingId]);

  // Actualizar importe_total_facturar cuando los importes cambien
  useEffect(() => {
    if (mode === 'view' || mode === 'edit') {
      const { importe_recibo_suministro, importe_descuento_agua, importe_incremento_agua } = formData;
      const total = importe_recibo_suministro - importe_descuento_agua + importe_incremento_agua;
      setFormData(prev => ({
        ...prev,
        importe_total_facturar: total
      }));
      setIsEditing(mode === 'edit');
    }
  }, [formData.importe_recibo_suministro, mode, formData.importe_descuento_agua, formData.importe_incremento_agua]);

  // Actualizar factor_agua cuando importe_total_facturar o consumo_unidades_facturacion cambien
  useEffect(() => {
    const { importe_total_facturar, consumo_unidades_facturacion } = formData;
    const factor = consumo_unidades_facturacion > 0 ? importe_total_facturar / consumo_unidades_facturacion : 0;
    setFormData(prev => ({
      ...prev,
      factor_agua:  parseFloat(factor.toFixed(2))
    }));
  }, [formData.importe_total_facturar, formData.consumo_unidades_facturacion]);

  useEffect(() => {
    if (suministros) {


      if (suministros.length > 0 && !formData.nro_suministro) {
        setFormData(prev => ({
          ...prev,
          nro_suministro: suministros[0].numero // Inicializa con el primer medidor disponible
        }));
      }

      var elems = document.querySelectorAll('select');
      M.FormSelect.init(elems);
      M.updateTextFields();
    }
  }, [suministros]);

  const handleRetornar = () => {
    navigate('/facturaciones-agua');
  };

  const handleCancel = () => {
    navigate('/facturaciones-agua');
  };
  const handleEdit = () => {
    setIsEditing(true);
  };


  // Manejar el cambio de fecha y actualizar formData con el formato YYYY-MM
  
  const handlePeriodoChange = (date) => {
    const periodoFormateado = formatPeriodo(date); // Convierte la fecha seleccionada a YYYY-MM
    console.log("periodoFormateado "+periodoFormateado);
    setFormData((prev) => ({ ...prev, periodo: periodoFormateado }));
  };

  const handleLoadLecturas = async () => {
    const { nro_suministro, periodo } = formData;
    console.log(" OJO nro_suministro: " + nro_suministro + " periodo: " + periodo);
    
    // Validar que el suministro y el periodo estén seleccionados
    if (!nro_suministro || !periodo) {
      M.toast({ html: 'Seleccione un medidor y un periodo.', classes: 'red' });
      return;
    }
  
    console.log("Cargando lecturas para el periodo:", periodo);
  
    // Desglosar el periodo actual en año y mes
    const [year, month] = periodo.split('-').map(Number); // Convertir año y mes a números
    const nuevoMes = month - 1 === 0 ? 12 : month - 1; // Si estamos en enero, retroceder al mes 12 (diciembre)
    const nuevoAnio = month - 1 === 0 ? year - 1 : year; // Si retrocedemos desde enero, ir al año anterior
  
    // Formatear el periodo anterior a formato YYYY-MM
    const periodoAnteriorFormateado = `${nuevoAnio}-${nuevoMes.toString().padStart(2, '0')}`;
  
    try {
      // Llamar al thunk para obtener las lecturas del periodo actual y anterior
      const lecturasActualResponse =  await dispatch(get_lectura_agua_by_periodo({ periodo, buildingId }));
      const lecturasAnteriorResponse =  await dispatch(get_lectura_agua_by_periodo({ periodo: periodoAnteriorFormateado, buildingId }));
  
      const lecturasActual = lecturasActualResponse.payload.lecturas || [];
      const lecturasAnterior = lecturasAnteriorResponse.payload.lecturas || [];
  
      console.log("COMBINADO lecturasActual.length " + lecturasActual.length);
      console.log("COMBINADO lecturasAnterior.length " + lecturasAnterior.length);
  
      // Combinar las lecturas basándose en codigo_unidad_facturacion
      const lecturasCombinadas = lecturasActual.map((lecturaActual) => {
        // Encontrar la lectura anterior que coincida con el mismo codigo_unidad_facturacion
        const lecturaAnterior = lecturasAnterior.find(
          (la) => la.codigo_unidad_facturacion === lecturaActual.codigo_unidad_facturacion
        ) || {};
  
        // Calcular el total_consumo_mes y asegurar que tenga solo 2 decimales
        const total_consumo_mes = parseFloat((lecturaActual.lectura - (lecturaAnterior.lectura || 0)).toFixed(2));
        
        return {
          codigo_unidad_facturacion: lecturaActual.codigo_unidad_facturacion,
          lectura_anterior: lecturaAnterior.lectura || 0,
          foto_anterior: lecturaAnterior.foto || '',
          lectura_actual: lecturaActual.lectura || 0,
          foto_actual: lecturaActual.foto || '',
          total_consumo_mes,
          // Calcular cuota_agua asegurando que también tenga 2 decimales
          cuota_agua: parseFloat((total_consumo_mes * formData.factor_agua).toFixed(2)),
        };
      });
  
      // Calcular el consumo total de las unidades de facturación
      const consumoTotal = lecturasCombinadas.reduce((sum, item) => sum + item.total_consumo_mes, 0);
  
      // Actualizar el estado del formulario con las lecturas combinadas y el consumo total
      setFormData(prev => ({
        ...prev,
        lecturas: lecturasCombinadas,
        consumo_unidades_facturacion: consumoTotal,
      }));
  
    } catch (error) {
      console.error("Error al cargar las lecturas:", error);
      M.toast({ html: 'Error al cargar las lecturas.', classes: 'red' });
    }
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));

    setFormData((prev) => {
      const newFormData = {
        ...prev,
        [name]: isNaN(numericValue) ? 0 : numericValue, // Convertir a número si es numérico
      };

      // Actualizar el importe_total_facturar cuando cambien los valores
      const totalFacturar = newFormData.importe_recibo_suministro - newFormData.importe_descuento_agua + newFormData.importe_incremento_agua;

      // Actualizar el consumo_unidades_facturacion (sumar todos los total_consumo_mes)
      const consumoTotal = newFormData.lecturas.reduce((sum, lectura) => sum + lectura.total_consumo_mes, 0);

      // Actualizar el factor_agua
      const factorAgua = consumoTotal > 0 ? totalFacturar / consumoTotal : 0;

      // Actualizar lecturasCombinadas para recalcular cuota_agua
      const lecturasActualizadas = newFormData.lecturas.map((lectura) => ({
        ...lectura,
        cuota_agua: parseFloat((lectura.total_consumo_mes * factorAgua).toFixed(2)), // Calcular la cuota_agua con 2 decimales
      }));

      // Calcular el total de cuota_agua
      const totalCuotaAgua = lecturasActualizadas.reduce((sum, lectura) => sum + lectura.cuota_agua, 0);


      // Retornar el nuevo estado con los valores actualizados
      return {
        ...newFormData,
        importe_total_facturar: totalFacturar, // Actualiza importe_total_facturar
        consumo_unidades_facturacion: parseFloat(consumoTotal.toFixed(2)), // Actualiza consumo_unidades_facturacion
        factor_agua: parseFloat(factorAgua.toFixed(2)), // Actualiza factor_agua
        total_cuota_agua: parseFloat(totalCuotaAgua.toFixed(2)), // Actualiza total_cuota_agua
        lecturas: lecturasActualizadas, // Actualiza las lecturas con cuota_agua recalculada
      };
    });
  };

  const handleSave = async () => {
    const updatedData = { ...formData };

    if (isEditing) {
      if (mode === 'create') {
        const data = { ...updatedData, buildingId, usuarioRegistrador: codigo_usuario };
        //console.log(" En CREATE data: " + JSON.stringify(data));

        //dispatch(save_lectura_agua(data));

        const resultAction = dispatch(save_facturacion_agua(data));
        if (save_facturacion_agua.fulfilled.match(resultAction)) {
          console.log("Eentro por ok")
          M.toast({ html: 'Facturación de agua guardada exitosamente.', classes: 'green' });
        } else if (save_facturacion_agua.rejected.match(resultAction)) {
          //console.log("Eentro por ERROR resultAction.error "+JSON.stringify(resultAction.error))
          // Mostrar el mensaje de error si existe un error de validación en el payload
          //console.log("error_save_lectura_agua "+error_save_lectura_agua)
          //const errorMessage = resultAction.error.message || 'Ocurrió un error al guardar la lectura de agua.';
          const errorMessage = error_save_facturacion_agua || '-Ocurrió un error al guardar la facturación de agua.';
          //M.toast({ html: resultAction.payload.error, classes: 'red' });
          M.toast({ html: errorMessage, classes: 'red' });
          return;
        } else {
          console.log("Eentro por ELSE " + JSON.stringify(resultAction.payload))
        }
      } else {
        const data = { ...updatedData, usuarioActualizacion: codigo_usuario };
        //console.log(" En UPDATE data: " + JSON.stringify(data));
        const resultAction =  dispatch(update_facturacion_agua({ idFacturacion_agua, facturacion_aguaData: data }));
        if (update_facturacion_agua.fulfilled.match(resultAction)) {
          console.log("Eentro por ok")
          M.toast({ html: 'Facturación de agua actualizada exitosamente.', classes: 'green' });
        } else if (update_facturacion_agua.rejected.match(resultAction)) {
          //console.log("Eentro por ERROR resultAction.error "+JSON.stringify(resultAction.error))
          // Mostrar el mensaje de error si existe un error de validación en el payload
          //console.log("error_save_lectura_agua "+error_save_lectura_agua)
          //const errorMessage = resultAction.error.message || 'Ocurrió un error al guardar la lectura de agua.';
          const errorMessage = error_update_facturacion_agua || '-Ocurrió un error al actualizar la facturación de agua.';
          //M.toast({ html: resultAction.payload.error, classes: 'red' });
          M.toast({ html: errorMessage, classes: 'red' });
          return;
        } else {
          console.log("Eentro por ELSE update " + JSON.stringify(resultAction.payload))
        }
      }

    }

    navigate('/facturaciones-agua');
  };

  useEffect(() => {
    if (facturacion_agua && (mode === 'view' || mode === 'edit')) {
      //console.log("facturacion_agua data:", facturacion_agua);  // Imprime toda la data de lectura_agua
      setFormData(prev => ({
        ...prev,
        consumo_unidades_facturacion: facturacion_agua.consumo_unidades_facturacion,
        factor_agua: facturacion_agua.factor_agua,
        importe_descuento_agua: facturacion_agua.importe_descuento_agua,
        importe_incremento_agua: facturacion_agua.importe_incremento_agua,
        importe_recibo_suministro: facturacion_agua.importe_recibo_suministro,
        importe_total_facturar: facturacion_agua.importe_total_facturar,
        nro_suministro: facturacion_agua.nro_suministro,
        periodo: facturacion_agua.periodo,
        total_cuota_agua: facturacion_agua.total_cuota_agua,
        nota: facturacion_agua.nota,
        fecha_vencimiento_recibo: new Date(facturacion_agua.fecha_vencimiento_recibo),
        estado: facturacion_agua.estado,
        moneda: facturacion_agua.moneda,
        lecturas: facturacion_agua.lecturas.map((lectura_unidad_facturacion, index) => ({
          codigo_unidad_facturacion: lectura_unidad_facturacion.codigo_unidad_facturacion,
          cuota_agua: lectura_unidad_facturacion?.cuota_agua || 0,
          foto_actual: lectura_unidad_facturacion?.foto_actual || null,
          foto_anterior: lectura_unidad_facturacion?.foto_anterior || null,
          lectura_actual: lectura_unidad_facturacion?.lectura_actual || 0,
          lectura_anterior: lectura_unidad_facturacion?.lectura_anterior || 0,
          total_consumo_mes: lectura_unidad_facturacion?.total_consumo_mes || 0,
        }))
      }));
      setIsEditing(mode === 'edit');
    }
  }, [facturacion_agua, mode]);

  const handleMonedaChange = (e) => {
    const selectedMoneda = MONEDAS.find(moneda => moneda.value === e.target.value);
    setFormData({
      ...formData,
      moneda: {
        codigo: selectedMoneda.value,
        simbolo: selectedMoneda.label
      }
    });
  };

  return (
    <div className="container facturacion-agua-form">
      <div>
        <div className="card">
          <div className="card-content">
            <div className="card-action">
              {isEditing ? (
                <>
                  <a className="btn green tooltipped" data-position="top" data-tooltip="Guardar cambios" onClick={handleSave}>
                    <i className="material-icons left">save</i>Guardar
                  </a>
                  <a className="btn red tooltipped" data-position="top" data-tooltip="Cancelar edición" onClick={handleCancel}>
                    <i className="material-icons left">cancel</i>Cancelar
                  </a>
                </>
              ) : (
                <a className="btn green tooltipped" data-position="top" data-tooltip="Editar detalles" onClick={handleEdit}>
                  <i className="material-icons left">edit</i>Editar
                </a>
              )}

              <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={handleRetornar}>
                <i className="material-icons left">arrow_back</i>
              </a>
            </div>

            <span className="card-title">{mode === 'create' ? 'Crear Facturación de Agua' : mode === 'edit' ? 'Editar Facturación de Agua' : 'Detalles de la Facturación de Agua'}</span>
            <form onSubmit={e => e.preventDefault()}>
              <div className="row">
                <div className="input-field col s6 m3">
                  <select
                    name="nro_suministro"
                    value={formData.nro_suministro || ''}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  >
                    {suministros.map(s => (
                      <option key={s.id} value={s.numero}>{s.nombre} - {s.numero}</option>
                    ))}
                  </select>
                  <label>N° Suministro</label>
                </div>
                <div className="input-field col s6 m1">
                  <DatePicker
                    selected={parseFromYYYYMM(formData.periodo)}
                    onChange={handlePeriodoChange} // Manejar el cambio de la fecha
                    showMonthYearPicker
                    dateFormat="MM/yyyy"
                    name="periodo"
                    disabled={!isEditing}
                    locale="es" // Establece el locale aquí
                  />
                  <label className="active" htmlFor="periodo">Periodo:</label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s6 m2">
                  <select name="moneda" value={formData.moneda.codigo} onChange={handleMonedaChange} disabled={!isEditing}>
                    {MONEDAS.map(moneda => (
                      <option key={moneda.value} value={moneda.value}>{moneda.label}</option>
                    ))}
                  </select>
                  <label htmlFor="moneda">Monedas:</label>
                </div>
                <div className="input-field col s4">
                  <Cleave
                    placeholder="0.00"
                    name="importe_recibo_suministro"
                    value={formData.importe_recibo_suministro}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="active" htmlFor="importe_recibo_suministro">Importe Recibo Suministro</label>
                </div>
                <div className="input-field col s6 m2">
                  <input
                    type="text"
                    name="estado"
                    value={formData.estado || ''}
                    disabled={true}
                  />
                  <label htmlFor="estado" className="active">Estado</label>
                </div>
                <div className="input-field col s4">
                  <Cleave
                    placeholder="0.00"
                    name="importe_descuento_agua"
                    value={formData.importe_descuento_agua}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="active" htmlFor="importe_descuento_agua">Importe Descuento Agua</label>
                </div>
                <div className="input-field col s4">
                  <Cleave
                    placeholder="0.00"
                    name="importe_incremento_agua"
                    value={formData.importe_incremento_agua}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                  <label className="active" htmlFor="importe_incremento_agua">Importe Incremento Agua</label>
                </div>
              </div>

              <div className="row">
                <div className="input-field col s4">
                  <Cleave
                    placeholder="0.00"
                    name="importe_total_facturar"
                    value={formData.importe_total_facturar}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    disabled
                  />
                  <label className="active" htmlFor="importe_total_facturar">Importe Total a Facturar</label>
                </div>
                <div className="input-field col s4">
                  <DatePicker
                    selected={formData.fecha_vencimiento_recibo}
                    onChange={date => setFormData(prev => ({ ...prev, fecha_vencimiento_recibo: date }))}
                    dateFormat="dd/MM/yyyy"
                    name="fecha_vencimiento_recibo"
                    locale="es" // Establece el locale aquí
                    disabled={!isEditing}
                  />
                  <label className="active" htmlFor="fecha_vencimiento_recibo">Fecha de Vencimiento</label>
                </div>
                <div className="input-field col s4">
                  <Cleave
                    placeholder="0.00"
                    name="consumo_unidades_facturacion"
                    value={formData.consumo_unidades_facturacion}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    disabled
                  />
                  <label className="active" htmlFor="consumo_unidades_facturacion">Total consumo(m³) </label>
                </div>
                <div className="input-field col s4">
                  <Cleave
                    placeholder="0.00"
                    name="factor_agua"
                    value={formData.factor_agua}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    disabled
                  />
                  <label className="active" htmlFor="factor_agua">Factor Agua</label>
                </div>
                <div className="input-field col s4">
                  <Cleave
                    placeholder="0.00"
                    name="total_cuota_agua"
                    value={formData.total_cuota_agua}
                    options={{ numeral: true, numeralDecimalMark: '.', numeralThousandsGroupStyle: 'thousand' }}
                    disabled
                  />
                  <label className="active" htmlFor="total_cuota_agua">Total Cuota Agua</label>
                </div>
              </div>

              <button className="btn" onClick={handleLoadLecturas} disabled={!isEditing}>Cargar Lecturas</button>

              <h3>Lecturas de Agua</h3>
              <table>
                <thead>
                  <tr>
                    <th>Unidad Facturación</th>
                    <th>Lectura Anterior</th>
                    <th>Foto Anterior</th>
                    <th>Lectura Actual</th>
                    <th>Foto Actual</th>
                    <th>Consumo Mes (m³)</th>
                    <th>Cuota Agua</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.lecturas.map((lectura, index) => (
                    <tr key={index}>
                      <td>{lectura.codigo_unidad_facturacion}</td>
                      <td>{lectura.lectura_anterior}</td>
                      <td>{lectura.foto_anterior ? (
                        <img src={lectura.foto_anterior} alt="Foto Anterior" width="50" />
                      ) : null} {/* Solo se renderiza si hay una foto anterior */}</td>
                      <td>{lectura.lectura_actual}</td>
                      <td>{lectura.foto_actual ? (
                        <img src={lectura.foto_actual} alt="Foto Actual" width="50" />
                      ) : null} {/* Solo se renderiza si hay una foto actual */}</td>
                      <td>{lectura.total_consumo_mes.toFixed(2)}</td>
                      <td>{lectura.cuota_agua.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facturacion_aguaForm;
