// src/pages/UploadAndListDepartments.js
import React from 'react';
import Carga_unidades_facturacion from './carga-unidades-facturacionPage';
import Unidades_facturacion_cargadas from './unidades-facturacion-cargadasPage';
import { useNavigate } from 'react-router-dom';

const Carga_lista_unidades_facturacion = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <h4>Carga Unidades de facturación</h4>
      <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/unidades-facturacion')}>
              <i className="material-icons left">arrow_back</i>
        </a>         
      <Carga_unidades_facturacion />
      <Unidades_facturacion_cargadas />
    </div>
  );
};

export default Carga_lista_unidades_facturacion;