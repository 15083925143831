import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetLecturas_agua } from './lecturas_aguaSlice';



export const update_lectura_agua = createAsyncThunk('lectura_agua/update_lectura_agua', async ({ idLectura_agua, lectura_aguaData }, thunkAPI) => {
  //console.log("ejecutando thunk update_lectura_agua con idLectura_agua: " + idLectura_agua);

  try {

    const response = await apiClient.put(`/lecturas-agua/${idLectura_agua}`, lectura_aguaData);

    const lectura_agua = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetLecturas_agua(lectura_agua.buildingId));// Resetear el estado para refetch de lectura_aguas


    return lectura_agua;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_lectura_agua = createAsyncThunk('lectura_agua/save_lectura_agua', async (lectura_aguaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`lecturas-agua`, lectura_aguaData);

    const lectura_agua = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetLecturas_agua(lectura_agua.buildingId));// Resetear el estado para refetch de lectura_aguas

    return lectura_agua;

  } catch (error) {
    //console.log("error.response.data " + JSON.stringify(error));
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_lectura_agua = createAsyncThunk('lectura_agua/anular_lectura_agua', async ({ idLectura_agua, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_lectura_agua con idLectura_agua: " + idLectura_agua);

  try {

    const response = await apiClient.patch(`/lecturas-agua/${idLectura_agua}/anular`, { usuarioAnulacion });

    const lectura_agua = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetLecturas_agua(lectura_agua.buildingId));// Resetear el estado para refetch de lectura_aguas

    return lectura_agua;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_lectura_agua = createAsyncThunk('lectura_agua/get_lectura_agua', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_lectura_agua con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/lecturas-agua/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const lectura_agua = response.data;

    return lectura_agua;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_lectura_agua_by_periodo = createAsyncThunk('lectura_agua/get_lectura_agua_x_periodo', async ({ buildingId, periodo }, thunkAPI) => {
  //console.log("ejecutando thunk get_lectura_agua con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/lecturas-agua/${buildingId}/${periodo}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const lectura_agua = response.data;

    return lectura_agua;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});