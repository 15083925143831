import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {auth} from '../../firebase';
import {signInWithCustomToken} from 'firebase/auth';
import apiClient from '../../utils/apiClient';

export const loginUsuario = createAsyncThunk('auth/login', async (credencial, thunkAPI) => {

    try {        
      //console.log("SEGUI antes de login credencial "+ JSON.stringify(credencial));
        const response = await apiClient.post('/auth/login', credencial);
        

        //const response = await apiClient.post('/login', credencial);
        //console.log("SEGUI Despues response.data "+ JSON.stringify(response.data));
        const { edificios, usuario, unidades_facturacion, persona } = response.data;
        //console.log("SEGUI Despues de login usuario "+ JSON.stringify(usuario));
        //console.log("SEGUI Despues de login edificios "+ JSON.stringify(edificios));
/*
        if (!response.ok) {
          
          const error = await response.json();
          console.log("ERROR OJO2 "+ JSON.stringify(error));
          return thunkAPI.rejectWithValue(error.message || 'Login fallido');
        }
  */      
        
        //console.log("usuario "+JSON.stringify(usuario));
        //console.log("CUSTOM TOKEN "+usuario.customToken);
        //await signInWithCustomToken(auth, usuario.customToken);
    
        // Sign in with the custom token
        const userCredential = await signInWithCustomToken(auth, usuario.customToken);
        // Get the authenticated user
        const authenticatedUser = userCredential.user;

        return { usuario, edificios, unidades_facturacion, persona };
        //return response.data;

      } catch (error) {
        //console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
  export const sendPasswordResetEmail = createAsyncThunk('auth/sendPasswordResetEmail', async (credencial, thunkAPI) => {

    try { 
      console.log("OJO ANTES DE LOGIN ");       
        const response = await apiClient.post('/login', credencial);
        
        if (!response.ok) {
          
          const error = await response.json();
          console.log("ERROR OJO2 "+ JSON.stringify(error));
          return thunkAPI.rejectWithValue(error.message || 'Login fallido');
        }

        const { edificios, usuario } = response.data;
            
        await signInWithCustomToken(auth, usuario.customToken);
    
        // Sign in with the custom token
        const userCredential = await signInWithCustomToken(auth, usuario.customToken);
        // Get the authenticated user
        const authenticatedUser = userCredential.user;

        return { usuario, edificios };
      } catch (error) {
        console.log("ERROR  error.response.data "+error.response.data );
        //return thunkAPI.rejectWithValue(error.response.data);
      }
  });