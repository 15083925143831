import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { get_medicamentos_x_edificio, save_medicamento, update_medicamento } from './medicamentoThunk';
import { get_grupos_x_tipo } from '../medicamentos/grupoThunk';
import { getStorage, ref, uploadBytes, getDownloadURL, connectStorageEmulator } from 'firebase/storage'; 
import M from 'materialize-css/dist/js/materialize.min.js'; 
import './AdminMedicamentos.css';

const AdminMedicamentos = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Inicializar useNavigate
    const edificio = useSelector((state) => state.edificio.edificio);
    const buildingId = edificio?.id;
    const medicamentos = useSelector((state) => state.medicamento.medicamentos) || [];
    const grupos_medicamento = useSelector((state) => state.grupo.grupos) || [];

    const [selectedMedicamento, setSelectedMedicamento] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileUploads, setFileUploads] = useState([]);

    useEffect(() => {
        if (buildingId) {
            dispatch(get_medicamentos_x_edificio(buildingId));
            dispatch(get_grupos_x_tipo('medicamentos'));
        }
    }, [dispatch, buildingId]);

    useEffect(() => {
        if (isModalOpen) {
            M.FormSelect.init(document.querySelectorAll('select'));
            M.updateTextFields();
        }
    }, [isModalOpen]);

    const handleOpenModal = (medicamento = { nombre: '', descripcion: '', idgrupo_medicamento: grupos_medicamento[0]?.id || '', grupo: grupos_medicamento[0]?.nombre || '', archivos: [], stock: 0 }) => {
        setSelectedMedicamento(medicamento);
        setFileUploads([]);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedMedicamento(null);
        setIsModalOpen(false);
    };

    const uploadFiles = async () => {
        const storage = getStorage();
        const uploadPromises = fileUploads.map(async (file) => {
            if (window.location.hostname === 'localhost') {
                connectStorageEmulator(storage, 'localhost', 9199);
            }

            const storageRefPath = `cargaMedicamentos/${selectedMedicamento.id || 'new'}/${file.name}_${Date.now()}`;
            const fileRef = ref(storage, storageRefPath);

            const snapshot = await uploadBytes(fileRef, file);
            return await getDownloadURL(fileRef);
        });

        return Promise.all(uploadPromises);
    };

    const handleSave = async () => {
        try {
            const archivosSubidos = await uploadFiles();
            const selectedGrupo = grupos_medicamento.find((g) => g.id === selectedMedicamento.idgrupo_medicamento);
            const dataToSave = {
                ...selectedMedicamento,
                grupo: selectedGrupo?.nombre || '',
                archivos: [...(selectedMedicamento.archivos || []), ...archivosSubidos],
                buildingId,
            };

            if (selectedMedicamento.id) {
                await dispatch(update_medicamento({ idMedicamento: selectedMedicamento.id, medicamentoData: dataToSave })).unwrap();
            } else {
                await dispatch(save_medicamento(dataToSave)).unwrap();
            }

            dispatch(get_medicamentos_x_edificio(buildingId));
            handleCloseModal();
        } catch (error) {
            console.error('Error al guardar el medicamento:', error);
        }
    };

    const handleFileChange = (event) => {
        setFileUploads(Array.from(event.target.files));
    };

    const handleRemoveFile = (fileUrl) => {
        const updatedArchivos = selectedMedicamento.archivos.filter((file) => file !== fileUrl);
        setSelectedMedicamento({ ...selectedMedicamento, archivos: updatedArchivos });
    };

    return (
        <div className="admin-medicamentos-container">
            <h3 className="center-align">Gestión de Medicamentos</h3>
            <button onClick={() => handleOpenModal()} className="btn blue">Nuevo Medicamento</button>
            <button className="btn blue return-btn" onClick={() => navigate(-1)}>
                <i className="material-icons left">arrow_back</i> Volver
            </button>
            <div className="medicamentos-list">
                {medicamentos.map((med, index) => (
                    <div key={index} className="medicamento-item">
                        <h5>{med.nombre}</h5>
                        <p>Stock: {med.stock}</p>
                        <p>Descripción: {med.descripcion}</p>
                        <p>Grupo: {med.grupo}</p>
                        <button onClick={() => handleOpenModal(med)} className="btn yellow">Editar</button>
                    </div>
                ))}
            </div>

            {isModalOpen && (
                <div className="custom-modal">
                    <div className="modal-content">
                        <h4>{selectedMedicamento?.id ? 'Editar' : 'Crear'} Medicamento</h4>
                        <form>
                            <div className="input-field">
                                <input
                                    type="text"
                                    value={selectedMedicamento?.nombre || ''}
                                    onChange={(e) => setSelectedMedicamento({ ...selectedMedicamento, nombre: e.target.value })}
                                    placeholder="Nombre"
                                />
                            </div>
                            <div className="input-field">
                                <textarea
                                    value={selectedMedicamento?.descripcion || ''}
                                    onChange={(e) => setSelectedMedicamento({ ...selectedMedicamento, descripcion: e.target.value })}
                                    placeholder="Descripción"
                                ></textarea>
                            </div>
                            <div className="input-field">
                                <input
                                    type="number"
                                    min="0"
                                    value={selectedMedicamento?.stock || 0}
                                    onChange={(e) => setSelectedMedicamento({ ...selectedMedicamento, stock: parseInt(e.target.value, 10) || 0 })}
                                    placeholder="Stock"
                                />
                                <label className="active">Stock</label>
                            </div>
                            <div className="input-field">
                                <select
                                    value={selectedMedicamento?.idgrupo_medicamento || ''}
                                    onChange={(e) => setSelectedMedicamento({ ...selectedMedicamento, idgrupo_medicamento: e.target.value })}
                                >
                                    {grupos_medicamento.map((grupo) => (
                                        <option key={grupo.id} value={grupo.id}>{grupo.nombre}</option>
                                    ))}
                                </select>
                                <label>Grupo</label>
                            </div>
                            <div className="file-field input-field">
                                <div className="btn">
                                    <span>Subir Archivos</span>
                                    <input type="file" multiple onChange={handleFileChange} />
                                </div>
                                <div className="file-path-wrapper">
                                    <input className="file-path validate" type="text" placeholder="Archivos seleccionados" />
                                </div>
                            </div>
                            <div className="file-list">
                                {selectedMedicamento?.archivos.map((fileUrl, index) => (
                                    <div key={index} className="file-item">
                                        <a href={fileUrl} target="_blank" rel="noopener noreferrer">Archivo {index + 1}</a>
                                        <button type="button" className="btn red btn-small" onClick={() => handleRemoveFile(fileUrl)}>Eliminar</button>
                                    </div>
                                ))}
                            </div>
                        </form>
                        <div className="modal-footer">
                            <button onClick={handleSave} className="btn green">Guardar</button>
                            <button onClick={handleCloseModal} className="btn red">Cancelar</button>
                        </div>
                    </div>
                    <div className="modal-overlay" onClick={handleCloseModal}></div>
                </div>
            )}
        </div>
    );
};

export default AdminMedicamentos;
