// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';


// Expiración de sesión en milisegundos (1 minuto)
const SESSION_EXPIRATION_TIME = 3600000;

// Configuración de persistencia
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth','usuario','reserva', 'area_comun','unidad_facturacion', 'plantilla_activo', 'activo', 'medicamento', 'contenido', 'respuesta', 'reunion', 'justificacion', 'asistencia', 'publicacion', 'lead', 'preferencia', 'persona_asistencia', 'contacto_referencia', 'grupo', 'facturacion_individual', 'requerimiento', 'zona_segura', 'protocolo', 'categoria_requerimiento', 'recibos', 'isAuthenticated','conceptos_facturacion', 'edificio', 'edificioSeleccionado'], // Solo persiste el reducer de auth  
  transforms: [
    expireReducer('auth', {
      expireSeconds: SESSION_EXPIRATION_TIME,
      expiredState: {
        isAuthenticated: false,
        usuario: null,
        token: null,
        status: 'idle',
        error: null,
        edificioSeleccionado: null,
        edificios: [],
        recibos:[],
        edificio:null,
        selectedConcepts:[],
        conceptos_facturacion:[],
        unidad_facturacion: null,
      },
      autoExpire: true,
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };