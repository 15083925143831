import { createSlice } from '@reduxjs/toolkit';
import {get_unidades_facturacion_cargadas, mover_unidades_facturacion_cargadas} from './unidades-facturacion-cargadasThunk';

const unidades_facturacion_cargadasSlice = createSlice({
    name: 'unidades_facturacion_cargadas',
    initialState: {
      unidades_facturacion_cargadas: [],
      //fetchStatus : 'idle',
      //error: null,
      status: {
        get_unidades_facturacion_cargadas: 'idle',
        moveValidUnidades_facturacion_cargadas: 'idle',
      },
      error: {
        get_unidades_facturacion_cargadas: null,
        moveValidUnidades_facturacion_cargadas: null,
      },
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_unidades_facturacion_cargadas.pending, (state) => {
              state.status.get_unidades_facturacion_cargadas  = 'loading';
          })
          .addCase(get_unidades_facturacion_cargadas.fulfilled, (state, action) => {
              state.status.get_unidades_facturacion_cargadas  = 'succeeded';
              //console.log("Unidad_facturacion_cargadaes cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.unidades_facturacion_cargadas = action.payload;

          })
          .addCase(get_unidades_facturacion_cargadas.rejected, (state, action) => {
            //console.log("ejecutando Slice failed rejected ");
              state.status.get_unidades_facturacion_cargadas  = 'failed';              
              //console.log("error action.error.message "+action.error.message );
              state.error.get_unidades_facturacion_cargadas = action.payload || 'Failed to fetch receipts';
          })
//
        .addCase(mover_unidades_facturacion_cargadas.pending, (state) => {
            state.status.mover_unidades_facturacion_cargadas  = 'loading';
        })
        .addCase(mover_unidades_facturacion_cargadas.fulfilled, (state, action) => {
            state.status.mover_unidades_facturacion_cargadas  = 'succeeded';
            //console.log("Unidad_facturacion_cargadaes cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
            //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
            state.unidades_facturacion_cargadas = action.payload;

        })
        .addCase(mover_unidades_facturacion_cargadas.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
            state.status.mover_unidades_facturacion_cargadas  = 'failed';
            //console.log("error action.error.message "+action.error.message );
            state.error.mover_unidades_facturacion_cargadas = action.payload || 'Failed to fetch receipts';
        });

    },
  });
    
  export const selectUnidad_facturacion_cargada_cargadaById = (state, propiedad_cargadoId) =>
    state.unidades_facturacion_cargadas.unidades_facturacion_cargadas.find((propiedad_cargado) => propiedad_cargado.id === propiedad_cargadoId);
  
  export default unidades_facturacion_cargadasSlice.reducer;
