import React, { useEffect, useState  } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {get_estacionamientos_x_edificio} from './estacionamientoThunk';
import M from 'materialize-css';

import {formatDate02} from '../../utils/formateo';


const Estacionamientos_edificio = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  //const user = useSelector((state) => state.usuario.user);
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);  
  const edificio = useSelector(state => state.edificio.edificio);
  //const codigo_edificio = edificio?.codigo;
  const estacionamientos = useSelector((state) => state.estacionamiento.estacionamientos);
  const usuario = useSelector((state) => state.auth.usuario);
  const fetchStatus  = useSelector((state) => state.estacionamiento.fetchStatus );    
  const error = useSelector((state) => state.estacionamiento.error);
  const [filtro, setFiltro] = useState('');
  
  //console.log("en mis recibos isAuthenticated: "+isAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
        //console.log("no existe usuario");
      navigate('/');
    }
    M.AutoInit(); // Inicializa los componentes de Materialize CSS
    console.log("fetchStatus: "+ fetchStatus);
    console.log("edificio.id: "+ edificio.id);
    if (fetchStatus  === 'idle' && edificio.id) {
      dispatch(get_estacionamientos_x_edificio(edificio.id));
      
    }


  }, [isAuthenticated, navigate, fetchStatus , edificio, dispatch]);

  const handleRefresh = () => {    
    dispatch(get_estacionamientos_x_edificio(edificio.id));
  };

  const formatearEstacionamientos = (estacionamientos) => {
    if (!Array.isArray(estacionamientos)) {
      return []; // Retorna un arreglo vacío si depas no es un arreglo
    }
    return estacionamientos.map(estacionamiento => ({
      ...estacionamiento,
      fecha_registro_formato01: formatDate02(estacionamiento.timestamp_registro),
    }));
  };

  const estacionamientosFormateados = formatearEstacionamientos(estacionamientos);

  const handleFilterChange = (e) => {    
    setFiltro(e.target.value);
    
  };

  const estacionamientosFiltrados = Array.isArray(estacionamientosFormateados) ? estacionamientosFormateados.filter((estacionamiento) => {
    
    const filterLowerCase = filtro.toLowerCase();
    return estacionamiento.tipo.toString().includes(filterLowerCase) ||
        estacionamiento.numero.toLowerCase().includes(filterLowerCase) ||
        estacionamiento.nivel.toLowerCase().includes(filterLowerCase) ||
        estacionamiento.tipo_nivel.toLowerCase().includes(filterLowerCase) ||
        estacionamiento.area_ocupada.toString().toLowerCase().includes(filterLowerCase) ||
        estacionamiento.area_techada.toString().toLowerCase().includes(filterLowerCase) ||
        estacionamiento.fecha_registro_formato01.toString().includes(filterLowerCase)         
        
      ;
  })
  : [];

  if (fetchStatus  === 'loading') {
    return <div>Loading...</div>;
  }
/*
  if (fetchStatus  === 'failed') {
    return <div>Error: {error}</div>;
  }
*/
  return (    
    <div className="container">
        <h5>Estacionamientos</h5>
        <a className="btn green tooltipped" data-position="top" data-tooltip="Refrescar vista" onClick={() => handleRefresh()}>
              <i className="material-icons left">refresh</i>Refrescar
        </a>
        <a className="btn orange tooltipped" data-position="top" data-tooltip="Cargar Depas" onClick={() => navigate('/carga-estacionamientos')}>
              <i className="material-icons left">cloud_upload</i>Cargar Estacionamientos
        </a>        
        <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/edificio')}>
              <i className="material-icons left">arrow_back</i>
        </a>        
   
        <div className="col s12 m12 input-field">
        <label htmlFor="filtro_tabla_estacionamientos_cargados"><i className="material-icons">search</i> Filtrar</label>
            <input type="text" id="filtro_tabla_estacionamientos_cargados" 
             value={filtro}
          onChange={handleFilterChange} />           
        </div>
    <table className="striped responsive-table tabla_estacionamientos_cargados">
      <thead>
        <tr>
          <th>Edificio</th>
          <th>Tipo</th>
          <th>Estac.</th>
          <th>Nivel</th>
          <th>Tip_niv</th>
          <th>Area_ocupada</th>
          <th>Area_techada</th>                    
          <th>usuario</th>
          <th>Id. carga</th>
          <th>Registro</th>
        </tr>
      </thead>
      <tbody id="table-body-estacionamientos-cargados">                    
        {estacionamientosFiltrados.map((estacionamiento) => (
          <tr key={estacionamiento.id}>
            <td>{estacionamiento.codigo_edificio}</td>
            <td>{estacionamiento.tipo}</td>
            <td>{estacionamiento.numero}</td>
            <td>{estacionamiento.nivel}</td>
            <td>{estacionamiento.tipo_nivel}</td>
            <td>{estacionamiento.area_ocupada}</td>
            <td>{estacionamiento.area_techada}</td>
            <td>{estacionamiento.usuario_carga}</td>
            <td>{estacionamiento.uid_carga}</td>
            <td>{estacionamiento.fecha_registro_formato01}</td>
          </tr>
          ))}
      
      </tbody>
    </table>    
    </div>
  );
};

export default Estacionamientos_edificio;
