import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

export const crear_usuarios_residentes = createAsyncThunk('seguridad/crear_usuarios_residentes', async (jsonBodyRequest, thunkAPI) => {
  console.log("ejecutando thunk validar_token_reset con "+JSON.stringify(jsonBodyRequest));
  
  try { 
      //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
      //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');        
      const response = await apiClient.post('/usuarios/crear-usuarios-residentes-pendientes',jsonBodyRequest);
      
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      //console.log("12 response.status: "+response.status );
      //const resp_registro={};
      
        return response.data;
      
      //console.log("13 JSON.stringify(resp_registro) "+ JSON.stringify(resp_registro) );
      //return resp_registro;
    } catch (error) {
      console.log("JSON.stringify(error) "+JSON.stringify(error) );
      //console.log("JSON.stringify(error.response.data) "+JSON.stringify(error.response.data) );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});

export const password_reset = createAsyncThunk('seguridad/password_reset', async (codigo_usuario, thunkAPI) => {
    console.log("ejecutando thunk password_reset con "+codigo_usuario);
    
    try { 
        //console.log("ejecutando thunk codigo_usuario: "+codigo_usuario);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.post('/usuarios/password_reset',{
            codigo_usuario
        });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const respuesta = response.data;
            
        return respuesta ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const validar_token_reset = createAsyncThunk('seguridad/validar_token_reset', async (jsonBodyRequest, thunkAPI) => {
    console.log("ejecutando thunk validar_token_reset con "+JSON.stringify(jsonBodyRequest));
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');        
        const response = await apiClient.post('/usuarios/validar_token_reset',jsonBodyRequest);
        
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        //console.log("12 response.status: "+response.status );
        //const resp_registro={};
        
          return response.data;
        
        //console.log("13 JSON.stringify(resp_registro) "+ JSON.stringify(resp_registro) );
        //return resp_registro;
      } catch (error) {
        console.log("JSON.stringify(error) "+JSON.stringify(error) );
        //console.log("JSON.stringify(error.response.data) "+JSON.stringify(error.response.data) );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const update_password = createAsyncThunk('seguridad/update_password', async (jsonBodyRequest, thunkAPI) => {
    console.log("ejecutando thunk update_password con "+JSON.stringify(jsonBodyRequest));
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');        
        const response = await apiClient.post('/usuarios/update_password',jsonBodyRequest);
        
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        //console.log("12 response.status: "+response.status );
        //const resp_registro={};
        
          return response.data;
        
        //console.log("13 JSON.stringify(resp_registro) "+ JSON.stringify(resp_registro) );
        //return resp_registro;
      } catch (error) {
        console.log("JSON.stringify(error) "+JSON.stringify(error) );
        //console.log("JSON.stringify(error.response.data) "+JSON.stringify(error.response.data) );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
  
