// src/pages/UploadAndListDepartments.js
import React from 'react';
import Carga_estacionamientos from './carga-estacionamientosPage';
import Estacionamientos_cargados from './estacionamientos-cargadosPage';
import { useNavigate } from 'react-router-dom';

const Carga_lista_estacionamientos = () => {
  const navigate = useNavigate();

  
  return (
    <div className="container">
      <h4>Carga Estac.</h4>
      <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={() => navigate('/estacionamientos')}>
              <i className="material-icons left">arrow_back</i>
        </a>         
      <Carga_estacionamientos />
      <Estacionamientos_cargados />
    </div>
  );
};

export default Carga_lista_estacionamientos;