import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  get_activos_x_edificio,
  save_activo,
  update_activo,
  anular_activo,
} from "./activoThunk";
import { setActivoSeleccionada, clearActivoSeleccionada } from "./activoSlice";

const ActivosPage = ({ buildingId }) => {
  const dispatch = useDispatch();
  const activos = useSelector((state) => state.activo.activos);
  const loading = useSelector((state) => state.activo.status_get_activos_x_edificio === "loading");
  const [estado, setEstado] = useState("activo"); // Estado por defecto

  const [activoSeleccionado, setActivoSeleccionado] = useState(null);

  // Fetch de activos
  useEffect(() => {
    dispatch(get_activos_x_edificio({ buildingId, estado }));
  }, [dispatch, buildingId, estado]);

  // Manejar creación de activo
  const handleCreate = () => {
    const nuevoActivo = {
      nombre: "Nuevo Activo",
      categoria: "Acabados",
      subcategoria: "Pisos",
      tipo: "Porcelanato",
      ubicacion: "Sala",
      frecuencia_mantenimiento: ["mensual"],
      instrucciones_mantenimiento: "Limpiar con detergente suave.",
      buildingId,
    };
    dispatch(save_activo(nuevoActivo));
  };

  // Manejar edición de activo
  const handleEdit = (activo) => {
    const activoEditado = { ...activo, nombre: `${activo.nombre} (Editado)` };
    dispatch(update_activo({ idActivo: activo.id, activoData: activoEditado }));
  };

  // Manejar eliminación (anulación) de activo
  const handleAnular = (idActivo) => {
    const usuarioAnulacion = "admin"; // Este dato debe venir del usuario autenticado
    dispatch(anular_activo({ idActivo, usuarioAnulacion }));
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1>Gestión de Activos</h1>

      {/* Filtro por estado */}
      <div style={{ marginBottom: "20px" }}>
        <label>Estado: </label>
        <select
          value={estado}
          onChange={(e) => setEstado(e.target.value)}
          style={{ marginLeft: "10px" }}
        >
          <option value="activo">Activo</option>
          <option value="inactivo">Inactivo</option>
        </select>
      </div>

      {/* Listado de activos */}
      {loading ? (
        <p>Cargando activos...</p>
      ) : (
        <div>
          {activos.map((activo) => (
            <div
              key={activo.id}
              style={{
                padding: "10px",
                border: "1px solid #ccc",
                marginBottom: "10px",
                borderRadius: "5px",
              }}
            >
              <h2>{activo.nombre}</h2>
              <p>Categoría: {activo.categoria} / {activo.subcategoria}</p>
              <p>Ubicación: {activo.ubicacion}</p>
              <button
                onClick={() => handleEdit(activo)}
                style={{
                  marginRight: "10px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Editar
              </button>
              <button
                onClick={() => handleAnular(activo.id)}
                style={{
                  backgroundColor: "#dc3545",
                  color: "white",
                  border: "none",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Anular
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Botón para agregar nuevo activo */}
      <button
        onClick={handleCreate}
        style={{
          marginTop: "20px",
          padding: "10px 20px",
          backgroundColor: "#28a745",
          color: "white",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Agregar Activo
      </button>
    </div>
  );
};

export default ActivosPage;
