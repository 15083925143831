import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get_encuesta } from './encuestaThunk';
import { get_respuesta } from './respuestaThunk';
import './RespuestaFormPage.css';

const RespuestaFormPage = () => {
    const { id: idRespuesta } = useParams(); // Recibe idRespuesta desde los parámetros
    const dispatch = useDispatch();

    const [respuestas, setRespuestas] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const encuesta = useSelector((state) => state.encuesta.encuesta);
    const respuesta = useSelector((state) => state.respuesta.respuesta);

    useEffect(() => {
        if (idRespuesta) {
            // Obtener la respuesta usando el idRespuesta
            dispatch(get_respuesta(idRespuesta)).then((action) => {
                const fetchedRespuesta = action.payload;
                if (fetchedRespuesta?.idEncuesta) {
                    // Si se obtuvo la respuesta y tiene un idEncuesta, cargar la encuesta
                    dispatch(get_encuesta(fetchedRespuesta.idEncuesta));
                    setRespuestas(
                        fetchedRespuesta.respuestas.reduce((acc, item) => {
                            acc[item.preguntaId] = item.valor;
                            return acc;
                        }, {})
                    );
                }
                setIsLoading(false);
            });
        }
    }, [dispatch, idRespuesta]);

    if (isLoading) {
        return <p>Cargando respuestas...</p>;
    }

    if (!encuesta) {
        return <p>No se encontró la encuesta asociada.</p>;
    }

    return (
        <div className="container respuesta-form">
            <div className="card">
                <div className="card-content">
                    <h5>{encuesta.titulo}</h5>
                    <p>{encuesta.descripcion}</p>
                    {encuesta.preguntas.map((pregunta, index) => (
                        <div key={pregunta.id} className="pregunta-card">
                            <label className="pregunta-texto">
                                {index + 1}. {pregunta.texto}
                            </label>
                            {pregunta.tipoRespuesta === 'respuesta_corta' && (
                                <p className="respuesta-texto">
                                    Respuesta: {respuestas[pregunta.id] || 'No respondida'}
                                </p>
                            )}
                            {pregunta.tipoRespuesta === 'opcion_multiple' && (
                                <ul className="opciones-lista">
                                    {pregunta.opciones.map((opcion, opcionIndex) => (
                                        <li
                                            key={opcionIndex}
                                            className={`opcion-item ${
                                                respuestas[pregunta.id] === opcion ? 'opcion-seleccionada' : ''
                                            }`}
                                        >
                                            {opcion}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {pregunta.adjuntoUrl && (
                                <div className="adjunto-container">
                                    <img
                                        src={pregunta.adjuntoUrl}
                                        alt="Adjunto"
                                        className="adjunto-imagen"
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RespuestaFormPage;
