import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { get_encuesta } from './encuestaThunk';
import { save_respuesta } from './respuestaThunk';
import './EncuestaFormPage.css';

const EncuestaFormPage = () => {
    const { id: idEncuesta } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [respuestas, setRespuestas] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const encuesta = useSelector((state) => state.encuesta.encuesta);
    const usuario = useSelector((state) => state.auth.usuario);
    const edificio = useSelector((state) => state.edificio.edificio);
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    
    useEffect(() => {
        if (idEncuesta) {
            dispatch(get_encuesta(idEncuesta));
        }
    }, [dispatch, idEncuesta]);

    const handleInputChange = (preguntaId, valor) => {
        setRespuestas({ ...respuestas, [preguntaId]: valor });
    };

    const handleSubmit = () => {
        if (!encuesta.preguntas.every((pregunta) => respuestas[pregunta.id])) {
            alert('Por favor, responde todas las preguntas antes de enviar.');
            return;
        }
        setIsSubmitting(true);
        const payload = {
            idEncuesta,
            numero_encuesta: encuesta.numero_encuesta,
            titulo: encuesta.titulo,
            codigo_usuario: usuario.codigo_usuario,
            buildingId: edificio.id,
            codigo_unidad_facturacion: unidad_facturacionSeleccionado?.codigo_unidad_facturacion,
            respuestas: Object.entries(respuestas).map(([preguntaId, valor]) => ({
                preguntaId,
                valor,
            })),
        };
        dispatch(save_respuesta(payload)).then(() => {
            setIsSubmitting(false);
            navigate('/mis-encuestas');
        });
    };

    if (!encuesta) {
        return <p>Cargando encuesta...</p>;
    }

    return (
        <div className="container encuesta-form">
            <div className="card">
                <div className="card-content">
                    <h5>{encuesta.titulo}</h5>
                    <p>{encuesta.descripcion}</p>
                    {encuesta.preguntas.map((pregunta, index) => (
                        <div key={pregunta.id} className="pregunta-card">
                            <label className="pregunta-texto">
                                {index + 1}. {pregunta.texto}
                                {pregunta.esObligatoria && <span style={{ color: 'red' }}> *</span>}
                            </label>
                            {pregunta.tipoRespuesta === 'respuesta_corta' && (
                                <input
                                    type="text"
                                    className="respuesta-input"
                                    value={respuestas[pregunta.id] || ''}
                                    onChange={(e) => handleInputChange(pregunta.id, e.target.value)}
                                    placeholder="Escribe tu respuesta"
                                />
                            )}
                            {pregunta.tipoRespuesta === 'opcion_multiple' && (
                                <ul className="opciones-lista">
                                    {pregunta.opciones.map((opcion, opcionIndex) => (
                                        <li key={opcionIndex} className="opcion-item">
                                            <label>
                                                <input
                                                    type="radio"
                                                    name={`pregunta_${pregunta.id}`}
                                                    value={opcion}
                                                    checked={respuestas[pregunta.id] === opcion}
                                                    onChange={(e) =>
                                                        handleInputChange(pregunta.id, e.target.value)
                                                    }
                                                />
                                                <span>{opcion}</span>
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {pregunta.adjuntoUrl && (
                                <div className="adjunto-container">
                                    <img
                                        src={pregunta.adjuntoUrl}
                                        alt="Adjunto"
                                        className="adjunto-imagen"
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                    <button
                        className="btn-carga-personas blue"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Enviando...' : 'Enviar Respuestas'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EncuestaFormPage;
