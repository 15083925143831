//import { storage } from '../firebase'; 
//import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

export const uploadImageToStorage = async (file, path) => {
    const storage = getStorage();
    const storageRef = ref(storage, `${path}/${file.name}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
};
