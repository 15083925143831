import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

export const get_planillas_recibos = createAsyncThunk('planillas_recibos/get_planillas_recibos', async (buildingId, thunkAPI) => {
    //console.log("ejecutando thunk get_planillas_recibos con "+buildingId);
    
    try { 
        //console.log("ejecutando thunk buildingId: "+buildingId);
        const response = await apiClient.get(`/edificios/${buildingId}/planillas-recibos`);
       
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const planillas_recibos = response.data;
            
        return planillas_recibos ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
