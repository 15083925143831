import { createSlice } from '@reduxjs/toolkit';
import { get_lead, update_lead, save_lead, anular_lead, get_lead_x_publicacion_comprador, get_leads_x_vendedor, agregar_mensaje } from './leadThunk';

const leadSlice = createSlice({
  name: 'lead',
  initialState: {
    lead: null,
    leads: [],
    leadSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_lead_x_publicacion_comprador: 'idle',
    error_get_lead_x_publicacion_comprador: null,              
    status_agregar_mensaje: 'idle',
    error_agregar_mensaje: null,    
    status_get_leads_x_vendedor: 'idle',
    error_get_leads_x_vendedor: null,
    status_update_lead: 'idle',
    error_update_lead: null,    
    status_save_lead: 'idle',
    error_save_lead: null,
    status_anular_lead: 'idle',
    error_anular_lead: null,
  },
  reducers: {
    setLeadSeleccionada: (state, action) => {
      state.leadSeleccionada = action.payload;
      
    },
    clearLeadSeleccionada(state) {
      state.leadSeleccionada = null;
      state.lead = null;
    },
    resetStateGetLeads_x_publicacion: () => {
        return {
            status_get_lead_x_publicacion_comprador : 'idle',
        };
      },
      resetStateGetLeads_x_depa: () => {
        return {
            status_get_leads_x_vendedor : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_lead.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_lead.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lead = action.payload;

      })
      .addCase(get_lead.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      
      .addCase(agregar_mensaje.pending, (state) => {
        state.status_agregar_mensaje = 'loading';
      })
      .addCase(agregar_mensaje.fulfilled, (state, action) => {
        state.status_agregar_mensaje = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lead = action.payload.lead;

      })
      .addCase(agregar_mensaje.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_agregar_mensaje = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_uagregar_mensaje = action.payload || 'Failed to fetch building';
      })

      .addCase(update_lead.pending, (state) => {
        state.status_update_lead = 'loading';
      })
      .addCase(update_lead.fulfilled, (state, action) => {
        state.status_update_lead = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lead = action.payload.lead;

      })
      .addCase(update_lead.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_lead = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_lead = action.payload || 'Failed to fetch building';
      })
      .addCase(save_lead.pending, (state) => {
        state.status_save_lead = 'loading';
      })
      .addCase(save_lead.fulfilled, (state, action) => {
        state.status_save_lead = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lead = action.payload.lead;

      })
      .addCase(save_lead.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_lead = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_lead = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_lead.pending, (state) => {
        state.status_save_lead = 'loading';
      })
      .addCase(anular_lead.fulfilled, (state, action) => {
        state.status_anular_lead = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lead = action.payload.lead;

      })
      .addCase(anular_lead.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_lead = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_lead = action.payload || 'Failed to fetch building';
      })
      
      .addCase(get_leads_x_vendedor.pending, (state) => {
        state.status_get_leads_x_vendedor = 'loading';
        
      })
      .addCase(get_leads_x_vendedor.fulfilled, (state, action) => {
        state.status_get_leads_x_vendedor = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_lead_x_publicacion_comprador FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.leads = action.payload;

      })
      .addCase(get_leads_x_vendedor.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_leads_x_vendedor = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_leads_x_vendedor = action.payload || 'Failed to fetch building';
        state.leads =[];
      })
      
      .addCase(get_lead_x_publicacion_comprador.pending, (state) => {
        state.status_get_lead_x_publicacion_comprador = 'loading';
        
      })
      .addCase(get_lead_x_publicacion_comprador.fulfilled, (state, action) => {
        state.status_get_lead_x_publicacion_comprador = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_lead_x_publicacion_comprador FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.lead = action.payload;

      })
      .addCase(get_lead_x_publicacion_comprador.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_lead_x_publicacion_comprador = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_lead_x_publicacion_comprador = action.payload || 'Failed to fetch building';
        //state.leads =null;
      })
  },
});

export const { setLeadSeleccionada, clearLeadSeleccionada } = leadSlice.actions;
export const selectLeadsById = (state, idLead) =>
    state.lead.leads.find((lead) => lead.id === idLead);
export const { resetStateGetLeads_x_publicacion } = leadSlice.actions;
export const { resetStateGetLeads_x_depa } = leadSlice.actions;
export default leadSlice.reducer;
