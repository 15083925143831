import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetCategorias_requerimientos } from './categoria_requerimientoSlice';


export const update_categoria_requerimiento = createAsyncThunk('categoria_requerimiento/update_categoria_requerimiento', async ({ idCategoria_requerimiento, categoria_requerimientoData }, thunkAPI) => {
  //console.log("ejecutando thunk update_categoria_requerimiento con idCategoria_requerimiento: " + idCategoria_requerimiento);

  try {

    const response = await apiClient.put(`/categorias-requerimientos/${idCategoria_requerimiento}`, categoria_requerimientoData);

    const categoria_requerimiento = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetCategorias_requerimientos);// Resetear el estado para refetch de categoria_requerimientos

    return categoria_requerimiento;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_categoria_requerimiento = createAsyncThunk('categoria_requerimiento/save_categoria_requerimiento', async (categoria_requerimientoData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/categorias-requerimientos`, categoria_requerimientoData);

    const categoria_requerimiento = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetCategorias_requerimientos);// Resetear el estado para refetch de categoria_requerimientos

    return categoria_requerimiento;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_categoria_requerimiento = createAsyncThunk('categoria_requerimiento/anular_categoria_requerimiento', async ({ idCategoria_requerimiento, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_categoria_requerimiento con idCategoria_requerimiento: " + idCategoria_requerimiento);

  try {

    const response = await apiClient.patch(`/categorias-requerimientos/${idCategoria_requerimiento}/anular`, { usuarioAnulacion });

    const categoria_requerimiento = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetCategorias_requerimientos);// Resetear el estado para refetch de categoria_requerimientos

    return categoria_requerimiento;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_categorias_requerimientos = createAsyncThunk('categoria_requerimiento/get_categorias_requerimientos', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_reservas con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/categorias-requerimientos',{
        params: { 
          buildingId,           
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const categoria_requerimientos = response.data;
          
      return categoria_requerimientos ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_categoria_requerimiento = createAsyncThunk('categoria_requerimiento/get_categoria_requerimiento', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_categoria_requerimiento con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/categoria_requerimientos/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const categoria_requerimiento = response.data;

    return categoria_requerimiento;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_categoria_requerimientos_x_unid_fact = createAsyncThunk('categoria_requerimiento/get_categoria_requerimientos', async ({buildingId, codigo_unidad_facturacion}, thunkAPI) => {
  console.log("ejecutando thunk get_categoria_requerimientos con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/categoria_requerimientos',{
        params: { 
          buildingId,
          codigo_unidad_facturacion
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const categoria_requerimientos = response.data;
          
      return categoria_requerimientos ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});