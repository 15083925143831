// src/components/ReservaFormPage.js 

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    get_reserva,
    update_reserva,
    save_reserva,
    anular_reserva,
    validate_turno,
    update_constancia_reserva
} from './reservaThunk';
import { clearReservaSeleccionada, clearTurnoValidation } from '../mis-areas-comunes/reservaSlice';
import { get_areas_comunes } from '../mis-areas-comunes/area_comunThunk';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './reservaFormPage.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import Cleave from 'cleave.js/react';
import { parseFromYYYYMM, formatPeriodo } from '../../utils/util';
import { TIPO_AREA_COMUN_SUM } from '../../constants/constants';
import { storage } from '../../firebase';
import { ref, uploadBytes, getDownloadURL, connectStorageEmulator } from 'firebase/storage';

const ReservaForm = ({ tipo_area_comun, mode }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    registerLocale('es', es);
    const { id: idReserva } = useParams();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const edificioSeleccionado = useSelector(state => state.edificio.edificio);
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    const codigo_unidad_facturacion = unidad_facturacionSeleccionado?.codigo_unidad_facturacion || '';
    const buildingId = edificioSeleccionado?.id || '';
    
    // Estados para determinar el modo de edición/creación
    const [isEditing, setIsEditing] = useState(mode === 'edit');
    const [isCreating, setIsCreating] = useState(mode === 'create');

    // Función auxiliar para determinar si estamos en modo "edit" o "create"
    const isInEditOrCreateMode = () => isEditing || isCreating;

    // Obtener áreas comunes de tipo SUM desde Redux
    const areasComun = useSelector((state) => state.area_comun.areas_comunes);
    const reserva = useSelector((state) => state.reserva.reserva);
    const {
        status_save_reserva,
        error_save_reserva,
        status_update_reserva,
        error_update_reserva,
        status_anular_reserva,
        error_anular_reserva,
        status_validate_turno,
        error_validate_turno,
        turno_available,
        turno_message,
        error_update_constancia_reserva, 
        status_update_constancia_reserva 
    } = useSelector((state) => state.reserva);

    const usuario = useSelector((state) => state.auth.usuario);
    const codigo_usuario = usuario ? usuario.id : null;

    // Acceder a los datos pasados desde Calendario_reservasPage.js
    const { area_comun, fecha_reserva, turno } = location.state || {};

    console.log("Datos recibidos en ReservaForm:", location.state);
    const [formData, setFormData] = useState({
        tipo_area_comun: tipo_area_comun  || '',
        area_comun: area_comun || '',
        fecha_reserva: fecha_reserva ? new Date(fecha_reserva) : new Date(),
        idturno: turno ? turno.idturno : '',
        turno: turno ? turno.descripcion : '',
        turno_hora_inicio: turno ? turno.turno_hora_inicio : '',
        turno_hora_fin: turno ? turno.turno_hora_fin : '',
        costo: turno ? turno.costo : 0,
        garantia: turno ? turno.garantia : 0,
        aforo: turno ? turno.aforo : 0,
    });

    const [turnosDisponibles, setTurnosDisponibles] = useState([]);

    // Estados para manejo de carga y errores en modo "view" y "edit"
    const [loadingReserva, setLoadingReserva] = useState(false);
    const [errorReserva, setErrorReserva] = useState('');

    // Estado para manejar la imagen de constancia de pago
    const [constanciaPago, setConstanciaPago] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [uploadErrorLocal, setUploadErrorLocal] = useState('');
    const fileInputRef = useRef(null); // Referencia al input de archivo

    // Verificar autenticación
    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    // Función para formatear fechas locales
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (`0${date.getMonth() + 1}`).slice(-2); // getMonth() es 0-based
        const day = (`0${date.getDate()}`).slice(-2);
        return `${year}-${month}-${day}`;
    };

    /**
     * Parsea una cadena de fecha 'YYYY-MM-DD' como fecha local.
     * @param {string} dateString - La cadena de fecha en formato 'YYYY-MM-DD'.
     * @returns {Date} - Objeto Date basado en la hora local.
     */
    const parseLocalDate = (dateString) => {
        const [year, month, day] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // Meses en JavaScript son 0-based
    };

    // Obtener áreas comunes al montar el componente
    useEffect(() => {
        // Obtener todas las áreas comunes de tipo SUM al cargar la página
        dispatch(get_areas_comunes({ buildingId, tipo_area_comun: tipo_area_comun }));
    }, [buildingId, tipo_area_comun, dispatch]);

    // Limpiar reserva seleccionada y validación al crear
    useEffect(() => {
        if (isCreating) {
            dispatch(clearReservaSeleccionada());
            dispatch(clearTurnoValidation());
        }
    }, [isCreating, dispatch]);

    // Manejar el modo "view" y "edit" para obtener y poblar los datos de la reserva
    useEffect(() => {
        console.log("ANALIZANDO mode " + mode + " idReserva: " + idReserva);
        if ((mode === 'view' || mode === 'edit') && idReserva) { // Añadido modo 'edit'
            setLoadingReserva(true);
            dispatch(get_reserva(idReserva))
                .unwrap()
                .then((res) => {
                    //console.log("==> Trajo reserva y hara setFormData con JSON.stringify(res): " + JSON.stringify(res));
                    console.log("==> res.area_comun: " + res.area_comun);
                    console.log("==> JSON.stringify(areasComun): " + JSON.stringify(areasComun));

                    // Encontrar el objeto de area_comun en areasComun basado en el nombre
                    const selectedArea = areasComun.find(area => area.nombre === res.area_comun);
                    console.log("==> JSON.stringify(selectedArea): " + JSON.stringify(selectedArea));

                    if (selectedArea) {
                        setTurnosDisponibles(selectedArea.turnos);

                        setFormData({
                            tipo_area_comun: res.tipo_area_comun,
                            area_comun: res.area_comun, // ya es una cadena
                            fecha_reserva: parseLocalDate(res.fecha_reserva), // Usar parseLocalDate
                            idturno: res.idturno,
                            turno: res.turno.descripcion,
                            turno_hora_inicio: res.turno.turno_hora_inicio,
                            turno_hora_fin: res.turno.turno_hora_fin,
                            costo: res.turno.costo,
                            garantia: res.turno.garantia,
                            aforo: res.turno.aforo,
                        });
                    } else {
                        //console.error("Área Común no encontrada en areasComun");
                        //M.toast({ html: 'Área Común no encontrada.', classes: 'red' });
                    }

                    setLoadingReserva(false);
                })
                .catch((error) => {
                    console.error("Error al obtener la reserva:", error);
                    setErrorReserva(error.message || 'Error al obtener la reserva.');
                    setLoadingReserva(false);
                });
        }
    }, [mode, idReserva, dispatch, areasComun]);

    // Inicializar formData basado en el modo y la presencia de location.state
    useEffect(() => {
        if (mode === 'create') {
            if (area_comun && fecha_reserva && turno) {
                // **Escenario 2:** Modo "create" con location.state
                console.log("Escenario 2: Modo 'create' con location.state");
                console.log("useEffect interes-turno.descripcion: " + turno.descripcion);

                // Encontrar el área común seleccionada
                const selectedArea = areasComun.find(area => area.nombre === area_comun);
                console.log("useEffect interes-selectedArea:", selectedArea);

                if (selectedArea) {
                    setTurnosDisponibles(selectedArea.turnos);

                    // Encontrar el turno seleccionado dentro de las opciones disponibles
                    const selectedTurno = selectedArea.turnos.find(t => t.descripcion === turno.descripcion);
                    console.log("useEffect interes-selectedTurno:", selectedTurno);

                    if (selectedTurno) {
                        // Actualizar formData con el turno seleccionado
                        setFormData(prev => ({
                            ...prev,
                            tipo_area_comun: selectedArea.tipo_area_comun,
                            area_comun: area_comun,
                            fecha_reserva: new Date(fecha_reserva),
                            idturno: selectedTurno.idturno,
                            turno: selectedTurno.descripcion,
                            turno_hora_inicio: selectedTurno.turno_hora_inicio,
                            turno_hora_fin: selectedTurno.turno_hora_fin,
                            costo: selectedTurno.costo,
                            garantia: selectedTurno.garantia,
                            aforo: selectedTurno.aforo,
                        }));
                    } else {
                        // Si el turno recibido no existe en las opciones disponibles, resetear los campos relacionados
                        setFormData(prev => ({
                            ...prev,
                            tipo_area_comun: selectedArea.tipo_area_comun,
                            area_comun: area_comun,
                            fecha_reserva: new Date(fecha_reserva),
                            idturno: '',
                            turno: '',
                            turno_hora_inicio: '',
                            turno_hora_fin: '',
                            costo: 0,
                            garantia: 0,
                            aforo: 0,
                        }));
                    }
                }
            } else {
                // **Escenario 1:** Modo "create" sin location.state
                console.log("Escenario 1: Modo 'create' sin location.state");
                if (areasComun.length > 0) {
                    const firstArea = areasComun[0];
                    setFormData(prev => ({
                        ...prev,
                        tipo_area_comun: firstArea.tipo_area_comun,
                        area_comun: firstArea.nombre,
                        fecha_reserva: new Date(),
                        idturno: firstArea.turnos[0]?.idturno || '',
                        turno: firstArea.turnos[0]?.descripcion || '',
                        turno_hora_inicio: firstArea.turnos[0]?.turno_hora_inicio || '',
                        turno_hora_fin: firstArea.turnos[0]?.turno_hora_fin || '',
                        costo: firstArea.turnos[0]?.costo || 0,
                        garantia: firstArea.turnos[0]?.garantia || 0,
                        aforo: firstArea.turnos[0]?.aforo || 0,
                    }));
                    setTurnosDisponibles(firstArea.turnos);
                }
            }
        } else if (mode === 'edit') { // Añadido modo 'edit'
            // **Escenario 3:** Modo "edit"
            console.log("Escenario 3: Modo 'edit'");
            if (reserva) {
                setFormData({
                    tipo_area_comun: reserva.tipo_area_comun,
                    area_comun: reserva.area_comun.nombre,
                    fecha_reserva: new Date(reserva.fecha_reserva),
                    idturno: reserva.idturno,
                    turno: reserva.turno.descripcion,
                    turno_hora_inicio: reserva.turno.turno_hora_inicio,
                    turno_hora_fin: reserva.turno.turno_hora_fin,
                    costo: reserva.turno.costo,
                    garantia: reserva.turno.garantia,
                    aforo: reserva.turno.aforo,
                });
                setTurnosDisponibles(reserva.area_comun.turnos);
            }
        }
    }, [mode, area_comun, fecha_reserva, turno, areasComun, reserva]);

    // Escuchar cambios en formData para realizar acciones dependientes (si es necesario)
    useEffect(() => {
        console.log("formData actualizado:", JSON.stringify(formData));

        // Inicializar los selects de Materialize
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
        M.updateTextFields();
    }, [formData]);

    // Verificar la coincidencia de valores
    useEffect(() => {
        console.log("Valor de formData.turno:", formData.turno);
        console.log("Opciones disponibles:", turnosDisponibles.map(t => t.descripcion));
    }, [formData.turno, turnosDisponibles]);

    // Manejar cambios en los inputs
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleDateChange = (date) => {
        setFormData(prev => ({ ...prev, fecha_reserva: date }));
    };

    const handleAreaComunChange = (e) => {
        const selectedAreaNombre = e.target.value;
        setFormData(prev => ({ ...prev, area_comun: selectedAreaNombre, turno: '', idturno: '' })); // Resetear turno e idturno
        console.log("selectedAreaNombre: " + selectedAreaNombre);

        // Encontrar el área seleccionada para obtener sus turnos
        const selectedArea = areasComun.find(area => area.nombre === selectedAreaNombre);

        if (selectedArea) {
            console.log("Area Encontrada:", selectedArea);

            // Establecer las opciones disponibles para el turno
            setTurnosDisponibles(selectedArea.turnos);

            if (selectedArea.turnos.length > 0) {
                const firstTurno = selectedArea.turnos[0];
                setFormData(prev => ({
                    ...prev,
                    tipo_area_comun: selectedArea.tipo_area_comun,
                    idturno: firstTurno.idturno,
                    turno: firstTurno.descripcion,
                    turno_hora_inicio: firstTurno.turno_hora_inicio,
                    turno_hora_fin: firstTurno.turno_hora_fin,
                    costo: firstTurno.costo,
                    garantia: firstTurno.garantia,
                    aforo: firstTurno.aforo,
                }));
            } else {
                // Si no hay turnos disponibles, resetear los campos relacionados
                setFormData(prev => ({
                    ...prev,
                    idturno: '',
                    turno: '',
                    turno_hora_inicio: '',
                    turno_hora_fin: '',
                    costo: 0,
                    garantia: 0,
                    aforo: 0,
                }));
            }
        } else {
            console.log("Área no encontrada o no existe");
            setTurnosDisponibles([]);
            setFormData(prev => ({
                ...prev,
                idturno: '',
                turno: '',
                turno_hora_inicio: '',
                turno_hora_fin: '',
                costo: 0,
                garantia: 0,
                aforo: 0,
            }));
        }
    };

    // **Actualización: Cambiar el manejador de turno para usar idturno**
    const handleIdturnoChange = (e) => {
        const selectedIdturno = e.target.value;
        setFormData(prev => ({ ...prev, idturno: selectedIdturno }));

        console.log("selectedIdturno: " + selectedIdturno);

        // Encontrar el turno seleccionado para obtener sus atributos
        const selectedTurno = turnosDisponibles.find(turno => turno.idturno === selectedIdturno);

        if (selectedTurno) {
            setFormData(prev => ({
                ...prev,
                tipo_area_comun: tipo_area_comun,
                turno: selectedTurno.descripcion,
                turno_hora_inicio: selectedTurno.turno_hora_inicio,
                turno_hora_fin: selectedTurno.turno_hora_fin,
                costo: selectedTurno.costo,
                garantia: selectedTurno.garantia,
                aforo: selectedTurno.aforo,
            }));
            dispatch(clearTurnoValidation());
        } else {
            console.log("Turno no encontrado o no existe");
            setFormData(prev => ({
                ...prev,
                turno: '',
                turno_hora_inicio: '',
                turno_hora_fin: '',
                costo: 0,
                garantia: 0,
                aforo: 0,
            }));
        }
    };

    const formatNumber = (number) => {
        if (isNaN(number)) return '';
        return new Intl.NumberFormat('es-PE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(number);
    };

    const handleSave = async () => {
        // Validaciones básicas
        if (!formData.area_comun) {
            M.toast({ html: 'Por favor, selecciona un Área Común.', classes: 'red' });
            return;
        }

        if (!formData.fecha_reserva) {
            M.toast({ html: 'Por favor, selecciona una Fecha de Reserva.', classes: 'red' });
            return;
        }

        if (!formData.turno_hora_inicio || !formData.turno_hora_fin) {
            M.toast({ html: 'El horario seleccionado no es válido.', classes: 'red' });
            return;
        }

        if (turno_available === false) {
            M.toast({ html: turno_message || 'El turno seleccionado no está disponible.', classes: 'red' });
            return;
        }

        // Preparar los datos para enviar al backend
        const dataToSave = {
            tipo_area_comun: tipo_area_comun,
            area_comun: formData.area_comun,
            fecha_reserva: formatDate(formData.fecha_reserva), // Usar la función formatDate
            idturno: formData.idturno,
            turno: {
                idturno: formData.idturno,
                descripcion: formData.turno,
                turno_hora_inicio: formData.turno_hora_inicio,
                turno_hora_fin: formData.turno_hora_fin,
                costo: formData.costo,
                garantia: formData.garantia,
                aforo: formData.aforo,
            },
            usuarioRegistrador: codigo_usuario,
            buildingId: buildingId,
            codigo_unidad_facturacion: codigo_unidad_facturacion,
        };
        
        console.log("dataToSave:", JSON.stringify(dataToSave));
        
        if (isEditing && mode !== 'create') {
            // Actualizar reserva
            const resultAction = await dispatch(update_reserva({ idReserva, reservaData: dataToSave }));
            if (update_reserva.fulfilled.match(resultAction)) {
                M.toast({ html: 'Reserva actualizada exitosamente.', classes: 'green' });
            } else {
                const errorMessage = error_update_reserva || 'Ocurrió un error al actualizar la reserva.';
                M.toast({ html: errorMessage, classes: 'red' });
                return;
            }
        } else if (isCreating) {
            // Crear nueva reserva
            const resultAction = await dispatch(save_reserva(dataToSave));
            if (save_reserva.fulfilled.match(resultAction)) {
                M.toast({ html: 'Reserva creada exitosamente.', classes: 'green' });
            } else {
                const errorMessage = error_save_reserva || 'Ocurrió un error al guardar la reserva.';
                M.toast({ html: errorMessage, classes: 'red' });
                return;
            }
        }

        dispatch(clearReservaSeleccionada());
        handleRetornar();       
        
    };

    const handleValidateTurno = async () => {
        // Validar que todos los campos necesarios estén seleccionados
        if (!formData.area_comun) {
            M.toast({ html: 'Por favor, selecciona un Área Común.', classes: 'red' });
            return;
        }

        if (!formData.fecha_reserva) {
            M.toast({ html: 'Por favor, selecciona una Fecha de Reserva.', classes: 'red' });
            return;
        }

        if (!formData.turno_hora_inicio || !formData.turno_hora_fin) {
            M.toast({ html: 'El horario seleccionado no es válido.', classes: 'red' });
            return;
        }

        // Despachar el Thunk para validar el turno
        dispatch(validate_turno({
            buildingId,
            area_comun: formData.area_comun,
            fecha_reserva: formatDate(formData.fecha_reserva), // Usar formatDate para enviar la fecha
            idturno: formData.idturno,
            turno_hora_inicio: formData.turno_hora_inicio,
            turno_hora_fin: formData.turno_hora_fin
        }));
    };

    const handleEdit = () => {
        setIsEditing(true);
    };
  
    const handleAnular = () => {
        if (window.confirm("¿Estás seguro de que quieres anular esta reserva? Esta acción no se puede deshacer.")) {
            dispatch(anular_reserva({ idReserva, usuarioAnulacion: codigo_usuario }))
                .then((resultAction) => {
                    if (anular_reserva.fulfilled.match(resultAction)) {
                        M.toast({ html: 'Reserva anulada correctamente', classes: 'rounded red' });
                        handleRetornar();                      
                
                    } else {
                        const errorMessage = error_anular_reserva || 'Ocurrió un error al anular la reserva.';
                        M.toast({ html: errorMessage, classes: 'red' });
                    }
                });
        }
    };

    const handleRetornar = () => {
        dispatch(clearReservaSeleccionada());        
        if(tipo_area_comun==="SUM"){
            navigate('/reservas-sum')
        }else{
            if(tipo_area_comun==="PARRILLA"){
                navigate('/reservas-parrilla')
            }else{
                if(tipo_area_comun==="GIMNASIO"){
                    navigate('/reservas-gym')
                }else{
                    if(tipo_area_comun==="YOGA"){
                        navigate('/reservas-yoga')
                    }else{
                        navigate('/');
                    }
                    
                }
                
            }
        
        }        
    };

    const handleCancel = () => {
        setIsEditing(false);
        dispatch(clearReservaSeleccionada());
        if (mode === 'create') {
            handleRetornar();
        }
    };

    const handleOpenCalendar = () => {
        navigate('/calendario-reservas', {});
    };

    // Nueva función para manejar el clic en un turno disponible
    const handleTurnoClick = (turno, date) => {
        // Verificar que el turno esté disponible
        if (!turno.disponible) return;

        console.log("handleTurnoClick fechaSeleccionada:", date); // Añadido

        // Preparar los datos para la navegación
        const dataToPass = {
            area_comun: formData.area_comun,
            fecha_reserva: date,
            turno: turno
        };

        console.log("handleTurnoClick Navegando con datos:", JSON.stringify(dataToPass)); // Añadido

        // Navegar a la página de creación de reserva con los datos preseleccionados
        navigate('/reserva-sum/create', { state: dataToPass });
    };

    // Eliminado: const renderTileContent = ({ date, view }) => { ... }

    const handleDayClick = (date) => {
        // Verificar si la fecha seleccionada es válida (hoy o futuro)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Establecer la hora a medianoche para comparar solo fechas

        const selectedDate = new Date(date);
        selectedDate.setHours(0, 0, 0, 0);

        if (selectedDate < today) {
            // Fecha pasada, no hacer nada o mostrar un mensaje
            M.toast({ html: 'No puedes seleccionar una fecha pasada.', classes: 'red' });
            return;
        }

        const dataToPass = { selectedDate: date, selectedArea: formData.area_comun };
        console.log("Navegando con datos de día:", dataToPass); // Añadido
        navigate('/reserva-sum/create', { state: dataToPass });
    };

    // **Nueva Función: Manejar la selección de archivo**
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            // Validar el tipo de archivo (imagen)
            if (!file.type.startsWith('image/')) {
                M.toast({ html: 'Por favor, selecciona un archivo de imagen.', classes: 'red' });
                return;
            }
            setConstanciaPago(file);
        }
    };

    // **Nueva Función: Manejar la subida de la constancia de pago a Firebase Storage**
    const handleUploadConstancia = async () => {
        if (!constanciaPago) {
            M.toast({ html: 'Por favor, selecciona una imagen para la constancia de pago.', classes: 'red' });
            return;
        }

        setUploading(true);
        setUploadErrorLocal('');

        try {
            // Conectar al emulador de Storage si se está ejecutando en local
            if (window.location.hostname === 'localhost') {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            // Crear una referencia al archivo en Firebase Storage
            const storageRefPath = `cargaReservas/${idReserva}/${constanciaPago.name}_${Date.now()}`;
            const constanciaRef = ref(storage, storageRefPath);

            // Subir el archivo
            const snapshot = await uploadBytes(constanciaRef, constanciaPago);

            // Obtener la URL de descarga
            const downloadURL = await getDownloadURL(constanciaRef);

            console.log("Constancia de pago subida. URL:", downloadURL);

            // Preparar los datos para enviar al backend
            const dataToSave = {
                usuarioRegistrador: codigo_usuario,
                constancia_pago_url: downloadURL,
                // Asegúrate de que el backend también registre la fecha de carga
            };
            const resultAction = await dispatch(update_constancia_reserva({ idReserva, reservaData: dataToSave }));
            if (update_constancia_reserva.fulfilled.match(resultAction)) {            
                M.toast({ html: 'Constancia de pago subida exitosamente.', classes: 'green' });
                // Opcional: Refrescar los detalles de la reserva si no se actualizan automáticamente
                dispatch(get_reserva(idReserva));
            } else {
                const errorMessage = error_update_constancia_reserva || 'Ocurrió un error al actualizar la constancia.';
                M.toast({ html: errorMessage, classes: 'red' });
                return;
            }

            // Resetear el archivo seleccionado
            setConstanciaPago(null);
        } catch (error) {
            console.error("Error al subir la constancia de pago:", error);
            setUploadErrorLocal(error.message || 'Ocurrió un error al subir la constancia de pago.');
            M.toast({ html: uploadErrorLocal || 'Error al subir la constancia de pago.', classes: 'red' });
        } finally {
            setUploading(false);
        }
    };

    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    <div className="card-action">
                        {isInEditOrCreateMode() ? (
                            <>
                                <button
                                    type="button"
                                    className="btn green tooltipped"
                                    data-position="top"
                                    data-tooltip="Guardar cambios"
                                    onClick={handleSave}
                                >
                                    <i className="material-icons left">save</i>Guardar
                                </button>
                                <button
                                    type="button"
                                    className="btn red tooltipped"
                                    data-position="top"
                                    data-tooltip="Cancelar edición"
                                    onClick={handleCancel}
                                >
                                    <i className="material-icons left">cancel</i>Cancelar
                                </button>
                                <button
                                    type="button"
                                    className="btn purple tooltipped"
                                    data-position="top"
                                    data-tooltip="Ver Calendario"
                                    onClick={handleOpenCalendar}
                                >
                                    <i className="material-icons left">calendar_today</i>Calendario
                                </button>
                            </>
                        ) : (
                            <>
                                {mode === 'view' && (
                                    <>
                                        <button
                                            type="button"
                                            className="btn green tooltipped"
                                            data-position="top"
                                            data-tooltip="Editar detalles"
                                            onClick={handleEdit}
                                        >
                                            <i className="material-icons left">edit</i>Editar
                                        </button>
                                        <button
                                            type="button"
                                            className="btn orange tooltipped"
                                            data-tooltip="Anular Reserva"
                                            onClick={handleAnular}
                                        >
                                            <i className="material-icons left">delete</i>Anular
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                        <button
                            type="button"
                            className="btn blue tooltipped"
                            data-position="top"
                            data-tooltip="Volver"
                            onClick={handleRetornar}
                        >
                            <i className="material-icons left">arrow_back</i>Volver
                        </button>
                    </div>

                    <span className="card-title">
                        {mode === 'create'
                            ? 'Crear Reserva ' + tipo_area_comun
                            : mode === 'edit'
                            ? 'Editar Reserva '+ tipo_area_comun
                            : mode === 'view'
                            ? 'Detalles de la Reserva'
                            : 'Detalles de la Reserva'}
                    </span>

                    {/* Mostrar indicadores de carga o error en modo "view" o "edit" */}
                    {(mode === 'view' || mode === 'edit') && loadingReserva && (
                        <div className="row">
                            <div className="col s12 center-align">
                                <div className="preloader-wrapper small active">
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="gap-patch">
                                            <div className="circle"></div>
                                        </div>
                                        <div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                                <p>Cargando detalles de la reserva...</p>
                            </div>
                        </div>
                    )}

                    {(mode === 'view' || mode === 'edit') && errorReserva && (
                        <div className="row">
                            <div className="col s12 center-align">
                                <span className="red-text">Error: {errorReserva}</span>
                            </div>
                        </div>
                    )}

                    {/* Mostrar el formulario solo si no está cargando en modo "view" o "edit" */}
                    {!(mode === 'view' && loadingReserva) && !(mode === 'edit' && loadingReserva) && (
                        <form onSubmit={e => e.preventDefault()}>
                            {/* Primera fila: Área Común y Fecha de Reserva con Botón Validar Turno */}
                            <div className="row">
                                {/* Área Común */}
                                <div className="input-field col s12 m3">
                                    <select
                                        name="area_comun"
                                        value={formData.area_comun}
                                        onChange={handleAreaComunChange}
                                        disabled={!isInEditOrCreateMode()}
                                    >
                                        <option value="" disabled>Seleccione un área común</option>
                                        {areasComun.map(area => (
                                            <option key={area.id} value={area.nombre}>{area.nombre}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="area_comun">Área Común:</label>
                                </div>

                                {/* Fecha de Reserva */}
                                <div className="input-field col s12 m3">
                                    <DatePicker
                                        selected={formData.fecha_reserva}
                                        onChange={handleDateChange}
                                        name="fecha_reserva"
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        disabled={!isInEditOrCreateMode()}
                                        className="datepicker"
                                        minDate={mode !== 'view' ? new Date() : undefined} // En modo "view", permitir ver fechas pasadas
                                    />
                                    <label className="active" htmlFor="fecha_reserva">Fecha de Reserva:</label>
                                </div>

                                {/* Botón Validar Turno */}
                                {isInEditOrCreateMode() && (
                                    <div className="col s12 m3" style={{ display: 'flex', alignItems: 'flex-end' }}>
                                        <button
                                            type="button"
                                            className="btn yellow darken-3 tooltipped"
                                            data-position="top"
                                            data-tooltip="Validar Turno"
                                            onClick={handleValidateTurno}
                                        >
                                            <i className="material-icons left">check_circle</i>Validar Turno
                                        </button>
                                    </div>
                                )}
                            </div>

                            {/* Mensajes de Validación */}
                            {isInEditOrCreateMode() && (
                                <div className="row">
                                    <div className="col s12 m8 offset-m3">
                                        {/* Mostrar resultado de la validación */}
                                        {status_validate_turno === 'loading' && (
                                            <div className="row">
                                                <div className="col s12 m3">
                                                    <span>Cargando...</span>
                                                </div>
                                            </div>
                                        )}

                                        {status_validate_turno === 'succeeded' && turno_available !== null && (
                                            <div className={`row ${turno_available ? 'green-text' : 'red-text'}`}>
                                                <div className="col s12 m3">
                                                    <span>{turno_message}</span>
                                                </div>
                                            </div>
                                        )}

                                        {status_validate_turno === 'failed' && (
                                            <div className="row red-text">
                                                <div className="col s12 m3">
                                                    <span>Error: {error_validate_turno}</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Segunda fila: Selector de Turno y Horas de Turno Inicio y Fin */}
                            <div className="row">
                                {/* Selector de Turno */}
                                <div className="input-field col s12 m3">
                                    <select
                                        name="idturno" // Cambiar el name a idturno
                                        value={formData.idturno}
                                        onChange={handleIdturnoChange} // Cambiar el manejador a handleIdturnoChange
                                        disabled={!isInEditOrCreateMode() || turnosDisponibles.length === 0}
                                    >
                                        <option value="" disabled>Seleccione un turno</option>
                                        {turnosDisponibles.map((turno) => (
                                            <option key={turno.idturno} value={turno.idturno}>{turno.descripcion}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="idturno">Turno:</label>
                                </div>

                                {/* Hora Inicio */}
                                <div className="input-field col s12 m2">
                                    <input
                                        type="text"
                                        name="turno_hora_inicio"
                                        value={formData.turno_hora_inicio}
                                        disabled
                                    />
                                    <label className="active" htmlFor="turno_hora_inicio">Hora Inicio:</label>
                                </div>

                                {/* Hora Fin */}
                                <div className="input-field col s12 m2">
                                    <input
                                        type="text"
                                        name="turno_hora_fin"
                                        value={formData.turno_hora_fin}
                                        disabled
                                    />
                                    <label className="active" htmlFor="turno_hora_fin">Hora Fin:</label>
                                </div>
                            </div>

                            {/* Tercera fila: Costo, Garantía y Aforo */}
                            <div className="row">
                                {/* Costo */}
                                <div className="input-field col s12 m2">
                                    <Cleave
                                        placeholder="0.00"
                                        name="costo"
                                        value={formatNumber(formData.costo)}
                                        options={{
                                            numeral: true,
                                            numeralDecimalMark: '.',
                                            delimiter: ',',
                                            numeralDecimalScale: 2
                                        }}
                                        onChange={handleInputChange}
                                        disabled={true} // Solo lectura
                                    />
                                    <label className="active" htmlFor="costo">Costo:</label>
                                </div>

                                {/* Garantía */}
                                <div className="input-field col s12 m2">
                                    <Cleave
                                        placeholder="0.00"
                                        name="garantia"
                                        value={formatNumber(formData.garantia)}
                                        options={{
                                            numeral: true,
                                            numeralDecimalMark: '.',
                                            delimiter: ',',
                                            numeralDecimalScale: 2
                                        }}
                                        onChange={handleInputChange}
                                        disabled={true} // Solo lectura
                                    />
                                    <label className="active" htmlFor="garantia">Garantía:</label>
                                </div>

                                {/* Aforo */}
                                <div className="input-field col s12 m2">
                                    <input
                                        type="number"
                                        name="aforo"
                                        value={formData.aforo}
                                        onChange={handleInputChange}
                                        disabled={true} // Solo lectura
                                    />
                                    <label className="active" htmlFor="aforo">Aforo:</label>
                                </div>
                            </div>

                            {/* **Nueva Sección: Información de Pago de Reserva en Modo View/Edit** */}
                            {/* Estos campos y botón solo se muestran en modos 'view' y 'edit' */}
                            {!isCreating && reserva?.recaudacion && (
                                <>
                                    <div className="row">
                                        <div className="col s12">
                                            <h5>Información de Pago de Reserva</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* Banco */}
                                        <div className="input-field col s12 m4">
                                            <input
                                                type="text"
                                                name="banco"
                                                value={reserva.recaudacion.banco || ''}
                                                disabled
                                            />
                                            <label className="active" htmlFor="banco">Banco:</label>
                                        </div>

                                        {/* Nombre de la Cuenta */}
                                        <div className="input-field col s12 m4">
                                            <input
                                                type="text"
                                                name="nombre_cuenta"
                                                value={reserva.recaudacion.nombre_cuenta || ''}
                                                disabled
                                            />
                                            <label className="active" htmlFor="nombre_cuenta">Nombre de la Cuenta:</label>
                                        </div>

                                        {/* Código de Recaudación */}
                                        <div className="input-field col s12 m4">
                                            <input
                                                type="text"
                                                name="codigo_recaudacion"
                                                value={reserva.recaudacion.codigo_recaudacion || ''}
                                                disabled
                                            />
                                            <label className="active" htmlFor="codigo_recaudacion">Código de Recaudación:</label>
                                        </div>
                                    </div>

                                    {/* Renderizado Condicional para Constancia de Pago */}
                                    {reserva.constancia_pago_url ? (
                                        // Si la constancia ya está cargada
                                        <div className="row">
                                            <div className="col s12">
                                                <p>
                                                    <strong>Constancia ya cargada el:</strong> {reserva.fecha_constancia_formateada}
                                                </p>
                                                <button
                                                    type="button"
                                                    className="btn blue tooltipped"
                                                    data-position="top"
                                                    data-tooltip="Visualizar Constancia de Pago"
                                                    onClick={() => window.open(reserva.constancia_pago_url, '_blank')}
                                                >
                                                    <i className="material-icons left">visibility</i>Visualizar
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        // Si la constancia no está cargada, mostrar los botones de subida
                                        <>
                                            {/* Botón para subir la constancia de pago */}
                                            <div className="row">
                                                <div className="col s12">
                                                    <button
                                                        type="button"
                                                        className="btn blue tooltipped"
                                                        data-position="top"
                                                        data-tooltip="Subir Constancia de Pago"
                                                        onClick={() => fileInputRef.current.click()}
                                                        disabled={uploading}
                                                    >
                                                        <i className="material-icons left">upload</i>Subir Constancia de Pago
                                                    </button>
                                                    {/* Mostrar estado de subida */}
                                                    {uploading && <span style={{ marginLeft: '10px' }}>Subiendo...</span>}
                                                </div>
                                                {/* Mostrar mensaje de error si existe */}
                                                {uploadErrorLocal && (
                                                    <div className="col s12">
                                                        <span className="red-text">Error: {uploadErrorLocal}</span>
                                                    </div>
                                                )}
                                            </div>
                                            {/* Input oculto para seleccionar archivo */}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                            />

                                            {/* Botón para confirmar la subida de la constancia de pago */}
                                            {constanciaPago && (
                                                <div className="row">
                                                    <div className="col s12">
                                                        <button
                                                            type="button"
                                                            className="btn green tooltipped"
                                                            data-position="top"
                                                            data-tooltip="Confirmar Subida"
                                                            onClick={handleUploadConstancia}
                                                            disabled={uploading}
                                                        >
                                                            <i className="material-icons left">check</i>Confirmar Subida
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            {/* **Fin de la Nueva Sección** */}
                        </form>
                    )}
                </div>
            </div>
        </div>
    );

};

export default ReservaForm;