import { createSlice } from '@reduxjs/toolkit';
import {get_config_unidades_facturacion} from './config_unidad_facturacionThunk';

const config_unidad_facturacionSlice = createSlice({
    name: 'config_unidad_facturacion',
    initialState: {
      config_unidades_facturacion: [],
      fetchStatus : 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_config_unidades_facturacion.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_config_unidades_facturacion.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              //console.log("Propiedades cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.config_unidades_facturacion = action.payload;

          })
          .addCase(get_config_unidades_facturacion.rejected, (state, action) => {
            //console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';
              //console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch receipts';
          });
    },
  });
    
  export const selectPropiedadById = (state, config_unidad_facturacionId) =>
    state.config_unidad_facturacion.config_unidades_facturacion.find((config_unidad_facturacion) => config_unidad_facturacion.id === config_unidad_facturacionId);
  
  export default config_unidad_facturacionSlice.reducer;
