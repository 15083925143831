// ChangePasswordPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {update_password} from '../seguridad/seguridadThunk';
import { resetState } from '../seguridad/seguridadSlice';

const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  //const codigo_usuario = useSelector((state) => state.seguridad.json_resp_validar_token_reset.codigo_usuario); // Obtener el codigo_usuario del estado global  

  const dispatch = useDispatch();  
  const navigate = useNavigate();

  const {
    status_password_reset,
    error_password_reset,
    status_validar_token_reset,
    status_update_password,
    error_validar_token_reset,
    error_update_password,
    json_resp_password_reset,
    json_resp_validar_token_reset,
    json_resp_update_password
  } = useSelector((state) => state.seguridad);

  let codigo_usuario ="";
  if(json_resp_validar_token_reset){
    codigo_usuario =json_resp_validar_token_reset.codigo_usuario;
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      alert("Las contraseñas no coinciden");
      return;
    }
    //dispatch(resetState()); // Limpiar estado antes de enviar una nueva solicitud
    dispatch(update_password({ codigo_usuario, newPassword }));
  };

  useEffect(() => {
    //console.log("status_validar_token_reset "+ status_validar_token_reset + " json_resp_update_password.success " +json_resp_validar_token_reset.success);
    if (status_update_password === 'succeeded' && json_resp_update_password.success) {
        dispatch(resetState()); // Limpiar estado antes de enviar una nueva solicitud  
        // Redirect
          navigate('/');
    }
  }, [status_update_password, json_resp_update_password, navigate]);

  return (
    <div className="container">
      <h1>Cambiar Contraseña</h1>
      <form onSubmit={handleSubmit}>
        <div className="input-field">
          <input 
            type="password" 
            id="newPassword" 
            value={newPassword} 
            onChange={(e) => setNewPassword(e.target.value)} 
            required 
          />
          <label htmlFor="newPassword">Nueva Contraseña</label>
        </div>
        <div className="input-field">
          <input 
            type="password" 
            id="confirmPassword" 
            value={confirmPassword} 
            onChange={(e) => setConfirmPassword(e.target.value)} 
            required 
          />
          <label htmlFor="confirmPassword">Confirmar Contraseña</label>
        </div>
        <button className="btn waves-effect waves-light" type="submit">Actualizar Contraseña</button>
      </form>
      {status_update_password === 'loading' && <p>Actualizando...</p>}
      {status_update_password === 'succeeded' && <p>{json_resp_update_password.message}</p>}
      {status_update_password === 'failed' && <p className="red-text">{error_update_password}</p>}
    </div>
  );
};

export default ChangePassword;