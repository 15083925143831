import { createSlice } from '@reduxjs/toolkit';
import {get_opciones } from './dashboardThunk';

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
      opciones: [],
      status : 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_opciones.pending, (state) => {
              state.status  = 'loading';
          })
          .addCase(get_opciones.fulfilled, (state, action) => {
              state.status  = 'succeeded';
              //console.log("DASHBOARD JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.opciones = action.payload;

          })
          .addCase(get_opciones.rejected, (state, action) => {
            //console.log("ejecutando Slice failed rejected ");
              state.status  = 'failed';              
              //console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch edificios';
          });
    },
  });
    
  export const selecOpcionById = (state, opcionId) =>
    state.dashboard.opciones.find((opcion) => opcion.id === opcionId);
  
  export default dashboardSlice.reducer;
