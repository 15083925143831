import React, { useEffect } from 'react'; 
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import M from 'materialize-css';
import './menuPage.css';

const Menu = () => {
  const { usuario } = useSelector((state) => state.auth);  // Estado de autenticación
  const location = useLocation();  // Detecta la ruta actual

  const mostrarMenus = (clasesMenu) => {
    const claseMenuDefecto = 'menusitos';
    const elementosLiDefecto = document.querySelectorAll('.' + claseMenuDefecto);

    elementosLiDefecto.forEach((li) => {
      li.style.display = 'none';
    });

    const clasesArray = Array.isArray(clasesMenu) ? clasesMenu : [clasesMenu];
    const selector = clasesArray.map((clase) => '.' + clase).join(',');

    const elementosLi = document.querySelectorAll(selector);
    elementosLi.forEach((li) => {
      li.style.display = 'block';
    });
  };

  useEffect(() => {
    M.Tooltip.init(document.querySelectorAll('.tooltipped'));
    M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'));
    M.Sidenav.init(document.querySelectorAll('.sidenav'));
    mostrarMenus('menu_inicio');
  }, []);

  // Ocultar menús en caso de login o sesión activa
  const ocultarMenus = usuario || location.pathname === '/login';

  return (
    <div id="menu">
      <nav>
        <div className="nav-wrapper blue darken-1">
          <Link to="/" className="brand-logo hide-on-med-and-down text-large" id="tituloPagina">
            <i className="material-icons">cloud</i>Tantaq
          </Link>

          <Link to="/" className="brand-logo hide-on-large-only" id="tituloPagina_short">
            <i className="material-icons">cloud</i>
            <span className="tituloSmallDevices">Tantaq</span>
          </Link>

          <Link to="#" data-target="mobile-demo" className="sidenav-trigger">
            <i className="material-icons">menu</i>
          </Link>

          <ul className="right hide-on-med-and-down">
            {/* Ocultar estos menús si el usuario está autenticado o en login */}
            {!ocultarMenus && (
              <>
                <li id="menu_beneficios" className="menusitos menu_inicio">
                  <Link to="/beneficios">Beneficios</Link>
                </li>
                <li id="menu_como_funciona" className="menusitos menu_inicio">
                  <Link to="/como-funciona">¿Cómo funciona?</Link>
                </li>
                <li id="menu_preguntas" className="menusitos menu_inicio">
                  <Link to="/preguntas">Preguntas</Link>
                </li>
                <li id="menu_ser_recaudador" className="menusitos menu_inicio">
                  <Link to="/ser-recaudador">Ser Recaudador</Link>
                </li>
                <li id="menu_contactanos" className="menusitos menu_inicio">
                  <Link to="/contactanos">Contáctanos</Link>
                </li>
              </>
            )}

            {/* Mostrar Login SOLO si el usuario no está autenticado */}
            {!usuario && (
              <li id="menu_login" className="menusitos menu_inicio">
                <Link to="/login">Login</Link>
              </li>
            )}

            {/* Opciones basadas en autenticación */}
            {location.pathname === '/forgot-password' && (
              <li>
                <Link to="/" className="tooltipped" data-position="bottom" data-tooltip="Login">
                  <i className="material-icons">account_circle</i>
                </Link>
              </li>
            )}

            {!usuario && location.pathname !== '/forgot-password' && (
              <li>
                <Link to="/forgot-password" className="tooltipped" data-position="bottom" data-tooltip="Recuperar Password">
                  <i className="material-icons">vpn_key</i>
                </Link>
              </li>
            )}

            {usuario && (
              <li>
                <Link to="/logout" className="tooltipped" data-position="bottom" data-tooltip="Logout">
                  <i className="material-icons">lock_open</i>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>

      {/* Menú lateral (Sidenav) para móviles */}
      <ul className="sidenav" id="mobile-demo">
        {!ocultarMenus && (
          <>
            <li id="menu_lat_beneficios" className="menusitos menu_inicio">
              <Link to="/beneficios">Beneficios</Link>
            </li>
            <li id="menu_lat_como_funciona" className="menusitos menu_inicio">
              <Link to="/como-funciona">¿Cómo funciona?</Link>
            </li>
            <li id="menu_lat_preguntas" className="menusitos menu_inicio">
              <Link to="/preguntas">Preguntas</Link>
            </li>
            <li id="menu_lat_ser_recaudador" className="menusitos menu_inicio">
              <Link to="/ser-recaudador">Ser Recaudador</Link>
            </li>
            <li id="menu_lat_contactanos" className="menusitos menu_inicio">
              <Link to="/contactanos">Contáctanos</Link>
            </li>
          </>
        )}

        {!usuario && (
          <li id="menu_lat_login" className="menusitos menu_inicio">
            <Link to="/login">Login</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Menu;
