import { createSlice } from '@reduxjs/toolkit';
import { get_medicamento, update_medicamento, save_medicamento, anular_medicamento, get_medicamentos_x_edificio } from './medicamentoThunk';

const medicamentoSlice = createSlice({
  name: 'medicamento',
  initialState: {
    medicamento: null,
    medicamentos: [],
    medicamentoSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_medicamentos_x_edificio: 'idle',
    error_get_medicamentos_x_edificio: null,
    status_update_medicamento: 'idle',
    error_update_medicamento: null,    
    status_save_medicamento: 'idle',
    error_save_medicamento: null,
    status_anular_medicamento: 'idle',
    error_anular_medicamento: null,
  },
  reducers: {
    setMedicamentoSeleccionada: (state, action) => {
      state.medicamentoSeleccionada = action.payload;
      
    },
    clearMedicamentoSeleccionada(state) {
      state.medicamentoSeleccionada = null;
      state.medicamento = null;
    },
    resetStateGetMedicamentos_x_edificio: () => {
        return {
            status_get_medicamentos_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_medicamento.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_medicamento.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.medicamento = action.payload;

      })
      .addCase(get_medicamento.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_medicamento.pending, (state) => {
        state.status_update_medicamento = 'loading';
      })
      .addCase(update_medicamento.fulfilled, (state, action) => {
        state.status_update_medicamento = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.medicamento = action.payload.medicamento;

      })
      .addCase(update_medicamento.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_medicamento = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_medicamento = action.payload || 'Failed to fetch building';
      })
      .addCase(save_medicamento.pending, (state) => {
        state.status_save_medicamento = 'loading';
      })
      .addCase(save_medicamento.fulfilled, (state, action) => {
        state.status_save_medicamento = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.medicamento = action.payload.medicamento;

      })
      .addCase(save_medicamento.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_medicamento = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_medicamento = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_medicamento.pending, (state) => {
        state.status_save_medicamento = 'loading';
      })
      .addCase(anular_medicamento.fulfilled, (state, action) => {
        state.status_anular_medicamento = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.medicamento = action.payload.medicamento;

      })
      .addCase(anular_medicamento.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_medicamento = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_medicamento = action.payload || 'Failed to fetch building';
      })
      .addCase(get_medicamentos_x_edificio.pending, (state) => {
        state.status_get_medicamentos_x_edificio = 'loading';
        
      })
      .addCase(get_medicamentos_x_edificio.fulfilled, (state, action) => {
        state.status_get_medicamentos_x_edificio = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_medicamentos_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.medicamentos = action.payload;

      })
      .addCase(get_medicamentos_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_medicamentos_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_medicamentos_x_edificio = action.payload || 'Failed to fetch building';
        state.medicamentos =[];
      })
  },
});

export const { setMedicamentoSeleccionada, clearMedicamentoSeleccionada } = medicamentoSlice.actions;
export const selectMedicamentos_emergenciaById = (state, idMedicamento) =>
    state.medicamento.medicamentos.find((medicamento) => medicamento.id === idMedicamento);
export const { resetStateGetMedicamentos_x_edificio } = medicamentoSlice.actions;
export default medicamentoSlice.reducer;
