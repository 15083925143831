import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_zonas_seguras_x_depa } from './zona_seguraThunk'; // Thunk para obtener las zonas seguras del propietario
import { useNavigate } from 'react-router-dom'; // Para navegación
import './MisZonas_seguras.css'; // Asegúrate de tener un archivo de estilos

const MisZonas_seguras = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const zonas_seguras = useSelector((state) => state.zona_segura.zonas_seguras) || [];
    const unidad_facturacionSeleccionado = useSelector((state) => state.unidad_facturacion.unidad_facturacionSeleccionado);
    const edificioSeleccionado = useSelector((state) => state.edificio.edificio);

    const codigo_unidad_facturacion = unidad_facturacionSeleccionado?.codigo_unidad_facturacion;
    const buildingId = edificioSeleccionado?.id;

    useEffect(() => {
        if (buildingId && codigo_unidad_facturacion) {
            dispatch(get_zonas_seguras_x_depa({ buildingId, codigo_unidad_facturacion }));
        }
    }, [dispatch, buildingId, codigo_unidad_facturacion]);

    return (
        <div className="mis-zonas-seguras-container">
            <button className="btn blue return-btn" onClick={() => navigate(-1)}>
                <i className="material-icons left">arrow_back</i> Volver
            </button>
            <h3 className="center-align">Mis Zonas Seguras</h3>
            <div className="zonas-grid">
                {zonas_seguras.length > 0 ? (
                    zonas_seguras.map((zona, index) => (
                        <div key={index} className="zona-card">
                            <div className="zona-card-images">
                                {zona.imageUrl.map((url, i) => (
                                    <img key={i} src={url} alt={`Zona segura ${zona.titulo} - Imagen ${i + 1}`} className="responsive-img zona-image" />
                                ))}
                            </div>
                            <div className="zona-card-content">
                                <h5>{zona.titulo}</h5>
                                <p><strong>Descripción:</strong> {zona.descripcion}</p>
                                <p><strong>Tipo de Unidad:</strong> {zona.tipo_unidad_inmobiliaria}</p>
                                <p><strong>Número de Unidad:</strong> {zona.numero_unidad_inmobiliaria}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="center-align">No tienes zonas seguras asignadas.</p>
                )}
            </div>
        </div>
    );
};

export default MisZonas_seguras;
