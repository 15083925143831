import { createSlice } from '@reduxjs/toolkit';
import {get_residentes_cargados_x_edificio, mover_residentes_cargados} from './residentes-cargadosThunk';

const residentes_cargadosSlice = createSlice({
    name: 'residentes_cargados',
    initialState: {
      residentes_cargados: [],
      //fetchStatus : 'idle',
      //error: null,
      status: {
        get_residentes_cargados_x_edificio: 'idle',
        moveValidresidentes: 'idle',
      },
      error: {
        get_residentes_cargados_x_edificio: null,
        moveValidDepartments: null,
      },
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_residentes_cargados_x_edificio.pending, (state) => {
              state.status.get_residentes_cargados_x_edificio  = 'loading';
          })
          .addCase(get_residentes_cargados_x_edificio.fulfilled, (state, action) => {
              state.status.get_residentes_cargados_x_edificio  = 'succeeded';
              //console.log("Depas cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.residentes_cargados = action.payload;

          })
          .addCase(get_residentes_cargados_x_edificio.rejected, (state, action) => {
            //console.log("ejecutando Slice failed rejected ");
              state.status.get_residentes_cargados_x_edificio  = 'failed';              
              //console.log("error action.error.message "+action.error.message );
              state.error.get_residentes_cargados_x_edificio = action.payload || 'Failed to fetch receipts';
          })
//
        .addCase(mover_residentes_cargados.pending, (state) => {
            state.status.mover_residentes_cargados  = 'loading';
        })
        .addCase(mover_residentes_cargados.fulfilled, (state, action) => {
            state.status.mover_residentes_cargados  = 'succeeded';
            //console.log("Depas cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
            //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
            state.residentes_cargados = action.payload;

        })
        .addCase(mover_residentes_cargados.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
            state.status.mover_residentes_cargados  = 'failed';
            //console.log("error action.error.message "+action.error.message );
            state.error.mover_residentes_cargados = action.payload || 'Failed to fetch receipts';
        });

    },
  });
    
  export const selectResidente_cargadoById = (state, residente_cargadoId) =>
    state.depas_cargados.residentes_cargados.find((residente_cargado) => residente_cargado.id === residente_cargadoId);
  
  export default residentes_cargadosSlice.reducer;
