import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetConceptos } from './conceptos_facturacionSlice';



export const update_concepto = createAsyncThunk('concepto/update_concepto', async ({ idConcepto, conceptoData }, thunkAPI) => {
  //console.log("ejecutando thunk update_concepto con idConcepto: " + idConcepto);

  try {

    const response = await apiClient.put(`/conceptos-facturacion/${idConcepto}`, conceptoData);

    const concepto = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetConceptos(concepto.buildingId));// Resetear el estado para refetch de conceptos


    return concepto;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_concepto = createAsyncThunk('concepto/save_concepto', async (conceptoData, thunkAPI) => {

  try {
    console.log("antes de invocación de post creacion de concepto")
    const response = await apiClient.post(`/conceptos-facturacion`, conceptoData);

    const concepto = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetConceptos(concepto.buildingId));// Resetear el estado para refetch de conceptos

    return concepto;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_concepto = createAsyncThunk('concepto/anular_concepto', async ({ idConcepto, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_concepto con idConcepto: " + idConcepto);

  try {

    const response = await apiClient.patch(`/conceptos-facturacion/${idConcepto}/anular`, { usuarioAnulacion });

    const concepto = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetConceptos(concepto.buildingId));// Resetear el estado para refetch de conceptos

    return concepto;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_concepto = createAsyncThunk('concepto/get_concepto', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_concepto con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/conceptos-facturacion/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const concepto = response.data;

    return concepto;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
