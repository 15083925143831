import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetProtocolos_x_edificio } from './protocoloSlice';


export const update_protocolo = createAsyncThunk('protocolo/update_protocolo', async ({ idProtocolo, protocoloData }, thunkAPI) => {
  console.log("ejecutando thunk update_protocolo con idProtocolo: " + idProtocolo);

  try {

    const response = await apiClient.put(`/protocolos/${idProtocolo}`, protocoloData);

    const protocolo = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetProtocolos_x_edificio(protocolo.buildingId));// Resetear el estado para refetch de protocolos

    return protocolo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_protocolo = createAsyncThunk('protocolo/save_protocolo', async (protocoloData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/protocolos`, protocoloData);

    const protocolo = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetProtocolos_x_edificio(protocolo.buildingId));// Resetear el estado para refetch de protocolos

    return protocolo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_protocolo = createAsyncThunk('protocolo/anular_protocolo', async ({ idProtocolo, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_protocolo con idProtocolo: " + idProtocolo);

  try {

    const response = await apiClient.patch(`/protocolos/${idProtocolo}/anular`, { usuarioAnulacion });

    const protocolo = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetProtocolos_x_edificio(protocolo.buildingId));// Resetear el estado para refetch de protocolos

    return protocolo;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_protocolo = createAsyncThunk('protocolo/get_protocolo', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_protocolo con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/protocolos/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const protocolo = response.data;

    return protocolo;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_protocolos_x_edificio = createAsyncThunk('protocolo/get_protocolos_x_edificio', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_protocolos con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/protocolos',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const protocolos = response.data;
          
      return protocolos ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
