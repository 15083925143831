import { createSlice } from '@reduxjs/toolkit';
import { get_lectura_agua, update_lectura_agua, save_lectura_agua, anular_lectura_agua } from './lectura_aguaThunk';

const lectura_aguaSlice = createSlice({
  name: 'lectura_agua',
  initialState: {
    lectura_agua: null,
    lectura_agua_x_periodo: null,
    lectura_aguaSeleccionado: null,
    status: 'idle',
    error: null,
    status_update_lectura_agua: 'idle',
    error_update_lectura_agua: null,
    status_save_lectura_agua: 'idle',
    error_save_lectura_agua: null,
    status_anular_lectura_agua: 'idle',
    error_anular_lectura_agua: null,
    status_get_lectura_agua_x_periodo: 'idle',
    error_get_lectura_agua_x_periodo: null,
    
  },
  reducers: {
    setLectura_aguaSeleccionado: (state, action) => {
      state.lectura_aguaSeleccionado = action.payload;
      
    },
    clearLectura_aguaSeleccionado(state) {
      state.lectura_aguaSeleccionado = null;
      state.lectura_agua = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_lectura_agua.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_lectura_agua.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lectura_agua = action.payload;

      })
      .addCase(get_lectura_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_lectura_agua.pending, (state) => {
        state.status_update_lectura_agua = 'loading';
      })
      .addCase(update_lectura_agua.fulfilled, (state, action) => {
        state.status_update_lectura_agua = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lectura_agua = action.payload.lectura_agua;

      })
      .addCase(update_lectura_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_lectura_agua = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_lectura_agua = action.payload || 'Failed to fetch building';
      })
      .addCase(save_lectura_agua.pending, (state) => {
        state.status_save_lectura_agua = 'loading';
      })
      .addCase(save_lectura_agua.fulfilled, (state, action) => {
        state.status_save_lectura_agua = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lectura_agua = action.payload.lectura_agua;

      })
      .addCase(save_lectura_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_lectura_agua = 'failed';
        //console.log("error JSON.stringify(action.payload) " + JSON.stringify(action.payload));
        //state.error_save_lectura_agua = action.payload.error || '-Falló al guardar lectura_agua';
        state.error_save_lectura_agua = action.payload.error;
      })
      .addCase(anular_lectura_agua.pending, (state) => {
        state.status_save_lectura_agua = 'loading';
      })
      .addCase(anular_lectura_agua.fulfilled, (state, action) => {
        state.status_anular_lectura_agua = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.lectura_agua = action.payload.lectura_agua;

      })
      .addCase(anular_lectura_agua.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_lectura_agua = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_lectura_agua = action.payload || 'Failed to fetch building';
      })

  },
});

export const { setLectura_aguaSeleccionado, clearLectura_aguaSeleccionado } = lectura_aguaSlice.actions;
export default lectura_aguaSlice.reducer;
