import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetFacturaciones_agua } from './facturaciones_aguaSlice';



export const update_facturacion_agua = createAsyncThunk('facturacion_agua/update_facturacion_agua', async ({ idFacturacion_agua, facturacion_aguaData }, thunkAPI) => {
  //console.log("ejecutando thunk update_facturacion_agua con idLectura_agua: " + idLectura_agua);

  try {

    const response = await apiClient.put(`/facturaciones-agua/${idFacturacion_agua}`, facturacion_aguaData);

    const facturacion_agua = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetFacturaciones_agua(facturacion_agua.buildingId));// Resetear el estado para refetch de facturacion_aguas


    return facturacion_agua;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_facturacion_agua = createAsyncThunk('facturacion_agua/save_facturacion_agua', async (facturacion_aguaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`facturaciones-agua`, facturacion_aguaData);

    const facturacion_agua = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetFacturaciones_agua(facturacion_agua.buildingId));// Resetear el estado para refetch de facturacion_aguas

    return facturacion_agua;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_facturacion_agua = createAsyncThunk('facturacion_agua/anular_facturacion_agua', async ({ idLectura_agua, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_facturacion_agua con idLectura_agua: " + idLectura_agua);

  try {

    const response = await apiClient.patch(`/facturaciones-agua/${idLectura_agua}/anular`, { usuarioAnulacion });

    const facturacion_agua = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetFacturaciones_agua(facturacion_agua.buildingId));// Resetear el estado para refetch de facturacion_aguas

    return facturacion_agua;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_facturacion_agua = createAsyncThunk('facturacion_agua/get_facturacion_agua', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_facturacion_agua con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/facturaciones-agua/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const facturacion_agua = response.data;

    return facturacion_agua;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
