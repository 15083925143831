import { createSlice } from '@reduxjs/toolkit';
import { get_unidades_facturacion_x_edificio } from './unidad_facturacionThunk';

const unidad_facturacionSlice = createSlice({
  name: 'unidad_facturacion',
  initialState: {
    unidades_facturacion: [],
    unidad_facturacionSeleccionado: null,
    fetchStatus: 'idle',
    error: null,
  },
  reducers: {
    setUnidad_facturacionSeleccionado: (state, action) => {
      state.unidad_facturacionSeleccionado = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_unidades_facturacion_x_edificio.pending, (state) => {
        state.fetchStatus = 'loading';
      })
      .addCase(get_unidades_facturacion_x_edificio.fulfilled, (state, action) => {
        state.fetchStatus = 'succeeded';
        //console.log("Propiedades cargados JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.unidades_facturacion = action.payload;

      })
      .addCase(get_unidades_facturacion_x_edificio.rejected, (state, action) => {
        console.log("ejecutando Slice failed rejected ");
        state.fetchStatus = 'failed';
        console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch receipts';
      });
  },
});

export const selectPropiedadById = (state, unidad_facturacionId) =>
  state.unidad_facturacion.unidades_facturacion.find((unidad_facturacion) => unidad_facturacion.id === unidad_facturacionId);
export const { setUnidad_facturacionSeleccionado } = unidad_facturacionSlice.actions;
export default unidad_facturacionSlice.reducer;
