// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
//import { getAnalytics } from "firebase/analytics";
//import {getFunctions} from "firebase/functions";

import 'firebase/auth';

import { getStorage, connectStorageEmulator } from 'firebase/storage';
//import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAqbdRiaeD6r3gY2K6aCMePHdfhs3OjQXY",
  authDomain: "tantaq-apis.firebaseapp.com",
  projectId: "tantaq-apis",
  storageBucket: "tantaq-apis.appspot.com",
  messagingSenderId: "291440358739",
  appId: "1:291440358739:web:366d5a48eb0606a6e05da6",
  measurementId: "G-QQDJ89193V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage and export it
const storage = getStorage(app);

// Get a reference to the auth service
const auth = getAuth(app);

if (window.location.hostname === 'localhost') {
  // Conecta a los emuladores locales
  //connectFirestoreEmulator(firestore, 'localhost', 8080);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectAuthEmulator(auth, 'http://localhost:9099');
}
  
//const analytics = getAnalytics(app);
//const functions = getFunctions(app);

export {storage, auth};