import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

  export const get_unidades_inmobiliarias_cargadas = createAsyncThunk('unidades-inmobiliarias-carga/get_unidades_inmobiliarias_cargadas', async (codigo_edificio, thunkAPI) => {
    //console.log("ejecutando thunk registrar_unidades_inmobiliarias_cargadas con "+codigo_edificio);
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get(`/edificios/${codigo_edificio}/unidades-inmobiliarias-cargadas`);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const unidades_inmobiliarias_carga = response.data;
            
        return unidades_inmobiliarias_carga ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  export const mover_unidades_inmobiliarias_cargadas = createAsyncThunk('unidades-inmobiliarias-carga/mover_unidades_inmobiliarias_cargadas', async (jsonBodyRequest, thunkAPI) => {
    //console.log("ejecutando thunk mover_unidades_inmobiliarias_cargadas con "+JSON.stringify(jsonBodyRequest));
    
    try { 
        //console.log("ejecutando thunk codigo_edificio: "+codigo_edificio);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.post('/unidades-inmobiliarias-carga/registro',jsonBodyRequest);
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const resp_registro = response.data;
            
        return resp_registro;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });

  
