import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetZonas_seguras_x_edificio } from './zona_seguraSlice';


export const update_zona_segura = createAsyncThunk('zona_segura/update_zona_segura', async ({ idZona_segura, zona_seguraData }, thunkAPI) => {
  //console.log("ejecutando thunk update_zona_segura con idZona_segura: " + idZona_segura);

  try {

    const response = await apiClient.put(`/zonas-seguras/${idZona_segura}`, zona_seguraData);

    const zona_segura = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetZonas_seguras_x_edificio(zona_segura.buildingId));// Resetear el estado para refetch de zonas_seguras

    return zona_segura;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_zona_segura = createAsyncThunk('zona_segura/save_zona_segura', async (zona_seguraData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/zonas-seguras`, zona_seguraData);

    const zona_segura = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetZonas_seguras_x_edificio(zona_segura.buildingId));// Resetear el estado para refetch de zonas_seguras

    return zona_segura;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_zona_segura = createAsyncThunk('zona_segura/anular_zona_segura', async ({ idZona_segura, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_zona_segura con idZona_segura: " + idZona_segura);

  try {

    const response = await apiClient.patch(`/zonas-seguras/${idZona_segura}/anular`, { usuarioAnulacion });

    const zona_segura = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetZonas_seguras_x_edificio(zona_segura.buildingId));// Resetear el estado para refetch de zonas_seguras

    return zona_segura;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_zona_segura = createAsyncThunk('zona_segura/get_zona_segura', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_zona_segura con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/zonas-seguras/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const zona_segura = response.data;

    return zona_segura;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_zonas_seguras_x_edificio = createAsyncThunk('zona_segura/get_zonas_seguras_x_edificio', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_zonas_seguras con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/zonas-seguras',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const zonas_seguras = response.data;
          
      return zonas_seguras ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_zonas_seguras_x_depa = createAsyncThunk('zona_segura/get_zonas_seguras_x_depa', async ({ buildingId, codigo_unidad_facturacion }, thunkAPI) => {
  console.log("ejecutando thunk get_zonas_seguras con buildingId: "+buildingId + " codigo_unidad_facturacion "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/zonas-seguras',{
        params: { 
          buildingId, 
          codigo_unidad_facturacion          
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const zonas_seguras = response.data;
          
      return zonas_seguras ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
