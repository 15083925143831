import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetPreferencias_x_edificio } from './preferenciaSlice';


export const update_preferencia = createAsyncThunk('preferencia/update_preferencia', async ({ idPreferencia, preferenciaData }, thunkAPI) => {
  //console.log("ejecutando thunk update_preferencia con idPreferencia: " + idPreferencia);

  try {

    const response = await apiClient.put(`/preferencias/${idPreferencia}`, preferenciaData);

    const preferencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPreferencias_x_edificio(preferencia.buildingId));// Resetear el estado para refetch de preferencias

    return preferencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_preferencia = createAsyncThunk('preferencia/save_preferencia', async (preferenciaData, thunkAPI) => {

  try {

    const response = await apiClient.post(`/preferencias`, preferenciaData);

    const preferencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPreferencias_x_edificio(preferencia.buildingId));// Resetear el estado para refetch de preferencias

    return preferencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_preferencia = createAsyncThunk('preferencia/anular_preferencia', async ({ idPreferencia, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_preferencia con idPreferencia: " + idPreferencia);

  try {

    const response = await apiClient.patch(`/preferencias/${idPreferencia}/anular`, { usuarioAnulacion });

    const preferencia = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetPreferencias_x_edificio(preferencia.buildingId));// Resetear el estado para refetch de preferencias

    return preferencia;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const get_preferencia = createAsyncThunk('preferencia/get_preferencia', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_preferencia con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/preferencias/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const preferencia = response.data;

    return preferencia;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_preferencias_x_edificio = createAsyncThunk('preferencia/get_preferencias_x_edificio', async ({ buildingId, estado }, thunkAPI) => {
  console.log("ejecutando thunk get_preferencias con "+buildingId);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/preferencias',{
        params: { 
          buildingId, 
          estado 
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const preferencias = response.data;
          
      return preferencias ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
export const get_preferencias_x_depa = createAsyncThunk('preferencia/get_preferencias_x_depa', async ({ buildingId, codigo_unidad_facturacion }, thunkAPI) => {
  console.log("ejecutando thunk get_preferencias con buildingId: "+buildingId + " codigo_unidad_facturacion "+codigo_unidad_facturacion);
  
  try { 
      //console.log("ejecutando thunk buildingId: "+buildingId);      
      const response = await apiClient.get('/preferencias',{
        params: { 
          buildingId, 
          codigo_unidad_facturacion          
        }
    });
      //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
      const preferencias = response.data;
          
      return preferencias ;
    } catch (error) {
      console.log("error.response.data "+error.response.data );
      return thunkAPI.rejectWithValue(error.response.data);
    }
});
