import { createSlice } from '@reduxjs/toolkit';
import {get_edificios_x_admin} from './edificiosThunk';

const edificiosSlice = createSlice({
    name: 'edificios',
    initialState: {
      edificios: [],
      fetchStatus : 'idle',
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
          .addCase(get_edificios_x_admin.pending, (state) => {
              state.fetchStatus  = 'loading';
          })
          .addCase(get_edificios_x_admin.fulfilled, (state, action) => {
              state.fetchStatus  = 'succeeded';
              //console.log("3 JSON.stringify(action.payload): "+JSON.stringify(action.payload));
              //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
              state.edificios = action.payload;

          })
          .addCase(get_edificios_x_admin.rejected, (state, action) => {
            console.log("ejecutando Slice failed rejected ");
              state.fetchStatus  = 'failed';              
              console.log("error action.error.message "+action.error.message );
              state.error = action.payload || 'Failed to fetch edificios';
          });
    },
  });
    
  export const selecEdificioById = (state, edificioId) =>
    state.edificios.edificios.find((edificio) => edificio.id === edificioId);
  
  export default edificiosSlice.reducer;
