import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; 
import { get_protocolos_x_edificio } from './protocoloThunk';
import './MisProtocolosEmergenciaPage.css';

const Mis_protocolos_emergencia = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // Inicializar useNavigate
    const edificio = useSelector((state) => state.edificio.edificio);
    const buildingId = edificio?.id;
    const protocolos = useSelector((state) => state.protocolo.protocolos) || [];

    useEffect(() => {
        if (buildingId) {
            dispatch(get_protocolos_x_edificio({ buildingId }));
        }
    }, [dispatch, buildingId]);

    return (
        <div className="protocolos-container">
            <button className="btn blue return-btn" onClick={() => navigate(-1)}>
                <i className="material-icons left">arrow_back</i> Volver
            </button>
            <h3 className="center-align">Protocolos de Emergencia</h3>
            <p className="center-align">Aquí puedes encontrar los protocolos importantes en caso de emergencia.</p>
            <div className="protocolos-grid">
                {protocolos.length > 0 ? (
                    protocolos.map((protocolo, index) => (
                        <div key={index} className="protocolo-card">
                            <h5>{protocolo.titulo}</h5>
                            <p>{protocolo.descripcion}</p>
                            <div className="protocolo-archivos">
                                {protocolo.archivos.map((fileUrl, i) => (
                                    <a
                                        key={i}
                                        href={fileUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn archivo-btn"
                                    >
                                        Ver Archivo {i + 1}
                                    </a>
                                ))}
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="center-align">No hay protocolos disponibles por el momento.</p>
                )}
            </div>
        </div>
    );
};
export default Mis_protocolos_emergencia;
