import React, { useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get_recibos_x_planilla } from '../mis_recibos/recibosThunk';
import { formatDate, formatCurrency, formatPeriod, formatDate02, formatearPeriodo_MesAnio01, formatearImporte01 } from '../../utils/formateo';

const RecibosPage = () => {
  const params = useParams();
  console.log("useParams(): ", params);

  const planilla = useSelector(state => state.planilla.planilla);

  // Asegúrate de obtener el parámetro correcto de la URL.
  const idPlanilla = params.idPlanilla || params.id;
  console.log("idPlanilla: ", idPlanilla);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const {
    recibos = [],
    fetchStatus_get_recibos_x_planilla,
    error_get_recibos_x_planilla,
  } = useSelector((state) => state.recibos);

  const conceptosFacturacion = useSelector(
    (state) => state.planilla.planilla.conceptos_facturacion || []
  );

  useEffect(() => {
    console.log("useEffect ejecutado");
    console.log("VER idPlanilla: ", idPlanilla);
    if (idPlanilla) {
      dispatch(get_recibos_x_planilla(idPlanilla));
    }
  }, [dispatch, idPlanilla]);

  const handleRetornar = () => {
    navigate('/planillas-recibos');
};

  if (fetchStatus_get_recibos_x_planilla === 'loading') {
    return <div>Cargando recibos...</div>;
  }

  if (fetchStatus_get_recibos_x_planilla === 'failed') {
    return <div>Error: {error_get_recibos_x_planilla}</div>;
  }

  // Calcular la suma de cada concepto
  const calculateSums = () => {
    const sums = {};
    if (Array.isArray(recibos)) {
      recibos.forEach((recibo) => {
        if (Array.isArray(recibo.conceptos)) {
          recibo.conceptos.forEach((concepto) => {
            if (!sums[concepto.titulo]) {
              sums[concepto.titulo] = 0;
            }
            sums[concepto.titulo] += concepto.importe;
          });
        }
      });
    }
    return sums;
  };

  const sums = calculateSums();

  const formatNumber = (number) => {
    return new Intl.NumberFormat('es-PE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  return (
    <div className="container">
      <h2>Recibos - Periodo: {formatearPeriodo_MesAnio01(planilla.periodo)}</h2>
      <div className="card-action">
        <a className="btn blue tooltipped" data-position="top" data-tooltip="Volver" onClick={handleRetornar}>
          <i className="material-icons left">arrow_back</i>
        </a>
      </div>
      <table className="highlight">
        <thead>
          <tr>
            <th>Unidad de Facturación</th>
            <th>Porcentaje Participación</th>
            {Array.isArray(recibos) &&
              recibos.length > 0 &&
              Array.isArray(recibos[0].conceptos) &&
              recibos[0].conceptos.map((concepto) => (
                <th key={concepto.idconcepto}>
                  {concepto.titulo.length > 30
                    ? `${concepto.titulo.slice(0, 30)}...`
                    : concepto.titulo}
                </th>
              ))}
            <th>Suma</th>
          </tr>
        </thead>
        <tbody>
          {/* Fila con los conceptos de facturación */}
          <tr>
            <td><strong>Conceptos de Facturación</strong></td>
            <td></td>
            {conceptosFacturacion.map((concepto) => (
              <td key={concepto.idconcepto}>
                {formatNumber(concepto.importe)}
              </td>
            ))}
            <td>
              <strong>
                {formatNumber(
                  conceptosFacturacion.reduce(
                    (sum, c) => sum + c.importe,
                    0
                  )
                )}
              </strong>
            </td>
          </tr>

          <tr>
            <td><strong>Total</strong></td>
            <td></td>
            {Object.values(sums).map((sum, index) => (
              <td key={index}>
                <strong>{formatNumber(sum)}</strong>
              </td>
            ))}
            <td>
              <strong>
                {formatNumber(
                  Object.values(sums).reduce((total, sum) => total + sum, 0)
                )}
              </strong>
            </td>
          </tr>

          {Array.isArray(recibos) &&
            recibos.map((recibo) => (
              <tr key={recibo.numero_recibo}>
                <td>
                  <Link to={`/recibo-detalle/${recibo.codigo_unidad_facturacion}`}>
                    {recibo.codigo_unidad_facturacion}
                  </Link>
                </td>
                <td>{recibo.porcentaje_participacion}</td>
                {Array.isArray(recibo.conceptos) &&
                  recibo.conceptos.map((concepto) => (
                    <td key={concepto.idconcepto}>
                      {formatNumber(concepto.importe)}
                    </td>
                  ))}
                <td>
                  {formatNumber(
                    Array.isArray(recibo.conceptos)
                      ? recibo.conceptos.reduce(
                        (sum, c) => sum + c.importe,
                        0
                      )
                      : 0
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default RecibosPage;
