import { createSlice } from '@reduxjs/toolkit';
import { get_suministro, update_suministro, save_suministro, anular_suministro } from './suministroThunk';

const suministroSlice = createSlice({
  name: 'suministro',
  initialState: {
    suministro: null,
    suministroSeleccionado: null,
    status: 'idle',
    error: null,
    status_update_suministro: 'idle',
    error_update_suministro: null,
    status_save_suministro: 'idle',
    error_save_suministro: null,
    status_anular_suministro: 'idle',
    error_anular_suministro: null,

  },
  reducers: {
    setSuministroSeleccionado: (state, action) => {
      state.suministroSeleccionado = action.payload;
      
    },
    clearSuministroSeleccionado(state) {
      state.suministroSeleccionado = null;
      state.suministro = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_suministro.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_suministro.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.suministro = action.payload;

      })
      .addCase(get_suministro.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })

      .addCase(update_suministro.pending, (state) => {
        state.status_update_suministro = 'loading';
      })
      .addCase(update_suministro.fulfilled, (state, action) => {
        state.status_update_suministro = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.suministro = action.payload.suministro;

      })
      .addCase(update_suministro.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_suministro = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_suministro = action.payload || 'Failed to fetch building';
      })
      .addCase(save_suministro.pending, (state) => {
        state.status_save_suministro = 'loading';
      })
      .addCase(save_suministro.fulfilled, (state, action) => {
        state.status_save_suministro = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.suministro = action.payload.suministro;

      })
      .addCase(save_suministro.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_suministro = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_suministro = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_suministro.pending, (state) => {
        state.status_save_suministro = 'loading';
      })
      .addCase(anular_suministro.fulfilled, (state, action) => {
        state.status_anular_suministro = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.suministro = action.payload.suministro;

      })
      .addCase(anular_suministro.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_suministro = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_suministro = action.payload || 'Failed to fetch building';
      })

  },
});

export const { setSuministroSeleccionado, clearSuministroSeleccionado } = suministroSlice.actions;
export default suministroSlice.reducer;
