import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';
import { resetStateGetSuministros } from './suministrosSlice';



export const update_suministro = createAsyncThunk('suministro/update_suministro', async ({ idSuministro, suministroData }, thunkAPI) => {
  //console.log("ejecutando thunk update_suministro con idSuministro: " + idSuministro);

  try {

    const response = await apiClient.put(`/suministros/${idSuministro}`, suministroData);

    const suministro = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetSuministros(suministro.buildingId));// Resetear el estado para refetch de suministros


    return suministro;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const save_suministro = createAsyncThunk('suministro/save_suministro', async (suministroData, thunkAPI) => {

  try {

    const response = await apiClient.post(`suministros`, suministroData);

    const suministro = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetSuministros(suministro.buildingId));// Resetear el estado para refetch de suministros

    return suministro;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const anular_suministro = createAsyncThunk('suministro/anular_suministro', async ({ idSuministro, usuarioAnulacion }, thunkAPI) => {
  //console.log("ejecutando thunk anular_suministro con idSuministro: " + idSuministro);

  try {

    const response = await apiClient.patch(`/suministros/${idSuministro}/anular`, { usuarioAnulacion });

    const suministro = response.data;

    // Despachar acción para resetear el estado de fetching
    thunkAPI.dispatch(resetStateGetSuministros(suministro.buildingId));// Resetear el estado para refetch de suministros

    return suministro;

  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});


export const get_suministro = createAsyncThunk('suministro/get_suministro', async (id, thunkAPI) => {
  //console.log("ejecutando thunk get_suministro con id: " + id);

  try {

    //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
    //parametro pasado por path
    const response = await apiClient.get(`/suministros/${id}`);
    //console.log("11 JSON.stringify(response.data) " + JSON.stringify(response.data));
    const suministro = response.data;

    return suministro;
  } catch (error) {
    console.log("error.response.data " + error.response.data);
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
