import { createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from '../../utils/apiClient';

export const get_edificios_x_admin = createAsyncThunk('edificios/get_edificios_x_admin', async (usuario_admin, thunkAPI) => {
    //console.log("ejecutando thunk get_recibos_x_depa con "+codigo_inmobiliario);
    
    try { 
        console.log("ejecutando thunk usuario_admin: "+usuario_admin);
        //const response = await apiClient.get('/recibos?codigo_inmobiliario=${codigo_inmobiliario}');
        const response = await apiClient.get('/edificios',{
            params: {usuario_admin}
        });
        //console.log("11 JSON.stringify(response.data) "+ JSON.stringify(response.data) );
        const edificios = response.data;
            
        return edificios ;
      } catch (error) {
        console.log("error.response.data "+error.response.data );
        return thunkAPI.rejectWithValue(error.response.data);
      }
  });
