import { createSlice } from '@reduxjs/toolkit';
import { get_plantilla_activo, update_plantilla_activo, save_plantilla_activo, anular_plantilla_activo, get_plantillas_activo_x_edificio } from './plantilla_activoThunk';

const plantilla_activoSlice = createSlice({
  name: 'plantilla_activo',
  initialState: {
    plantilla_activo: null,
    plantillas_activo: [],
    plantilla_activoSeleccionada: null,
    status: 'idle',
    error: null,
    status_get_plantillas_activo_x_edificio: 'idle',
    error_get_plantillas_activo_x_edificio: null,
    status_update_plantilla_activo: 'idle',
    error_update_plantilla_activo: null,    
    status_save_plantilla_activo: 'idle',
    error_save_plantilla_activo: null,
    status_anular_plantilla_activo: 'idle',
    error_anular_plantilla_activo: null,
  },
  reducers: {
    setActivoSeleccionada: (state, action) => {
      state.plantilla_activoSeleccionada = action.payload;
      
    },
    clearActivoSeleccionada(state) {
      state.plantilla_activoSeleccionada = null;
      state.plantilla_activo = null;
    },
    resetStateGetPlantillas_activo_x_edificio: () => {
        return {
            status_get_plantillas_activo_x_edificio : 'idle',
        };
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_plantilla_activo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(get_plantilla_activo.fulfilled, (state, action) => {
        state.status = 'succeeded';
        //console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.plantilla_activo = action.payload;

      })
      .addCase(get_plantilla_activo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error = action.payload || 'Failed to fetch building';
      })      

      .addCase(update_plantilla_activo.pending, (state) => {
        state.status_update_plantilla_activo = 'loading';
      })
      .addCase(update_plantilla_activo.fulfilled, (state, action) => {
        state.status_update_plantilla_activo = 'succeeded';        
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.plantilla_activo = action.payload.plantilla_activo;

      })
      .addCase(update_plantilla_activo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_update_plantilla_activo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_update_plantilla_activo = action.payload || 'Failed to fetch building';
      })
      .addCase(save_plantilla_activo.pending, (state) => {
        state.status_save_plantilla_activo = 'loading';
      })
      .addCase(save_plantilla_activo.fulfilled, (state, action) => {
        state.status_save_plantilla_activo = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.plantilla_activo = action.payload.plantilla_activo;

      })
      .addCase(save_plantilla_activo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_save_plantilla_activo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_save_plantilla_activo = action.payload || 'Failed to fetch building';
      })
      .addCase(anular_plantilla_activo.pending, (state) => {
        state.status_save_plantilla_activo = 'loading';
      })
      .addCase(anular_plantilla_activo.fulfilled, (state, action) => {
        state.status_anular_plantilla_activo = 'succeeded';
        //console.log("3 JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("3.1 JSON.stringify(action.payload.user): "+JSON.stringify(action.payload.user));
        state.plantilla_activo = action.payload.plantilla_activo;

      })
      .addCase(anular_plantilla_activo.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_anular_plantilla_activo = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_anular_plantilla_activo = action.payload || 'Failed to fetch building';
      })
      .addCase(get_plantillas_activo_x_edificio.pending, (state) => {
        state.status_get_plantillas_activo_x_edificio = 'loading';
        
      })
      .addCase(get_plantillas_activo_x_edificio.fulfilled, (state, action) => {
        state.status_get_plantillas_activo_x_edificio = 'succeeded';
        console.log("EDIFICIO JSON.stringify(action.payload): " + JSON.stringify(action.payload));
        //console.log("status_get_plantillas_activo_x_edificio FULLDILED JSON.stringify(action.payload): "+JSON.stringify(action.payload));
        state.plantillas_activo = action.payload;

      })
      .addCase(get_plantillas_activo_x_edificio.rejected, (state, action) => {
        //console.log("ejecutando Slice failed rejected ");
        state.status_get_plantillas_activo_x_edificio = 'failed';
        //console.log("error action.error.message " + action.error.message);
        state.error_get_plantillas_activo_x_edificio = action.payload || 'Failed to fetch building';
        state.plantillas_activo =[];
      })
  },
});

export const { setPlantilla_activoSeleccionada, clearPlantilla_activoSeleccionada } = plantilla_activoSlice.actions;
export const selectPlantillas_activo_ById = (state, idActivo) =>
    state.plantilla_activo.plantillas_activo.find((plantilla_activo) => plantilla_activo.id === idActivo);
export const { resetStateGetPlantillas_activo_x_edificio } = plantilla_activoSlice.actions;
export default plantilla_activoSlice.reducer;
